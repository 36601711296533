import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup, NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { Calendar, CalendarOptions, EventInput } from '@fullcalendar/core';
import { DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/core';

import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';

import { RestApiService } from 'src/app/core/services/rest-api.service';
import { DataService } from 'src/app/core/services/data.service';
import { Schedule } from 'src/app/core/models/schedule';

import { Site } from 'src/app/core/models/site';
import { DatePipe } from '@angular/common';
import { SiteEmployee } from 'src/app/core/models/siteEmployee';
import { AssignedEmps } from 'src/app/core/models/assignedEmps';


@Component({
  selector: 'app-site-all-calendar',
  templateUrl: './site-all-calendar.component.html',
  styleUrls: ['./site-all-calendar.component.scss']
})
export class SiteAllCalendarComponent implements OnInit {

  
  // bread crumb items
  breadCrumbItems: Array<{}>;

  @ViewChild('modalShow') modalShow: TemplateRef<any>;
  @ViewChild('editEmployee') editmodalShow: TemplateRef<any>;

  formEditData: FormGroup;
  submitted = false;
  category: any[];
  newEventDate: any;

  EditedEventDate: any;

  editedEventData: any;
  editedEmpId: any;
  editedTimeFrom: any;
  editedTimeTo: any;

  

  // editEvent: any;
  calendarEvents: any[];
  // event form
  formData: FormGroup;
  calendarOptions: CalendarOptions;
  
  testSiteDays : [{
    schedulerName: string,
    from: string,
    to: string,
    firstName: string,
    lastName: string,
    schedulerId: number,
  }]

  assignedEmployees: AssignedEmps[] = []

  sitePositions :Schedule[] = [];

  schedulerDetails: Schedule;

  fetchError = false;
  loading = true;
  displayContent = false;
  displaySiteContent = false;
  errorContent = false;
  siteId: any;
  scheduleId: any;

  retrievedSite: Site ;
  breakRules: any[] = [];
  ScheduleNameErrorMessage ="";
  ScheduleBeginErrorMessage ="";
  ScheduleEndErrorMessage ="";
  ScheduleBreakRuleErrorMessage ="";


  shiftBreakError: any;
  timeFromError: any;
  timeToError: any;
  dailyShiftsError: any;
  daysError: any;
  cycleDaysError: any;
  employeeIDError: any;
  daysMessageError: any;

  editedTimeFromError: any;
  editedTimeToError: any;

  selectedDays = [];

  Employees: SiteEmployee[] = [];

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.siteId = +params.get('siteId'); 
    });

    this.getAllSchedulers();
    this.getAllPositions();
    this.getSitedetails();
    this.getBreakRules();
    this.getSiteEmployees();
  }

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private rest: RestApiService,
    private data: DataService,
  ) {
    const name = Calendar.name;
  }

 


  async getSitedetails(){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/sites/${this.siteId}`
      ).subscribe( 
        (res: any) => {
          
          this.retrievedSite = res;
          this.displaySiteContent = true;
        } ,
       (error : any) => {
        this.displaySiteContent = false;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getAllSchedulers(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/sites/${this.siteId}/schedulers/calendar`
      ).subscribe( 
        (res: any) => {
          // this.fetchError = false;
          this.testSiteDays = res;
          // this.loading = false;
          
          // this.setOptions();
          
          // this.displayContent = true;

        },
        (error: any) => {
          // this.fetchError = true;
          // this.displayContent = false;
          // this.loading = true;
       });

    } catch (error) {
      console.log("error Zones"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  async getAllPositions(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/sites/${this.siteId}/schedulers`
      ).subscribe( 
        (res: any) => {
          this.fetchError = false;
          this.sitePositions = res;
          this.loading = false;
          
          this.setOptions();
          
          this.displayContent = true;
        },
        (error: any) => {
          this.fetchError = true;
          this.displayContent = false;
          this.loading = true;

       });

    } catch (error) {
      console.log("error Zones"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  resources = [];

  setResources() {
    this.sitePositions.forEach(item => {
      const existingResource = this.resources.find(resource => resource.id === item.id);
      if (!existingResource) {
        this.resources.push({ id: item.id, title: item.name });
      }
    });
  }

  rangeFrom: Date;
  rangeTo: Date;
  setRange(f: NgForm) {
    this.rangeFrom = f.value.calendarBeginDate;
    this.rangeTo = f.value.calendarEndDate;
    
    this.setOptions();
  }

  resetRange() {
    this.rangeFrom = null;
    this.rangeTo = null;
    
    this.setOptions();
  }
  

  calendarTest: EventInput[]=[];
  
  eventGuid: number;
  createEventId() {
    this.eventGuid = 0;
      return String(this.eventGuid++);
  }

  // setEmps(){
  //   if(this.testSiteDays){
  //     for (let i = 0; i < this.testSiteDays.length; i++) {
  //       const shiftDay = this.testSiteDays[i];
  //       this.calendarTest.push({
  //         id: this.createEventId(),
  //         title: shiftDay.firstName + ' ' + shiftDay.lastName,
  //         start: shiftDay.from,
  //         end: shiftDay.to,
  //         className: 'bg-warning',
  //       });
  //     }
  //   }
  //   else if (!this.testSiteDays) {
  //     this.calendarTest = []
  //   }
    
  // }

  // setEmps(){
  //   if(this.testSiteDays){
  //     for (let i = 0; i < this.testSiteDays.length; i++) {
  //       const shiftDay = this.testSiteDays[i];
  //       this.calendarTest.push({
  //         id: this.createEventId(),
  //         title: shiftDay.firstName + ' ' + shiftDay.lastName,
  //         start: shiftDay.from,
  //         end: shiftDay.to,
  //         resourceId: shiftDay.schedulerId.toString(),
  //         className: 'eventColor',
  //         johnTest: "Testtttt",
  //       });
  //     }
  //   }
  //   else if (!this.testSiteDays) {
  //     this.calendarTest = []
  //   }
    
  // }

  setAssigned() {
    this.sitePositions.forEach(position => {
      if (position.shifts && position.shifts.shiftDays) {
        position.shifts.shiftDays.forEach(shiftDay => {
          let assignedEmp = {
            // day: shiftDay.day,
            schedulerName: position.name,
            from: shiftDay.day+" "+shiftDay.timeFrom+":00",
            to: shiftDay.day+" "+shiftDay.timeTo+":00",
            firstName: shiftDay.employee.first_name,
            lastName: shiftDay.employee.last_name,
            schedulerId: position.id,
            employeeId: shiftDay.employee.id,
            shiftDay: shiftDay.shiftDayId,
            positionName: position.name,
            timeFrom: shiftDay.timeFrom+":00",
            timeTo: shiftDay.timeTo+":00",
            backgroundColor: shiftDay.backgroundColor,
            textColor: shiftDay.textColor,
          };
    
          this.assignedEmployees.push(assignedEmp);
        });
      }
    });

  }
  

  setEmps(){
    if(this.assignedEmployees){
      for (let i = 0; i < this.assignedEmployees.length; i++) {
        const shiftDay = this.assignedEmployees[i];
        this.calendarTest.push({
          id: this.createEventId(),
          title: shiftDay.firstName + ' ' + shiftDay.lastName,
          start: shiftDay.from,
          end: shiftDay.to,
          resourceId: shiftDay.schedulerId.toString(),
          // className: 'eventColor',
          backgroundColor: shiftDay.backgroundColor,
          textColor: shiftDay.textColor,
          ShiftDayId: shiftDay.shiftDay,
          employeeId: shiftDay.employeeId,
          positionId: shiftDay.schedulerId,
          positionName: shiftDay.positionName,
          timeStart: shiftDay.timeFrom,
          timeEnd: shiftDay.timeTo,
        });
      }
    }
    else if (!this.assignedEmployees) {
      this.calendarTest = []
    }
    
  }

  setOptions(){
    
    this.setResources();
    this.setAssigned();
    this.setEmps();

    this.calendarOptions = {
      plugins: [
        interactionPlugin,
        dayGridPlugin,
        timeGridPlugin,
        listPlugin,
        resourceTimelinePlugin
      ],
      headerToolbar: {
        left: 'today,custom1Week,custom2Week,custom1Months,custom2Months',
        center: 'title',
        right: 'prevYear,prev,next,nextYear'
      },
      views: {
        custom1Week: {
            type: 'resourceTimelineWeek',
            duration: { weeks: 1 },
            slotDuration: {days: 1},
            buttonText: '1 Week'
        },
        custom2Week: {
          type: 'resourceTimelineWeek',
          duration: { weeks: 2 },
          slotDuration: {days: 1},
          buttonText: '2 Weeks'
      },
      custom2Months: {
        type: 'resourceTimelineMonth',
        duration: { months: 2 },
        slotDuration: {days: 1},
        buttonText: '2 Months'
      },
      custom1Months: {
        type: 'resourceTimelineMonth',
        duration: { months: 1 },
        slotDuration: {days: 1},
        buttonText: '1 Month'
      },
      },
      initialView: "custom1Week",
      // initialDate: this.schedulerDetails.beginDate,
      validRange: {
        start: this.rangeFrom, // Set the start date
        end: this.rangeTo,   // Set the end date
      },
      themeSystem: "bootstrap",
      // initialEvents: this.calendarTest,
      resources: this.resources,
      
      events: this.calendarTest,
      slotMinWidth: 130,
      resourceAreaWidth: "25%",
      nowIndicator: true,
      weekends: true,
      editable: false,
      selectable: false,
      selectMirror: true,
      dayMaxEvents: true,
      displayEventTime: true,
      displayEventEnd: true,
      dayHeaderFormat:{ weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true },
      resourceAreaHeaderContent: 'Positions',
      // dateClick: this.openAssignModal.bind(this),
      eventClick: this.slotSelect.bind(this),
      // eventsSet: this.handleEvents.bind(this),
      eventTimeFormat: { // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        meridiem: 'narrow',
        hour12: false
      }
    };

  }

  
  currentEvents: EventApi[] = [];
  
  openSchedulerModal(content: any) {
    this.modalService.open(content,
       { centered: true, 
          size: 'lg', 
          windowClass: 'detailsModal' 
        });
  }

  async getBreakRules(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/schedulers-break-rules`
      ).subscribe( 
        (res: any) => {
          this.fetchError = false;
          this.breakRules = res;
        },
        (error: any) => {
          this.fetchError = true;
       });

    } catch (error) {
      console.log("error Zones"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  async addSchedule(f: NgForm){
    
    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/sites/${this.retrievedSite.id}/schedulers`,

       {
        name: f.value.scheduleName,
        beginDate: f.value.scheduleBeginDate,
        endDate: f.value.scheduleEndDate,
        breakRuleId: f.value.scheduleBreakRule,
        
       }
       
       ).subscribe( 
       (res: any) => {

         f.reset();
         window.location.reload();
       } ,
      (error : any) => {
        error.error.errors.forEach(err => {
          switch (err.field) {
            case 'name':
              this.ScheduleNameErrorMessage = err.message;
              break;

            case 'beginDate':
              this.ScheduleBeginErrorMessage = err.message;
              break;

            case 'endDate':
              this.ScheduleEndErrorMessage = err.message;
              break;

            case 'breakRuleId':
              this.ScheduleBreakRuleErrorMessage = err.message;
              break;
            

            // Add more cases for other fields if needed
          }
        });
      }
    );

   } catch (error) {
     this.data.error(error['message']);
   }
  }

  openShiftModal(content: any) {
    
    this.modalService.open(content,
       { centered: true, 
          size: 'lg', 
          windowClass: 'detailsModal' 
        });

        
  }

  closeShiftModal() {
    this.daysMessageError= '';
    this.modalService.dismissAll();
  }

  
  days: any = [
    
    {
      id: 1,
      name: 'Monday'
    },
    {
      id: 2,
      name: 'Tuesday'
    },
    {
      id: 3,
      name: 'Wednesday'
    },
    {
      id: 4,
      name: 'Thursday'
    },
    {
      id: 5,
      name: 'Friday'
    },
    {
      id: 6,
      name: 'Saturday'
    },
    {
      id: 0,
      name: 'Sunday'
    },
  ];

  toggleDay(day) {
    if (this.isSelected(day)) {
      this.selectedDays = this.selectedDays.filter(id => id !== day.id);
    } else {
      this.selectedDays.push(day.id);
    }

  }

  isSelected(day) {
    return this.selectedDays.includes(day.id);
  }

  toggleAllDays(event) {
    if (event.target.checked) {
      // If "All Days" checkbox is checked, add all days to selectedDays
      this.selectedDays = this.days.map(day => day.id);
    } else {
      // If "All Days" checkbox is unchecked, remove all days from selectedDays
      this.selectedDays = [];
    }

    this.days.forEach(day => {
      const checkbox = document.getElementById(day.id.toString()) as HTMLInputElement;
      checkbox.checked = event.target.checked;
    });

  }


  async addShiftDetails(f: NgForm){
    
    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/sites/${this.siteId}/schedulers/${f.value.shiftPositionId}/shifts`,

       {
        breakInMinutes: f.value.shiftBreak,
        timeFrom: f.value.shiftTimeFrom,
        timeTo: f.value.shiftTimeTo,
        dailyShifts: f.value.dailyShifts,
        cycleDays: parseInt(f.value.shiftCycleDays, 10),
        days: this.selectedDays,
        isTemplate: false,

       }
       
       ).subscribe( 
       (res: any) => {

         f.reset();
         window.location.reload();
       } ,
      (error : any) => {
        error.error.errors.forEach(err => {
          switch (err.field) {
            case 'breakInMinutes':
              this.shiftBreakError = err.message;
              break;

            case 'timeFrom':
              this.timeFromError = err.message;
              break;

            case 'timeTo':
              this.timeToError = err.message;
              break;

            case 'dailyShifts':
              this.dailyShiftsError = err.message;
              break;

              case 'days':
              this.daysError = err.message;
              break;

              case 'cycleDays':
              this.cycleDaysError = err.message;
              break;
            

            // Add more cases for other fields if needed
          }
        });
      }
    );

   } catch (error) {
     this.data.error(error['message']);
   }
  }

  // openAssignModal(event?: any) {
  //   this.newEventDate = event;
  //   this.showShiftMessage = false;
  //   console.log(this.newEventDate);
  //   console.log(this.newEventDate.resource._resource.id);
  //   this.modalService.open(this.modalShow,
  //     { centered: true, 
  //        size: 'lg', 
  //        windowClass: 'detailsModal' 
  //      });
  // }

  AssignedShiftDays: number[] = [];
  showAssignBtn: boolean = false;

  slotSelect(event?: any) {
    this.editedEventData = event;
    if(!this.editedEventData.el.classList.contains('activeDay')){
      this.editedEventData.el.classList.add('activeDay');
    }else{
      this.editedEventData.el.classList.remove('activeDay');
    }
    
    const assignShift = this.editedEventData.event._def.extendedProps.ShiftDayId;
    this.toggleShift(assignShift);
    this.updateShowAssignBtn();
  }

  updateShowAssignBtn() {
    this.showAssignBtn = this.AssignedShiftDays.length > 0;
  }

  toggleShift(day:number) {
    if (this.isSelectedShift(day)) {
      this.AssignedShiftDays = this.AssignedShiftDays.filter(shift => shift !== day);
    } else {
      this.AssignedShiftDays.push(day);
    }

    console.log(this.AssignedShiftDays);
  }

  isSelectedShift(day:number) {
    return this.AssignedShiftDays.includes(day);
  }

  async getSiteEmployees(){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/sites/${this.siteId}/employees`
      ).subscribe( 
        (res: any) => {
          
          this.Employees = res;
          
        } ,
       (error : any) => {
        
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  showShiftMessage: boolean = false;
  

  // async AssignEmployee(f: NgForm) {

  //   const datePipe = new DatePipe('en-US');
  //   this.newEventDate.date = datePipe.transform(this.newEventDate.date, 'yyyy-MM-dd');
  
  //   try {

  //     await this.rest.post(
  //      `${this.data.getBaseUrl()}api/v1/sites/${this.siteId}/schedulers/${this.newEventDate.resource._resource.id}/shifts/update`,
  
  //      {
  //       day: this.newEventDate.date,
  //       timeFrom: f.value.empTimeFrom,
  //       timeTo: f.value.empTimeTo,
  //       employeeId: f.value.shiftEmpId,
  //       isTemplate: false,
  //      }
       
  //      ).subscribe( 
  //      (res: any) => {
  
  //        f.reset();
  //        window.location.reload();
  //      } ,
  //     (error : any) => {
  //       console.log(error);
  //       if (error.error.errors){
  //         error.error.errors.forEach(err => {
  //           switch (err.field) {
  //             case 'employeeId':
  //               this.employeeIDError = err.message;
  //               break;
  //             case 'day':
  //               this.daysMessageError = err.message;
  //               break;
  //               case 'timeFrom':
  //               this.timeFromError = err.message;
  //               break;
  //             case 'timeTo':
  //               this.timeToError = err.message;
  //               break;
  
  //             // Add more cases for other fields if needed
  //           }
  //         });
  //       }
  //       else{
  //         if (error.error.code == "E_SCHEDULER_SHIFT_NOT_FOUND"){
  //           this.showShiftMessage = true;
  //         }
  //         else{
  //           this.showShiftMessage = false;
  //         }
  //       }
        

        
  //     }
  //   );
  
  //  } catch (error) {
  //    this.data.error(error['message']);
  //    console.log(error);
  //  }
  // }


  async AssignEmployee(employeeId: number) {
    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/sites/${this.siteId}/schedulers/shifts/update/multiple`,
  
       {
        shiftDaysId: this.AssignedShiftDays,
        employeeId: employeeId,
        isTemplate: false,
       }
       
       ).subscribe( 
       (res: any) => {

         window.location.reload();
       } ,
      (error : any) => {
        console.log(error);
        if (error.error.errors){
          error.error.errors.forEach(err => {
            switch (err.field) {
              case 'employeeId':
                this.employeeIDError = err.message;
                break;
              case 'day':
                this.daysMessageError = err.message;
                break;
                case 'timeFrom':
                this.timeFromError = err.message;
                break;
              case 'timeTo':
                this.timeToError = err.message;
                break;
  
              // Add more cases for other fields if needed
            }
          });
        }
        else{
          if (error.error.code == "E_SCHEDULER_SHIFT_NOT_FOUND"){
            this.showShiftMessage = true;
          }
          else{
            this.showShiftMessage = false;
          }
        }
        

        
      }
    );
  
   } catch (error) {
     this.data.error(error['message']);
     console.log(error);
   }
  }

  async EditEmployee(f: NgForm) {

    const datePipe = new DatePipe('en-US');
    this.EditedEventDate = datePipe.transform(this.editedEventData.event.start, 'yyyy-MM-dd');
    
    const timeFromPipe = new Date(`${this.EditedEventDate+`T`+f.value.empTimeFrom}`);
    const timeToPipe = new Date(`${this.EditedEventDate+`T`+f.value.empTimeTo}`);
    
    const timeFrom = datePipe.transform(timeFromPipe, 'HH:mm');
    const timeTo = datePipe.transform(timeToPipe, 'HH:mm');

    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/sites/${this.siteId}/schedulers/${this.editedEventData.event._def.extendedProps.positionId}/shifts/update/${this.editedEventData.event._def.extendedProps.ShiftDayId}`,
  
       {
        day: this.EditedEventDate,
        timeFrom: timeFrom,
        timeTo: timeTo,
        employeeId: f.value.shiftEmpId,
        isTemplate: false,
       }
       
       ).subscribe( 
       (res: any) => {
  
         f.reset();
         window.location.reload();
       } ,
      (error : any) => {
        console.log(error);
        if (error.error.errors){
          error.error.errors.forEach(err => {
            switch (err.field) {
              case 'employeeId':
                this.employeeIDError = err.message;
                break;
              case 'day':
                this.daysMessageError = err.message;
                break;

                case 'timeFrom':
                this.editedTimeFromError = err.message;
                break;
              case 'timeTo':
                this.editedTimeToError = err.message;
                break;
  
              // Add more cases for other fields if needed
            }
          });
        }
        else{
          if (error.error.code == "E_SCHEDULER_SHIFT_NOT_FOUND"){
            this.showShiftMessage = true;
          }
          else{
            this.showShiftMessage = false;
          }
        }
        

        
      }
    );
  
   } catch (error) {
     this.data.error(error['message']);
     console.log(error);
   }
  }

  onViewTemplate(siteId: string) {
    this.router.navigate([`site`,siteId,`site_template`]);
  }
}
