<div class="container-fluid">
    <div class="row">
      <div class="col-12 mb-3 alignContent-spaceBetween">
        <h2>Employees</h2>
        <button class="btn submitBtn" (click)="navigateToAddEmp()"><i class="fas fa-plus"></i> Add Employee</button>
      </div>

      <div class="col-12" *ngIf="fetchError">
        <ngb-alert type="danger"  [dismissible]="false" class="text-center">
          Something Went Wrong
        </ngb-alert>
      </div>

      <!-- <div class="col-12"  *ngIf="userApproved">
        <ngb-alert type="success"  [dismissible]="false" class="text-center fadeAlert">{{successMessage}}</ngb-alert>
      </div>

      <div class="col-12"  *ngIf="userDeleted">
        <ngb-alert type="success"  [dismissible]="false" class="text-center fadeAlert">{{successMessage}}</ngb-alert>
      </div> -->

      <app-loader [loading]="loading"></app-loader>
      
      <div *ngIf="!fetchError && !loading" class="col-12">
        <div class="card">
          <div class="card-body">

            <ul class="nav nav-pills tabs_border justify-content-center" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="pills-active-tab" data-bs-toggle="pill" data-bs-target="#pills-active" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Active</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-inActive-tab" data-bs-toggle="pill" data-bs-target="#pills-inActive" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Not Active</button>
              </li>
          </ul>

          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-active" role="tabpanel" aria-labelledby="pills-active-tab">
              <div  *ngIf="displayTableActive">
                <div class="table-responsive">
                  <table datatable [dtOptions]="dtOptions" id="datatable" class="table align-middle table-nowrap table-hover">
                    <thead class="table-light">
                      <tr>
                        <th scope="col">UID</th>
                        <th scope="col">Name</th>
                        <th scope="col">Title</th>
                        <th scope="col">Mobile</th>
                        <th scope="col">Hiring Status</th>
                        <th scope="col">View </th>
                        <th scope="col">Edit </th>
                        <th scope="col">Terminate</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let Employee of activeEmployees">
                        
                        <td>{{Employee.internalId}}</td>
                        <td>{{Employee.firstName}} {{Employee.lastName}}</td>
                        <td>{{Employee.role.name}}</td>
                        <td>{{Employee.mobile}}</td>
                        <!-- <td>{{Employee.hiringStatus}}</td> -->
                        <td>
                          <span *ngIf="Employee.hiringStatus == 'HIRED'" class=""><i class="fas fa-check-circle trueCheck"></i> Hired</span>
                          <span *ngIf="Employee.hiringStatus == 'NOT_HIRED'" class=""><i class="fas fa-times-circle falseCheck"></i> Not Hired</span>
                          <span *ngIf="Employee.hiringStatus == 'PENDING_HIRING'" class=""><i class="fas fa-minus-circle warningCheck"></i> Pending Hiring</span>
                      </td>
                        <!-- <td>
                            <span *ngIf="Employee.isActive == true" class=""><i class="fas fa-check-square trueCheck"></i> Active</span>
                            <span *ngIf="Employee.isActive == false" class=""><i class="fas fa-times-circle falseCheck"></i> Not Active</span>
                        </td> -->
                        <td>
                          <button class="btn" (click)="viewEmp(Employee)"><i class="fas fa-eye"></i></button>
                        </td>
                        <td>
                          <button class="btn" (click)="openEditModal(editEmployeeModal, Employee)"><i class="far fa-edit"></i></button>
                        </td>
                        <td>
                          <button *ngIf="Employee.termination == null; else terminatedText " class="btn terminate"  (click)="openModalMd(terminateModal, Employee)"><i class="fas fa-times-circle"></i> Terminate</button>
    
                          <ng-template #terminatedText> <!-- Template for when myVar is not null -->
                            <button  class="btn unTerminate" (click)="unTerminateEmployee(Employee.userId)"><i class="fas fa-check-circle"></i> UnTerminate</button>
                          </ng-template>
                        </td>
                        
                      </tr>
                    </tbody>
                  </table>
                </div>
    
                <div>
                  <app-pagination
                    [total]="PaginationObjActive.total"
                    [perPage]="PaginationObjActive.perPage"
                    [currentPage]="PaginationObjActive.currentPage"
                    [firstPage]="PaginationObjActive.firstPage"
                    [lastPage]="PaginationObjActive.lastPage"
                    [nextPage]="PaginationObjActive.nextPage"
                    [previousPage]="PaginationObjActive.previousPage"
                    (pageChange)="onPageChangeActive($event)"
                  ></app-pagination>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="pills-inActive" role="tabpanel" aria-labelledby="pills-inActive-tab">
              <div  *ngIf="displayTableInActive">
                <div class="table-responsive">
                  <table datatable [dtOptions]="dtOptions" id="datatable" class="table align-middle table-nowrap table-hover">
                    <thead class="table-light">
                      <tr>
                        <th scope="col">UID</th>
                        <th scope="col">Name</th>
                        <th scope="col">Title</th>
                        <th scope="col">Mobile</th>
                        <th scope="col">Hiring Status</th>
                        <th scope="col">View </th>
                        <th scope="col">Edit </th>
                        <th scope="col">Terminate</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let Employee of inActiveEmployees">
                        
                        <td>{{Employee.internalId}}</td>
                        <td>{{Employee.firstName}} {{Employee.lastName}}</td>
                        <td>{{Employee.role.name}}</td>
                        <td>{{Employee.mobile}}</td>
                        <!-- <td>{{Employee.hiringStatus}}</td> -->
                        <td>
                          <span *ngIf="Employee.hiringStatus == 'HIRED'" class=""><i class="fas fa-check-circle trueCheck"></i> Hired</span>
                          <span *ngIf="Employee.hiringStatus == 'NOT_HIRED'" class=""><i class="fas fa-times-circle falseCheck"></i> Not Hired</span>
                          <span *ngIf="Employee.hiringStatus == 'PENDING_HIRING'" class=""><i class="fas fa-minus-circle warningCheck"></i> Pending Hiring</span>
                        </td>
                        <!-- <td>
                            <span *ngIf="Employee.isActive == true" class=""><i class="fas fa-check-square trueCheck"></i> Active</span>
                            <span *ngIf="Employee.isActive == false" class=""><i class="fas fa-times-circle falseCheck"></i> Not Active</span>
                        </td> -->
                        <td>
                          <button class="btn" (click)="viewEmp(Employee)"><i class="fas fa-eye"></i></button>
                        </td>
                        <td>
                          <button class="btn" (click)="openEditModal(editEmployeeModal, Employee)"><i class="far fa-edit"></i></button>
                        </td>
                        <td>
                          <button *ngIf="Employee.termination == null; else terminatedText " class="btn terminate"  (click)="openModalMd(terminateModal, Employee)"><i class="fas fa-times-circle"></i> Terminate</button>
    
                          <ng-template #terminatedText> <!-- Template for when myVar is not null -->
                            <button  class="btn unTerminate"  (click)="unTerminateEmployee(Employee.userId)"><i class="fas fa-check-circle"></i>  UnTerminate</button>
                          </ng-template>
                        </td>
                        
                      </tr>
                    </tbody>
                  </table>
                </div>
    
                <div>
                  <app-pagination
                    [total]="PaginationObjInActive.total"
                    [perPage]="PaginationObjInActive.perPage"
                    [currentPage]="PaginationObjInActive.currentPage"
                    [firstPage]="PaginationObjInActive.firstPage"
                    [lastPage]="PaginationObjInActive.lastPage"
                    [nextPage]="PaginationObjInActive.nextPage"
                    [previousPage]="PaginationObjInActive.previousPage"
                    (pageChange)="onPageChangeInActive($event)"
                  ></app-pagination>
                </div>
              </div>
            </div>
        </div>

          
            
            
          </div>
        </div>
      </div>

    </div>
</div>


<ng-template #terminateModal role="document" let-modal >
  <div class="modal-header">
    <h5 class="modal-title mt-0">Terminate Employee</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="closeTerminateModal()"></button>
  </div>
  <div class="modal-body">
    <form #f="ngForm" >
      <div class="row">
        <div class="form-group col-12">
          <label for="">Termination Reason</label>
          <input type="text" class="form-control" name="terminateReason" ngModel required #terminateReason="ngModel">
          <span class="text-danger" *ngIf="!terminateReason.valid && terminateReason.touched">Please Enter A Valid Answer</span>
        </div>

        <div class="form-group col-12">
          <!-- <input type="checkbox" id="rehire" name="rehire" ngModel #rehire="ngModel"> -->
          <label for="rehire">Rehireable?</label>
          <select type="text" class="form-control" id="rehire" name="rehire" ngModel #rehire="ngModel">
            <option value="">Select Answer</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
        </select>
        </div>
      </div>
      <div class="text-center">
        <button type="submit" [disabled]="!f.valid" class="btn terminateButton" (click)="terminateEmployee(f)">Terminate</button>
        <button class="btn cancelBtn" (click)="closeTerminateModal()">Cancel</button>
      </div>
    </form>
  </div>
  
</ng-template>
<!-- 
<ng-template #unTerminateModal role="document" let-modal >
  <div class="modal-header">
    <h5 class="modal-title mt-0">Unterminate Employee</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="closeTerminateModal()"></button>
  </div>
  <div class="modal-body">
    <form #f="ngForm" >
      <div class="row">
        <div class="form-group col-12">
          <label for="">Termination Reason</label>
          <input type="text" class="form-control" name="terminateReason" ngModel required #terminateReason="ngModel">
          <span class="text-danger" *ngIf="!terminateReason.valid && terminateReason.touched">Please Enter A Valid Answer</span>
        </div>
      </div>
      <div class="text-center">
        <button type="submit" [disabled]="!f.valid" class="btn terminateButton" (click)="unTerminateEmployee(f)">Unterminate</button>
      </div>
    </form>
  </div>
  
</ng-template> -->
<!-- Edit Employee Modal -->
<ng-template #editEmployeeModal role="document" let-modal >
  <div class="modal-header">
    <h5 class="modal-title mt-0">Edit Employee</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="closeEditModal()"></button>
  </div>
  <div class="modal-body">
    <form #f="ngForm" >
      <div class="row section_style">
        <div class="col-12">
            <h4>General Info</h4>
        </div>
        <div class="form-group col-12 col-md-4">
          <label for="">First Name</label>
          <input type="text" class="form-control" name="empFirstName" ngModel [(ngModel)]="selectedEditable.firstName" required #empFirstName="ngModel">
          <span class="text-danger" *ngIf="!empFirstName.valid && empFirstName.touched">Please Enter A Valid Answer</span>
          <p class="text-danger m-0" *ngIf="fNameErrorMessage"><strong>{{fNameErrorMessage}}</strong></p>
        </div>
        <div class="form-group col-12 col-md-4">
            <label for="">Last Name</label>
            <input type="text" class="form-control" name="empLastName" ngModel [(ngModel)]="selectedEditable.lastName" required #empLastName="ngModel">
            <span class="text-danger" *ngIf="!empLastName.valid && empLastName.touched">Please Enter A Valid Answer</span>
            <p class="text-danger m-0" *ngIf="lNameErrorMessage"><strong>{{lNameErrorMessage}}</strong></p>
        </div>
        <div class="form-group col-12 col-md-4">
            <label for="">Username</label>
            <input type="text" class="form-control" name="empUserName" ngModel [(ngModel)]="selectedEditable.username" required #empUserName="ngModel" >
            <span class="text-danger" *ngIf="!empUserName.valid && empUserName.touched">Please Enter A Valid Answer</span>
            <p class="text-danger m-0" *ngIf="usernameErrorMessage"><strong>{{usernameErrorMessage}}</strong></p>
        </div>
        <div class="form-group col-12 col-md-4">
            <label for="">Internal ID</label>
            <input type="text" class="form-control" name="empInternalId" ngModel [(ngModel)]="selectedEditable.internalId" required #empInternalId="ngModel" >
            <span class="text-danger" *ngIf="!empInternalId.valid && empInternalId.touched">Please Enter A Valid Answer</span>
            <p class="text-danger m-0" *ngIf="internalIdErrorMessage"><strong>{{internalIdErrorMessage}}</strong></p>
        </div>
        <div class="form-group col-12 col-md-4">
            <label for="">Email</label>
            <input type="email" class="form-control" name="empEmail" ngModel [(ngModel)]="selectedEditable.email" required #empEmail="ngModel">
            <span class="text-danger" *ngIf="!empEmail.valid && empEmail.touched">Please Enter A Valid Answer</span>
            <p class="text-danger m-0" *ngIf="emailErrorMessage"><strong>{{emailErrorMessage}}</strong></p>
        </div>
        <div class="form-group col-12 col-md-4">
            <label for="">Mobile</label>
            <input type="text" class="form-control" name="empMobile" ngModel [(ngModel)]="selectedEditable.mobile" required #empMobile="ngModel">
            <span class="text-danger" *ngIf="!empMobile.valid && empMobile.touched">Please Enter A Valid Answer</span>
            <p class="text-danger m-0" *ngIf="mobileErrorMessage"><strong>{{mobileErrorMessage}}</strong></p>
        </div>
        <div class="form-group col-12 col-md-4">
            <label for="">Gender</label>
            <select type="text" class="form-control" name="empGender" ngModel [(ngModel)]="selectedEditable.gender" required #empGender="ngModel">
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
            </select>
            <span class="text-danger" *ngIf="!empGender.valid && empGender.touched">Please Choose A Valid Answer</span>
            <p class="text-danger m-0" *ngIf="genderErrorMessage"><strong>{{genderErrorMessage}}</strong></p>
        </div>
        <div class="form-group col-12 col-md-4">
            <label for="">Birth Date</label>
            <input type="date" class="form-control" name="empBirthdate" ngModel [(ngModel)]="selectedEditable.birthdate" required #empBirthdate="ngModel">
            <span class="text-danger" *ngIf="!empBirthdate.valid && empBirthdate.touched">Please Enter A Valid Answer</span>
            <p class="text-danger m-0" *ngIf="birthdateErrorMessage"><strong>{{birthdateErrorMessage}}</strong></p>
        </div>
        <div class="form-group col-12 col-md-4">
            <label for="">Employment Date</label>
            <input type="date" class="form-control" name="empEmploymentdate" [(ngModel)]="selectedEditable.employmentDate" ngModel required #empEmploymentdate="ngModel">
            <span class="text-danger" *ngIf="!empEmploymentdate.valid && empEmploymentdate.touched">Please Enter A Valid Answer</span>
            <p class="text-danger m-0" *ngIf="employmentDateErrorMessage"><strong>{{employmentDateErrorMessage}}</strong></p>
        </div>

        <div class="form-group col-12 col-md-4">
            <label for="">Pay Rate</label>
            <input type="number" class="form-control" name="empPayRate" ngModel [(ngModel)]="selectedEditable.payrate" required #empPayRate="ngModel">
            <span class="text-danger" *ngIf="!empPayRate.valid && empPayRate.touched">Please Enter A Valid Answer</span>
            <p class="text-danger m-0" *ngIf="payRateErrorMessage"><strong>{{payRateErrorMessage}}</strong></p>
        </div>

        <div class="form-group col-12 col-md-4">
          <label for="">Pay Type</label>
          <select type="text" class="form-control" [(ngModel)]="selectedEditable.payType" name="empPayType" ngModel required #empPayType="ngModel">
            <option value="">Select Type</option>
            <option value="HOURLY">Hourly</option>
            <option value="SALARY">Salary</option>
          </select>
          <span class="text-danger" *ngIf="!empPayType.valid && empPayType.touched">Please Enter A Valid Answer</span>
      </div>
        
      </div>

      <div class="row section_style">
        <div class="form-group col-12 col-md-4">
            <label for="">Role</label>
            <select type="text" class="form-control" name="empRole" ngModel [(ngModel)]="selectedEditable.role.id" required #empRole="ngModel">
                <option value="">Select Role</option>
                <option *ngFor="let Role of Roles" [value]="Role.id">{{Role.name}}</option>
            </select>
            <span class="text-danger" *ngIf="!empRole.valid && empRole.touched">Please Choose A Valid Answer</span>
        </div>
        <div class="form-group col-12 col-md-4">
            <label for="">Zone</label>
            <select type="text" class="form-control" name="empZone" ngModel [(ngModel)]="selectedEditable.zone.id" #empZone="ngModel">
                <option value="">Select Zone</option>
                <option *ngFor="let Zone of Zones" [value]="Zone.id">{{Zone.name}}</option>
            </select>
            <!-- <span class="text-danger" *ngIf="!empZone.valid && empZone.touched">Please Choose A Valid Answer</span> -->
        </div>
        <div class="form-group col-12 col-md-4">
            <label for="">Status</label>
            <select type="text" class="form-control" name="empStatus" ngModel [(ngModel)]="selectedEditable.isActive" required #empStatus="ngModel">
                <option value="">Select Status</option>
                <option value="1">Active</option>
                <option value="0">Not Active</option>
            </select>
            <span class="text-danger" *ngIf="!empStatus.valid && empStatus.touched">Please Choose A Valid Answer</span>
            <p class="text-danger m-0" *ngIf="activeErrorMessage"><strong>{{activeErrorMessage}}</strong></p>
        </div>
        <div class="form-group col-12 col-md-4">
          <label for="">Hiring Status</label>
          <select type="text" class="form-control" name="empHiring" ngModel [(ngModel)]="selectedEditable.hiringStatus" required #empHiring="ngModel">
              <option value="">Select Hiring Status</option>
              <option value="HIRED">Hired</option>
              <option value="PENDING_HIRING">Pending Hiring</option>
              <option value="NOT_HIRED">Not Hired</option>
          </select>
          <span class="text-danger" *ngIf="!empHiring.valid && empHiring.touched">Please Choose A Valid Answer</span>
          <p class="text-danger m-0" *ngIf="hiringErrorMessage"><strong>{{hiringErrorMessage}}</strong></p>
      </div>
      </div>

      <div class="row section_style">
        <div class="col-12">
            <h4>Address</h4>
        </div>
        <div class="form-group col-12 col-md-6">
            <label for="">Street</label>
            <input type="text" class="form-control" name="empAdressStreet" ngModel [(ngModel)]="selectedEditable.address.address" required #empAdressStreet="ngModel">
            <span class="text-danger" *ngIf="!empAdressStreet.valid && empAdressStreet.touched">Please Enter A Valid Answer</span>
            <p class="text-danger m-0" *ngIf="addressErrorMessage"><strong>{{addressErrorMessage}}</strong></p>
        </div>
        <div class="form-group col-12 col-md-6">
            <label for="">Country</label>
            <select type="text" class="form-control" name="empCountry" ngModel [(ngModel)]="selectedEditable.address.country.id" required #empCountry="ngModel" (ngModelChange)="onChangeCountry($event)">
                <option  value="">Select Country</option>
                <option *ngFor="let Country of Countries" [value]="Country.id">{{Country.name}}</option>
            </select>
            <span class="text-danger" *ngIf="!empCountry.valid && empCountry.touched">Please Choose A Valid Answer</span>
            <p class="text-danger m-0" *ngIf="countryErrorMessage"><strong>{{countryErrorMessage}}</strong></p>
        </div>
        <div class="form-group col-12 col-md-6">
            <label for="">State</label>
            <select type="text" class="form-control" name="empCity" ngModel [(ngModel)]="selectedEditable.address.city.id" required #empCity="ngModel"  (ngModelChange)="onChangeCity($event)">
                <option  value="">Select State</option>
                <option *ngFor="let City of Cities" [value]="City.id">{{City.name}}</option>
            </select>
            <span class="text-danger" *ngIf="!empCity.valid && empCity.touched">Please Choose A Valid Answer</span>
            <p class="text-danger m-0" *ngIf="cityErrorMessage"><strong>{{cityErrorMessage}}</strong></p>
        </div>
        <div class="form-group col-12 col-md-6">
            <label for="">City</label>
            <select type="text" class="form-control" name="empArea" ngModel [(ngModel)]="selectedEditable.address.area.id" required #empArea="ngModel" >
                <option  value="">Select City</option>
                <option *ngFor="let Area of Areas" [value]="Area.id">{{Area.name}}</option>
            </select>
            <span class="text-danger" *ngIf="!empArea.valid && empArea.touched">Please Choose A Valid Answer</span>
            <p class="text-danger m-0" *ngIf="areaErrorMessage"><strong>{{areaErrorMessage}}</strong></p>
        </div>
        <div class="form-group col-12 col-md-6">
          <label for="">Zip Code</label>
          <input type="text" class="form-control" name="empZipCode" ngModel [(ngModel)]="selectedEditable.address.zipcode" required #empZipCode="ngModel" placeholder="Enter Zip Code">
          <span class="text-danger" *ngIf="!empZipCode.valid && empZipCode.touched">Please Choose A Valid Answer</span>
          <p class="text-danger m-0" *ngIf="zipCodeErrorMessage"><strong>{{zipCodeErrorMessage}}</strong></p>
      </div>
      </div>

      <div class="row section_style">
        <div class="col-12">
            <h4>Passwords</h4>
        </div>
        <div class="form-group col-12 col-md-6">
            <label for="">Enter Your Password</label>
            <input type="password" class="form-control" name="empPassword" [(ngModel)]="formPassword" #empPassword="ngModel"  (ngModelChange)="onChangePassword()">
            <span class="text-danger" *ngIf="!empPassword.valid && empPassword.touched">Please Enter A Valid Answer</span>
            <p class="text-danger m-0" *ngIf="passwordErrorMessage"><strong>{{passwordErrorMessage}}</strong></p>
        </div>
        <div class="form-group col-12 col-md-6">
            <label for="">Confirm Password</label>
            <input type="password" class="form-control" name="confirmEmpPassword" [(ngModel)]="formConfirmPassword" #confirmEmpPassword="ngModel" (ngModelChange)="onChangeConfirmPassword()">
            <span class="text-danger" *ngIf="!confirmEmpPassword.valid && confirmEmpPassword.touched || showMatchMessage">Passwords don't match</span>
            <p class="text-danger m-0" *ngIf="passwordConfirmErrorMessage"><strong>{{passwordConfirmErrorMessage}}</strong></p>
        </div>
      </div>

      <div class="row section_style">
        <div class="col-12">
            <h4>Licenses</h4>
        </div>
        <div class="form-group col-12 col-md-3">
            <label for="">Choose License</label>
            <select class="form-control" name="selectedLicenseId" [(ngModel)]="selectedLicenseId"  (change)="updateForm()">
                <option selected value="0">Select License</option>
                <option *ngFor="let license of licenses" [value]="license.id">{{ license.name }}</option>
            </select>
            <p class="text-danger m-0" *ngIf="licensesErrorMessage"><strong>{{licensesErrorMessage}}</strong></p>
        </div>

        <div class="form-group col-12" *ngIf="selectedLicense">
            <div class="row">
                <div *ngIf="selectedLicense.hasIssueDate === 1" class="col-12 col-md-3">
                    <label for="">Issue Date</label>
                    <input class="form-control" name="selectedLicenseIssueDate" required type="date" placeholder="Issue Date" [(ngModel)]="selectedLicenseIssueDate">
                </div>
                <div *ngIf="selectedLicense.hasExpiryDate === 1" class="col-12 col-md-3">
                    <label for="">Expiry Date</label>
                    <input class="form-control" name="selectedLicenseExpiryDate" required type="date" placeholder="Expiry Date" [(ngModel)]="selectedLicenseExpiryDate">

                </div>
                <div *ngIf="selectedLicense.hasLicenseNumber === 1" class="col-12 col-md-3">
                    <label for="">License Number</label>
                    <input class="form-control" name="selectedLicenseNumber" required type="text" placeholder="License Number" [(ngModel)]="selectedLicenseNumber">

                </div>
                <div class="col-12 col-md-3 btn_padding">
                    <button
                    [disabled]="!areAllInputsFilled()"
                     type="button" class="btn submitBtn w-100" (click)="submitForm()"><i class="fas fa-plus"></i> Add Licence</button>
                </div>
            </div>
            
          </div>

          <div class="col-12" *ngIf="finalLicenses">
            <hr>
            <div class="table-responsive">
                <table  id="datatable" class="datatable table align-middle table-nowrap table-hover">
                  <thead class="table-light">
                    <tr>
  
                      <th scope="col">License Name</th>
                      <th scope="col">Issue Date</th>
                      <th scope="col">Expiry Date</th>
                      <th scope="col">License Number</th>
                      <th scope="col">Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let License of finalLicenses; let i = index">
  
                      <td>{{License.name}}</td>
                      <td>{{License.issueDate}}</td>
                      <td>{{License.expirationDate}}</td>
                      <td>{{License.licenseNumber}}</td>
                      <td>
                        <button type="button" class="btn removeBtn" (click)="removeFinalLicense(i)"><i class="fas fa-times-circle"></i></button>
                      </td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>

          </div>
        
      </div>

      <div class="row section_style">
        <div class="col-12">
            <h4>Uniforms</h4>
        </div>
        <div class="form-group col-12 col-md-3">
            <label for="">Choose Uniform</label>
            <select class="form-control" name="selectedUniformId" ngModel [(ngModel)]="selectedUniformId"  (change)="updateUniForm()">
                <option selected value="0">Select Uniform</option>
                <option *ngFor="let uniform of uniforms" [value]="uniform.id">{{ uniform.name }}</option>
            </select>
            <p class="text-danger m-0" *ngIf="uniformErrorMessage"><strong>{{uniformErrorMessage}}</strong></p>
        </div>

        <div class="form-group col-12" *ngIf="selectedUniform">
            <div class="row">
                <div class="col-12 col-md-3">
                    <label for="">Receiving Date</label>
                    <input class="form-control" name="selectedUniformDate" ngModel required type="date" placeholder="Receiving Date" [(ngModel)]="selectedUniformDate">
                </div>
                <div class="col-12 col-md-3">
                    <label for="">Quantity</label>
                    <input class="form-control" name="selectedUniformQty" ngModel required type="number" placeholder="Uniform Quantity" [(ngModel)]="selectedUniformQty">

                </div>
                <div class="col-12 col-md-3 btn_padding">
                    <button
                    [disabled]="!areAllInputsFilled_unifrom()"
                     type="button" class="btn submitBtn w-100" (click)="submitUniForm()"><i class="fas fa-plus"></i> Add Uniform</button>
                </div>
            </div>
            
          </div>

          <div class="col-12" *ngIf="finalUniforms">
            <hr>
            <div class="table-responsive">
                <table  id="datatable" class="datatable table align-middle table-nowrap table-hover">
                  <thead class="table-light">
                    <tr>
  
                      <th scope="col">Uniform Name</th>
                      <th scope="col">Receiving Date</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let uniform of finalUniforms; let i = index">
  
                      <td>{{uniform.name}}</td>
                      <td>{{uniform.receivingDate}}</td>
                      <td>{{uniform.quantity}}</td>
                      <td>
                        <button type="button" class="btn removeBtn" (click)="removeFinalUniform(i)"><i class="fas fa-times-circle"></i></button>
                      </td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>

          </div>
        
      </div>

      <hr>

      <div class="row">
        <div class="form-group col-12 text-center">
            <button [disabled]="!f.valid"  (click)="updateEmployee(f)" class="btn  submitBtn" ><i class="fas fa-check"></i> Save Employee</button>
          </div>
      </div>
      
    </form>
  </div>
  
</ng-template>