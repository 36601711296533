<div class="container-fluid">

    <app-loader [loading]="loading"></app-loader>

    
    <div class="row" *ngIf="showContent">
        <div class="col-12">
            <div class="row">

                <div class="col-12 text-center mt-3 mb-3" >
                    <button
                        class="btn submitBtn"
                        (click)="saveOptimizeRunsheet()"
                    >Save This Sequence</button>
                </div>
 
                <div class="col-12 col-md-6 mb-3">
                    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                        <div class="example-box" *ngFor="let route of runOptimization.route" cdkDrag>
                          <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                          

                          <p>{{route.location}}</p>
                          <p>{{route.timeFrame}}</p>
                          <p>{{route.locationLabel}}</p>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6 mb-3">
                    <div #mapElement style="height: 400px; width: 100%"></div>
                </div>
                
            </div>
        </div>
    </div>



</div>