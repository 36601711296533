import { NgModule } from '@angular/core';
import { LayoutsModule } from 'src/app/layouts/layouts.module';

@NgModule({
  declarations: [
    
  ],
  exports: [
    LayoutsModule
  ]
})
export class MainPageModule { }