<app-loader [loading]="loading"></app-loader>
<div  *ngIf="displayContent" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="mb-3 alignContent-spaceBetween">
          <h2>{{schedulerDetails.name}}</h2>
          <button *ngIf="!schedulerDetails.shifts" (click)="openShiftModal(addShift)" class="btn submitBtn"><i class="fas fa-plus" ></i> Add Shift Details</button>
        </div>
      </div>
      <div *ngIf="schedulerDetails.shifts" class="col-12">
        <div class="row row-cols-md-4 ">
          <div class="col-6 card">
            <div class="card-body">
              <label for=""><i class="fas fa-calendar-week"></i> Days</label>
              <h4>
                <span *ngFor="let day of schedulerDetails.shifts.days; let last = last">
                  {{day.name}}{{!last ? ', ' : ''}}
                </span>
              </h4>
            </div>
            
          </div>
          <div class="col-6 card">
            <div class="card-body">
              <label for=""><i class="fas fa-clock"></i> Time</label>
              <h4>From {{schedulerDetails.shifts.timeFrom}} to {{schedulerDetails.shifts.timeTo}}</h4>
            </div>
            
          </div>
          <div class="col-6 card">
            <div class="card-body">
              <label for=""><i class="fas fa-coffee"></i> Break</label>
              <h4>{{schedulerDetails.shifts.breakInMinutes}} Mins.</h4>
            </div>
            
          </div>
          <div class="col-6 card">
            <div class="card-body">
              <label for=""><i class="fas fa-hashtag"></i> Daily Shifts</label>
              <h4>{{schedulerDetails.shifts.dailyShifts}}</h4>
            </div>
            
          </div>
          
        </div>
        
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div *ngIf="!schedulerDetails.shifts">
              <p class="text-center mb-0">
                <strong>Please Add Shift Details to be able to assign employees</strong>
              </p>
            </div>
            <div *ngIf="schedulerDetails.shifts" class="app-calendar">
              <!-- calendar -->
              <full-calendar [options]="calendarOptions"></full-calendar>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
  
<!-- Add New Event MODAL -->
<ng-template #modalShow let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Assign Employee</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="closeShiftModal()"></button>
  </div>
  <div class="modal-body">
      <form #f="ngForm">
          <div class="row section_style">
              <div class="form-group col-12 col-md-6">
                  <label for="">Day</label>
                  <h4>{{newEventDate.date | date:'E, MMM dd yyyy' }}</h4>
                  <p class="text-danger m-0" *ngIf="daysMessageError"><strong>{{daysMessageError}}</strong></p>
              </div>
              <div class="form-group col-12 col-md-6">
                  <label for="">Employee</label>
                  <select  class="form-control" name="shiftEmpId" ngModel required #shiftEmpId="ngModel">
                    <option value="">Select Employee</option>
                    <option *ngFor="let emp of Employees" [value]="emp.id">{{emp.firstName}} {{emp.lastName}}</option>
                  </select>
                  <span class="text-danger" *ngIf="!shiftEmpId.valid && shiftEmpId.touched">Please Enter A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="employeeIDError"><strong>{{employeeIDError}}</strong></p>
              </div>
            <div class="form-group col-12 col-md-6">
                <label for="">Time From (Optional)</label>
                <input type="time" class="form-control" name="empTimeFrom" ngModel #shiftTimeTo="ngModel" >
                <!-- <span class="text-danger" *ngIf="!empTimeFrom.valid && empTimeFrom.touched">Please Enter A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="timeToError"><strong>{{timeToError}}</strong></p> -->
            </div>

            <div class="form-group col-12 col-md-6">
              <label for="">Time To (Optional)</label>
              <input type="time" class="form-control" name="empTimeTo" ngModel #shiftTimeTo="ngModel" >
              <!-- <span class="text-danger" *ngIf="!shiftTimeTo.valid && shiftTimeTo.touched">Please Enter A Valid Answer</span>
              <p class="text-danger m-0" *ngIf="timeToError"><strong>{{timeToError}}</strong></p> -->
          </div>

          </div>

          <div class="row">
              <div class="form-group col-12 text-center">
                  <button [disabled]="!f.valid" (click)="AssignEmployee(f)" class="btn submitBtn" ><i class="fas fa-plus"></i> Assign Employee</button>
              </div>
          </div>
          
      </form>
  </div>
  <!-- end modal dialog-->
</ng-template>
<!-- end modal-->

<!-- Edit event modal -->
<ng-template #editmodalShow let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Edit Event</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body p-4">
    <form [formGroup]="formEditData" (ngSubmit)="editEventSave()">
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label class="control-label">Event name</label>
            <input class="form-control" placeholder="Insert Event Name" type="text" name="editTitle"
              formControlName="editTitle">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label class="control-label">Event category</label>
            <select class="form-control" name="ecategory" formControlName="editCategory" required>
              <option *ngFor="let option of category" value="{{ option.value }}"> {{ option.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-6">
          <button type="button" class="btn btn-danger delete-event ms-1" (click)="confirm()">Delete</button>
        </div>
        <div class="col-6 text-end">
          <button type="button" class="btn btn-light" (click)="modal.dismiss('Close')">Close</button>
          <button type="submit" class="btn btn-success ms-1">Save</button>
        </div>
      </div>
    </form>
  </div>

</ng-template>

<!-- Add Shift modal -->
<ng-template #addShift role="document" let-modal >
  <div class="modal-header">
    <h5 class="modal-title mt-0">Add Scheduler</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
      <form #f="ngForm">
          <div class="row section_style">
              <div class="form-group col-12 col-md-6">
                  <label for="">Break In Minutes</label>
                  <input type="number" class="form-control" name="shiftBreak" ngModel required #shiftBreak="ngModel" >
                  <span class="text-danger" *ngIf="!shiftBreak.valid && shiftBreak.touched">Please Enter A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="shiftBreakError"><strong>{{shiftBreakError}}</strong></p>
              </div>
              <div class="form-group col-12 col-md-6">
                  <label for="">Time From</label>
                  <input type="time" class="form-control" name="shiftTimeFrom" ngModel required #shiftTimeFrom="ngModel" >
                  <span class="text-danger" *ngIf="!shiftTimeFrom.valid && shiftTimeFrom.touched">Please Enter A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="timeFromError"><strong>{{timeFromError}}</strong></p>
              </div>
            <div class="form-group col-12 col-md-6">
                <label for="">Time To</label>
                <input type="time" class="form-control" name="shiftTimeTo" ngModel required #shiftTimeTo="ngModel" >
                <span class="text-danger" *ngIf="!shiftTimeTo.valid && shiftTimeTo.touched">Please Enter A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="timeToError"><strong>{{timeToError}}</strong></p>
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="">Daily Shifts</label>
                <input type="number" class="form-control" name="dailyShifts" ngModel required #dailyShifts="ngModel" >

              <span class="text-danger" *ngIf="!dailyShifts.valid && dailyShifts.touched">Please Enter A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="dailyShiftsError"><strong>{{dailyShiftsError}}</strong></p>
            </div>
            

            <div class="form-group col-12 col-md-6">
              <label for="">Days</label>
              <div class="checkboxContainer" *ngFor="let day of days">
                <input type="checkbox" id="{{day.id}}" name="{{day.id}}" (change)="toggleDay(day)"> 
                <label for="{{day.id}}"> {{day.name}}</label>
              </div>
              <!-- <span class="text-danger" *ngIf="!shiftDays.valid && shiftDays.touched">Please Enter A Valid Answer</span> -->
                <p class="text-danger m-0" *ngIf="daysError"><strong>{{daysError}}</strong></p>
            </div>

            <div class="form-group col-12 col-md-6">
              <label for="">Cycle Days</label>
              <select  class="form-control" name="shiftCycleDays" ngModel required #shiftCycleDays="ngModel">
                <option value="">Select Cylce</option>
                <option value="7">7 Days</option>
                <option value="14">14 Days</option>
              </select>
              <span class="text-danger" *ngIf="!shiftCycleDays.valid && shiftCycleDays.touched">Please Enter A Valid Answer</span>
              <p class="text-danger m-0" *ngIf="cycleDaysError"><strong>{{cycleDaysError}}</strong></p>
            </div>
          </div>

          <div class="row">
              <div class="form-group col-12 text-center">
                  <button [disabled]="!f.valid || selectedDays.length === 0" (click)="addShiftDetails(f)" class="btn submitBtn" ><i class="fas fa-plus"></i> Add Shift</button>
              </div>
          </div>
          
      </form>
  </div>
  
</ng-template>