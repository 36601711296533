import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/core/services/data.service';
import { RestApiService } from 'src/app/core/services/rest-api.service';
import { NgForm } from '@angular/forms';
import { Role } from 'src/app/core/models/role';
import { Zone } from 'src/app/core/models/zone';
import { Country } from 'src/app/core/models/country';
import { City } from 'src/app/core/models/city';
import { Area } from 'src/app/core/models/area';
import { Employee } from 'src/app/core/models/employee';
import { License } from 'src/app/core/models/license';
import { LicenseEmployee } from 'src/app/core/models/licenseEmployee';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Uniform } from 'src/app/core/models/uniform';
import { UniformEmp } from 'src/app/core/models/uniform_emp';

@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.scss']
})
export class AddEmployeeComponent implements OnInit {

  @ViewChild('f') AddEmployeeForm !: NgForm;
  // @ViewChild('reset') resetPassForm !: NgForm;
  loading = true;
  fetchError = false;
  itemAdded = false;
  itemDeleted = false;
  
  Roles: Role[] = [];
  Zones: Zone[] = [];
  Countries: Country[] = [];
  Cities: City[] = [];
  Areas: Area[] = [];
  dtOptions: any = {};
  displayTable: boolean = false;
  selectedId = '';
  formPassword = '';
  formConfirmPassword = '';
  resetFormPassword = '';
  resetFormConfirmPassword = '';
  validPassword: boolean = false;
  showMatchMessage: boolean = false;
  successMessage = '';
  errorMessage = '';
  Employees: Employee[] = [];
  ErrorList = [];
  
  mobileTaken = false;
  usernameTaken = false;
  internalIdTaken = false;
  emailTaken = false;
  
  // Error handling variables
  fNameErrorMessage="";
  lNameErrorMessage="";
  emailErrorMessage="";
  mobileErrorMessage="";
  usernameErrorMessage="";
  internalIdErrorMessage="";
  passwordErrorMessage="";
  passwordConfirmErrorMessage="";
  birthdateErrorMessage="";
  employmentDateErrorMessage="";
  payRateErrorMessage="";
  licensesErrorMessage="";
  activeErrorMessage="";
  hiringErrorMessage="";
  genderErrorMessage="";
  addressErrorMessage="";
  countryErrorMessage="";
  cityErrorMessage="";
  areaErrorMessage=""; 
  zipCodeErrorMessage=""; 
  
  licenses: License[] = [];

  constructor( private rest: RestApiService, private data: DataService, private modalService: NgbModal, private router: Router ) { }

  ngOnInit(): void {
    this.dtOptions = {
      processing: true
    };
    this.getRoles();
    this.getZones();
    this.getCountries();
    this.getLicenses();
    this.getUniforms();
  }

  async getRoles(){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/roles/employee`
      ).subscribe( 
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.Roles = res;
          this.loading = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getZones(){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/zones?all=true`
      ).subscribe( 
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.Zones = res.data;
          this.loading = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  onChangePassword() {
    this.formConfirmPassword = '';
  }

  onChangeConfirmPassword(){
    if(this.formPassword === this.formConfirmPassword){
      this.validPassword = true;
      this.showMatchMessage = false;
    }
    else{
      this.validPassword = false;
      this.showMatchMessage = true;
    }
  }

  async getCountries(){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/countries`
      ).subscribe( 
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.Countries = res;
          this.loading = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getCities(id: string){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/cities/${id}`
      ).subscribe( 
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.Cities = res;
          this.loading = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getAreas(id: string){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/areas/${id}`
      ).subscribe( 
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.Areas = res;
          this.loading = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  onChangeCountry(selectedValue: string) {
    this.getCities(selectedValue);
    this.Areas = [];
  }

  onChangeCity(selectedValue: string) {
    this.getAreas(selectedValue);
  }

  async getLicenses(pager?:number){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/licenses?all=true`
      ).subscribe( 
        (res: any) => {
          this.successMessage = res.message;
          this.fetchError = false;
          this.licenses = res.data;
          this.loading = false;
          this.displayTable = true;
        },
       (error: any ) => {
          this.fetchError = true;
          this.loading = false;
          this.errorMessage= error.error.message;
       });

    } catch (error) {
      this.data.error(error['message']);
      this.loading = true;
    }
  }

  selectedLicenseId: number | null = null;
  retrivedLicenses: License[] = [];
  selectedLicenseIssueDate = '';
  selectedLicenseExpiryDate = '';
  selectedLicenseNumber: number;
  finalLicenses: LicenseEmployee[] = [];
  selectedLicense: License | null = null;
  
  updateForm() {
    
    this.retrivedLicenses = this.licenses;
    this.selectedLicense = this.retrivedLicenses.find(license => license.id == this.selectedLicenseId);
    this.resetFormFields();
    console.log(this.selectedLicense);
  }

  resetFormFields() {
    this.selectedLicenseIssueDate = '';
    this.selectedLicenseExpiryDate = '';
    this.selectedLicenseNumber = null;
  }


  removeFinalLicense(index: number) {
    this.finalLicenses.splice(index, 1);
  }

  submitForm() {
    const selectedIdx = this.licenses.findIndex(license => license.id == this.selectedLicense.id);
    if (selectedIdx !== -1 && this.selectedLicense !== null) {
      const newLicense: LicenseEmployee = {
        // ...this.licenses[selectedIdx],
        // licenseId:selectedIdx,
        licenseId: this.selectedLicenseId,
        name: this.selectedLicense.name,
        issueDate: this.selectedLicenseIssueDate,
        expirationDate: this.selectedLicenseExpiryDate,
        licenseNumber: this.selectedLicenseNumber
      };
      this.finalLicenses.push(newLicense);
      this.resetFormFields();
      this.selectedLicenseId = null;
      this.selectedLicense = null;
    }
    console.log(this.finalLicenses);
  }

  areAllInputsFilled(): boolean {
    if (this.selectedLicense.hasIssueDate === 1 && !this.selectedLicenseIssueDate) {
        return false;
    }
    if (this.selectedLicense.hasExpiryDate === 1 && !this.selectedLicenseExpiryDate) {
        return false;
    }
    if (this.selectedLicense.hasLicenseNumber === 1 && !this.selectedLicenseNumber) {
        return false;
    }
    return true;
}

  async addEmployee(){
    try {
      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/employees`, 
       {
        firstName: this.AddEmployeeForm.value.empFirstName,
        lastName: this.AddEmployeeForm.value.empLastName,
        email: this.AddEmployeeForm.value.empEmail,
        mobile: this.AddEmployeeForm.value.empMobile,
        username: this.AddEmployeeForm.value.empUserName,
        internalId: this.AddEmployeeForm.value.empInternalId,
        birthdate: this.AddEmployeeForm.value.empBirthdate,
        employmentDate: this.AddEmployeeForm.value.empEmploymentdate,
        payrate: this.AddEmployeeForm.value.empPayRate,
        payType: this.AddEmployeeForm.value.empPayType,
        roleId: this.AddEmployeeForm.value.empRole,
        zoneId: this.AddEmployeeForm.value.empZone,
        isActive: this.AddEmployeeForm.value.empStatus,
        hiringStatus: this.AddEmployeeForm.value.empHiring,
        gender: this.AddEmployeeForm.value.empGender,
        password: this.AddEmployeeForm.value.empPassword,
        passwordConfirmation: this.AddEmployeeForm.value.confirmEmpPassword,
        licenses: this.finalLicenses,
        uniforms: this.finalUniforms,
        address :{
          countryId: this.AddEmployeeForm.value.empCountry,
          cityId: this.AddEmployeeForm.value.empCity,
          areaId: this.AddEmployeeForm.value.empArea,
          zipcode: this.AddEmployeeForm.value.empZipCode,
          address: this.AddEmployeeForm.value.empAdressStreet
        }

       }).subscribe( 
       (res: any) => {
         this.successMessage = res.message;
         this.fetchError = false;
         this.itemAdded = true;
         this.AddEmployeeForm.reset();
         this.validPassword = true;
         console.log(res);
         this.router.navigate(['/employees']);
       } ,
      (error : any) => {
        // this.errorMessage= error.error.message;
        error.error.errors.forEach(err => {
          switch (err.field) {
            case 'email':
              this.emailErrorMessage = err.message;
              break;
            case 'mobile':
              this.mobileErrorMessage = err.message;
              break;
            case 'username':
              this.usernameErrorMessage = err.message;
              break;

            case 'password':
              this.passwordErrorMessage = err.message;
              break;
              case 'internalId':
              this.internalIdErrorMessage = err.message;
              break;
              case 'birthdate':
              this.birthdateErrorMessage = err.message;
              break;
              case 'employmentDate':
              this.employmentDateErrorMessage = err.message;
              break;
              case 'payrate':
              this.payRateErrorMessage = err.message;
              break;
              case 'firstName':
              this.fNameErrorMessage = err.message;
              break;
              case 'lastName':
              this.lNameErrorMessage = err.message;
              break;
              case 'passwordConfirmation':
              this.passwordConfirmErrorMessage = err.message;
              break;
              case 'licenses':
              this.licensesErrorMessage = err.message;
              break;
              case 'uniforms':
              this.uniformErrorMessage = err.message;
              break;
              case 'isActive':
              this.activeErrorMessage = err.message;
              break;
              case 'hiringStatus':
              this.hiringErrorMessage = err.message;
              break;
              case 'address.countryId':
              this.countryErrorMessage = err.message;
              break;
              case 'address.cityId':
              this.cityErrorMessage = err.message;
              break;
              case 'address.areaId':
              this.areaErrorMessage = err.message;
              break;
              case 'address.address':
              this.addressErrorMessage = err.message;
              break;
              case 'address.zipcode':
              this.zipCodeErrorMessage = err.message;
              break;
              case 'gender':
              this.genderErrorMessage = err.message;
              break;
            // Add more cases for other fields if needed
          }
        });
        console.log(error.error.errors);
      }
    );

   } catch (error) {
    this.ErrorList = error;
    console.log("Catch Error List: " + this.ErrorList);
    console.log("Catchh" + error);
     this.data.error(error['message']);
   }
  }

 

  // Uniforms

  uniforms: Uniform[] = [];
  async getUniforms(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/uniforms`
      ).subscribe( 
        (res: any) => {
          this.successMessage = res.message;
          this.fetchError = false;
          this.uniforms = res.data;
          this.loading = false;
          this.displayTable = true;
        },
        (error: any) => {
          this.fetchError = true;
          this.loading = false;
          this.errorMessage= error.error.message;
       });

    } catch (error) {
      console.log("error Zones"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }


  selectedUniformId: number | null = null;
  retrivedUniforms: Uniform[] = [];
  finalUniforms: UniformEmp[] = [];
  selectedUniform: Uniform | null = null;

  uniformErrorMessage="";

  selectedUniformDate = '';
  selectedUniformQty: number;

  resetUniFormFields() {
    this.selectedUniformDate = '';
    this.selectedUniformQty = null;
  }

  updateUniForm() {
    
    this.retrivedUniforms = this.uniforms;
    this.selectedUniform = this.retrivedUniforms.find(uniform => uniform.id == this.selectedUniformId);
    this.areAllInputsFilled_unifrom();
    console.log(this.selectedUniform);
  }

  areAllInputsFilled_unifrom(): boolean {
    if (!this.selectedUniformDate) {
        return false;
    }
    if (!this.selectedUniformQty) {
        return false;
    }
    
    return true;
  }

  submitUniForm() {
    const selectedIdx = this.uniforms.findIndex(uniform => uniform.id == this.selectedUniform.id);
    if (selectedIdx !== -1 && this.selectedUniform !== null) {
      const newUniform: UniformEmp = {
        uniformId: this.selectedUniformId,
        name: this.selectedUniform.name,
        receivingDate: this.selectedUniformDate,
        quantity: this.selectedUniformQty
      };
      this.finalUniforms.push(newUniform);
      this.resetUniFormFields();
      this.selectedUniformId = null;
      this.selectedUniform = null;
    }
    console.log(this.finalUniforms);
  }

  removeFinalUniform(index: number) {
    this.finalUniforms.splice(index, 1);
  }

}

