<div class="container-fluid">
    <app-loader [loading]="loading"></app-loader>
    <div class="row">
        
        <div *ngIf="showContent">
            <div  class="col-12 mb-3 alignContent-spaceBetween">
                <div>
                    <h2>{{zoneDetails.name}}</h2>
                </div>
            </div>
        <ul class="nav nav-pills tabs_border" id="myTab" role="tablist">

            <li class="nav-item" role="presentation">
                <button (click)="showDetails()" class="nav-link active" id="pills-positions-tab" data-bs-toggle="pill" data-bs-target="#details_tab" type="button" role="tab" aria-controls="pills-positions-tab" aria-selected="false">Details</button>
            </li>

            <li class="nav-item" role="presentation">
                <button (click)="showSites()" class="nav-link " id="pills-sites-tab" data-bs-toggle="pill" data-bs-target="#sites_tab" type="button" role="tab" aria-controls="pills-sites-tab" aria-selected="false">Sites</button>
            </li>

            <li class="nav-item" role="presentation">
                <button (click)="showPositions()" class="nav-link" id="pills-positions-tab" data-bs-toggle="pill" data-bs-target="#positions_tab" type="button" role="tab" aria-controls="pills-positions-tab" aria-selected="false">Positions</button>
            </li>
            <li class="nav-item" role="presentation">
                <button (click)="showRunSheets()" class="nav-link" id="pills-runSheets-tab" data-bs-toggle="pill" data-bs-target="#runSheets_tab" type="button" role="tab" aria-controls="pills-runSheets-tab" aria-selected="false">Run Sheets</button>
            </li>
            

        </ul>
        <div class="tab-content" id="myTabContent">

            <div *ngIf="showDetailsContent" class="tab-pane fade active show" id="details_tab" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                <div class="mb-3 alignContent-spaceBetween">
                    <h2>Details</h2>
                </div>
                <div>
                    <div class="row row-cols-lg-3">
                        <div class="singleDetail">
                            <span>Internal ID</span>
                            <p>{{zoneDetails?.internalId}}</p>
                        </div>
                        <div class="singleDetail">
                            <span>Details</span>
                            <p>{{zoneDetails?.details}}</p>
                        </div>
                        <div class="singleDetail">
                            <span>Address</span>
                            <p>{{zoneDetails?.address}}</p>
                        </div>
                        <div class="singleDetail">
                            <span>Country</span>
                            <p>{{zoneDetails?.country?.name}}</p>
                        </div>
                        <div class="singleDetail">
                            <span>State</span>
                            <p>{{zoneDetails?.city?.name}}</p>
                        </div>
                        <div class="singleDetail">
                            <span>City</span>
                            <p>{{zoneDetails?.area?.name}}</p>
                        </div>
                        <div class="singleDetail">
                            <span>Zip <Code></Code></span>
                            <p>{{zoneDetails?.zipcode}}</p>
                        </div>
                        
                        
                    </div>
                </div>
            </div>

            <div *ngIf="showSitesContent" class="" id="sites_tab" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                <div class="mb-3 alignContent-spaceBetween">
                    <h2>Sites</h2>
                    <div class="d-flex gap-2">
                        <button  (click)="openModal(assignSiteModal, 'md')" class="btn submitBtn">Assign Site</button>
                    </div>
                </div>

                <div>
                    <div class="card">
                        <div class="card-body" >
                            <div class="table-responsive">
                                <table #dataTabless datatable [dtOptions]="dtOptions" class="datatable table align-middle table-nowrap table-hover">
                                    <thead class="table-light">
                                    <tr>
                                        
                                        <th scope="col">Internal ID</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">Company</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Mobile</th>
                                        <th scope="col">Address</th>
                                        <th scope="col">View</th>
                                        
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let site of sites">
                                        
                                        
                                        <td>{{site.internalId}}</td>
                                        <td>
                                            <span [ngClass]="{
                                                'blueBg': site.type?.id == '1',
                                                'yellowBg': site.type?.id == '2',
                                                'greenBg': site.type?.id == '3'
                                                }"
                                                >{{site.type?.name}}</span>
                                            
                                        </td>
                                        <td>{{site.company.name}}</td>
                                        <td>{{site.contact.firstName}} {{site.contact.lastName}}</td>
                                        <td>{{site.contact.email}}</td>
                                        <td>{{site.contact.mobile}}</td>
                                        <td>{{site.address}}, {{site.city.name}}</td>
                                        <td>
                                            <button (click)="onViewSite(site.id)" class="btn"><i class="fas fa-eye"></i></button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
        
                        </div>
                    </div>
                </div>
            </div>
        
            <div *ngIf="showPositionsContent" class="" id="positions_tab" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                <div class="mb-3 alignContent-spaceBetween">
                    <h2>Positions</h2>
                    <div class="d-flex gap-2">
                        <button (click)="openModal(addPositionModal, 'xl')" class="btn submitBtn">Add Position</button>
                    </div>
                </div>
                <div>
                    <div class="card">
                        <div class="card-body" >
                            <div class="table-responsive">
                            <table datatable [dtOptions]="dtOptions" class="datatable table align-middle table-nowrap table-hover">
                                <thead class="table-light">
                                <tr>
                                    <th scope="col">Status</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Start Date</th>
                                    <th scope="col">End Date</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Schedule memo</th>
                                    <th scope="col">Break Rule</th>
                                    <th scope="col">Hard Reqs.</th>
                                    <th scope="col">Conditional Reqs.</th>
                                    <th scope="col">Soft Reqs.</th>
                                    <th scope="col">Delete</th>
                                    
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let pos of positions">
                                    
                                    <td>
                                        <span *ngIf="pos.status">Active</span>
                                        <span *ngIf="!pos.status">Archived</span>
                                    </td>
                                    <td>{{pos.name}}</td>
                                    <td>{{pos.serviceStartDate | date:"MM-dd-yyyy"}}</td>
                                    <td>{{pos.serviceEndDate | date:"MM-dd-yyyy"}}</td>
                                    <td>{{pos.description}}</td>
                                    <td>{{pos.scheduleMemo}}</td>
                                    <td>{{pos.breakRule.name}}</td>
                                    <td>
                                        <span class="rule_style" *ngFor="let rule of pos.hardRequirements">{{rule.name}}</span>
                                    </td>
                                    <td>
                                        <span class="rule_style" *ngFor="let rule of pos.conditionalRequirements">{{rule.name}}</span>
                                    </td>
                                    <td>
                                        <span class="rule_style" *ngFor="let rule of pos.softRequirements">{{rule.name}}</span>
                                    </td>
                                    <td>
                                        <button class="btn" (click)="deletePosition(pos.id)"><i class="fas fa-trash-alt"></i></button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
        
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="showRunSheetContent" class="" id="runSheets_tab" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                <div class="mb-3 alignContent-spaceBetween">
                    <h2>Run Sheets</h2>
                    <div class="d-flex gap-2">
                        <button (click)="openModal(addRunSheetModal, 'lg')" class="btn submitBtn">Create Run Sheet</button>
                    </div>
                </div>
                <div>

                    <div class="card">
                        <div class="card-body" >

                            <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                  <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Run Sheets</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                  <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Runsheet Assignment & Details</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                                    <div class="table-responsive">
                                        <table datatable [dtOptions]="dtOptions" class="datatable table align-middle table-nowrap table-hover">
                                            <thead class="table-light">
                                            <tr>
                                                <th scope="col">Week Day</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Begin Date</th>
                                                <th scope="col">Time</th>
                                                <th scope="col">Duration</th>
                                                <th scope="col">Position</th>
                                                <th scope="col">Jobs</th>
                                                <th scope="col">Work</th>
                                                <th scope="col">Efficiency</th>
                                                <th scope="col">Optimize</th>
                                                
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr *ngFor="let run of runSheets">
                                                
                                                <td>{{run.day.name}}</td>
                                                <td>{{run.description}}</td>
                                                <td>{{run.startDate | date:"MM-dd-yyyy"}}</td>
                                                <td>{{run.timeFrom}} - {{run.timeTo}}</td>
                                                <td>{{run.duration.hours}}H - {{run.duration.minutes}}Mins</td>
                                                <td>
                                                    <span *ngIf="run.position">{{run.position.name}}</span>
                                                    <span *ngIf="!run.position">No Position</span>
                                                </td>
                                                <td>{{run.jobs}}</td>
                                                <td>{{run.work}}</td>
                                                <td>{{run.efficency}}</td>
                                                <td>
                                                    <button class="btn submitBtn" (click)="openOptimizeModal(optimizeRunModal, 'xl', run.id)">Optimize</button>    
                                                </td>
                                                
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                                    <app-loader [loading]="loading_sheet"></app-loader>

                                    <div class="auto_assign">
                                        <button class="btn submitBtn" (click)="autoAssginRunSheet()">Auto Assign Tours</button>
                                    </div>
                                    <div class="table-responsive">
                                        <table datatable [dtOptions]="dtOptions" class="datatable table align-middle table-nowrap table-hover">
                                            <thead class="table-light">
                                            <tr>
                                                <th scope="col">Type</th>
                                                <th scope="col">Day</th>
                                                <th scope="col">Time / Time Range</th>
                                                <th scope="col">Site UID</th>
                                                <th scope="col">Site</th> 
                                                <th scope="col">Duration</th>
                                                <th scope="col">Date Range</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Route Position</th>
                                                <th scope="col">Runsheet</th>
                                                
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr *ngFor="let run of runSheetsAssignment">
                                                
                                                <td>{{run.patrolType}}</td>
                                                <td>{{run.day}}</td>
                                                <td>{{run.timeFrom}} - {{run.timeTo}}</td>
                                                <td>{{run.site.internalId}}</td>
                                                <td>{{run.site.company.name}}</td>
                                                <td>{{run.duration.hours}}H - {{run.duration.minutes}}Mins</td>
                                                <td>Starting {{run.startDate | date:"MM-dd-yyyy"}}</td>
                                                <td>
                                                    <span *ngIf="run.isActive"><i class="fas fa-check-circle trueCheck"></i> Active</span>
                                                    <span *ngIf="!run.isActive"><i class="fas fa-times-circle falseCheck"></i> Not Active</span>
                                                </td>
                                                <td>
                                                    {{run.routePosition}}
                                                </td>
                                                <td>
                                                    <select class="form-select" id="assignRun" name="assignRun" [(ngModel)]="run.runsheetId" #assignRun="ngModel" (change)="assginRunSheet($event.target.value, run.id)">
                                                        <option disabled value="">Not Assigned</option>
                                                        <option *ngFor="let sheet of run.runsheets" [value]="sheet.id">{{sheet.position.name}}</option>
                                                    </select>    
                                                </td>
                                                
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            
                            

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>


    
</div>

<!-- Assign Site to Zone Modal -->
<ng-template #assignSiteModal role="document" let-modal >
    <div class="modal-header">
      <h5 class="modal-title mt-0">Assign Site</h5>
      <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
  
      <div class="row">

        <div class="col-12">
  
          <form #f="ngForm" >
            <div class="row row-cols-md-1">

          <div class="form-group col">
            <label for="">Unassigned Sites</label>
            <select type="text" class="form-control" name="unassignedSelect" ngModel required #unassignedSelect="ngModel">
                <option  value="">Select Site</option>
                <option *ngFor="let site of unassignedSites" [value]="site.id">{{site.company.name}}</option>
            </select>
        </div>
  
            </div>
            <div class="text-center">
              <button type="submit" [disabled]="!f.valid" class="btn submitBtn" (click)="assignSite(f)"><i class="fas fa-plus"></i> Assign</button>
        
              <button class="btn cancelBtn" (click)="modal.dismiss('Cross click')">Cancel</button>
              
            </div>
  
          </form>
        </div>
      </div>
      
      
    </div>
    
</ng-template>

<!-- Add Position to Zone Modal -->
<ng-template #addPositionModal role="document" let-modal >
    <div class="modal-header">
      <h5 class="modal-title mt-0">Add Position</h5>
      <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
  
      <div class="row">

        <div class="col-12">
  
          <form #f="ngForm" >
            <div class="row row-cols-md-1 section_style">
                <div class="col-12">
                    <h5>Post Base Settings</h5>
                </div>

                <div class="form-group col-12 col-md-4">
                    <label for="">Post Name</label>
                    <input type="text" class="form-control" name="postName" ngModel required #postName="ngModel" >
                    <span class="text-danger" *ngIf="!postName.valid && postName.touched">Please Enter A Valid Answer</span>
                    <!-- <p class="text-danger m-0" *ngIf="siteInternalIdErrorMessage"><strong>{{siteInternalIdErrorMessage}}</strong></p> -->
                </div>
                <div class="form-group col-12 col-md-4">
                    <label for="">Post ID (Optional)</label>
                    <input type="text" class="form-control" name="postId" ngModel  #postId="ngModel" >
                    <!-- <p class="text-danger m-0" *ngIf="siteInternalIdErrorMessage"><strong>{{siteInternalIdErrorMessage}}</strong></p> -->
                </div>
                
                <div class="form-group col-12 col-md-4">
                    <label for="">Status</label>
                    <select type="text" class="form-control" name="postStatus" ngModel required #postStatus="ngModel" >
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Archived</option>
                    </select>
                    <span class="text-danger" *ngIf="!postStatus.valid && postStatus.touched">Please Enter A Valid Answer</span>
                    <!-- <p class="text-danger m-0" *ngIf="accountErrorMessage"><strong>{{accountErrorMessage}}</strong></p> -->
                </div>

                <div class="form-group col-12 col-md-6">
                    <label for="">Short Description of Tasks</label>
                    <textarea class="form-control" name="shortDesc" ngModel required #shortDesc="ngModel" rows="2"></textarea>
                    <span class="text-danger" *ngIf="!shortDesc.valid && shortDesc.touched">Please Enter A Valid Answer</span>
                    <!-- <p class="text-danger m-0" *ngIf="siteInternalIdErrorMessage"><strong>{{siteInternalIdErrorMessage}}</strong></p> -->
                </div>

                <div class="form-group col-12 col-md-6">
                    <label for="">Schedule memo</label>
                    <textarea class="form-control" name="memo" ngModel required #memo="ngModel" rows="2"></textarea>
                    <span class="text-danger" *ngIf="!memo.valid && memo.touched">Please Enter A Valid Answer</span>
                    <!-- <p class="text-danger m-0" *ngIf="siteInternalIdErrorMessage"><strong>{{siteInternalIdErrorMessage}}</strong></p> -->
                </div>
                
          
  
            </div>

            <div class="row row-cols-md-1 section_style">
                <div class="col-12">
                    <h5>Compliances</h5>
                </div>

                <div class="form-group col-12 col-md-4">
                    <label for="">Hard Requirements</label>
                    <ng-select [multiple]="true" name="hardReq" ngModel required #hardReq="ngModel">
                        <ng-option *ngFor="let req of requirements" [value]="req.id">{{req.name}}</ng-option>
                    </ng-select>
                    <span class="text-danger" *ngIf="!hardReq.valid && hardReq.touched">Please Enter A Valid Answer</span>
                    <!-- <p class="text-danger m-0" *ngIf="siteInternalIdErrorMessage"><strong>{{siteInternalIdErrorMessage}}</strong></p> -->
                </div>
                <div class="form-group col-12 col-md-4">
                    <label for="">Conditional Requirements</label>
                    <ng-select [multiple]="true" name="condReq" ngModel required #condReq="ngModel">
                        <ng-option *ngFor="let req of requirements" [value]="req.id">{{req.name}}</ng-option>
                    </ng-select>
                    <span class="text-danger" *ngIf="!condReq.valid && condReq.touched">Please Enter A Valid Answer</span>
                    <!-- <p class="text-danger m-0" *ngIf="siteInternalIdErrorMessage"><strong>{{siteInternalIdErrorMessage}}</strong></p> -->
                </div>
                <div class="form-group col-12 col-md-4">
                    <label for="">Soft Requirements</label>
                    <ng-select [multiple]="true" name="softReq" ngModel required #softReq="ngModel">
                        <ng-option *ngFor="let req of requirements" [value]="req.id">{{req.name}}</ng-option>
                    </ng-select>
                    <span class="text-danger" *ngIf="!softReq.valid && softReq.touched">Please Enter A Valid Answer</span>
                    <!-- <p class="text-danger m-0" *ngIf="siteInternalIdErrorMessage"><strong>{{siteInternalIdErrorMessage}}</strong></p> -->
                </div>
                
          
  
            </div>

            <div class="row row-cols-md-1 section_style">
                <div class="col-12">
                    <h5>Service Dates</h5>
                </div>

                <div class="form-group col-12 col-md-4">
                    <label for="">Service Duration</label>
                    <div class="checkboxContainer" >
                        <input type="checkbox" id="temporaryService" name="temporaryService" ngModel [(ngModel)]="tempService">
                        <label for="temporaryService">Temporary Service</label>
                    </div>
                </div>
                <div class="form-group col-12 col-md-4">
                    <label for="">Begin Date</label>
                    <input type="date" class="form-control" name="beginDate" ngModel required #beginDate="ngModel" >
                    <span class="text-danger" *ngIf="!beginDate.valid && beginDate.touched">Please Enter A Valid Answer</span>
                    <!-- <p class="text-danger m-0" *ngIf="siteInternalIdErrorMessage"><strong>{{siteInternalIdErrorMessage}}</strong></p> -->
                </div>
                
                <div *ngIf="tempService" class="form-group col-12 col-md-4">
                    <label for="">End Date</label>
                    <input type="date" class="form-control" name="endDate" ngModel required #endDate="ngModel" >
                    <span class="text-danger" *ngIf="!endDate.valid && endDate.touched">Please Enter A Valid Answer</span>
                    <!-- <p class="text-danger m-0" *ngIf="accountErrorMessage"><strong>{{accountErrorMessage}}</strong></p> -->
                </div>
          
  
            </div>

            <div class="row row-cols-md-1 section_style">
                <div class="col-12">
                    <h5>Break Rule Settings</h5>
                </div>

                
                <div class="form-group col-12 col-md-4">
                    <label for="">Break Rule</label>
                    <select type="text" class="form-control" name="breakRule" ngModel required #breakRule="ngModel" >
                        <option *ngFor="let break of breakRules" [value]="break.id">{{break.name}}</option>
                    </select>
                    <span class="text-danger" *ngIf="!breakRule.valid && breakRule.touched">Please Enter A Valid Answer</span>
                    <!-- <p class="text-danger m-0" *ngIf="accountErrorMessage"><strong>{{accountErrorMessage}}</strong></p> -->
                </div>
          
  
            </div>


            <div class="text-center">
              <button type="submit" [disabled]="!f.valid" class="btn submitBtn" (click)="addPosition(f)"><i class="fas fa-plus"></i> Assign</button>
        
              <button class="btn cancelBtn" (click)="modal.dismiss('Cross click')">Cancel</button>
              
            </div>
  
          </form>
        </div>
      </div>
      
      
    </div>
    
</ng-template>

<!-- Add Runsheet to Zone Modal -->
<ng-template #addRunSheetModal role="document" let-modal >
    <div class="modal-header">
      <h5 class="modal-title mt-0">Create Run Sheet</h5>
      <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
  
      <div class="row">

        <div class="col-12">
  
          <form #f="ngForm" class="row">
            <div class="form-group col-12 col-md-6">
                <label for="">Begin Date</label>
                <input type="date" class="form-control" name="sheetBeginDate" ngModel required #sheetBeginDate="ngModel" >
                <span class="text-danger" *ngIf="!sheetBeginDate.valid && sheetBeginDate.touched">Please Enter A Valid Answer</span>
                <!-- <p class="text-danger m-0" *ngIf="siteInternalIdErrorMessage"><strong>{{siteInternalIdErrorMessage}}</strong></p> -->
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="">Position / Unit</label>
                <select type="text" class="form-control" name="runPosition" ngModel required #runPosition="ngModel" >
                    <option value="">Select Position / Unit</option>
                    <option *ngFor="let pos of positions" [value]="pos.id">{{pos.name}}</option>
                    
                </select>
                <span class="text-danger" *ngIf="!runPosition.valid && runPosition.touched">Please Enter A Valid Answer</span>
            </div>
            
            <div class="form-group col-12 col-md-6">
                <label for="">Start Time</label>
                <input type="time" class="form-control" name="sheetBeginTime" ngModel required #sheetBeginTime="ngModel" >
                <span class="text-danger" *ngIf="!sheetBeginTime.valid && sheetBeginTime.touched">Please Enter A Valid Answer</span>
                <!-- <p class="text-danger m-0" *ngIf="siteInternalIdErrorMessage"><strong>{{siteInternalIdErrorMessage}}</strong></p> -->
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="">End Time</label>
                <input type="time" class="form-control" name="sheetEndTime" ngModel required #sheetEndTime="ngModel" >
                <span class="text-danger" *ngIf="!sheetEndTime.valid && sheetEndTime.touched">Please Enter A Valid Answer</span>
            </div>

            <div class="form-group col-12">
                <label for="">Custom Description </label>
                <textarea class="form-control" name="customDesc" ngModel #customDesc="ngModel" rows="2"></textarea>
                
                <!-- <p class="text-danger m-0" *ngIf="siteInternalIdErrorMessage"><strong>{{siteInternalIdErrorMessage}}</strong></p> -->
            </div>

            <div class="form-group col-12">
                <label for="">Days</label>
                <div class="shiftDaysContainer">
                    <div class="checkboxContainer">
                        <input type="checkbox" id="allDays" name="allDays" (change)="toggleAllDays($event)"> 
                        <label for="allDays">All Days</label>
                    </div>
                    <div class="checkboxContainer" *ngFor="let day of days">
                        <input type="checkbox" id="{{day.id}}" name="{{day.id}}" (change)="toggleDay(day)"> 
                        <label for="{{day.id}}"> {{day.name}}</label>
                    </div>
                </div>
                
                <p class="text-danger m-0" *ngIf="daysError"><strong>{{daysError}}</strong></p>
            </div>



            <div class="text-center">
              <button type="submit" [disabled]="!f.valid || selectedDays.length === 0" class="btn submitBtn" (click)="addRunSheet(f)"><i class="fas fa-plus"></i> Assign</button>
        
              <button class="btn cancelBtn" (click)="modal.dismiss('Cross click')">Cancel</button>
              
            </div>
  
          </form>
        </div>
      </div>
      
      
    </div>
    
</ng-template>


<!-- Optimize Runsheet Modal -->
<ng-template #optimizeRunModal role="document" let-modal >
    <div class="modal-header">
      <h5 class="modal-title mt-0">Optimize Run Sheet</h5>
      <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
  
      <div class="row">
        
        <div class="col-12 text-center">
            <button class="btn submitBtn" (click)="optimizeRunsheet()">Original Provided Time Range</button>
          
        </div>

        <div class="col-12 mt-3">
            <app-loader [loading]="loading_optimize"></app-loader>
        </div>

        <div *ngIf="optimizingResults" class="col-12">
            
            <div class="sheetSummary">
                <div class="driveTime summaryDetails">
                    <p><i class="fas fa-car"></i> {{optimizeResult.drivingTime}} Mins</p>
                    <p>Driving Time</p>
                </div>
                <div class="workingTime summaryDetails">
                    <p><i class="fas fa-toolbox"></i> {{optimizeResult.workingTime}} Mins</p>
                    <p>Working Time</p>
                </div>
                <div class="idleTime summaryDetails">
                    <p><i class="fas fa-exclamation"></i> {{optimizeResult.idleTime}} Mins</p>
                    <p>Idle Time</p>
                </div>
                <div class="totalTime summaryDetails">
                    <p><i class="fas fa-clock"></i> {{optimizeResult.totalDuration}} Mins</p>
                    <p>Total Duration</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-8">
                    <div class="sheet_routes">
                        <div *ngFor="let route of optimizeResult.route" class="single_route">
                            
                            <div class="route_icon">
                                <i *ngIf="route.type == 'IN_TRANSIT'" class="fas fa-car"></i> 
                                <i *ngIf="route.type == 'IDLE'" class="fas fa-exclamation"></i> 
                                <i *ngIf="route.type == 'TASK_ACTIVITY'" class="fas fa-toolbox"></i> 
                            </div>
                            <div class="route_status">
                                <p class="duration">{{route.duration}}</p>
                                <p class="driveTime" *ngIf="route.type == 'IN_TRANSIT'">In Transit</p>
                                <p class="idleTime" *ngIf="route.type == 'IDLE'">Idle</p>
                                <p class="workingTime" *ngIf="route.type == 'TASK_ACTIVITY'">Task Activity</p>
                                <p *ngIf="route.status">{{route.status}}</p>
                            </div>
                            <div *ngIf="route.locationLabel" class="route_desc">
                                <p>{{route.locationLabel}}</p>
                                <p>{{route.location}}</p>
                                <p>Arriving At: {{route.startTime}}</p>
                                <p>Departing at: {{route.endTime}}</p>
                                <p>Time window: {{route.timeFrame}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 route_locations">
                    <div>
                        <label for="">Start Location</label>
                        <p>{{optimizeResult.startLocation}}</p>
                    </div>
                    
                    <div>
                        <label for="">End Location</label>
                        <p>{{optimizeResult.endLocation}}</p>
                    </div>

                    <div>
                        <button
                            class="btn submitBtn w-100"
                            (click)="onViewOptimize(zoneId, optSheetID)"
                        >Save This Sequence</button>
                    </div>
                    
                </div>
            </div>

            
        </div>
      </div>
      
      
    </div>
    
</ng-template>