import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Renderer2, ViewChild } from '@angular/core';

import { Calendar, CalendarOptions, EventInput } from '@fullcalendar/core';
import { DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/core';

import dayGridPlugin from '@fullcalendar/daygrid';

import { Employee } from 'src/app/core/models/employee';
import { DataService } from '../../core/services/data.service';
import { RestApiService } from '../../core/services/rest-api.service';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Pagination } from 'src/app/core/models/pagination';
import { Router, NavigationExtras } from '@angular/router';
import { Role } from 'src/app/core/models/role';
import { Zone } from 'src/app/core/models/zone';
import { Country } from 'src/app/core/models/country';
import { City } from 'src/app/core/models/city';
import { Area } from 'src/app/core/models/area';
import { License } from 'src/app/core/models/license';
import { LicenseEmployee } from 'src/app/core/models/licenseEmployee';
import { AssignedEmps } from 'src/app/core/models/assignedEmps';
import { EmployeeSites } from 'src/app/core/models/employeeSites';
import { UniformEmp } from 'src/app/core/models/uniform_emp';
import { Uniform } from 'src/app/core/models/uniform';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {

  @ViewChild('f') UpdateEmployeeForm !: NgForm;

  loading = true;
  fetchError = false;
  userApproved = false;
  userDeleted = false;
  Employees: Employee[] = [];
  activeEmployees: Employee[] = [];
  inActiveEmployees: Employee[] = [];

  dtOptions: any = {
    paging: false,
    lengthChange: false,
    info: false,
    searching: true,
  };

  displayTableActive: boolean = false;
  displayTableInActive: boolean = false;

  PaginationObj: Pagination = {
    total: 0,
    perPage: 0,
    currentPage: 0,
    firstPage: 0,
    lastPage: 0,
    nextPage: 0,
    previousPage: 0,
    pageChange: new EventEmitter<number>(),
  };

  PaginationObjActive: Pagination = {
    total: 0,
    perPage: 0,
    currentPage: 0,
    firstPage: 0,
    lastPage: 0,
    nextPage: 0,
    previousPage: 0,
    pageChange: new EventEmitter<number>(),
  };

  PaginationObjInActive: Pagination = {
    total: 0,
    perPage: 0,
    currentPage: 0,
    firstPage: 0,
    lastPage: 0,
    nextPage: 0,
    previousPage: 0,
    pageChange: new EventEmitter<number>(),
  };
  

  selectedUserDetails: Employee;
  selectedTerminated: Employee;
  selectedEditable: Employee;

  // For Edit Employee
  Roles: Role[] = [];
  Zones: Zone[] = [];
  Countries: Country[] = [];
  Cities: City[] = [];
  Areas: Area[] = [];
  selectedId = '';
  selectedEditId = '';
  formPassword = '';
  formConfirmPassword = '';
  resetFormPassword = '';
  resetFormConfirmPassword = '';
  validPassword: boolean = false;
  showMatchMessage: boolean = false;
  successMessage = '';
  errorMessage = '';
  ErrorList = [];

  
  // Error handling variables
  fNameErrorMessage="";
  lNameErrorMessage="";
  emailErrorMessage="";
  mobileErrorMessage="";
  usernameErrorMessage="";
  internalIdErrorMessage="";
  passwordErrorMessage="";
  passwordConfirmErrorMessage="";
  birthdateErrorMessage="";
  employmentDateErrorMessage="";
  payRateErrorMessage="";
  licensesErrorMessage="";
  activeErrorMessage="";
  hiringErrorMessage="";
  genderErrorMessage="";
  addressErrorMessage="";
  countryErrorMessage="";
  cityErrorMessage="";
  areaErrorMessage=""; 
  zipCodeErrorMessage=""; 

  licenses: License[] = [];
  
  constructor(private renderer: Renderer2, private el: ElementRef, private cdr: ChangeDetectorRef, private rest: RestApiService, private data: DataService, private modalService: NgbModal, private router: Router ) {
    const name = Calendar.name;
  }

  // ngOnInit() {
  //   this.breadCrumbItems = [{ label: 'Contacts' }, { label: 'Users List', active: true }];
  // }

  @ViewChild('loadSchedule', { static: false }) loadScheduleBtn: ElementRef;
  ngOnInit(): void {
    this.getActiveEmployees(1);
    this.getInActiveEmployees(1);
    this.getRoles();
    this.getZones();
    this.getCountries();
    this.getLicenses();
    this.getUniforms();
  }

  // async getEmployees(pager:number){
  //   try {
  //     await this.rest.getAllUsers(
  //       `${this.data.getBaseUrl()}api/v1/employees?page=${pager}`
  //     ).subscribe( 
  //       (res: any) => {
  //         // this.successMessage = res.message;
  //         this.fetchError = false;
  //         this.loading = false;
  //         this.Employees = res.data;
  //         this.displayTable = true;
  //         this.PaginationObj = res.pagination;
          
  //       } ,
  //      (error : any) => {
  //       this.errorMessage= error.error.message;
  //       this.fetchError = true;
  //       this.loading = false;
  //      });

  //   } catch (error) {
  //     this.loading = true;
  //     this.data.error(error['message']);
  //   }

  // }

  async getActiveEmployees(pager:number){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/employees?page=${pager}&status=active`
      ).subscribe( 
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.loading = false;
          this.activeEmployees = res.data;
          this.displayTableActive = true;
          this.PaginationObjActive = res.pagination;
          
        } ,
       (error : any) => {
        this.errorMessage= error.error.message;
        this.fetchError = true;
        this.loading = false;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getInActiveEmployees(pager:number){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/employees?page=${pager}&status=inactive`
      ).subscribe( 
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.loading = false;
          this.inActiveEmployees = res.data;
          this.displayTableInActive = true;
          this.PaginationObjInActive = res.pagination;
          
        } ,
       (error : any) => {
        this.errorMessage= error.error.message;
        this.fetchError = true;
        this.loading = false;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }


  navigateToAddEmp() {
    this.router.navigate(['/addEmployee']);
  }

  navigateToEditEmp() {
    this.router.navigate(['/editEmployee']);
  }

  openEditModal(content: any, user: Employee) {
    this.modalService.open(content,
       { centered: true, 
          size: 'lg', 
          windowClass: 'detailsModal' ,
          backdrop: 'static'
        });
    this.selectedEditable = user;
    console.log(this.selectedEditable);
    this.selectedEditId = user.userId;
    this.finalLicenses = this.selectedEditable.licenses;
    this.finalUniforms = this.selectedEditable.uniforms;
    this.getCities(this.selectedEditable.address.country.id);
    this.getAreas(this.selectedEditable.address.city.id);
  }

  openModal(content: any, user: Employee) {
    this.modalService.open(content,
       { centered: true, 
          size: 'lg', 
          windowClass: 'detailsModal' ,
          backdrop: 'static'
        });
    this.selectedUserDetails = user;
    this.showEmpDetails = true;
    this.showScheduleContent = false;
    this.showBannedContent = false;
    this.getAllPositions(this.selectedUserDetails.userId);
    this.getBanned(this.selectedUserDetails.userId);
    
  }

  openModalMd(content: any, user: Employee) {
    this.modalService.open(content,
       { centered: true, 
          size: 'md', 
          windowClass: 'terminateModal' 
        });
    this.selectedTerminated = user;
    console.log(this.selectedTerminated);
  }

  

  closeEditModal() {
    // delete this.selectedEditable;
    // this.selectedEditId = "";
    console.log(this.selectedEditable);
    this.modalService.dismissAll();
  }

  closeTerminateModal() {
    delete this.selectedTerminated;
    console.log(this.selectedTerminated);
    this.modalService.dismissAll();
  }

  onPageChangeActive(page: number): void {
    this.loading = true;
    this.displayTableActive = false;
    this.getActiveEmployees(page);
  }

  onPageChangeInActive(page: number): void {
    this.loading = true;
    this.displayTableInActive = false;
    this.getInActiveEmployees(page);
  }

  

  async terminateEmployee(f: NgForm){
    console.log(this.selectedTerminated);
    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/employees/${this.selectedTerminated.userId}/terminate`, 
       {
          reason: f.value.terminateReason,
          isRehirable: f.value.rehire,

       }).subscribe( 
       (res: any) => {
          this.successMessage = res.message;
          this.fetchError = false;
          window.location.reload();
          // f.reset();
          // this.closeTerminateModal();
       } ,
       (error : any) => {
        this.fetchError = true;
        this.closeTerminateModal();
        this.errorMessage= error.error.message;
       });


   } catch (error) {
    console.log(error);
     this.data.error(error['message']);
     
   }
  }

  async unTerminateEmployee(userId: string){
    // console.log(this.selectedTerminated);
    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/employees/${userId}/unterminate`, 
       {
          

       }).subscribe( 
       (res: any) => {

          window.location.reload();
          // f.reset();
          // this.closeTerminateModal();
       } ,
       (error : any) => {

       });


   } catch (error) {
    console.log(error);
     this.data.error(error['message']);
     
   }
  }
  
  async getRoles(){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/roles/employee`
      ).subscribe( 
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.Roles = res;
          this.loading = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getZones(){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/zones?all=true`
      ).subscribe( 
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.Zones = res.data;
          this.loading = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  onChangePassword() {
    this.formConfirmPassword = '';
  }

  onChangeConfirmPassword(){
    if(this.formPassword === this.formConfirmPassword){
      this.validPassword = true;
      this.showMatchMessage = false;
    }
    else{
      this.validPassword = false;
      this.showMatchMessage = true;
    }
  }

  async getCountries(){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/countries`
      ).subscribe( 
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.Countries = res;
          this.loading = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getCities(id: string){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/cities/${id}`
      ).subscribe( 
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.Cities = res;
          this.loading = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getAreas(id: string){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/areas/${id}`
      ).subscribe( 
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.Areas = res;
          this.loading = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  onChangeCountry(selectedValue: string) {
    this.getCities(selectedValue);
    this.Areas = [];
  }

  onChangeCity(selectedValue: string) {
    this.getAreas(selectedValue);
  }

  async getLicenses(pager?:number){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/licenses?all=true`
      ).subscribe( 
        (res: any) => {
          this.successMessage = res.message;
          this.fetchError = false;
          this.licenses = res.data;
          this.loading = false;
        },
       (error: any ) => {
          this.fetchError = true;
          this.loading = false;
          this.errorMessage= error.error.message;
       });

    } catch (error) {
      this.data.error(error['message']);
      this.loading = true;
    }
  }

  selectedLicenseId: number | null = null;
  retrivedLicenses: License[] = [];
  selectedLicenseIssueDate = '';
  selectedLicenseExpiryDate = '';
  selectedLicenseNumber: number;
  finalLicenses: LicenseEmployee[] = [];
  selectedLicense: License | null = null;
  
  updateForm() {
    
    this.retrivedLicenses = this.licenses;
    this.selectedLicense = this.retrivedLicenses.find(license => license.id == this.selectedLicenseId);
    this.resetFormFields();
    console.log(this.selectedLicense);
  }

  resetFormFields() {
    this.selectedLicenseIssueDate = '';
    this.selectedLicenseExpiryDate = '';
    this.selectedLicenseNumber = null;
  }


  removeFinalLicense(index: number) {
    this.finalLicenses.splice(index, 1);
  }

  submitForm() {
    const selectedIdx = this.licenses.findIndex(license => license.id == this.selectedLicense.id);
    if (selectedIdx !== -1 && this.selectedLicense !== null) {
      const selectedName = this.licenses[selectedIdx].name;
      const newLicense: LicenseEmployee = {
        licenseId:this.selectedLicense.id,
        name: selectedName,
        issueDate: this.selectedLicenseIssueDate,
        expirationDate: this.selectedLicenseExpiryDate,
        licenseNumber: this.selectedLicenseNumber
      };
      this.finalLicenses.push(newLicense);
      this.resetFormFields();
      this.selectedLicenseId = null;
      this.selectedLicense = null;
    }

    console.log(this.finalLicenses);
  }

  areAllInputsFilled(): boolean {
    if (this.selectedLicense.hasIssueDate === 1 && !this.selectedLicenseIssueDate) {
        return false;
    }
    if (this.selectedLicense.hasExpiryDate === 1 && !this.selectedLicenseExpiryDate) {
        return false;
    }
    if (this.selectedLicense.hasLicenseNumber === 1 && !this.selectedLicenseNumber) {
        return false;
    }
    return true;
}


async updateEmployee(f: NgForm){
  
  try {
    await this.rest.post(
     `${this.data.getBaseUrl()}api/v1/employees/${this.selectedEditId}`, 
     {
      firstName: f.value.empFirstName,
      lastName: f.value.empLastName,
      email: f.value.empEmail,
      mobile: f.value.empMobile,
      username: f.value.empUserName,
      internalId: f.value.empInternalId,
      birthdate: f.value.empBirthdate,
      employmentDate: f.value.empEmploymentdate,
      payrate: f.value.empPayRate,
      payType: f.value.empPayType,
      roleId: f.value.empRole,
      zoneId: f.value.empZone,
      isActive: f.value.empStatus,
      hiringStatus: f.value.empHiring,
      gender: f.value.empGender,
      password: f.value.empPassword,
      passwordConfirmation: f.value.confirmEmpPassword,
      licenses: this.finalLicenses,
      uniforms: this.finalUniforms,
      address :{
        countryId: f.value.empCountry,
        cityId: f.value.empCity,
        areaId: f.value.empArea,
        zipcode: f.value.empZipCode,
        address: f.value.empAdressStreet
      }

     }).subscribe( 
     (res: any) => {
       this.successMessage = res.message;
       this.fetchError = false;
       this.validPassword = true;
       window.location.reload();
     } ,
    (error : any) => {
      // this.errorMessage= error.error.message;
      error.error.errors.forEach(err => {
        switch (err.field) {
          case 'email':
            this.emailErrorMessage = err.message;
            break;
          case 'mobile':
            this.mobileErrorMessage = err.message;
            break;
          case 'username':
            this.usernameErrorMessage = err.message;
            break;

          case 'password':
            this.passwordErrorMessage = err.message;
            break;
            case 'internalId':
            this.internalIdErrorMessage = err.message;
            break;
            case 'birthdate':
            this.birthdateErrorMessage = err.message;
            break;
            case 'employmentDate':
            this.employmentDateErrorMessage = err.message;
            break;
            case 'payrate':
            this.payRateErrorMessage = err.message;
            break;
            case 'firstName':
            this.fNameErrorMessage = err.message;
            break;
            case 'lastName':
            this.lNameErrorMessage = err.message;
            break;
            case 'passwordConfirmation':
            this.passwordConfirmErrorMessage = err.message;
            break;
            case 'licenses':
            this.licensesErrorMessage = err.message;
            break;
            case 'isActive':
            this.activeErrorMessage = err.message;
            break;
            case 'address.countryId':
            this.countryErrorMessage = err.message;
            break;
            case 'address.cityId':
            this.cityErrorMessage = err.message;
            break;
            case 'address.areaId':
            this.areaErrorMessage = err.message;
            break;
            case 'address.address':
            this.addressErrorMessage = err.message;
            break;
            case 'address.zipcode':
              this.zipCodeErrorMessage = err.message;
              break;
            case 'gender':
            this.genderErrorMessage = err.message;
            break;
          // Add more cases for other fields if needed
        }
      });
      console.log(error.error.errors);
    }
  );

 } catch (error) {
  this.ErrorList = error;
  console.log("Catch Error List: " + error);
   this.data.error(error['message']);
 }
}


// Employee Schedule

showEmpDetails:boolean = false;
showScheduleContent:boolean = true;
showBannedContent:boolean = false;

displayScheduleContent:boolean = false;
loading_schedule:boolean = true;

calendarOptions: CalendarOptions;
currentEvents: EventApi[] = [];

getEmployeeSites: EmployeeSites[]=[]

// assignedEmployees: EmployeeSites[]=[];
calendarTest: EventInput[]=[];

showDetails() {
  this.showEmpDetails = true;
  this.showScheduleContent = false;
  this.showBannedContent = false;
}

showSchedulers() {
  this.showEmpDetails = false;
  this.showScheduleContent = true;
  this.showBannedContent = false;
  // this.renderer.selectRootElement(this.loadScheduleBtn.nativeElement).click();
  // this.setOptions()

}

showBanned() {
  this.showEmpDetails = false;
  this.showScheduleContent = false;
  this.showBannedContent = true;
}

closeModal() {
  // delete this.selectedUserDetails;
  // console.log(this.selectedUserDetails);
  this.loading_schedule = true;
  this.displayScheduleContent = false;
  this.modalService.dismissAll();
  this.getEmployeeSites = [];
  this.calendarTest = [];
  this.displayScheduleContent = false;
  this.loading_schedule = true;
  console.log(this.getEmployeeSites);
}

BannedSites: any[] = [];
async getBanned(siteId:string){
  try {
    await this.rest.getAll(
      `${this.data.getBaseUrl()}api/v1/employees/${siteId}/banned-sites`
    ).subscribe(
      (res: any) => {
        this.fetchError = false;
        this.BannedSites = res;
        this.loading = false;
      },
      (error: any) => {
        console.log(error);
    });

  } catch (error) {
    console.log("error"+ error);
    this.data.error(error['message']);
    // this.loading = true;

  }
}


async getAllPositions(userId: string){
  try {
    await this.rest.getAll(
      `${this.data.getBaseUrl()}api/v1/employees/${userId}/schedulers/calendar`
    ).subscribe(
      (res: any) => {
        this.getEmployeeSites = res;
        console.log(this.getEmployeeSites);
        // this.setOptions;
        
      },
      (error: any) => {
        this.fetchError = true;
        // this.loading_schedule = true;
        this.displayScheduleContent = false;

     });

  } catch (error) {
    this.data.error(error['message']);
    // this.loading = true;

  }
}


  viewEmp(employee: Employee): void {
    this.router.navigate(['/employee-details', employee.userId]);
  }


  // Uniforms

  

  // Uniforms

  uniforms: Uniform[] = [];
  async getUniforms(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/uniforms`
      ).subscribe( 
        (res: any) => {
          this.successMessage = res.message;
          this.fetchError = false;
          this.uniforms = res.data;
          this.loading = false;
        },
        (error: any) => {
          this.fetchError = true;
          this.loading = false;
          this.errorMessage= error.error.message;
       });

    } catch (error) {
      console.log("error Zones"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }


  selectedUniformId: number | null = null;
  retrivedUniforms: Uniform[] = [];
  finalUniforms: UniformEmp[] = [];
  selectedUniform: Uniform | null = null;

  uniformErrorMessage="";

  selectedUniformDate = '';
  selectedUniformQty: number;

  resetUniFormFields() {
    this.selectedUniformDate = '';
    this.selectedUniformQty = null;
  }

  updateUniForm() {
    
    this.retrivedUniforms = this.uniforms;
    this.selectedUniform = this.retrivedUniforms.find(uniform => uniform.id == this.selectedUniformId);
    this.areAllInputsFilled_unifrom();
    console.log(this.selectedUniform);
  }

  areAllInputsFilled_unifrom(): boolean {
    if (!this.selectedUniformDate) {
        return false;
    }
    if (!this.selectedUniformQty) {
        return false;
    }
    
    return true;
  }

  submitUniForm() {
    const selectedIdx = this.uniforms.findIndex(uniform => uniform.id == this.selectedUniform.id);
    if (selectedIdx !== -1 && this.selectedUniform !== null) {
      const newUniform: UniformEmp = {
        uniformId: this.selectedUniformId,
        name: this.selectedUniform.name,
        receivingDate: this.selectedUniformDate,
        quantity: this.selectedUniformQty
      };
      this.finalUniforms.push(newUniform);
      this.resetUniFormFields();
      this.selectedUniformId = null;
      this.selectedUniform = null;
    }
    console.log(this.finalUniforms);
  }

  removeFinalUniform(index: number) {
    this.finalUniforms.splice(index, 1);
  }
}
