import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from '../services/data.service';
import { Site } from '../models/site'; // Import your model here

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  constructor(private http: HttpClient,  private data: DataService) {}

  getHeaders() {
    const token = localStorage.getItem('token');
    return token ? new HttpHeaders().set('Authorization', 'Bearer ' + token) : null;
  }
  
  getSiteDetails(siteId: number): Observable<Site> {
    return this.http.get<Site>(`${this.data.getBaseUrl()}api/v1/sites/${siteId}`, { headers: this.getHeaders() }); // Replace with your API endpoint
  }

  
}
