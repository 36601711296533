<div class="container-fluid">
    <div class="row">

      <div class="col-12 mb-3 alignContent-spaceBetween">
        <h2>Uniforms</h2>
        <button class="btn submitBtn" (click)="openModal(addUniformModal)"><i class="fas fa-plus"></i> Add Uniform</button>
      </div>

      <div class="col-12" *ngIf="fetchError">
        <ngb-alert type="danger"  [dismissible]="false" class="text-center"><strong>Error:</strong> {{errorMessage}}</ngb-alert>
      </div>

      <app-loader [loading]="loading"></app-loader>
      
      <div class="col-12" *ngIf="!fetchError && !loading">
        <div class="card">
          <div class="card-body" *ngIf="displayTable">
            <div class="table-responsive">
              <table datatable [dtOptions]="dtOptions" id="datatable" class="datatable table align-middle table-nowrap table-hover">
                <thead class="table-light">
                  <tr>
                    
                    <th scope="col">Uniform Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let uniform of uniforms">
                    
                    <td>{{uniform.name}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <app-pagination
                [total]="PaginationObj.total"
                [perPage]="PaginationObj.perPage"
                [currentPage]="PaginationObj.currentPage"
                [firstPage]="PaginationObj.firstPage"
                [lastPage]="PaginationObj.lastPage"
                [nextPage]="PaginationObj.nextPage"
                [previousPage]="PaginationObj.previousPage"
                (pageChange)="onPageChange($event)"
              ></app-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>


<ng-template #addUniformModal role="document" let-modal >
  <div class="modal-header">
    <h5 class="modal-title mt-0">Add Uniform</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">

    <div class="row">
      <div *ngIf="errorMessage" class="col-12">
        <ngb-alert type="danger" [dismissible]="false" class="text-center">{{errorMessage}}</ngb-alert>
      </div>
      <div class="col-12">

        <form #f="ngForm" >
          <div class="row">

            <div class="form-group col-12">
              <label for="">Uniform Name</label>
              <input type="text" class="form-control" name="uniName" ngModel required #uniName="ngModel">
              <span class="text-danger" *ngIf="!uniName.valid && uniName.touched">Please Enter A Valid Answer</span>
            </div>

            <div class="col-12 text-center">
              <button type="submit" [disabled]="!f.valid" class="btn submitBtn" (click)="addUniform(f)"><i class="fas fa-plus"></i> Add</button>
        
              <button class="btn cancelBtn" (click)="modal.dismiss('Cross click')">Cancel</button>
              
            </div>
          </div>

        </form>
      </div>
    </div>
    
    
  </div>
  
</ng-template>