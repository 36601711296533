import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PayrunEmployeeSites } from 'src/app/core/models/payrun_employee_sites';
import { PayrunEmployee } from 'src/app/core/models/payrun_employee';
import { PayrunRawItems } from 'src/app/core/models/payrun_rawItems';
import { PayrunSummary } from 'src/app/core/models/payrun_summary';
import { DataService } from 'src/app/core/services/data.service';
import { RestApiService } from 'src/app/core/services/rest-api.service';
import { PayrunEmployeeShifts } from 'src/app/core/models/payrun_employee_shifts';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-payroll-details',
  templateUrl: './payroll-details.component.html',
  styleUrls: ['./payroll-details.component.scss']
})
export class PayrollDetailsComponent implements OnInit {

  dtOptions: any = {
    paging: true,
    lengthChange: false,
    info: false,
    searching: true,
  };

  constructor(
    private route: ActivatedRoute,
    private rest: RestApiService,
    private data: DataService,
    private http: HttpClient
  ) { }

  ret_payrunId: string;

  Payrun_summary: PayrunSummary;
  Payrun_raw: PayrunRawItems;
  Payrun_employee: PayrunEmployee;
  Payrun_employeeShifts: PayrunEmployeeShifts;
  Payrun_employeeSites: PayrunEmployeeSites;

  loading = true;

  displaySummary: boolean = false;
  displayRaw: boolean = false;
  display_byEmployee: boolean = false;
  display_byEmployeeShifts: boolean = false;
  display_bySites: boolean = false;
  displayWarnings: boolean = false;

  summary_employees: number = 0;
  summary_totalCost: number = 0;
  summary_totalHours: number = 0;

  token: string = "";

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const payId = +params.get('id');
      this.ret_payrunId = payId.toString();

      this.token = localStorage.getItem('token');
    });

    this.getPayrun_summary();
    this.loadWarnings();
  }


  exportSummary() {
    this.http.get(
      `${this.data.getBaseUrl()}api/v1/mobile/payrun-summary/export/${this.ret_payrunId}`,
      {
        responseType: 'blob',  // ✅ Ensure blob response
        headers: new HttpHeaders({
          'Accept': 'application/pdf',  // ✅ Explicitly request PDF
          'Authorization': `Bearer ${this.token}`,  // Ensure Authorization header is included
        }),
      }
    ).subscribe(
      (res: Blob) => {
        // Create a Blob and open as a PDF
        const blob = new Blob([res], { type: 'application/pdf' });
        const fileURL = window.URL.createObjectURL(blob);
        window.open(fileURL); // ✅ Open in new tab
      },
      (err: HttpErrorResponse) => {
        this.loading = false;
        console.error('Error exporting PDF:', err);
  
        // Debugging: Log exact error details
        if (err.error instanceof Blob) {
          err.error.text().then((text) => {
            console.error('Error Response Text:', text);
          });
        }
      }
    );
  }
  
  

  async getPayrun_summary() {
    this.loading = true;
    this.displaySummary = false;
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/mobile/payrun-summary/${this.ret_payrunId}`
      ).subscribe(
        (res: any) => {
          this.Payrun_summary = res;
          this.summary_employees = res.summary.totalEmployees;
          this.summary_totalCost = res.summary.totalCost;
          this.summary_totalHours = res.summary.totalWorkedHours;
          this.loading = false;
          this.displaySummary = true;
        },
        (err) => {

          this.loading = true;
        });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getPayrun_rawItems() {
    this.loading = true;
    this.displayRaw = false;
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/mobile/employee-shifts/${this.ret_payrunId}/raw-items`
      ).subscribe(
        (res: any) => {
          this.Payrun_raw = res;
          this.loading = false;
          this.displayRaw = true;
        },
        (err) => {

          this.loading = true;
        });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getPayrun_byEmployee() {
    this.loading = true;
    this.display_byEmployee = false;
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/mobile/employee/${this.ret_payrunId}/doublepay-overtime`
      ).subscribe(
        (res: any) => {
          this.Payrun_employee = res;
          this.loading = false;
          this.display_byEmployee = true;
        },
        (err) => {

          this.loading = true;
        });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getPayrun_byEmployeeShifts() {
    this.loading = true;
    this.display_byEmployeeShifts = false;
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/mobile/employee-shifts/${this.ret_payrunId}/doublepay-overtime`
      ).subscribe(
        (res: any) => {
          this.Payrun_employeeShifts = res;
          this.loading = false;
          this.display_byEmployeeShifts = true;
        },
        (err) => {

          this.loading = true;
        });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getPayrun_byEmployeeSite() {
    this.loading = true;
    this.display_bySites = false;
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/mobile/employee-shifts/site/${this.ret_payrunId}/doublepay-overtime`
      ).subscribe(
        (res: any) => {
          this.Payrun_employeeSites = res;
          this.loading = false;
          this.display_bySites = true;
        },
        (err) => {

          this.loading = true;
        });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  shifts_over_12: number = 0;
  shifts_approved: number = 0;
  shifts_moreThanHours: number = 0;

  async getPayrun_shiftsOver() {

    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/mobile/employee-shifts-over-12-hours/${this.ret_payrunId}`
      ).subscribe(
        (res: any) => {
          this.shifts_over_12 = res.count;
        },
        (err) => {

          this.loading = true;
        });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getPayrun_shiftsApproved() {

    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/mobile/approved-and-scheduled-hours/${this.ret_payrunId}`
      ).subscribe(
        (res: any) => {
          this.shifts_approved = res.count;

        },
        (err) => {

          this.loading = true;
        });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getPayrun_shiftsMoreThanHours() {
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/mobile/employee-working-more-than-scheduled/${this.ret_payrunId}`
      ).subscribe(
        (res: any) => {
          this.shifts_moreThanHours = res.count;
          this.loading = false;
          this.displayWarnings = true;
        },
        (err) => {

          this.loading = true;
        });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  loadWarnings() {
    this.getPayrun_shiftsOver();
    this.getPayrun_shiftsApproved();
    this.getPayrun_shiftsMoreThanHours();
  }




}
