import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { AuthGuardService } from './services/auth-guard.service';
import { LayoutComponent } from './layouts/layout.component';
import { Page404Component } from './extrapages/page404/page404.component';
import { DefaultComponent } from './pages/dashboards/default/default.component';
import { TestPageComponent } from './components/test-page/test-page.component';
import { LoginComponent } from './account/auth/login/login.component';
import { UsersComponent } from './components/users/users.component';
import { HomeComponent } from './components/home/home.component';
import { AdminUsersComponent } from './components/admin-users/admin-users.component';
import { PermissonsGuardService } from './services/permissions_guard.service';
import { AdminTabs } from 'src/app/core/enums/adminTabs';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ZonesComponent } from './components/zones/zones.component';
import { EmployeesComponent } from './components/employees/employees.component';
import { AddEmployeeComponent } from './components/add-employee/add-employee.component';
import { LicenseComponent } from './components/license/license.component';
import { SitesComponent } from './components/sites/sites.component';
import { AddSiteComponent } from './components/add-site/add-site.component';
import { ViewSiteComponent } from './components/view-site/view-site.component';
import { AddContractComponent } from './components/add-contract/add-contract.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { SiteAllCalendarComponent } from './components/site-all-calendar/site-all-calendar.component';
import { TableTestComponent } from './components/table-test/table-test.component';
import { SiteTemplateComponent } from './components/site-template/site-template.component';
import { EmployeeDetailsComponent } from './components/employee-details/employee-details.component';
import { UniformsComponent } from './components/uniforms/uniforms.component';
import { EditSiteComponent } from './components/edit/edit-site/edit-site.component';
import { ZoneDetailsComponent } from './components/zones/zone-details/zone-details.component';
import { OptimizationComponent } from './components/zones/zone-details/optimization/optimization.component';
import { RolesComponent } from './components/roles/roles.component';
import { SignInLogsComponent } from './components/sign-in-logs/sign-in-logs.component';

const routes: Routes = [
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },

  { path: '', component: TestPageComponent, canActivate: [AuthGuardService],
    children: [
      { path: '',  component: HomeComponent, canActivate: [AuthGuardService] },
      { path: 'users', pathMatch: 'full', component: UsersComponent, canActivate: [AuthGuardService, PermissonsGuardService] },
      { path: 'zones', pathMatch: 'full', component: ZonesComponent, canActivate: [AuthGuardService, PermissonsGuardService] },
      { path: 'zones/zoneDetails/:id', pathMatch: 'full', component: ZoneDetailsComponent, canActivate: [AuthGuardService, PermissonsGuardService] },
      { path: 'zones/zoneDetails/:zoneId/optimization/:runId', pathMatch: 'full', component: OptimizationComponent, canActivate: [AuthGuardService, PermissonsGuardService] },
      { path: 'uniforms', pathMatch: 'full', component: UniformsComponent, canActivate: [AuthGuardService, PermissonsGuardService] },
      { path: 'employees', pathMatch: 'full', component: EmployeesComponent, canActivate: [AuthGuardService, PermissonsGuardService] },
      { path: 'addEmployee', pathMatch: 'full', component: AddEmployeeComponent, canActivate: [AuthGuardService, PermissonsGuardService] },
      { path: 'licenses', pathMatch: 'full', component: LicenseComponent, canActivate: [AuthGuardService, PermissonsGuardService] },
      { path: 'roles', pathMatch: 'full', component: RolesComponent, canActivate: [AuthGuardService, PermissonsGuardService] },
      { path: 'logs', pathMatch: 'full', component: SignInLogsComponent, canActivate: [AuthGuardService, PermissonsGuardService] },
      { path: 'sites', pathMatch: 'full', component: SitesComponent, canActivate: [AuthGuardService, PermissonsGuardService] },
      { path: 'addSite', pathMatch: 'full', component: AddSiteComponent, canActivate: [AuthGuardService, PermissonsGuardService] },
      { path: 'viewSite/:id', pathMatch: 'full', component: ViewSiteComponent, canActivate: [AuthGuardService, PermissonsGuardService] },
      { path: 'editSite/:id', pathMatch: 'full', component: EditSiteComponent, canActivate: [AuthGuardService, PermissonsGuardService] },
      { path: 'viewSite/addContract/:id', pathMatch: 'full', component: AddContractComponent, canActivate: [AuthGuardService, PermissonsGuardService] },
      { path: 'adminUsers', pathMatch: 'full', component: AdminUsersComponent, canActivate: [AuthGuardService, PermissonsGuardService] },
      { path: 'site/:siteId/calendar/:scheduleId', pathMatch: 'full', component: CalendarComponent, canActivate: [AuthGuardService, PermissonsGuardService] },
      { path: 'site/:siteId/calendar', pathMatch: 'full', component: SiteAllCalendarComponent, canActivate: [AuthGuardService, PermissonsGuardService] },
      { path: 'site/:siteId/site_template', pathMatch: 'full', component: SiteTemplateComponent, canActivate: [AuthGuardService, PermissonsGuardService] },
      { path: 'employee-details/:id', pathMatch: 'full', component: EmployeeDetailsComponent, canActivate: [AuthGuardService, PermissonsGuardService] },
      { path: 'tableTest', pathMatch: 'full', component: TableTestComponent, canActivate: [AuthGuardService, PermissonsGuardService] },

      { path: 'unauthorized', component: UnauthorizedComponent },
      { path: '**', component: Page404Component },
      
    ] 
  },
  
  // {
  //   path: '',
  //   component: DefaultComponent,
  //   children: [{
  //     path: 'login',
  //     component: LoginComponent
  //     }, {
  //     path: 'testPage',
  //     component: TestPageComponent,
  //     canActivate: [AuthGuardService]
  //   }]}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
