import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router  } from '@angular/router';
import { DataService } from '../../../../core/services/data.service';
import { RestApiService } from '../../../../core/services/rest-api.service';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { ToastrService } from 'ngx-toastr';

declare var google: any;
@Component({
  selector: 'app-optimization',
  templateUrl: './optimization.component.html',
  styleUrls: ['./optimization.component.scss']
})
export class OptimizationComponent implements OnInit {

  constructor(private toastr: ToastrService, private route: ActivatedRoute, private rest: RestApiService, private data: DataService, private modalService: NgbModal, private router: Router) { }

  zoneId:string = "";
  sheetId:string = "";
  loading:boolean = true;
  showContent:boolean = false;
  zoneDetails: Zone;

  ngOnInit(): void {
    const zone_id = this.route.snapshot.paramMap.get('zoneId');
    const sheet_id = this.route.snapshot.paramMap.get('runId');
    this.zoneId = zone_id;
    this.sheetId = sheet_id;
    // this.getZoneDetails(zone_id);
    this.getRunSheetOptimization(zone_id,sheet_id);

  }

  async getZoneDetails(zoneId:string){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/zones/${zoneId}`
      ).subscribe(
        (res: any) => {
          this.zoneDetails = res;
          this.loading = false;
          this.showContent = true;
        },
        (error: any) => {
          console.log(error);
      });
  
    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;
  
    }
  }

  runOptimization: any;

  schedules = [
    {
      scheduleId: 0,
      sortOrder: 0
    }
  ];

  async getRunSheetOptimization(zoneId:string, sheetId:string){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/zones/${zoneId}/run-sheets/${sheetId}/optimization`
      ).subscribe(
        (res: any) => {
          this.runOptimization = res;
          this.loading = false;
          this.showContent = true;
          this.locations = this.runOptimization.route;
          setTimeout(() => {
            this.initMap();
          }, 100);
        },
        (error: any) => {
          console.log(error);
      });
  
    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;
  
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.runOptimization.route, event.previousIndex, event.currentIndex);

    this.schedules = this.runOptimization.route.map((item, index) => ({
      scheduleId: item.scheduleId,
      sortOrder: index
    }));
    // console.log(this.schedules);
  }

  async saveOptimizeRunsheet(){

    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/zones/${this.zoneId}/run-sheets/${this.sheetId}/save`, 

       {
        schedules: this.schedules
       }
      ).subscribe( 
       (res: any) => {
        this.toastr.success('Sequence Saved', 'Optimization ',{
          positionClass: 'toast-bottom-right',
        });
       } ,
      (error : any) => {

        console.log(error.error);
      });


   } catch (error) {
     this.data.error(error['message']);
     console.log("error"+error);
     
   }
  }


  // Locations Map

  // add site location
  siteGeo: string = "";
  @ViewChild('mapElement', {static: false}) mapElement: ElementRef;

  // initMap() {
  //   var mapOptions: any;
  //   var map: any;
  //   var location: any;

  //   location = new google.maps.LatLng(30.083750, 31.365124);
  //   mapOptions = {
  //       zoom: 12,
  //       center: new google.maps.LatLng(30.083750, 31.365124),
  //       mapTypeId: google.maps.MapTypeId.RoadMap
  //   };

  //   map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);

  //   const marker = new google.maps.Marker({
  //     position: { lat: 30.083750, lng: 31.365124 },
  //     map: map,
  //     title: 'Loc 1!'
  //   },
  //   {
  //     position: { lat: 30.080833, lng: 31.361969 },
  //     map: map,
  //     title: 'Loc 2!'
  //   });


  // }

  map: google.maps.Map;
  geocoder: google.maps.Geocoder;
  directionsService: google.maps.DirectionsService;
  directionsRenderer: google.maps.DirectionsRenderer;
  
  // locations = [
  //   {
  //     "scheduleId": 40,
  //     "location": "El-Akad, Al Izab, El Matareya, Cairo Governorate, Egypt",
  //     "timeFrame": "23:30 - 03:00",
  //     "locationLabel": "TeamTik - Site Inspection"
  //   },
  //   {
  //     "scheduleId": 3,
  //     "location": "abas elakad, Nasr City, Cairo Governorate, Egypt",
  //     "timeFrame": "23:00 - 03:00",
  //     "locationLabel": "TeamTik - Site Inspection"
  //   },
  //   {
  //     "scheduleId": 6,
  //     "location": "elbaroon palace, heliopolis, Cairo Governorate, Egypt",
  //     "timeFrame": "23:00 - 03:00",
  //     "locationLabel": "TeamTik - Site Inspection"
  //   }
  // ];

  locations;

  initMap() {
    const mapOptions: google.maps.MapOptions = {
      zoom: 14,
      center: new google.maps.LatLng(30.083750, 31.365124),
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
    this.geocoder = new google.maps.Geocoder();
    this.directionsService = new google.maps.DirectionsService();
    this.directionsRenderer = new google.maps.DirectionsRenderer();
    this.directionsRenderer.setMap(this.map);

    // this.calculateAndDisplayRoute();

    if (this.locations.length === 1) {
      this.geocodeSingleLocation(this.locations[0]);
    } else if (this.locations.length > 1) {
      this.calculateAndDisplayRoute();
    }
  }

  geocodeSingleLocation(location: any) {
    this.geocoder.geocode({ address: location.location }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        this.map.setCenter(results[0].geometry.location);

        const marker = new google.maps.Marker({
          map: this.map,
          position: results[0].geometry.location,
          title: location.locationLabel
        });
      } else {
        console.error('Geocode was not successful for the following reason: ' + status);
      }
    });
  }

  calculateAndDisplayRoute() {
    const waypoints = this.locations.map(location => ({ location: location.location, stopover: true }));
    
    if (this.locations.length < 2) {
      console.error('At least two locations are required to draw a route.');
      return;
    }

    const origin = this.locations[0].location;
    const destination = this.locations[this.locations.length - 1].location;

    this.directionsService.route({
      origin: origin,
      destination: destination,
      waypoints: waypoints.slice(1, -1), // exclude the origin and destination
      travelMode: google.maps.TravelMode.DRIVING
    }, (response, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        this.directionsRenderer.setDirections(response);
      } else {
        console.error('Directions request failed due to ' + status);
      }
    });
  }

}
