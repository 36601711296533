import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/core/services/data.service';
import { RestApiService } from 'src/app/core/services/rest-api.service';
import { Site } from 'src/app/core/models/site';
import { SiteService } from 'src/app/core/services/siteDetails.service';

@Component({
  selector: 'app-add-contract',
  templateUrl: './add-contract.component.html',
  styleUrls: ['./add-contract.component.scss']
})
export class AddContractComponent implements OnInit {

  constructor(private siteService: SiteService, private rest: RestApiService, private data: DataService, private route: ActivatedRoute, private router: Router) { }

  @ViewChild('f') AddContractForm !: NgForm;

  contractSiteId: number;
  contractSite: Site;
  serviceTypes: any[] = [];
  paymentMethods: any[] = [];
  paymentTerms: any[] = [];
  billingRecurrences: any[] = [];
  billingTypes: any[] = [];
  billingItems: any[] = [];
  selectedTypes: any[] = [];
  taxClasses: any[] = [];
  holidayGroups: any[] = [];
  holidayBillings: any[] = [];

  // Error handling variables
  labelErrorMessage="";
  codeErrorMessage="";
  startDateErrorMessage="";
  endDateErrorMessage="";
  paymentMethodIdErrorMessage="";
  paymentTermIdErrorMessage="";
  externalContractIdErrorMessage="";
  poNumberErrorMessage="";
  estimatedPerPeriodRevenueErrorMessage="";
  billingRecurrenceIdErrorMessage="";
  billingTypeIdErrorMessage="";
  billingItemErrorMessage="";
  billingCycleRefDateErrorMessage="";
  serviceTypesErrorMessage="";
  taxClassErrorMessage="";
  holidayBillingsErrorMessage="";
  holidayGroupsErrorMessage="";
  billRateErrorMessage="";
  holidayMultipilerErrorMessage="";

  company_name = false;
  
  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const siteId = +params.get('id');
      this.contractSiteId = siteId;
    });
    this.getSites(this.contractSiteId);
    this.getServiceTypes();
    this.getPaymentMethods();
    this.getPaymentTerms();
    this.getBillingRecurrences();
    this.getBillingTypes();
    this.getBillingItems();
    this.getTaxClasses();
    this.getHolidayGroups();
    this.getHolidayBillings();
  }

  async getSites(siteId:number){
    try {
      this.siteService.getSiteDetails(siteId).subscribe(
        (res: Site) => {
          this.contractSite = res;
          this.company_name = true;
          console.log(res);
        },
        (error: any) => {
          console.log(error);
        });

    } catch (error) {
      this.data.error(error['message']);
    }
  
  }

  async getTaxClasses(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/tax-classes`
      ).subscribe( 
        (res: any) => {

          this.taxClasses = res;
        },
        (error: any) => {
          console.log(error);
       });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  async getHolidayGroups(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/holiday-groups`
      ).subscribe( 
        (res: any) => {

          this.holidayGroups = res;
        },
        (error: any) => {
          console.log(error);
       });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  async getHolidayBillings(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/holiday-billing`
      ).subscribe( 
        (res: any) => {

          this.holidayBillings = res;
        },
        (error: any) => {
          console.log(error);
       });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  async getServiceTypes(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/service-types`
      ).subscribe( 
        (res: any) => {

          this.serviceTypes = res;
        },
        (error: any) => {
          console.log(error);
       });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  async getPaymentMethods(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/payment-methods`
      ).subscribe( 
        (res: any) => {

          this.paymentMethods = res;
        },
        (error: any) => {
          console.log(error);
       });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  async getPaymentTerms(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/payment-terms`
      ).subscribe( 
        (res: any) => {

          this.paymentTerms = res;
        },
        (error: any) => {
          console.log(error);
       });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  async getBillingRecurrences(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/billing-recurrences`
      ).subscribe( 
        (res: any) => {

          this.billingRecurrences = res;
        },
        (error: any) => {
          console.log(error);
       });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  async getBillingTypes(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/billing-types`
      ).subscribe( 
        (res: any) => {

          this.billingTypes = res;
        },
        (error: any) => {
          console.log(error);
       });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  async getBillingItems(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/bill-items`
      ).subscribe( 
        (res: any) => {

          this.billingItems = res;
        },
        (error: any) => {
          console.log(error);
       });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  toggleServiceType(service: any) {
    if (this.isSelected(service)) {
      this.selectedTypes = this.selectedTypes.filter(id => id !== service.id);
    } else {
      this.selectedTypes.push(service.id);
    }
  }

  isSelected(service: any) {
    return this.selectedTypes.includes(service.id);
  }


  async addContract(){
    
    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/sites/${this.contractSiteId}/contracts`,

       {

        siteId: this.contractSiteId,
        // label: this.contractSite.company.name,
        // code: this.contractSite.id,
        // label: this.AddContractForm.value.contractLabel,
        // code: this.AddContractForm.value.contractCodeId,
        startDate: this.AddContractForm.value.contractServStartDate,
        endDate: this.AddContractForm.value.contractServEndDate,
        paymentMethodId: this.AddContractForm.value.contractPaymentMethod,
        paymentTermId: this.AddContractForm.value.contractPaymentTerms,
        externalContractId: this.AddContractForm.value.contractOptionalId,
        poNumber: this.AddContractForm.value.contractPONumber,
        estimatedPerPeriodRevenue: this.AddContractForm.value.estPerRevenue,
        billingRecurrenceId: this.AddContractForm.value.contractBillingRecurrence,
        billingTypeId: this.AddContractForm.value.contractBillingType,
        billingItemId: this.AddContractForm.value.contractBillingItem,
        billingCycleRefDate: this.AddContractForm.value.cycleRefDate,

        taxClassId: this.AddContractForm.value.taxClass,
        billRate: this.AddContractForm.value.billRate,
        holidayBillingId: this.AddContractForm.value.holidayBilling,
        holidayGroupId: this.AddContractForm.value.holidayGroup,
        holidayMultiplier: this.AddContractForm.value.holidayMultiplier,
        
        serviceTypes: this.selectedTypes,

       }
       
       ).subscribe( 
       (res: any) => {

         this.AddContractForm.reset();
         this.router.navigate([`/viewSite/${this.contractSiteId}`]);
       } ,
      (error : any) => {
        error.error.errors.forEach(err => {
          switch (err.field) {
            case 'label':
              this.labelErrorMessage = err.message;
              break;

            case 'code':
              this.codeErrorMessage = err.message;
              break;

            case 'startDate':
              this.startDateErrorMessage = err.message;
              break;

            case 'endDate':
              this.endDateErrorMessage = err.message;
              break;

            case 'paymentMethodId':
              this.paymentMethodIdErrorMessage = err.message;
              break;

            case 'paymentTermId':
              this.paymentTermIdErrorMessage = err.message;
              break;

            case 'externalContractId':
              this.externalContractIdErrorMessage = err.message;
              break;

            case 'poNumber':
              this.poNumberErrorMessage = err.message;
              break;

            case 'estimatedPerPeriodRevenue':
              this.estimatedPerPeriodRevenueErrorMessage = err.message;
              break;

            case 'billingRecurrenceId':
              this.billingRecurrenceIdErrorMessage = err.message;
              break;

            case 'billingTypeId':
              this.billingTypeIdErrorMessage = err.message;
              break;

            case 'billingCycleRefDate':
              this.billingCycleRefDateErrorMessage = err.message;
              break;

            case 'serviceTypes':
              this.serviceTypesErrorMessage = err.message;
              break;

              case 'taxClassId':
              this.taxClassErrorMessage = err.message;
              break;
              case 'billRate':
              this.billRateErrorMessage = err.message;
              break;
              case 'holidayBillingId':
              this.holidayBillingsErrorMessage = err.message;
              break;
              case 'holidayGroupId':
              this.holidayGroupsErrorMessage = err.message;
              break;
              case 'holidayMultiplier':
              this.holidayMultipilerErrorMessage = err.message;
              break;

            // Add more cases for other fields if needed
          }
        });
      }
    );

   } catch (error) {
     this.data.error(error['message']);
   }
  }

}
