import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/core/services/data.service';
import { RestApiService } from 'src/app/core/services/rest-api.service';
import { Zone } from 'src/app/core/models/zone';
import { Pagination } from 'src/app/core/models/pagination';
import { NgForm } from '@angular/forms';

import { Country } from 'src/app/core/models/country';
import { City } from 'src/app/core/models/city';
import { Area } from 'src/app/core/models/area';
import { Router } from '@angular/router';

@Component({
  selector: 'app-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.scss']
})
export class ZonesComponent implements OnInit {

  @ViewChild('f') AddZoneForm !: NgForm;
  @ViewChild('reset') resetPassForm !: NgForm;
  
  loading = true;
  fetchError = false;
  itemAdded = false;
  itemDeleted = false;

  Countries: Country[] = [];
  Cities: City[] = [];
  Areas: Area[] = [];

  dtOptions: any = {
    paging: false,
    lengthChange: false,
    info: false,
    searching: true,
  };

  Zones: Zone[] = [];
  displayTable: boolean = false;
  successMessage = '';
  errorMessage = '';

  detailsErrorMessage="";
  addressErrorMessage="";
  countryErrorMessage="";
  cityErrorMessage="";
  areaErrorMessage=""; 
  zipCodeErrorMessage=""; 

  PaginationObj: Pagination = {
    total: 0,
    perPage: 0,
    currentPage: 0,
    firstPage: 0,
    lastPage: 0,
    nextPage: 0,
    previousPage: 0,
    pageChange: new EventEmitter<number>(),
  };

  // Error handling variables
  internalIdErrorMessage = "";

  constructor( private rest: RestApiService, private data: DataService, private modalService: NgbModal, private router: Router ) { }


  ngOnInit(): void {
    this.getZones(1);
    this.getCountries();
  }

  async getCountries(){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/countries`
      ).subscribe( 
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.Countries = res;
          this.loading = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getCities(id: string){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/cities/${id}`
      ).subscribe( 
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.Cities = res;
          this.loading = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getAreas(id: string){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/areas/${id}`
      ).subscribe( 
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.Areas = res;
          this.loading = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  onChangeCountry(selectedValue: string) {
    this.getCities(selectedValue);
    this.Areas = [];
  }

  onChangeCity(selectedValue: string) {
    this.getAreas(selectedValue);
  }

  openModal(content: any) {
    this.modalService.open(content,
       { centered: true, 
          size: 'lg', 
          windowClass: 'addLicenseModal' 
        });
    
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  onPageChange(page: number): void {
    this.loading = true;
    this.displayTable = false;
    this.getZones(page);
  }
  
  async getZones(pager:number){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/zones?page=${pager}`
      ).subscribe( 
        (res: any) => {
          this.successMessage = res.message;
          this.fetchError = false;
          this.Zones = res.data;
          this.PaginationObj = res.pagination;
          this.loading = false;
          this.displayTable = true;
        },
        (error: any) => {
          this.fetchError = true;
          this.loading = false;
          this.errorMessage= error.error.message;
       });

    } catch (error) {
      console.log("error Zones"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  onViewZone(zoneId: number) {
    this.router.navigate(['/zones/zoneDetails', zoneId]);
  }

  async addZone(f: NgForm){
    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/zones`, 
       {
          name: f.value.zoneName,
          internalId: f.value.zoneIntId,
          details: f.value.zoneDetails,
          address: f.value.zoneAddress,
          countryId: f.value.siteCountry,
          cityId: f.value.siteCity,
          areaId: f.value.siteArea,
          zipcode: f.value.zoneZipCode,

       }).subscribe( 
       (res: any) => {
         this.successMessage = res.message;
        //  this.getAdminUsers();
         this.fetchError = false;
         this.itemAdded = true;
         window.location.reload();
         f.reset();
         this.closeModal();
       } ,
      (error : any) => {
       
      //  this.errorMessage= error.error.message;
      //  this.internalIdErrorMessage= error.error.message;
       error.error.errors.forEach(err => {
        switch (err.field) {
          case 'internalId':
            this.internalIdErrorMessage = err.message;
            break;
          case 'details':
            this.detailsErrorMessage = err.message;
            break;
          case 'address':
            this.addressErrorMessage = err.message;
            break;

          case 'countryId':
            this.countryErrorMessage = err.message;
            break;
            case 'cityId':
            this.cityErrorMessage = err.message;
            break;
            case 'areaId':
            this.areaErrorMessage = err.message;
            break;
            case 'zipcode':
            this.zipCodeErrorMessage = err.message;
            break;
          // Add more cases for other fields if needed
        }
      });
      console.log(error.error);
      });


   } catch (error) {
     this.data.error(error['message']);
     console.log("error"+error);
     
   }
  }

}
