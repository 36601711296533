import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Employee } from '../models/employee';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class RestApiService {

  constructor(private http: HttpClient, private router: Router) { }

  getHeaders() {
    const token = localStorage.getItem('token');
    return token ? new HttpHeaders().set('Authorization', 'Bearer ' + token) : null;
  }

  getAll(link: string){
    return this.http.get<any>(link, { headers: this.getHeaders() });
  }

  getAllUsers(link: string) {
    return this.http.get<any>(link, { headers: this.getHeaders() });
  }

  getAllServices(link: string) {
    return this.http.get(link, { headers: this.getHeaders() });
  }

  getAllBookings(link: string) {
    return this.http.get(link, { headers: this.getHeaders() });
  }

  deleteWithId(link: string) {
    return this.http.delete(link, { headers: this.getHeaders() });
  }


  logout() {
    localStorage.clear();
    this.router.navigate(['/account/login']);
  }

  // post(link: string, body: any) {
  //   return this.http.post(link, body).toPromise();
  // }

  post(link: string, body: any) {
    return this.http.post(link, body, { headers: this.getHeaders() });
  }

  // delete(link: string) {

  //   return this.http.delete(link, { headers: this.getHeaders()}).toPromise();

  // }
  // public upload(link: string,formData) {

  //   return this.http.post<any>(link, formData, {
  //       reportProgress: true,
  //       observe: 'events'
  //     });
  // }

}
