import { Injectable } from '@angular/core';

import { NavigationStart, Router } from '@angular/router';

@Injectable()
export class DataService {
    message = '';
    messageType = 'danger';
    user: any;

    constructor(private router: Router) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.message = '';
            }
        });
    }

    error(message) {
        // this.messageType = 'danger';
        // this.message = message;
        // this.openSnackBar(message, 'Dismiss');
        console.log(message);
      }
    
    success(message) {
        // this.messageType = 'success';
        // this.message = message;
        // this.openSnackBar(message, 'Dismiss');
        console.log(message);
    }
    
    warning(message) {
        // this.messageType = 'warning';
        // this.message = message;
        console.log(message);
    }


    getBaseUrl() {
        return 'https://api.teamtik.com/'
    }

    getBaseUrlOfImg() {
        return 'https://api.teamtik.com'
    }

    // getBaseUrlEinvoiceServer() {
    //     return 'https://d3e8-197-47-14-130.ngrok.io/api/'
    // }


}