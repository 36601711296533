<div class="container-fluid">
    <div class="row">

        <div class="col-12 mb-3 alignContent-spaceBetween">
            <h2>Payrun Details</h2>
            <button class="btn submitBtn" (click)="exportSummary()"><i class="fas fa-file-export"></i> Export</button>

        </div>

        <div class="payrun_info">
            <div class="singleDetail">
                <p><i class="fas fa-users"></i> {{summary_employees}}</p>
                <span>Hourly employees</span>
            </div>
            <div class="singleDetail">
                <p><i class="fas fa-dollar-sign"></i> {{summary_totalCost}}</p>
                <span>Total Costs</span>
            </div>
            <div class="singleDetail">
                <p><i class="fas fa-clock"></i> {{summary_totalHours}}</p>
                <span>Total Hours</span>
            </div>
        </div>

        <div>
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" (click)="getPayrun_summary();" id="pills-summary-tab"
                        data-bs-toggle="pill" data-bs-target="#pills-summary" type="button" role="tab"
                        aria-controls="pills-summary" aria-selected="true">Summary</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" (click)="getPayrun_rawItems();" id="pills-raw-items-tab"
                        data-bs-toggle="pill" data-bs-target="#pills-raw-items" type="button" role="tab"
                        aria-controls="pills-raw-items" aria-selected="false">Raw Items</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" (click)="getPayrun_byEmployee();" id="pills-employees-tab"
                        data-bs-toggle="pill" data-bs-target="#pills-employees" type="button" role="tab"
                        aria-controls="pills-employees" aria-selected="false">By Employees</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" (click)="getPayrun_byEmployeeShifts();" id="pills-employees-shifts-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-employees-shifts" type="button" role="tab"
                        aria-controls="pills-employees-shifts" aria-selected="false">By Employees/Shifts</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" (click)="getPayrun_byEmployeeSite();" id="pills-account-position-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-account-position" type="button" role="tab"
                        aria-controls="pills-account-position" aria-selected="false">By Account/Position</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="pills-warnings-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-warnings" type="button" role="tab"
                        aria-controls="pills-warnings" aria-selected="false">Warnings</button>
                </li>
            </ul>

            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-summary" role="tabpanel"
                    aria-labelledby="pills-summary-tab" tabindex="0">
                    <div class="card" *ngIf="!loading">
                        <div class="card-body" *ngIf="displaySummary">
                            <div class="summary_info">
                                <div class="singleDetail">
                                    <p>{{Payrun_summary.summary.totalEmployees}}</p>
                                    <span>Employees</span>
                                </div>
                                <div class="singleDetail">
                                    <p>${{Payrun_summary.summary.totalCost}}</p>
                                    <span>Total Cost</span>
                                </div>
                                <div class="singleDetail">
                                    <p>{{Payrun_summary.summary.totalWorkedHours}}</p>
                                    <span>Total Hours</span>
                                </div>
                                <div class="singleDetail">
                                    <p>{{Payrun_summary.summary.totalOvertimeHours}}</p>
                                    <span>Overtime Hours</span>
                                </div>
                                <div class="singleDetail">
                                    <p>{{Payrun_summary.summary.holidayHours}}</p>
                                    <span>Holiday Hours</span>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table datatable [dtOptions]="dtOptions" id="datatable"
                                    class="datatable table align-middle table-nowrap table-hover">
                                    <thead class="table-light">
                                        <tr>

                                            <th scope="col">Name</th>
                                            <th scope="col">Position</th>
                                            <th scope="col">Approved Hours</th>
                                            <th scope="col">Break Time</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Working Hours</th>
                                            <th scope="col">Clock In/Out</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let Pay of Payrun_summary.shifts">

                                            <td>{{Pay.shifts.user.firstName}} {{Pay.shifts.user.lastName}}</td>

                                            <td>{{Pay.shifts.employeeShift.site.siteAddress}}</td>
                                            <td>{{Pay.shifts.employeeShift.approvedHours}}</td>
                                            <td>{{Pay.shifts.employeeShift.actuelbreakTime}}</td>
                                            <td>{{Pay.shifts.employeeShift.status}}</td>
                                            <td>{{Pay.shifts.employeeShift.workingHours}}</td>
                                            <td>{{Pay.shifts.employeeShift.clockIn | date:
                                                "yyyy-MM-dd"}}/{{Pay.employeeShift.clockOut | date: "yyyy-MM-dd"}}</td>



                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-raw-items" role="tabpanel" aria-labelledby="pills-raw-items-tab"
                    tabindex="0">
                    <div class="card" *ngIf="!loading">
                        <div class="card-body" *ngIf="displayRaw">
                            <div class="table-responsive">
                                <table datatable [dtOptions]="dtOptions" id="datatable"
                                    class="datatable table align-middle table-nowrap table-hover">
                                    <thead class="table-light">
                                        <tr>

                                            <th scope="col">Name</th>
                                            <th scope="col">Position</th>
                                            <th scope="col">Approved Hours</th>
                                            <th scope="col">Break Time</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Working Hours</th>
                                            <th scope="col">Overtime</th>
                                            <th scope="col">Clock In/Out</th>
                                            <th scope="col">Total Cost</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let Pay of Payrun_summary.shifts">

                                            <td>{{Pay.employeeShift.firstName}} {{Pay.employeeShift.lastName}}</td>

                                            <td>{{Pay.employeeShift.location}}</td>
                                            <td>{{Pay.employeeShift.approvedHours}}</td>
                                            <td>{{Pay.employeeShift.actuelbreakTime}}</td>
                                            <td>{{Pay.employeeShift.status}}</td>
                                            <td>{{Pay.employeeShift.workingHours}}</td>
                                            <td>{{Pay.employeeShift.overtimeHours}}</td>
                                            <td>{{Pay.employeeShift.clockIn | date:
                                                "yyyy-MM-dd"}}/{{Pay.employeeShift.clockOut | date: "yyyy-MM-dd"}}</td>
                                            <td>{{Pay.employeeShift.shiftTotalCost}}</td>



                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-employees" role="tabpanel" aria-labelledby="pills-employees-tab"
                    tabindex="0">
                    <div class="card" *ngIf="!loading">
                        <div class="card-body" *ngIf="display_byEmployee">
                            <div class="table-responsive">
                                <table datatable [dtOptions]="dtOptions" id="datatable"
                                    class="datatable table align-middle table-nowrap table-hover">
                                    <thead class="table-light">
                                        <tr>

                                            <th scope="col">ID</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Reg Hours</th>
                                            <th scope="col">Overtime</th>
                                            <th scope="col">Worked Hours</th>
                                            <th scope="col">Total Cost</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let Pay of Payrun_employee">

                                            <td>{{Pay.userId}}</td>
                                            <td>{{Pay.firstName}} {{Pay.lastName}}</td>
                                            <td>{{Pay.totalRegularHours}}</td>
                                            <td>{{Pay.totalOvertimeHours}}</td>
                                            <td>{{Pay.totalWorkedHours}}</td>
                                            <td>{{Pay.totalCost}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-employees-shifts" role="tabpanel"
                    aria-labelledby="pills-employees-shifts-tab" tabindex="0">
                    <div class="card" *ngIf="!loading">
                        <div class="card-body" *ngIf="display_byEmployeeShifts">
                            <div class="table-responsive">
                                <table datatable [dtOptions]="dtOptions" id="datatable"
                                    class="datatable table align-middle table-nowrap table-hover">
                                    <thead class="table-light">
                                        <tr>

                                            <th scope="col">ID</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Clock In/Out</th>
                                            <th scope="col">Account</th>
                                            <th scope="col">Position</th>
                                            <th scope="col">Reg Hours</th>
                                            <th scope="col">Working Hours</th>
                                            <th scope="col">Overtime</th>
                                            <th scope="col">Double Pay</th>
                                            <th scope="col">Approved Hours</th>
                                            <th scope="col">Base Rate</th>
                                            <th scope="col">PR By Hour</th>
                                            <th scope="col">Sum Hours</th>
                                            <th scope="col">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let Pay of Payrun_employeeShifts.shifts">

                                            <td>{{Pay.userId}}</td>
                                            <td>{{Pay.employeeShift.firstName}} {{Pay.employeeShift.lastName}}</td>
                                            <td>{{Pay.clockInTime | date:
                                                "yyyy-MM-dd"}} / {{Pay.clockOutTime | date: "yyyy-MM-dd"}}</td>
                                            <td>{{Pay.siteCompany}}</td>
                                            <td>{{Pay.siteAddress}}</td>
                                            <td>{{Pay.regularHours}}</td>
                                            <td>{{Pay.workingHours}}</td>
                                            <td>{{Pay.overtime}}</td>
                                            <td>{{ Pay.isDoublePay ? 'Yes' : 'No' }}</td>
                                            <td>{{Pay.approvedHours}}</td>
                                            <td>{{Pay.payrollRate}}</td>
                                            <td>{{Pay.payrollByHour}}</td>
                                            <td>{{Pay.workingHours + Pay.overtime}}</td>
                                            <td>${{Pay.shiftTotalCost}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-account-position" role="tabpanel"
                    aria-labelledby="pills-account-position-tab" tabindex="0">
                    <div class="card" *ngIf="!loading">
                        <div class="card-body" *ngIf="display_bySites">
                            <div class="table-responsive">
                                <table datatable [dtOptions]="dtOptions" id="datatable"
                                    class="datatable table align-middle table-nowrap table-hover">
                                    <thead class="table-light">
                                        <tr>

                                            <th scope="col">ID</th>
                                            <th scope="col">Address</th>
                                            <th scope="col">Location</th>
                                            <th scope="col">Reg Hours</th>
                                            <th scope="col">Working Hours</th>
                                            <th scope="col">Overtime</th>
                                            <th scope="col">Double Pay</th>
                                            <th scope="col">Approved Hours</th>
                                            <th scope="col">PR Rate</th>
                                            <th scope="col">PR By Hour</th>
                                            <th scope="col">Sum Hours</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let Pay of Payrun_employeeSites.shifts">

                                            <td>{{Pay.siteId}}</td>
                                            <td>{{Pay.siteAddress}}</td>
                                            <td>{{Pay.location}}</td>
                                            <td>{{Pay.regularHours}}</td>
                                            <td>{{Pay.workingHours}}</td>
                                            <td>{{Pay.overtime}}</td>
                                            <td>{{ Pay.isDoublePay ? 'Yes' : 'No' }}</td>
                                            <td>{{Pay.approvedHours}}</td>
                                            <td>{{Pay.payrollRate}}</td>
                                            <td>{{Pay.payrollByHour}}</td>
                                            <td>{{Pay.workingHours + Pay.overtime}}</td>
                                            
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-warnings" role="tabpanel"
                    aria-labelledby="pills-warnings-tab" tabindex="0">
                    <div class="card" *ngIf="!loading">
                        <div class="card-body" *ngIf="displayWarnings">
                            <div class="table-responsive">
                                <table datatable [dtOptions]="dtOptions" id="datatable"
                                    class="datatable table align-middle table-nowrap table-hover">
                                    <thead class="table-light">
                                        <tr>

                                            <th scope="col">Type</th>
                                            <th scope="col">Count</th>
                                            <th scope="col">Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><span class="status_warning">Warning</span></td>
                                            <td>{{shifts_over_12}}</td>
                                            <td>Shift has over 12 hours.</td>
                                        </tr>
                                        <tr>
                                            <td><span class="status_warning">Warning</span></td>
                                            <td>{{shifts_moreThanHours}}</td>
                                            <td>Shift(s) is 1 hour past the scheduled time</td>
                                        </tr>
                                        <tr>
                                            <td><span class="status_warning">Warning</span></td>
                                            <td>{{shifts_approved}}</td>
                                            <td>Approved hours are less than 50% of the scheduled ones</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>



        <app-loader [loading]="loading"></app-loader>

    </div>
</div>