import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/core/services/data.service";
import { RestApiService } from "src/app/core/services/rest-api.service";

@Component({
  selector: 'app-sign-in-logs',
  templateUrl: './sign-in-logs.component.html',
  styleUrls: ['./sign-in-logs.component.scss']
})
export class SignInLogsComponent implements OnInit {

  loading = true;
  displayTable: boolean = false;
  fetchError = false;
  successMessage = "";
  errorMessage = "";

  logs: any[] = [];
  
  constructor(
    private rest: RestApiService,
    private data: DataService,
  ) {}

  ngOnInit(): void {
    this.getLogs();
  }

  async getLogs() {
    this.loading = true;
    this.displayTable = false;
    try {
      await this.rest.getAll(`${this.data.getBaseUrl()}api/v1/login-logs`).subscribe(
        (res: any) => {

          this.logs = res;
          this.loading = false;
          this.displayTable = true;
        },
        (error: any) => {
          this.fetchError = true;
          this.loading = false;
          this.errorMessage = error.error.message;
        }
      );
    } catch (error) {
      this.data.error(error["message"]);
      this.loading = true;
    }
  }


}
