import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';

import { NgbNavModule, NgbAccordionModule, NgbTooltipModule, NgbModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { ExtrapagesModule } from './extrapages/extrapages.module';

import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initFirebaseBackend } from './authUtils';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { FakeBackendInterceptor } from './core/helpers/fake-backend';
import { DataService } from './core/services/data.service';
import { RestApiService } from './core/services/rest-api.service';
import { MainPageComponent } from './components/main-page/main-page.component';
import { TopbarComponent } from './layouts/topbar/topbar.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { RightsidebarComponent } from './layouts/rightsidebar/rightsidebar.component';
import { MainPageModule } from './components/main-page/main-page-module';
import { AuthGuardService } from './services/auth-guard.service';
import { PermissonsGuardService } from './services/permissions_guard.service';
import { UsersComponent } from './components/users/users.component';
import { HomeComponent } from './components/home/home.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SimplebarAngularModule } from 'simplebar-angular';
import { SharedModule } from './shared/shared.module';
import { WidgetModule } from './shared/widget/widget.module';
import { UIModule } from './shared/ui/ui.module';
import { DashboardsRoutingModule } from './pages/dashboards/dashboards-routing.module';
import { CommonModule, DatePipe } from '@angular/common';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import { DataTablesModule } from "angular-datatables";

import * as DataTables from 'datatables.net';

import { AdminUsersComponent } from './components/admin-users/admin-users.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ZonesComponent } from './components/zones/zones.component';
import { EmployeesComponent } from './components/employees/employees.component';
import { AddEmployeeComponent } from './components/add-employee/add-employee.component';
import { LicenseComponent } from './components/license/license.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SitesComponent } from './components/sites/sites.component';
import { AddSiteComponent } from './components/add-site/add-site.component';
import { ViewSiteComponent } from './components/view-site/view-site.component';
import { LoaderComponent } from './components/loader/loader.component';
import { AddContractComponent } from './components/add-contract/add-contract.component';
import { EditEmployeeComponent } from './components/edit/edit-employee/edit-employee.component';
import { EditSiteComponent } from './components/edit/edit-site/edit-site.component';
import { EditContractComponent } from './components/edit/edit-contract/edit-contract.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { SiteAllCalendarComponent } from './components/site-all-calendar/site-all-calendar.component';
import { TableTestComponent } from './components/table-test/table-test.component';
import { SiteTemplateComponent } from './components/site-template/site-template.component';

import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { EmployeeDetailsComponent } from './components/employee-details/employee-details.component';
import { UniformsComponent } from './components/uniforms/uniforms.component';
import { RolesComponent } from './components/roles/roles.component';
import { ZoneDetailsComponent } from './components/zones/zone-details/zone-details.component';

import { NgSelectModule } from '@ng-select/ng-select';

import {MatNativeDateModule} from '@angular/material/core';
import {MaterialExampleModule} from '../app/material.module';
import { OptimizationComponent } from './components/zones/zone-details/optimization/optimization.component';
import { ToastrModule } from 'ngx-toastr';
import { SignInLogsComponent } from './components/sign-in-logs/sign-in-logs.component';
import { PayrollComponent } from './components/payroll/payroll.component';
import { PayrollDetailsComponent } from './components/payroll/payroll-details/payroll-details.component';
// import { DragDropModule } from '@angular/cdk/drag-drop';

if (environment.defaultauth === 'firebase') {
  initFirebaseBackend(environment.firebaseConfig);
} else {
  // tslint:disable-next-line: no-unused-expression
  FakeBackendInterceptor;
}

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MainPageComponent,
    UsersComponent,
    HomeComponent,
    AdminUsersComponent,
    UnauthorizedComponent,
    ZonesComponent,
    EmployeesComponent,
    AddEmployeeComponent,
    LicenseComponent,
    PaginationComponent,
    SitesComponent,
    AddSiteComponent,
    ViewSiteComponent,
    LoaderComponent,
    AddContractComponent,
    EditEmployeeComponent,
    EditSiteComponent,
    EditContractComponent,
    CalendarComponent,
    SiteAllCalendarComponent,
    TableTestComponent,
    SiteTemplateComponent,
    EmployeeDetailsComponent,
    UniformsComponent,
    ZoneDetailsComponent,
    OptimizationComponent,
    RolesComponent,
    SignInLogsComponent,
    PayrollComponent,
    PayrollDetailsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    LayoutsModule,
    AppRoutingModule,
    ExtrapagesModule,
    CarouselModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    ScrollToModule.forRoot(),
    NgbModule,
    MainPageModule,
    FormsModule,
    NgApexchartsModule,
    SimplebarAngularModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardsRoutingModule,
    UIModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbNavModule,
    WidgetModule,
    NgApexchartsModule,
    SharedModule,
    SimplebarAngularModule, 
    DataTablesModule,
    FullCalendarModule,
    Ng2SmartTableModule,
    NgSelectModule,
    // DragDropModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCU1a-vqg5WruONlgJ90B2whl3U9vQlbks',
      libraries: ['places']
    }),

    MatNativeDateModule,
    MaterialExampleModule,
    ToastrModule.forRoot(), 
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true },
    DataService,
    RestApiService,
    AuthGuardService,
    PermissonsGuardService,
    DatePipe,
    GoogleMapsAPIWrapper
  ],
})
export class AppModule { }
