<form #f="ngForm" >
    <label for="startDate">Start Date:</label>
    <input type="date" class="form-control" id="startDate" name="startDate" ngModel #startDate="ngModel">
  
    <label for="endDate">End Date:</label>
    <input type="date" class="form-control" id="endDate" name="endDate" ngModel #endDate="ngModel">
  
    <!-- <button (click)="calculateDateRange()">Calculate Date Range</button> -->
  </form>
  
  <!-- <div >
    <h2>Date Range:</h2>
    <ul>
      <li *ngFor="let date of dateRangeArray">{{ date | date: 'EEE d' }}</li>
    </ul>
  </div> -->

  <hr>

<ng2-smart-table
  [settings]="settings"
  [source]="positions"
></ng2-smart-table>