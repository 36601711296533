
<div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h2 class="mb-4">Edit Employee</h2>
      </div>

      <div class="col-12">
        <form #f="ngForm" >
          <div class="row section_style">
            <div class="col-12">
                <h4>General Info</h4>
            </div>
            <div class="form-group col-12 col-md-4">
              <label for="">First Name</label>
              <input type="text" class="form-control" name="empFirstName" ngModel required #empFirstName="ngModel">
              <span class="text-danger" *ngIf="!empFirstName.valid && empFirstName.touched">Please Enter A Valid Answer</span>
              <p class="text-danger m-0" *ngIf="fNameErrorMessage"><strong>{{fNameErrorMessage}}</strong></p>
            </div>
            <div class="form-group col-12 col-md-4">
                <label for="">Last Name</label>
                <input type="text" class="form-control" name="empLastName" ngModel required #empLastName="ngModel">
                <span class="text-danger" *ngIf="!empLastName.valid && empLastName.touched">Please Enter A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="lNameErrorMessage"><strong>{{lNameErrorMessage}}</strong></p>
            </div>
            <div class="form-group col-12 col-md-4">
                <label for="">Username</label>
                <input type="text" class="form-control" name="empUserName" ngModel required #empUserName="ngModel" >
                <span class="text-danger" *ngIf="!empUserName.valid && empUserName.touched">Please Enter A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="usernameErrorMessage"><strong>{{usernameErrorMessage}}</strong></p>
            </div>
            <div class="form-group col-12 col-md-4">
                <label for="">Internal ID</label>
                <input type="text" class="form-control" name="empInternalId" ngModel required #empInternalId="ngModel" >
                <span class="text-danger" *ngIf="!empInternalId.valid && empInternalId.touched">Please Enter A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="internalIdErrorMessage"><strong>{{internalIdErrorMessage}}</strong></p>
            </div>
            <div class="form-group col-12 col-md-4">
                <label for="">Email</label>
                <input type="email" class="form-control" name="empEmail" ngModel required #empEmail="ngModel">
                <span class="text-danger" *ngIf="!empEmail.valid && empEmail.touched">Please Enter A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="emailErrorMessage"><strong>{{emailErrorMessage}}</strong></p>
            </div>
            <div class="form-group col-12 col-md-4">
                <label for="">Mobile</label>
                <input type="text" pattern="[0-9]{11}" class="form-control" name="empMobile" ngModel required #empMobile="ngModel">
                <span class="text-danger" *ngIf="!empMobile.valid && empMobile.touched">Please Enter A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="mobileErrorMessage"><strong>{{mobileErrorMessage}}</strong></p>
            </div>
            <div class="form-group col-12 col-md-4">
                <label for="">Gender</label>
                <select type="text" class="form-control" name="empGender" ngModel required #empGender="ngModel">
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                </select>
                <span class="text-danger" *ngIf="!empGender.valid && empGender.touched">Please Choose A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="genderErrorMessage"><strong>{{genderErrorMessage}}</strong></p>
            </div>
            <div class="form-group col-12 col-md-4">
                <label for="">Birth Date</label>
                <input type="date" class="form-control" name="empBirthdate" ngModel required #empBirthdate="ngModel">
                <span class="text-danger" *ngIf="!empBirthdate.valid && empBirthdate.touched">Please Enter A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="birthdateErrorMessage"><strong>{{birthdateErrorMessage}}</strong></p>
            </div>
            <div class="form-group col-12 col-md-4">
                <label for="">Employment Date</label>
                <input type="date" class="form-control" name="empEmploymentdate" ngModel required #empEmploymentdate="ngModel">
                <span class="text-danger" *ngIf="!empEmploymentdate.valid && empEmploymentdate.touched">Please Enter A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="employmentDateErrorMessage"><strong>{{employmentDateErrorMessage}}</strong></p>
            </div>

            <div class="form-group col-12 col-md-4">
                <label for="">Pay Rate</label>
                <input type="number" class="form-control" name="empPayRate" ngModel required #empPayRate="ngModel">
                <span class="text-danger" *ngIf="!empPayRate.valid && empPayRate.touched">Please Enter A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="payRateErrorMessage"><strong>{{payRateErrorMessage}}</strong></p>
            </div>

            <!-- <div class="form-group col-12 col-md-4">
                <label for="">Pay Type</label>
                <select type="text" class="form-control" name="empPayType" ngModel required #empPayType="ngModel">
                  <option value="">Select Type</option>
                  <option value="HOURLY">Hourly</option>
                  <option value="SALARY">Salary</option>
                </select>
                <span class="text-danger" *ngIf="!empPayType.valid && empPayType.touched">Please Enter A Valid Answer</span>
            </div> -->
            
          </div>

          <div class="row section_style">
            <div class="form-group col-12 col-md-4">
                <label for="">Role</label>
                <select type="text" class="form-control" name="empRole" ngModel required #empRole="ngModel">
                    <option value="">Select Role</option>
                    <option *ngFor="let Role of Roles" [value]="Role.id">{{Role.name}}</option>
                </select>
                <span class="text-danger" *ngIf="!empRole.valid && empRole.touched">Please Choose A Valid Answer</span>
            </div>
            <div class="form-group col-12 col-md-4">
                <label for="">Zone</label>
                <select type="text" class="form-control" name="empZone" ngModel #empZone="ngModel">
                    <option value="">Select Zone</option>
                    <option *ngFor="let Zone of Zones" [value]="Zone.id">{{Zone.name}}</option>
                </select>
                <!-- <span class="text-danger" *ngIf="!empZone.valid && empZone.touched">Please Choose A Valid Answer</span> -->
            </div>
            <div class="form-group col-12 col-md-4">
                <label for="">Status</label>
                <select type="text" class="form-control" name="empStatus" ngModel required #empStatus="ngModel">
                    <option value="">Select Status</option>
                    <option value="1">Active</option>
                    <option value="0">Not Active</option>
                </select>
                <span class="text-danger" *ngIf="!empStatus.valid && empStatus.touched">Please Choose A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="activeErrorMessage"><strong>{{activeErrorMessage}}</strong></p>
            </div>
          </div>

          <div class="row section_style">
            <div class="col-12">
                <h4>Address</h4>
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="">Street</label>
                <input type="text" class="form-control" name="empAdressStreet" ngModel required #empAdressStreet="ngModel">
                <span class="text-danger" *ngIf="!empAdressStreet.valid && empAdressStreet.touched">Please Enter A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="addressErrorMessage"><strong>{{addressErrorMessage}}</strong></p>
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="">Country</label>
                <select type="text" class="form-control" name="empCountry" ngModel required #empCountry="ngModel" (ngModelChange)="onChangeCountry($event)">
                    <option  value="">Select Country</option>
                    <option *ngFor="let Country of Countries" [value]="Country.id">{{Country.name}}</option>
                </select>
                <span class="text-danger" *ngIf="!empCountry.valid && empCountry.touched">Please Choose A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="countryErrorMessage"><strong>{{countryErrorMessage}}</strong></p>
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="">City</label>
                <select type="text" class="form-control" name="empCity" ngModel required #empCity="ngModel"  (ngModelChange)="onChangeCity($event)">
                    <option  value="">Select City</option>
                    <option *ngFor="let City of Cities" [value]="City.id">{{City.name}}</option>
                </select>
                <span class="text-danger" *ngIf="!empCity.valid && empCity.touched">Please Choose A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="cityErrorMessage"><strong>{{cityErrorMessage}}</strong></p>
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="">Area</label>
                <select type="text" class="form-control" name="empArea" ngModel required #empArea="ngModel" >
                    <option  value="">Select Area</option>
                    <option *ngFor="let Area of Areas" [value]="Area.id">{{Area.name}}</option>
                </select>
                <span class="text-danger" *ngIf="!empArea.valid && empArea.touched">Please Choose A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="areaErrorMessage"><strong>{{areaErrorMessage}}</strong></p>
            </div>
          </div>

          <div class="row section_style">
            <div class="col-12">
                <h4>Passwords</h4>
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="">Create a Password</label>
                <input type="password" class="form-control" name="empPassword" [(ngModel)]="formPassword" required #empPassword="ngModel"  (ngModelChange)="onChangePassword()">
                <span class="text-danger" *ngIf="!empPassword.valid && empPassword.touched">Please Enter A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="passwordErrorMessage"><strong>{{passwordErrorMessage}}</strong></p>
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="">Confirm Password</label>
                <input type="password" class="form-control" name="confirmEmpPassword" [(ngModel)]="formConfirmPassword" required #confirmEmpPassword="ngModel" (ngModelChange)="onChangeConfirmPassword()">
                <span class="text-danger" *ngIf="!confirmEmpPassword.valid && confirmEmpPassword.touched || showMatchMessage">Passwords don't match</span>
                <p class="text-danger m-0" *ngIf="passwordConfirmErrorMessage"><strong>{{passwordConfirmErrorMessage}}</strong></p>
            </div>
          </div>

          <div class="row section_style">
            <div class="col-12">
                <h4>Licenses</h4>
            </div>
            <div class="form-group col-12 col-md-3">
                <label for="">Choose License</label>
                <select class="form-control" name="selectedLicenseId" [(ngModel)]="selectedLicenseId"  (change)="updateForm()">
                    <option selected value="0">Select License</option>
                    <option *ngFor="let license of licenses" [value]="license.id">{{ license.name }}</option>
                </select>
                <p class="text-danger m-0" *ngIf="licensesErrorMessage"><strong>{{licensesErrorMessage}}</strong></p>
            </div>

            <div class="form-group col-12" *ngIf="selectedLicense">
                <div class="row">
                    <div *ngIf="selectedLicense.hasIssueDate === 1" class="col-12 col-md-3">
                        <label for="">Issue Date</label>
                        <input class="form-control" name="selectedLicenseIssueDate" required type="date" placeholder="Issue Date" [(ngModel)]="selectedLicenseIssueDate">
                    </div>
                    <div *ngIf="selectedLicense.hasExpiryDate === 1" class="col-12 col-md-3">
                        <label for="">Expiry Date</label>
                        <input class="form-control" name="selectedLicenseExpiryDate" required type="date" placeholder="Expiry Date" [(ngModel)]="selectedLicenseExpiryDate">

                    </div>
                    <div *ngIf="selectedLicense.hasLicenseNumber === 1" class="col-12 col-md-3">
                        <label for="">License Number</label>
                        <input class="form-control" name="selectedLicenseNumber" required type="text" placeholder="License Number" [(ngModel)]="selectedLicenseNumber">

                    </div>
                    <div class="col-12 col-md-3 btn_padding">
                        <button
                        [disabled]="!areAllInputsFilled()"
                         type="button" class="btn submitBtn w-100" (click)="submitForm()"><i class="fas fa-plus"></i> Add Licence</button>
                    </div>
                </div>
                
              </div>

              <div class="col-12" *ngIf="finalLicenses">
                <hr>
                <div class="table-responsive">
                    <table  id="datatable" class="datatable table align-middle table-nowrap table-hover">
                      <thead class="table-light">
                        <tr>
      
                          <th scope="col">License Name</th>
                          <th scope="col">Issue Date</th>
                          <th scope="col">Expiry Date</th>
                          <th scope="col">License Number</th>
                          <th scope="col">Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let License of finalLicenses; let i = index">
      
                          <td>{{License.name}}</td>
                          <td>{{License.issueDate}}</td>
                          <td>{{License.expirationDate}}</td>
                          <td>{{License.licenseNumber}}</td>
                          <td>
                            <button type="button" class="btn removeBtn" (click)="removeFinalLicense(i)"><i class="fas fa-times-circle"></i></button>
                          </td>
                          
                        </tr>
                      </tbody>
                    </table>
                  </div>

              </div>
            
          </div>

          <hr>

          <div class="row">
            <div class="form-group col-12 text-center">
                <button type="submit" [disabled]="!f.valid || !validPassword" class="btn  submitBtn" ><i class="fas fa-check"></i> Save Employee</button>
              </div>
          </div>
          
        </form>
      </div>


    </div>
</div>

