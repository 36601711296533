<div class="container-fluid">
    <div class="row">
        <div class="col-12">
          <h2 class="mb-4">Add Contract</h2>
        </div>
    </div>
    <div class="row">
      <div class="col-12">
        <form #f="ngForm" (ngSubmit)="addContract()">
          <div class="row section_style">
            <div class="col-12">
                <h4>Contract General Information</h4>
            </div>

            <div class="form-group col-12 col-md-6">
                <label for="">Contract Label</label>
                <h4 *ngIf="company_name">{{contractSite.company.name}}</h4>
                <!-- <input type="text" class="form-control" name="contractLabel" ngModel required #contractLabel="ngModel" >
                <span class="text-danger" *ngIf="!contractLabel.valid && contractLabel.touched">Please Enter A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="labelErrorMessage"><strong>{{labelErrorMessage}}</strong></p> -->
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="">Code / ID</label>
                <h4 *ngIf="company_name">{{contractSite.internalId}}</h4>
                <!-- <input type="text" class="form-control" name="contractCodeId" ngModel required #contractCodeId="ngModel" >
                <span class="text-danger" *ngIf="!contractCodeId.valid && contractCodeId.touched">Please Enter A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="codeErrorMessage"><strong>{{codeErrorMessage}}</strong></p> -->
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="">Service Start Date</label>
                <input type="date" class="form-control" name="contractServStartDate" ngModel required #contractServStartDate="ngModel" >
                <span class="text-danger" *ngIf="!contractServStartDate.valid && contractServStartDate.touched">Please Enter A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="startDateErrorMessage"><strong>{{startDateErrorMessage}}</strong></p>
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="">Service End Date (optional)</label>
                <input type="date" class="form-control" name="contractServEndDate" ngModel #contractServEndDate="ngModel" >
                <p class="text-danger m-0" *ngIf="endDateErrorMessage"><strong>{{endDateErrorMessage}}</strong></p>
            </div>
          </div>

          

          <div class="row section_style">
            <div class="col-12">
                <h4>Service Models</h4>
            </div>
            <div class="form-group col-12">
                <label for="">Service Types</label>
                <div class="zonesSelector_container">
                    <div class="checkboxContainer" *ngFor="let type of serviceTypes">
                        <input type="checkbox" id="{{type.id}}" name="{{type.id}}"  (change)="toggleServiceType(type)"> 
                        <label for="{{type.id}}"> {{type.name}}</label>
                    </div>
                </div>
                <p class="text-danger m-0" *ngIf="serviceTypesErrorMessage"><strong>{{serviceTypesErrorMessage}}</strong></p>
            </div>
            <div class="col-12">
                <hr>
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="">Payment Method</label>
                <select class="form-control" name="contractPaymentMethod" ngModel required #contractPaymentMethod="ngModel">
                    <option  value="">Select Method</option>
                    <option *ngFor="let method of paymentMethods" [value]="method.id">{{method.name}}</option>
                </select>
                <span class="text-danger" *ngIf="!contractPaymentMethod.valid && contractPaymentMethod.touched">Please Choose A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="paymentMethodIdErrorMessage"><strong>{{paymentMethodIdErrorMessage}}</strong></p>
            </div>

            <div class="form-group col-12 col-md-6">
                <label for="">Payment Terms</label>
                <select class="form-control" name="contractPaymentTerms" ngModel required #contractPaymentTerms="ngModel">
                    <option  value="">Select Method</option>
                    <option *ngFor="let term of paymentTerms" [value]="term.id">{{term.name}}</option>
                </select>
                <span class="text-danger" *ngIf="!contractPaymentTerms.valid && contractPaymentTerms.touched">Please Choose A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="paymentTermIdErrorMessage"><strong>{{paymentTermIdErrorMessage}}</strong></p>
            </div>

            <div class="form-group col-12 col-md-6">
                <label for="">External Contract ID (optional)</label>
                <input type="text" class="form-control" name="contractOptionalId" ngModel  #contractOptionalId="ngModel" >
                <p class="text-danger m-0" *ngIf="externalContractIdErrorMessage"><strong>{{externalContractIdErrorMessage}}</strong></p>
            </div>

            <div class="form-group col-12 col-md-6">
                <label for="">PO Number (optional)</label>
                <input type="text" class="form-control" name="contractPONumber" ngModel  #contractPONumber="ngModel" >
                <p class="text-danger m-0" *ngIf="poNumberErrorMessage"><strong>{{poNumberErrorMessage}}</strong></p>
            </div>

            <div class="form-group col-12 col-md-6">
                <label for="">Estimated Per Period Revenue (optional)</label>
                <input type="text" class="form-control" name="estPerRevenue" ngModel  #estPerRevenue="ngModel" >
                <p class="text-danger m-0" *ngIf="estimatedPerPeriodRevenueErrorMessage"><strong>{{estimatedPerPeriodRevenueErrorMessage}}</strong></p>
            </div>

          </div>


          <div class="row section_style">
            <div class="col-12">
                <h4>Invoice Recurrence</h4>
            </div>
            <div class="form-group col-12 col-md-4">
                <label for="">Billing Recurrence</label>
                <select class="form-control" name="contractBillingRecurrence" ngModel required #contractBillingRecurrence="ngModel">
                    <option  value="">Select Method</option>
                    <option *ngFor="let recurrence of billingRecurrences" [value]="recurrence.id">{{recurrence.name}}</option>
                </select>
                <span class="text-danger" *ngIf="!contractBillingRecurrence.valid && contractBillingRecurrence.touched">Please Choose A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="billingRecurrenceIdErrorMessage"><strong>{{billingRecurrenceIdErrorMessage}}</strong></p>
            </div>

            <div class="form-group col-12 col-md-4">
                <label for="">Billing Type</label>
                <select class="form-control" name="contractBillingType" ngModel required #contractBillingType="ngModel">
                    <option  value="">Select Method</option>
                    <option *ngFor="let bType of billingTypes" [value]="bType.id">{{bType.name}}</option>
                </select>
                <span class="text-danger" *ngIf="!contractBillingType.valid && contractBillingType.touched">Please Choose A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="billingTypeIdErrorMessage"><strong>{{billingTypeIdErrorMessage}}</strong></p>
            </div>

            <div class="form-group col-12 col-md-4">
                <label for="">Billing Item</label>
                <select class="form-control" name="contractBillingItem" ngModel required #contractBillingItem="ngModel">
                    <option  value="">Select Item</option>
                    <option *ngFor="let bItem of billingItems" [value]="bItem.id">{{bItem.name}}</option>
                </select>
                <span class="text-danger" *ngIf="!contractBillingItem.valid && contractBillingItem.touched">Please Choose A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="billingItemErrorMessage"><strong>{{billingItemErrorMessage}}</strong></p>
            </div>

            <div class="form-group col-12 col-md-4">
                <label for="">Cycle Reference Date</label>
                <input type="date" class="form-control" name="cycleRefDate" ngModel required #cycleRefDate="ngModel" >
                <span class="text-danger" *ngIf="!cycleRefDate.valid && cycleRefDate.touched">Please Choose A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="billingCycleRefDateErrorMessage"><strong>{{billingCycleRefDateErrorMessage}}</strong></p>
            </div>

            <div class="form-group col-12 col-md-4">
                <label for="">Tax Class</label>
                <select class="form-control" name="taxClass" ngModel required #taxClass="ngModel">
                    <option  value="">Select Item</option>
                    <option *ngFor="let item of taxClasses" [value]="item.id">{{item.name}}</option>
                </select>
                <span class="text-danger" *ngIf="!taxClass.valid && taxClass.touched">Please Choose A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="taxClassErrorMessage"><strong>{{taxClassErrorMessage}}</strong></p>
            </div>

            <div class="form-group col-12 col-md-4">
                <label for="">Bill Rate</label>
                <input type="number" class="form-control" name="billRate" ngModel required #billRate="ngModel" >
                <span class="text-danger" *ngIf="!billRate.valid && billRate.touched">Please Choose A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="billRateErrorMessage"><strong>{{billRateErrorMessage}}</strong></p>
            </div>

            <div class="form-group col-12 col-md-4">
                <label for="">Holiday Billing</label>
                <select class="form-control" name="holidayBilling" ngModel required #holidayBilling="ngModel">
                    <option  value="">Select Item</option>
                    <option *ngFor="let item of holidayBillings" [value]="item.id">{{item.name}}</option>
                </select>
                <span class="text-danger" *ngIf="!holidayBilling.valid && holidayBilling.touched">Please Choose A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="holidayBillingsErrorMessage"><strong>{{holidayBillingsErrorMessage}}</strong></p>
            </div>
            <div class="form-group col-12 col-md-4">
                <label for="">Holiday Group</label>
                <select class="form-control" name="holidayGroup" ngModel required #holidayGroup="ngModel">
                    <option  value="">Select Item</option>
                    <option *ngFor="let item of holidayGroups" [value]="item.id">{{item.name}}</option>
                </select>
                <span class="text-danger" *ngIf="!holidayGroup.valid && holidayGroup.touched">Please Choose A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="holidayGroupsErrorMessage"><strong>{{holidayGroupsErrorMessage}}</strong></p>
            </div>
            <div class="form-group col-12 col-md-4">
                <label for="">Holiday Multipiler</label>
                <input type="number" class="form-control" name="holidayMultiplier" ngModel required #holidayMultiplier="ngModel" >
                <span class="text-danger" *ngIf="!holidayMultiplier.valid && holidayMultiplier.touched">Please Choose A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="holidayMultipilerErrorMessage"><strong>{{holidayMultipilerErrorMessage}}</strong></p>
            </div>

          </div>
          

          



        <hr>

        <div class="row">
            <div class="form-group col-12 text-center">
                <button type="submit" [disabled]="!f.valid" class="btn submitBtn" ><i class="fas fa-plus"></i> Add Contract</button>
            </div>
        </div>
          
        </form>
      </div>


    </div>
</div>

