<div class="container-fluid">
    <div class="row">

        <div class="col-12 mb-3 alignContent-spaceBetween">
            <h2>HR & Payroll</h2>
        </div>


        <app-loader [loading]="loading"></app-loader>

        <div class="col-12" *ngIf="!loading">
            <div class="card">
                <div class="card-body" *ngIf="displayTable">
                    <div class="table-responsive">
                        <table datatable [dtOptions]="dtOptions" id="datatable"
                            class="datatable table align-middle table-nowrap table-hover">
                            <thead class="table-light">
                                <tr>

                                    <th scope="col">Payschedule</th>
                                    <th scope="col">Region</th>
                                    <th scope="col">Date From</th>
                                    <th scope="col">Date To</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Errors</th>
                                    <th scope="col">Employees</th>
                                    <th scope="col">Hours</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">View</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let Pay of Payruns">

                                    <td>{{Pay.payrollSchedule.frequency}}</td>

                                    <td>{{Pay.payrollSchedule.company_name}}</td>
                                    <td>{{Pay.startDate | date: "yyyy-MM-dd"}} </td>
                                    <td>{{Pay.endDate | date: "yyyy-MM-dd"}}</td>
                                    <td>{{Pay.status}}</td>

                                    <td>200</td>
                                    <td>529</td>
                                    <td>18449.2800</td>
                                    <td>$368,288.11</td>


                                    <td>
                                        <button class="btn" (click)="onViewPayrun(Pay.id)"><i class="far fa-eye"></i></button>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
        </div>

    </div>
</div>