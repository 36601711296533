import { Component,EventEmitter, OnInit, ViewChild } from '@angular/core';
import { Employee } from 'src/app/core/models/employee';
import { DataService } from '../../core/services/data.service';
import { RestApiService } from '../../core/services/rest-api.service';
import { NgForm } from '@angular/forms';
import { Pagination } from 'src/app/core/models/pagination';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  loading = true;
  fetchError = false;
  userApproved = false;
  userDeleted = false;
  displayTable: boolean = false;
  formPassword = '';
  formConfirmPassword = '';
  resetFormPassword = '';
  resetFormConfirmPassword = '';
  validPassword: boolean = false;
  showMatchMessage: boolean = false;
  Users: Employee[] = [];
  
  dtOptions: any = {
    paging: false,
    lengthChange: false,
    info: false,
    searching: true,
  };
  PaginationObj: Pagination = {
    total: 0,
    perPage: 0,
    currentPage: 0,
    firstPage: 0,
    lastPage: 0,
    nextPage: 0,
    previousPage: 0,
    pageChange: new EventEmitter<number>(),
  };

  // Error handling variables
  emailErrorMessage="";
  mobileErrorMessage="";
  usernameErrorMessage="";
  passwordErrorMessage="";

  // selectedUserDetails: User;

  selectedId = '';

  constructor( private rest: RestApiService, private data: DataService, private modalService: NgbModal ) { }

  // ngOnInit() {
  //   this.breadCrumbItems = [{ label: 'Contacts' }, { label: 'Users List', active: true }];
  // }

  
  successMessage = '';
  errorMessage = '';
  ngOnInit(): void {
    this.getUsers(1);
  }

  onPageChange(page: number): void {
    this.loading = true;
    this.displayTable = false;
    this.getUsers(page);
  }

  async getUsers(pager:number){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/users?page=${pager}`
      ).subscribe( 
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.Users = res.data;
          this.PaginationObj = res.pagination;
          this.loading = false;
          this.displayTable = true;
          console.log(this.Users);
        } ,
        (error: any) => {
          this.fetchError = true;
          this.loading = false;
          this.errorMessage= error.error.message;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  openModal(content: any) {
    this.modalService.open(content,
       { centered: true, 
          size: 'md', 
          windowClass: 'addUserModal' 
        });
    
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  onChangePassword() {
    this.formConfirmPassword = '';
  }

  onChangeConfirmPassword(){
    if(this.formPassword === this.formConfirmPassword){
      this.validPassword = true;
      this.showMatchMessage = false;
    }
    else{
      this.validPassword = false;
      this.showMatchMessage = true;
    }
  }

  async addUser(f: NgForm){
    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/users`, 
       {
        firstName: f.value.adminFirstName,
        lastName: f.value.adminLastName,
        email: f.value.adminEmail,
        mobile: f.value.adminMobile,
        username: f.value.adminUsername,
        password: f.value.adminPassword,
        passwordConfirmation: f.value.confirmAdminPassword,

       }).subscribe( 
       (res: any) => {
         this.successMessage = res.message;
        //  this.getAdminUsers();
         this.fetchError = false;
         window.location.reload();
         f.reset();
         this.closeModal();
       } ,
       (error : any) => {
        // this.fetchError = true;
        // this.errorMessage= error.error.message;
        // this.Errors = error.error.errors;
        this.errorMessage= error.error.message;
        error.error.errors.forEach(err => {
          switch (err.field) {
            case 'email':
              this.emailErrorMessage = err.message;
              break;
            case 'mobile':
              this.mobileErrorMessage = err.message;
              break;
            case 'username':
              this.usernameErrorMessage = err.message;
              break;

            case 'password':
              this.passwordErrorMessage = err.message;
              break;
            // Add more cases for other fields if needed
          }
        });
        console.log(error.error.errors);
       });


   } catch (error) {
     this.data.error(error['message']);
     console.log("error"+error);
     
   }
  }








}
