import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-table-test',
  templateUrl: './table-test.component.html',
  styleUrls: ['./table-test.component.scss']
})
export class TableTestComponent implements OnInit {
  @ViewChild('f') dateRangeForm !: NgForm;

  constructor(private datePipe: DatePipe) {
  }

  ngOnInit(): void {
    this.initializeDateRangeArray();
    console.log(this.dateRangeArray);
  }

  dateRangeArray: { [key: string]: { title: string } }[] = [];

  initializeDateRangeArray() {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    for (let i = 0; i < 7; i++) {
      const date = new Date();
      date.setDate(date.getDate() - i);
  
      const dayOfWeek = daysOfWeek[date.getDay()];
      const formattedDate = date.toISOString().substr(0, 10); // Format date as "YYYY-MM-DD"
      const dateString = `${dayOfWeek} ${formattedDate}`;
      
      this.dateRangeArray.push({ [dateString]: { title: dateString } });
    }
  }

// initializeDateRangeArray() {
//   for (let i = 0; i < 7; i++) {
//     const date = new Date();
//     date.setDate(date.getDate() - i);
//     this.dateRangeArray.push(date);
//   }
// }

  settings = {
    columns: {
      position: {
        title: 'Position',
        editable: false
      },
      ...this.dateRangeArray, // Dynamic date columns
    },
  };

  // formatDate(date: Date): string {
  //   // Implement your custom date formatting logic here
  //   // Example format: 'Tue 28/11'
  //   const day = date.toLocaleString('en-US', { weekday: 'short' });
  //   const formattedDate = `${day} ${date.getDate()}/${date.getMonth() + 1}`;
  //   return formattedDate;
  // }

  // generateDateColumns() {
  //   const dateColumns = {};

  //   this.dateRangeArray.forEach((date) => {
  //     // Format the date as needed using the formatDate method
  //     const formattedDate = this.formatDate(date);
  //     dateColumns[formattedDate] = { title: formattedDate };
  //   });

    
  // //   const customDates = ['Tue 28/11', 'Wed 29/11', 'Thu 30/11']; // Replace with your dates

  // // customDates.forEach((date) => {
  // //   dateColumns[date] = { title: date };
  // // });
  //   console.log(dateColumns);
  //   return dateColumns;
  // }


  positions: any[] = [
    {
      from: "2023-10-14 07:00:00",
      to: "2023-10-14 19:00:00",
      firstName: "John",
      lastName: "SS",
      schedulerId: 1,
      schedulerName: "test"
    },
    {
      from: "2023-10-15 07:00:00",
      to: "2023-10-15 19:00:00",
      firstName: "John",
      lastName: "SS",
      schedulerId: 1,
      schedulerName: "test"
    },
    {
      from: "2023-10-15 07:00:00",
      to: "2023-10-15 17:00:00",
      firstName: "George",
      lastName: "Amir",
      schedulerId: 1,
      schedulerName: "test"
    },
    {
      from: "2023-10-16 07:00:00",
      to: "2023-10-16 19:00:00",
      firstName: "John",
      lastName: "SS",
      schedulerId: 1,
      schedulerName: "test"
    }
  ]

  
  // getDatesBetweenRange(startDate: Date, endDate: Date): string[] {
  //   const dateArray: string[] = [];
  //   const currentDate = startDate;
  
  //   while (currentDate <= endDate) {
  //     const dayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][currentDate.getDay()];
  //     const formattedDate = currentDate.toISOString().substr(0, 10);
  //     const dateString = `${dayOfWeek} ${formattedDate}`;
  //     dateArray.push(dateString);
  
  //     // Increment currentDate by one day
  //     currentDate.setDate(currentDate.getDate() + 1);
  //   }
  
  //   return dateArray;
  // }

  // calculateDateRange() {
  //   console.log(this.dateRangeForm.value.startDate, this.dateRangeForm.value.endDate )
  //   this.dateRangeArray = this.getDatesBetweenRange(new Date(this.dateRangeForm.value.startDate), new Date(this.dateRangeForm.value.endDate));
  // }

  

  

}
