import { MenuItem } from './menu.model';
import { AdminRole } from 'src/app/core/models/adminRole';
import { SidebarComponent } from './sidebar.component';


export const MENU: MenuItem[] = [

    {
        id: 1,
        label: 'Employees',
        icon: 'fas fa-users',
        link: '/employees',
        isVisible: false
    },
    {
        id: 6,
        label: 'Sites',
        icon: 'fas fa-map-marker-alt',
        link: '/sites',
        isVisible: false
    },
    {
        id: 8,
        label: 'Zones',
        icon: 'fas fa-route',
        link: '/zones',
        isVisible: false
    },
    {
        id: 2,
        label: 'Admin Users',
        icon: 'fas fa-users',
        link: '/users',
        isVisible: false
    },

    {
        id: 3,
        label: 'Settings',
        icon: 'fas fa-cog',
        isVisible: false,
        subItems: [
            
            {
                id: 5,
                label: 'Licenses',
                link: '/licenses',
                parentId: 3
            },
            {
                id: 7,
                label: 'Uniforms',
                link: '/uniforms',
                parentId: 3
            },
            {
                id: 9,
                label: 'Roles',
                link: '/roles',
                parentId: 3
            },
            {
                id: 10,
                label: 'Sign In Logs',
                link: '/logs',
                parentId: 3
            },
        ]
    },


    // {
    //     id: 15,
    //     label: 'Admin Roles',
    //     icon: 'fas fa-user-lock',
    //     link: '/adminRoles',
    //     isVisible: false
    // },
    // {
    //     id: 2,
    //     label: 'Bookings',
    //     icon: 'fas fa-list',
    //     isVisible: false,
    //     subItems: [
    //         {
    //             id: 3,
    //             label: 'Bookings',
    //             link: '/bookings',
    //             parentId: 2
    //         },
    //         {
    //             id: 4,
    //             label: 'Time Slots',
    //             link: '/timeSlots',
    //             parentId: 2
    //         },
    //         {
    //             id: 10,
    //             label: 'Assigned',
    //             link: '/assignedWorkers',
    //             parentId: 2
    //         },
            
    //     ]
    // },
    // {
    //     id: 5,
    //     label: 'Services',
    //     icon: 'fas fa-cogs',
    //     isVisible: false,
    //     subItems: [
    //         {
    //             id: 11,
    //             label: 'Services',
    //             link: '/services',
    //             parentId: 5
    //         },
    //         {
    //             id: 12,
    //             label: 'Sub Services',
    //             link: '/subServices',
    //             parentId: 5
    //         }
            
    //     ]
    // },
    // {
    //     id: 6,
    //     label: 'Facilities',
    //     icon: 'fas fa-hands-wash',
    //     isVisible: false,
    //     subItems: [
    //         {
    //             id: 8,
    //             label: 'Facilities',
    //             link: '/facilities',
    //             parentId: 6
    //         },
    //         {
    //             id: 9,
    //             label: 'Facility Categories',
    //             link: '/facilityCategories',
    //             parentId: 6
    //         },
            
    //     ]
    // },
    // {
    //     id: 7,
    //     label: 'Notifications',
    //     icon: 'fas fa-bell',
    //     link: '/notifications',
    //     isVisible: false
    // },
    // {
    //     id: 13,
    //     label: 'Advertisements',
    //     icon: 'fas fa-bullhorn',
    //     link: '/advertisements',
    //     isVisible: false
    // },


];

