

<div class="container-fluid">
    <div class="row">
        <div class="col-12">
          <h2 class="mb-4">Edit Site</h2>
        </div>
    </div>

    <app-loader [loading]="loadingCont"></app-loader>

    <div  *ngIf="!fetchError && !loadingCont" class="row">
      <div class="col-12">
        <form #f="ngForm">
            <div class="row section_style">
              <div class="col-12">
                  <h4>Account Type</h4>
              </div>
              <div class="form-group col-12 col-md-4">
                  <label for="">Type</label>
                  <select type="text" disabled class="form-control" name="siteType" [(ngModel)]="selectedEditSite.type.id" ngModel required #siteType="ngModel" (ngModelChange)="updateIsCompId()">
                      <option value="">Select Type</option>
                      <option *ngFor="let type of SiteTypes" [value]="type.id">{{type.name}}</option>
                  </select>
                  <span class="text-danger" *ngIf="!siteType.valid && siteType.touched">Please Enter A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="accountErrorMessage"><strong>{{accountErrorMessage}}</strong></p>
              </div>
              <div class="form-group col-12 col-md-4">
                  <label for="">Internal ID</label>
                  <input type="text" disabled class="form-control" name="siteInternalId" ngModel [(ngModel)]="selectedEditSite.internalId" required #siteInternalId="ngModel" >
                  <span class="text-danger" *ngIf="!siteInternalId.valid && siteInternalId.touched">Please Enter A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="siteInternalIdErrorMessage"><strong>{{siteInternalIdErrorMessage}}</strong></p>
              </div>
              <div class="form-group col-12 col-md-4">
                <label for="">Notes</label>
                <input type="text" class="form-control" name="siteNotes" ngModel  [(ngModel)]="selectedEditSite.notes" required #siteNotes="ngModel" >
                <span class="text-danger" *ngIf="!siteNotes.valid && siteNotes.touched">Please Enter A Valid Answer</span>
            </div>
            </div>
      
            
      
            <div class="row section_style">
              <div class="col-12">
                  <h4>Company Information</h4>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="form-group col-12 col-md-4 text-center">
                    <img  src="{{selectedEditSite.company.logo}}" width="80" alt="">
                </div>
                  <div class="form-group col-12 col-md-4">
                    <label for="">Logo</label>
                    <input type="file" class="form-control" name="companyLogo" ngModel  #companyLogo="ngModel"  (change)="imageFileChange($event)">
                    <span class="text-danger" *ngIf="!companyLogo.valid && companyLogo.touched">Please Enter A Valid Answer</span>
                    <p class="text-danger m-0" *ngIf="CompLogoErrorMessage"><strong>{{CompLogoErrorMessage}}</strong></p>
                </div>
                  
                </div>
              </div>
      
              
              <div class="form-group col-12 col-md-4">
                  <label for="">Company Name</label>
                  <input type="text" class="form-control" name="companyName" ngModel  [(ngModel)]="selectedEditSite.company.name" required #companyName="ngModel" >
                  <span class="text-danger" *ngIf="!companyName.valid && companyName.touched">Please Enter A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="CompNameErrorMessage"><strong>{{CompNameErrorMessage}}</strong></p>
              </div>
              <div class="form-group col-12 col-md-4">
                  <label for="">Unique ID</label>
                  <input type="text" disabled class="form-control" name="companyInternalId" ngModel [(ngModel)]="selectedEditSite.company.internalId" required #companyInternalId="ngModel" >
                  <span class="text-danger" *ngIf="!companyInternalId.valid && companyInternalId.touched">Please Enter A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="internalIdErrorMessage"><strong>{{internalIdErrorMessage}}</strong></p>
              </div>
              <div class="form-group col-12 col-md-4">
                  <label for="">Time Zone</label>
                  <select type="text" class="form-control" name="companyTimeZone" ngModel [(ngModel)]="selectedEditSite.company.timezone" required #companyTimeZone="ngModel">
                      <option value="">Select Time Zone</option>
                      <option *ngFor="let TimeZone of TimeZones" [value]="TimeZone.timezone">{{TimeZone.timezone}}</option>
                      <!-- <option value="Cairo +02"> Cairo +02</option> -->
                  </select>
                  <span class="text-danger" *ngIf="!companyTimeZone.valid && companyTimeZone.touched">Please Enter A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="CompTimeZoneErrorMessage"><strong>{{CompTimeZoneErrorMessage}}</strong></p>
              </div>
              
              <div class="form-group col-12 col-md-4">
                  <label for="">Preferred Language</label>
                  <select type="text" class="form-control" name="companyLang" ngModel [(ngModel)]="selectedEditSite.company.language" required #companyLang="ngModel">
                      <option value="">Select Language</option>
                      <option *ngFor="let Lang of Languages" [value]="Lang.name">{{Lang.name}}</option>
                  </select>
                  <span class="text-danger" *ngIf="!companyLang.valid && companyLang.touched">Please Enter A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="CompLanguageErrorMessage"><strong>{{CompLanguageErrorMessage}}</strong></p>
              </div>
      
              
            </div>

    
      
            <div class="row section_style">
              <div class="col-12">
                  <h4>Main Contact</h4>
              </div>
              <div class="form-group col-12 col-md-4">
                  <label for="">First Name</label>
                  <input type="text" class="form-control" name="contactFirstName" ngModel [(ngModel)]="selectedEditSite.contact.firstName" required #contactFirstName="ngModel" >
                  <span class="text-danger" *ngIf="!contactFirstName.valid && contactFirstName.touched">Please Enter A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="FNameErrorMessage"><strong>{{FNameErrorMessage}}</strong></p>
              </div>
              <div class="form-group col-12 col-md-4">
                  <label for="">Last Name</label>
                  <input type="text" class="form-control" name="contactLastName" ngModel [(ngModel)]="selectedEditSite.contact.lastName" required #contactLastName="ngModel" >
                  <span class="text-danger" *ngIf="!contactLastName.valid && contactLastName.touched">Please Enter A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="lNameErrorMessage"><strong>{{lNameErrorMessage}}</strong></p>
              </div>
              <div class="form-group col-12 col-md-4">
                  <label for="">Email</label>
                  <input type="email" class="form-control" name="contactEmail" ngModel [(ngModel)]="selectedEditSite.contact.email" required #contactEmail="ngModel" >
                  <span class="text-danger" *ngIf="!contactEmail.valid && contactEmail.touched">Please Enter A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="emailErrorMessage"><strong>{{emailErrorMessage}}</strong></p>
              </div>
              <div class="form-group col-12 col-md-4">
                  <label for="">Mobile</label>
                  <input type="tel" class="form-control" name="contactMobile" ngModel [(ngModel)]="selectedEditSite.contact.mobile" required #contactMobile="ngModel" >
                  <span class="text-danger" *ngIf="!contactMobile.valid && contactMobile.touched">Please Enter A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="mobileErrorMessage"><strong>{{mobileErrorMessage}}</strong></p>
              </div>
              <div class="form-group col-12 col-md-4">
                  <label for="">Job Title</label>
                  <input type="text" class="form-control" name="contactJobTitle" ngModel [(ngModel)]="selectedEditSite.contact.jobTitle" required #contactJobTitle="ngModel" >
                  <span class="text-danger" *ngIf="!contactJobTitle.valid && contactJobTitle.touched">Please Enter A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="jobTitleErrorMessage"><strong>{{jobTitleErrorMessage}}</strong></p>
              </div>
            </div>
      
            
      
            
          <div class="row section_style">
              <div class="col-12">
                  <h4>Address</h4>
              </div>
              <div class="form-group col-12 col-md-6">
                  <label for="">Street</label>
                  <input type="text" class="form-control" name="siteAdressStreet" ngModel [(ngModel)]="selectedEditSite.address" required #siteAdressStreet="ngModel">
                  <span class="text-danger" *ngIf="!siteAdressStreet.valid && siteAdressStreet.touched">Please Enter A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="addressErrorMessage"><strong>{{addressErrorMessage}}</strong></p>
              </div>
              <div class="form-group col-12 col-md-6">
                  <label for="">Country</label>
                  <select type="text" class="form-control" name="siteCountry" ngModel [(ngModel)]="selectedEditSite.country.id" required #siteCountry="ngModel" (ngModelChange)="onChangeCountry($event)">
                      <option  value="">Select Country</option>
                      <option *ngFor="let Country of Countries" [value]="Country.id">{{Country.name}}</option>
                  </select>
                  <span class="text-danger" *ngIf="!siteCountry.valid && siteCountry.touched">Please Choose A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="countryErrorMessage"><strong>{{countryErrorMessage}}</strong></p>
              </div>
              <div class="form-group col-12 col-md-6">
                  <label for="">City</label>
                  <select type="text" class="form-control" name="siteCity" ngModel [(ngModel)]="selectedEditSite.city.id" required #siteCity="ngModel"  (ngModelChange)="onChangeCity($event)">
                      <option  value="">Select City</option>
                      <option *ngFor="let City of Cities" [value]="City.id">{{City.name}}</option>
                  </select>
                  <span class="text-danger" *ngIf="!siteCity.valid && siteCity.touched">Please Choose A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="cityErrorMessage"><strong>{{cityErrorMessage}}</strong></p>
              </div>
              <div class="form-group col-12 col-md-6">
                  <label for="">County</label>
                  <select type="text" class="form-control" name="siteArea" ngModel [(ngModel)]="selectedEditSite.area.id" required #siteArea="ngModel" >
                      <option  value="">Select County</option>
                      <option *ngFor="let Area of Areas" [value]="Area.id">{{Area.name}}</option>
                  </select>
                  <span class="text-danger" *ngIf="!siteArea.valid && siteArea.touched">Please Choose A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="areaErrorMessage"><strong>{{areaErrorMessage}}</strong></p>
              </div>
              <div class="form-group col-12 col-md-6">
                  <label for="">Zip Code</label>
                  <input type="text" class="form-control" name="siteZipCode" ngModel [(ngModel)]="selectedEditSite.zipcode" required #siteZipCode="ngModel" placeholder="Enter Zip Code">
                  <span class="text-danger" *ngIf="!siteZipCode.valid && siteZipCode.touched">Please Choose A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="zipCodeErrorMessage"><strong>{{zipCodeErrorMessage}}</strong></p>
              </div>
      
              <div class="form-group col-12">
                <label for="">Geo Location</label>
                <div #mapElement style="height: 300px; width: 100%"></div>
              </div>
          </div>
      
          <div class="row section_style">
            <div class="col-12">
                <h4>Billing Address</h4>
            </div>
            <div class="form-group col-12 col-md-6">
                <div class="checkboxContainer" >
                    <input type="checkbox" id="billSameAddress" name="billSameAddress" ngModel  [(ngModel)]="billSameAddressValue" #billSameAddress="ngModel">
                    <label for="billSameAddress">Same as Address</label>
                </div>
                <span class="text-danger" *ngIf="!billSameAddress.valid && billSameAddress.touched">Please Enter A Valid Answer</span>
            </div>
            <div class="col-12" *ngIf="!billSameAddressValue">
              <div *ngIf="selectedEditSite.billingAddress">
                <div class="row">
                    <div class="form-group col-12 col-md-6">
                        <label for="">Street</label>
                        
                        <input type="text" class="form-control" name="billAdressStreet" ngModel [(ngModel)]="selectedEditSite.billingAddress" required #billAdressStreet="ngModel">
                        <span class="text-danger" *ngIf="!billAdressStreet.valid && billAdressStreet.touched">Please Enter A Valid Answer</span>
                        <p class="text-danger m-0" *ngIf="billAddressErrorMessage"><strong>{{billAddressErrorMessage}}</strong></p>
                    </div>
                    <div class="form-group col-12 col-md-6">
                        <label for="">Country</label>
                        <select type="text" class="form-control" name="billCountry" ngModel [(ngModel)]="selectedEditSite.billingCountry.id" required #billCountry="ngModel" (ngModelChange)="onChangeBillCountry($event)">
                            <option  value="">Select Country</option>
                            <option *ngFor="let Country of Countries" [value]="Country.id">{{Country.name}}</option>
                        </select>
                        <span class="text-danger" *ngIf="!billCountry.valid && billCountry.touched">Please Choose A Valid Answer</span>
                        <p class="text-danger m-0" *ngIf="billCountryErrorMessage"><strong>{{billCountryErrorMessage}}</strong></p>
                    </div>
                    <div class="form-group col-12 col-md-6">
                        <label for="">City</label>
                        <select type="text" class="form-control" name="billCity" ngModel [(ngModel)]="selectedEditSite.billingCity.id" required #billCity="ngModel"  (ngModelChange)="onChangeBillCity($event)">
                            <option  value="">Select City</option>
                            <option *ngFor="let City of billCities" [value]="City.id">{{City.name}}</option>
                        </select>
                        <span class="text-danger" *ngIf="!billCity.valid && billCity.touched">Please Choose A Valid Answer</span>
                        <p class="text-danger m-0" *ngIf="billCityErrorMessage"><strong>{{billCityErrorMessage}}</strong></p>
                    </div>
                    <div class="form-group col-12 col-md-6">
                        <label for="">County</label>
                        <select type="text" class="form-control" name="billArea" ngModel [(ngModel)]="selectedEditSite.billingArea.id" required #billArea="ngModel" >
                            <option  value="">Select County</option>
                            <option *ngFor="let Area of billAreas" [value]="Area.id">{{Area.name}}</option>
                        </select>
                        <span class="text-danger" *ngIf="!billArea.valid && billArea.touched">Please Choose A Valid Answer</span>
                        <p class="text-danger m-0" *ngIf="billAreaErrorMessage"><strong>{{billAreaErrorMessage}}</strong></p>
                    </div>
                    <div class="form-group col-12 col-md-6">
                        <label for="">Zip Code</label>
                        <input type="text" class="form-control" name="billZipCode" ngModel [(ngModel)]="selectedEditSite.billingZipcode" required #billZipCode="ngModel" placeholder="Enter Zip Code">
                        <span class="text-danger" *ngIf="!billZipCode.valid && billZipCode.touched">Please Choose A Valid Answer</span>
                        <p class="text-danger m-0" *ngIf="billZipCodeErrorMessage"><strong>{{billZipCodeErrorMessage}}</strong></p>
                    </div>
                </div>
              </div>
              <div *ngIf="!selectedEditSite.billingAddress">
                <div class="row ">
                  <div class="form-group col-12 col-md-6">
                      <label for="">Street</label>
                      
                      <input type="text" class="form-control" name="billAdressStreet" ngModel  required #billAdressStreet="ngModel">
                      <span class="text-danger" *ngIf="!billAdressStreet.valid && billAdressStreet.touched">Please Enter A Valid Answer</span>
                      <p class="text-danger m-0" *ngIf="billAddressErrorMessage"><strong>{{billAddressErrorMessage}}</strong></p>
                  </div>
                  <div class="form-group col-12 col-md-6">
                      <label for="">Country</label>
                      <select type="text" class="form-control" name="billCountry" ngModel  required #billCountry="ngModel" (ngModelChange)="onChangeBillCountry($event)">
                          <option  value="">Select Country</option>
                          <option *ngFor="let Country of Countries" [value]="Country.id">{{Country.name}}</option>
                      </select>
                      <span class="text-danger" *ngIf="!billCountry.valid && billCountry.touched">Please Choose A Valid Answer</span>
                      <p class="text-danger m-0" *ngIf="billCountryErrorMessage"><strong>{{billCountryErrorMessage}}</strong></p>
                  </div>
                  <div class="form-group col-12 col-md-6">
                      <label for="">City</label>
                      <select type="text" class="form-control" name="billCity" ngModel required #billCity="ngModel"  (ngModelChange)="onChangeBillCity($event)">
                          <option  value="">Select City</option>
                          <option *ngFor="let City of billCities" [value]="City.id">{{City.name}}</option>
                      </select>
                      <span class="text-danger" *ngIf="!billCity.valid && billCity.touched">Please Choose A Valid Answer</span>
                      <p class="text-danger m-0" *ngIf="billCityErrorMessage"><strong>{{billCityErrorMessage}}</strong></p>
                  </div>
                  <div class="form-group col-12 col-md-6">
                      <label for="">County</label>
                      <select type="text" class="form-control" name="billArea" ngModel required #billArea="ngModel" >
                          <option  value="">Select County</option>
                          <option *ngFor="let Area of billAreas" [value]="Area.id">{{Area.name}}</option>
                      </select>
                      <span class="text-danger" *ngIf="!billArea.valid && billArea.touched">Please Choose A Valid Answer</span>
                      <p class="text-danger m-0" *ngIf="billAreaErrorMessage"><strong>{{billAreaErrorMessage}}</strong></p>
                  </div>
                  <div class="form-group col-12 col-md-6">
                      <label for="">Zip Code</label>
                      <input type="text" class="form-control" name="billZipCode" ngModel required #billZipCode="ngModel" placeholder="Enter Zip Code">
                      <span class="text-danger" *ngIf="!billZipCode.valid && billZipCode.touched">Please Choose A Valid Answer</span>
                      <p class="text-danger m-0" *ngIf="billZipCodeErrorMessage"><strong>{{billZipCodeErrorMessage}}</strong></p>
                  </div>
                </div>
              </div>
                
            </div>
            
        </div>
      
          <div class="row section_style">
              <div class="col-12">
                  <h4>Zones</h4>
                  <p>Removing this site from the zone will remove any of the zone settings applied to this site.</p>
              </div>
      
              <div class="col-12 col-md-6">
                  <div class="zonesSelector_container">
                      <div class="checkboxContainer" *ngFor="let Zone of Zones">
                          <input [checked]="isZoneSelected(Zone.id)" type="checkbox" id="{{Zone.id}}" name="{{Zone.id}}"  (change)="toggleZone(Zone)"> 
                          <label for="{{Zone.id}}"> {{Zone.name}}</label>
                      </div>
                  </div>
              </div>
          </div>
            
      
          <hr>
      
          <div class="row">
              <div class="form-group col-12 text-center">
                  <button  (click)="updateSite(f)" [disabled]="!f.valid" class="btn  submitBtn" ><i class="fas fa-plus"></i> Save Site</button>
              </div>
          </div>
            
          </form>
      </div>


    </div>
</div>

