import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Calendar, CalendarOptions, EventInput } from '@fullcalendar/core';
import { DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/core';

import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Site } from 'src/app/core/models/site';
import { Contract } from 'src/app/core/models/contract';
import { Schedule } from 'src/app/core/models/schedule';
import { DataService } from 'src/app/core/services/data.service';
import { RestApiService } from 'src/app/core/services/rest-api.service';
import { SiteService } from 'src/app/core/services/siteDetails.service';
import { NgForm } from '@angular/forms';
import { AssignedEmps } from 'src/app/core/models/assignedEmps';
import { CheckpointLookup } from 'src/app/core/models/checkpointLookup';
import { TourLookup } from 'src/app/core/models/tourLookup';
import { CheckpointQuestion } from 'src/app/core/models/checkpointQuestion';
import { Checkpoint } from 'src/app/core/models/checkpoint';
import { TourRoute } from 'src/app/core/models/tour_route';
import { TourDayTime } from 'src/app/core/models/tourDayTime';
import { Contact } from 'src/app/core/models/contact';
import { RoutePosition } from 'src/app/core/models/routePosition';
import { ScheduleDispatch } from 'src/app/core/models/scheduleDispatch';
import { SiteEmployee } from 'src/app/core/models/siteEmployee';
import { Trigger } from 'src/app/core/models/trigger';

declare var google: any;
@Component({
  selector: 'app-view-site',
  templateUrl: './view-site.component.html',
  styleUrls: ['./view-site.component.scss']
})
export class ViewSiteComponent implements OnInit  {
  retrievedSite: Site ;
  siteContracts: Contract[] = [];
  siteSchedulers: Schedule[] = [];
  checkpointLookups: CheckpointLookup[] = [];
  checkpointLookups_types: CheckpointLookup[] = [];
  checkpointLookups_categories: CheckpointLookup[] = [];
  checkpoints: Checkpoint[] = [];

  selectedEditContract: Contract;

  loading = true;
  fetchError = false;
  itemAdded = false;
  itemDeleted = false;
  viewData = false;
  displayTable = false;
  displaySchedulerTable = false;
  displayCheckpointTable = false;
  displayToursTable = false;
  dtOptions: any = {
    paging: false,
    lengthChange: false,
    info: false,
    searching: true,
  };

  selectedContractDetails: Contract;

  successMessage = '';
  errorMessage = '';

  selectedEditId="";

  imageUrl = ''; // Path to the nonexistent image

  serviceTypes: any[] = [];
  paymentMethods: any[] = [];
  paymentTerms: any[] = [];
  billingRecurrences: any[] = [];
  billingTypes: any[] = [];
  billingItems: any[] = [];
  selectedTypes: any[] = [];
  taxClasses: any[] = [];
  holidayGroups: any[] = [];
  holidayBillings: any[] = [];

  sitePositions :Schedule[] = [];
  assignedEmployees: AssignedEmps[] = []

  // Error handling variables
  labelErrorMessage="";
  codeErrorMessage="";
  startDateErrorMessage="";
  endDateErrorMessage="";
  paymentMethodIdErrorMessage="";
  paymentTermIdErrorMessage="";
  externalContractIdErrorMessage="";
  poNumberErrorMessage="";
  estimatedPerPeriodRevenueErrorMessage="";
  billingRecurrenceIdErrorMessage="";
  billingTypeIdErrorMessage="";
  billingItemErrorMessage="";
  billingCycleRefDateErrorMessage="";
  serviceTypesErrorMessage="";
  taxClassErrorMessage="";
  holidayBillingsErrorMessage="";
  holidayGroupsErrorMessage="";
  billRateErrorMessage="";
  holidayMultipilerErrorMessage="";

  ScheduleNameErrorMessage ="";
  ScheduleBeginErrorMessage ="";
  ScheduleEndErrorMessage ="";
  ScheduleBreakRuleErrorMessage ="";



  calendarEvents: any[];
  // event form
  calendarOptions: CalendarOptions;

  testSiteDays : [{
    schedulerName: string,
    from: string,
    to: string,
    firstName: string,
    lastName: string,
    backgroundColor: string,
    textColor: string,
  }]

  schedulerDetails: Schedule;

  displayScheduleContent = false;
  displaySiteContent = false;
  errorContent = false;
  siteId: any;
  scheduleId: any;

  setDefaultImage() {
    this.imageUrl = '../../../assets/images/deafult_picture.png'; // Path to the default image
  }

  constructor(private renderer: Renderer2, private el: ElementRef, private cdr: ChangeDetectorRef, private router: Router,
     private rest: RestApiService,
      private siteService: SiteService,
       private route: ActivatedRoute,
        private data: DataService,
        private modalService: NgbModal) {
          const name = Calendar.name;
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const siteId = +params.get('id');
      this.getSites(siteId);
      this.getAllPositions(siteId);
      this.getContractsById(siteId);
      this.getSitePolygons(siteId);
      this.getContacts(siteId);
      this.getCheckpointLookups();
      this.getServiceTypes();
      this.getToursLookups();
      this.getCheckpoints(siteId);
      this.getTours(siteId);
      this.getPaymentMethods();
      this.getPaymentTerms();
      this.getBillingRecurrences();
      this.getBillingTypes();
      this.getBillingItems();
      this.getTaxClasses();
      this.getHolidayGroups();
      this.getHolidayBillings();
      this.getBanned(siteId);
      this.getSiteEmployees(siteId);

      this.getPatrolType();
      this.getRoutePositions(siteId);
      this.getMobileDispatches(siteId);
    });
  }


  async getSites(siteId:number){
    try {
      this.siteService.getSiteDetails(siteId).subscribe(
        (res: Site) => {
          this.fetchError = false;
          this.loading = false;
          this.viewData = true;
          this.retrievedSite = res;
          this.imageUrl = res.company.logo;

        },
        (error: any) => {
          this.fetchError = true;
          this.loading = false;
          this.viewData = false;
          this.errorMessage = error.error.message;
        });

    } catch (error) {
      this.data.error(error['message']);
      this.loading = true;
    }

  }

  async getContractsById(siteId:number){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/sites/${siteId}/contracts`
      ).subscribe(
        (res: any) => {
          this.successMessage = res.message;
          this.fetchError = false;
          this.siteContracts = res.data;
          this.loading = false;
          this.displayTable = true;
        },
        (error: any) => {
          this.fetchError = true;
          this.loading = false;
          this.displayTable = false;
          this.errorMessage= error.error.message;
       });

    } catch (error) {
      console.log("error Zones"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  async getSchedulersById(siteId:number){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/sites/${siteId}/schedulers`
      ).subscribe(
        (res: any) => {

          this.fetchError = false;
          this.siteSchedulers = res;
          this.loading = false;
          this.displaySchedulerTable = true;
        },
        (error: any) => {
          this.fetchError = true;
          this.loading = false;
          this.displaySchedulerTable = false;
          this.errorMessage= error.error.message;
       });

    } catch (error) {
      console.log("error Zones"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  openContractModal(content: any, contract: Contract) {
    this.modalService.open(content,
       { centered: true,
          size: 'lg',
          windowClass: 'detailsModal'
        });
    this.selectedContractDetails = contract;
  }

  closeModal() {
    delete this.selectedContractDetails;
    this.modalService.dismissAll();
  }


  onAddContract(siteId: string) {
    this.router.navigate([`/viewSite/addContract`, siteId]);
  }

  onViewSchedule(siteId: string, scheduleId: string) {
    this.router.navigate([`site`,siteId,`calendar`,scheduleId]);
  }

  onViewAllSiteSchedule(siteId: string) {
    this.router.navigate([`site`,siteId,`calendar`]);
  }


  isTypeSelected(typeId: string): boolean {
    return this.selectedEditContract.serviceTypes.some(type => type.id === typeId);
  }


  openEditModal(content: any, contract: Contract) {
    this.modalService.open(content,
       { centered: true,
          size: 'lg',
          windowClass: 'detailsModal' ,
          backdrop: 'static'
        });
    this.selectedEditContract = contract;
    this.selectedEditId = contract.id;
    this.selectedTypes = this.selectedEditContract.serviceTypes.map(type => type.id);
  }

  closeEditModal() {
    // delete this.selectedEditSite;
    this.modalService.dismissAll();
  }

  async getTaxClasses(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/tax-classes`
      ).subscribe(
        (res: any) => {

          this.taxClasses = res;
        },
        (error: any) => {
          console.log(error);
       });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  async getHolidayGroups(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/holiday-groups`
      ).subscribe(
        (res: any) => {

          this.holidayGroups = res;
        },
        (error: any) => {
          console.log(error);
       });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  async getHolidayBillings(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/holiday-billing`
      ).subscribe(
        (res: any) => {

          this.holidayBillings = res;
        },
        (error: any) => {
          console.log(error);
       });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  async getServiceTypes(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/service-types`
      ).subscribe(
        (res: any) => {

          this.serviceTypes = res;
        },
        (error: any) => {
          console.log(error);
       });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  async getPaymentMethods(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/payment-methods`
      ).subscribe(
        (res: any) => {

          this.paymentMethods = res;
        },
        (error: any) => {
          console.log(error);
       });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  async getPaymentTerms(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/payment-terms`
      ).subscribe(
        (res: any) => {

          this.paymentTerms = res;
        },
        (error: any) => {
          console.log(error);
       });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  async getBillingRecurrences(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/billing-recurrences`
      ).subscribe(
        (res: any) => {

          this.billingRecurrences = res;
        },
        (error: any) => {
          console.log(error);
       });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  async getBillingTypes(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/billing-types`
      ).subscribe(
        (res: any) => {

          this.billingTypes = res;
        },
        (error: any) => {
          console.log(error);
       });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  async getBillingItems(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/bill-items`
      ).subscribe(
        (res: any) => {

          this.billingItems = res;
        },
        (error: any) => {
          console.log(error);
       });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  toggleServiceType(service: any) {
    if (this.isSelected(service)) {
      this.selectedTypes = this.selectedTypes.filter(id => id !== service.id);
    } else {
      this.selectedTypes.push(service.id);
    }
  }

  isSelected(service: any) {
    return this.selectedTypes.includes(service.id);
  }


  async updateContract(f: NgForm){

    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/sites/${this.retrievedSite.id}/contracts/${this.selectedEditId}`,

       {
        startDate: f.value.contractServStartDate,
        endDate: f.value.contractServEndDate,
        paymentMethodId: f.value.contractPaymentMethod,
        paymentTermId: f.value.contractPaymentTerms,
        externalContractId: f.value.contractOptionalId,
        poNumber: f.value.contractPONumber,
        estimatedPerPeriodRevenue: f.value.estPerRevenue,
        billingRecurrenceId: f.value.contractBillingRecurrence,
        billingTypeId: f.value.contractBillingType,
        billingItemId: f.value.contractBillingItem,
        billingCycleRefDate: f.value.cycleRefDate,

        taxClassId: f.value.taxClass,
        billRate: f.value.billRate,
        holidayBillingId: f.value.holidayBilling,
        holidayGroupId: f.value.holidayGroup,
        holidayMultiplier: f.value.holidayMultiplier,

        serviceTypes: this.selectedTypes,

       }

       ).subscribe(
       (res: any) => {

         f.reset();
         window.location.reload();
       } ,
      (error : any) => {
        error.error.errors.forEach(err => {
          switch (err.field) {
            case 'label':
              this.labelErrorMessage = err.message;
              break;

            case 'code':
              this.codeErrorMessage = err.message;
              break;

            case 'startDate':
              this.startDateErrorMessage = err.message;
              break;

            case 'endDate':
              this.endDateErrorMessage = err.message;
              break;

            case 'paymentMethodId':
              this.paymentMethodIdErrorMessage = err.message;
              break;

            case 'paymentTermId':
              this.paymentTermIdErrorMessage = err.message;
              break;

            case 'externalContractId':
              this.externalContractIdErrorMessage = err.message;
              break;

            case 'poNumber':
              this.poNumberErrorMessage = err.message;
              break;

            case 'estimatedPerPeriodRevenue':
              this.estimatedPerPeriodRevenueErrorMessage = err.message;
              break;

            case 'billingRecurrenceId':
              this.billingRecurrenceIdErrorMessage = err.message;
              break;

            case 'billingTypeId':
              this.billingTypeIdErrorMessage = err.message;
              break;

            case 'billingCycleRefDate':
              this.billingCycleRefDateErrorMessage = err.message;
              break;

            case 'serviceTypes':
              this.serviceTypesErrorMessage = err.message;
              break;

              case 'taxClassId':
              this.taxClassErrorMessage = err.message;
              break;
              case 'billRate':
              this.billRateErrorMessage = err.message;
              break;
              case 'holidayBillingId':
              this.holidayBillingsErrorMessage = err.message;
              break;
              case 'holidayGroupId':
              this.holidayGroupsErrorMessage = err.message;
              break;
              case 'holidayMultiplier':
              this.holidayMultipilerErrorMessage = err.message;
              break;

            // Add more cases for other fields if needed
          }
        });
      }
    );

   } catch (error) {
     this.data.error(error['message']);
   }
  }

  async addSchedule(f: NgForm){

    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/sites/${this.retrievedSite.id}/schedulers`,

       {
        name: f.value.scheduleName,
        beginDate: f.value.scheduleBeginDate,
        endDate: f.value.scheduleEndDate,
        breakRuleId: f.value.scheduleBreakRule,

       }

       ).subscribe(
       (res: any) => {

         f.reset();
         window.location.reload();
       } ,
      (error : any) => {
        error.error.errors.forEach(err => {
          switch (err.field) {
            case 'name':
              this.ScheduleNameErrorMessage = err.message;
              break;

            case 'beginDate':
              this.ScheduleBeginErrorMessage = err.message;
              break;

            case 'endDate':
              this.ScheduleEndErrorMessage = err.message;
              break;

            case 'breakRuleId':
              this.ScheduleBreakRuleErrorMessage = err.message;
              break;


            // Add more cases for other fields if needed
          }
        });
      }
    );

   } catch (error) {
     this.data.error(error['message']);
   }
  }

  // Site All Schedule
  async getAllPositions(siteId: number){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/sites/${siteId}/schedulers`
      ).subscribe(
        (res: any) => {
          this.fetchError = false;
          this.sitePositions = res;
          this.loading = false;
          this.setOptions();

          this.displayScheduleContent = true;
        },
        (error: any) => {
          this.fetchError = true;
          this.displayScheduleContent = false;
          this.loading = true;

       });

    } catch (error) {
      console.log("error Zones"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  calendarTest: EventInput[]=[];

  eventGuid: number;
  createEventId() {
    this.eventGuid = 0;
      return String(this.eventGuid++);
  }

  setAssigned() {
    this.sitePositions.forEach(position => {
      if (position.shifts && position.shifts.shiftDays) {
        position.shifts.shiftDays.forEach(shiftDay => {
          let assignedEmp = {
            // day: shiftDay.day,
            schedulerName: position.name,
            from: shiftDay.day+" "+shiftDay.timeFrom+":00",
            to: shiftDay.day+" "+shiftDay.timeTo+":00",
            firstName: shiftDay.employee.first_name,
            lastName: shiftDay.employee.last_name,
            schedulerId: position.id,
            employeeId: shiftDay.employee.id,
            shiftDay: shiftDay.shiftDayId,
            positionName: position.name,
            timeFrom: shiftDay.timeFrom+":00",
            timeTo: shiftDay.timeTo+":00",
            backgroundColor: shiftDay.backgroundColor,
            textColor: shiftDay.textColor,
          };

          this.assignedEmployees.push(assignedEmp);
        });
      }
    });

    
  }

  setEmps(){
    if(this.assignedEmployees){
      for (let i = 0; i < this.assignedEmployees.length; i++) {
        const shiftDay = this.assignedEmployees[i];
        this.calendarTest.push({
          id: this.createEventId(),
          title: shiftDay.firstName + ' ' + shiftDay.lastName,
          start: shiftDay.from,
          end: shiftDay.to,
          color: shiftDay.backgroundColor,
          textColor: shiftDay.textColor,
          className: 'eventColor',
        });
      }
    }
    else if (!this.assignedEmployees) {
      this.calendarTest = []
    }
    console.log(this.calendarTest);
  }

  setOptions(){
    this.setAssigned();
    this.setEmps();

    this.calendarOptions = {
      plugins: [
        interactionPlugin,
        dayGridPlugin,
        timeGridPlugin,
        listPlugin,
      ],
      headerToolbar: {
        left: 'dayGridMonth,dayGridWeek,dayGridDay',
        center: 'title',
        right: 'prevYear,prev,next,nextYear'
      },

      initialView: "dayGridMonth",
      // initialDate: this.schedulerDetails.beginDate,
      // validRange: {
      //   start: this.schedulerDetails.beginDate, // Set the start date
      //   end: this.schedulerDetails.endDate,   // Set the end date
      // },
      themeSystem: "bootstrap",
      events: this.calendarTest,
      weekends: true,
      editable: false,
      selectable: false,
      selectMirror: true,
      dayMaxEvents: true,
      displayEventTime: true,
      displayEventEnd: true,
      // dateClick: this.openModal.bind(this),
      // eventClick: this.handleEventClick.bind(this),
      // eventsSet: this.handleEvents.bind(this),
      eventTimeFormat: { // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        meridiem: 'narrow',
        hour12: false
      }
    };

  }


  currentEvents: EventApi[] = [];
  showScheduleContent = false;
  showSiteDetails = true;
  showContractDetails = false;
  showCheckpointContent = false;
  showTourContent = false;
  showContactsContent = false;
  showBannedContent = false;
  showGeoFencingContent = false;
  showMobileDispatchContent = false;

  showSchedulers() {
    this.showSiteDetails = false;
    this.showContractDetails = false;
    this.showCheckpointContent = false;
    this.showScheduleContent = true;
    this.showTourContent = false;
    this.showContactsContent = false;
    this.showGeoFencingContent = false;
    this.showBannedContent = false;
    this.showMobileDispatchContent = false;
  }

  showSiteInfo() {
    this.showScheduleContent = false;
    this.showContractDetails = false;
    this.showCheckpointContent = false;
    this.showSiteDetails = true;
    this.showTourContent = false;
    this.showContactsContent = false;
    this.showGeoFencingContent = false;
    this.showBannedContent = false;
    this.showMobileDispatchContent = false;
  }

  showContracts() {
    this.showScheduleContent = false;
    this.showSiteDetails = false;
    this.showCheckpointContent = false;
    this.showContractDetails = true;
    this.showTourContent = false;
    this.showContactsContent = false;
    this.showGeoFencingContent = false;
    this.showBannedContent = false;
    this.showMobileDispatchContent = false;
  }

  showCheckpoints() {
    this.showScheduleContent = false;
    this.showSiteDetails = false;
    this.showContractDetails = false;
    this.showCheckpointContent = true;
    this.showTourContent = false;
    this.showContactsContent = false;
    this.showGeoFencingContent = false;
    this.showBannedContent = false;
    this.showMobileDispatchContent = false;
  }

  showTours() {
    this.showTourContent = true;
    this.showScheduleContent = false;
    this.showSiteDetails = false;
    this.showContractDetails = false;
    this.showCheckpointContent = false;
    this.showContactsContent = false;
    this.showGeoFencingContent = false;
    this.showBannedContent = false;
    this.showMobileDispatchContent = false;
  }

  showContacts() {
    this.showContactsContent = true;
    this.showTourContent = false;
    this.showScheduleContent = false;
    this.showSiteDetails = false;
    this.showContractDetails = false;
    this.showCheckpointContent = false;
    this.showGeoFencingContent = false;
    this.showBannedContent = false;
    this.showMobileDispatchContent = false;
  }

  showBanned() {
    this.showBannedContent = true;
    this.showContactsContent = false;
    this.showTourContent = false;
    this.showScheduleContent = false;
    this.showSiteDetails = false;
    this.showContractDetails = false;
    this.showCheckpointContent = false;
    this.showGeoFencingContent = false;
    this.showMobileDispatchContent = false;
    
  }

  showGeoFencing() {
    this.showContactsContent = false;
    this.showTourContent = false;
    this.showScheduleContent = false;
    this.showSiteDetails = false;
    this.showContractDetails = false;
    this.showCheckpointContent = false;
    this.showBannedContent = false;
    this.showMobileDispatchContent = false;
    this.showGeoFencingContent = true;
    if (this.showGeoFencingContent) {
      setTimeout(() => {
        this.initMap();
      }, 100); // Adjust the delay time as needed (in milliseconds)
    }
  }

  showMobileDispatch() {
    this.showContactsContent = false;
    this.showTourContent = false;
    this.showScheduleContent = false;
    this.showSiteDetails = false;
    this.showContractDetails = false;
    this.showCheckpointContent = false;
    this.showBannedContent = false;
    this.showGeoFencingContent = false;
    this.showMobileDispatchContent = true;
    
  }

  async getAllSchedulers(siteId: any){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/sites/${siteId}/schedulers/calendar`
      ).subscribe(
        (res: any) => {
          this.fetchError = false;
          this.testSiteDays = res;

          this.setOptions();

          this.displayScheduleContent = true;
        },
        (error: any) => {
          this.fetchError = true;
          this.displayScheduleContent = false;
       });

    } catch (error) {
      console.log("error Zones"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }


  openCheckpointModal(content: any) {
    this.modalService.open(content,
       { centered: true,
          size: 'lg',
          windowClass: 'detailsModal' ,
          backdrop: 'static'
        });

  }

  scanedByInit: string = '';
  selectedInterval: string = '';
  monitorOption: string = '';
  extraOption: string = '';
  extra_DisplayMessageText: string = '';
  extra_reportOpenForm: string = '';
  extra_fileUnderInit: string = '';

  manualScanInit: string = '';

  // Multi Question Options
  extra_multiQuestionTypeInit: string = '';
  extra_multiQuestionTextInit: string = '';
  extra_multiQuestionCategoryInit: string = '';
  questionRange_minInit: string = '';
  questionRange_maxInit: string = '';
  extra_multiQuestionRequiredInit: boolean = true;

  
  async getCheckpointLookups(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/checkpoints/lookups`
      ).subscribe(
        (res: any) => {

          this.checkpointLookups = res;

          this.monitorOption = this.checkpointLookups['monitoring-options'][0].id;
          this.extra_multiQuestionTypeInit = this.checkpointLookups['question-types'][0].id;
          this.selectedInterval = this.checkpointLookups['interval-units'][0].id;
          this.extraOption = this.checkpointLookups['extra-scan-options'][0].id;
          this.manualScanInit = this.checkpointLookups['manual-scan'][1].id;

          this.checkpointLookups_types = this.checkpointLookups['question-types'];
          this.checkpointLookups_categories = this.checkpointLookups['file-under'];

        },
        (error: any) => {
          console.log(error);
       });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  finalQuestions: CheckpointQuestion[] = [];
  
  

  
  getTypeName(typeId: number): string {
    const type = this.checkpointLookups_types.find(t => t.id == typeId);
    return type ? type.name : '';
  }

  getCategoryName(categoryId: number): string {
    const category = this.checkpointLookups_categories.find(c => c.id == categoryId);
    return category ? category.name : '';
  }

  addQuestion(){
    const newQuestion: CheckpointQuestion = {
      type: this.extra_multiQuestionTypeInit,
      question: this.extra_multiQuestionTextInit,
      category: this.extra_multiQuestionCategoryInit,
      required: this.extra_multiQuestionRequiredInit,
      rangeFrom: this.questionRange_minInit,
      rangeTo: this.questionRange_maxInit,
    };
    
    this.finalQuestions.push(newQuestion);
    this.resetFormFields();

  }

  addQuestion_edit(){
    const newQuestion: CheckpointQuestion = {
      type: this.extra_multiQuestionTypeInit,
      question: this.extra_multiQuestionTextInit,
      category: this.extra_multiQuestionCategoryInit,
      required: this.extra_multiQuestionRequiredInit,
      rangeFrom: this.questionRange_minInit,
      rangeTo: this.questionRange_maxInit,
    };
    
    this.edit_checkpoint_multipleQuestions.push(newQuestion);
    this.resetFormFields();

  }

  changeQuestionType() {
    this.finalQuestions = [];
  }

  changeQuestionType_edit() {
    this.edit_checkpoint_multipleQuestions = this.default_editQuestions.slice(0, this.default_editQuestions.length);
  }

  resetFormFields() {
    this.questionRange_minInit = '';
    this.questionRange_maxInit = '';
    this.extra_multiQuestionTextInit = '';
    this.extra_multiQuestionCategoryInit = '';
  }

  areAllInputsFilled(): boolean {
    if(this.extra_multiQuestionTypeInit !== "208"){
      if (this.extra_multiQuestionTextInit === "") {
          return false;
      }
      else{
        return true;
      }
    }
    
    else if (this.extra_multiQuestionTypeInit == "208" ) {
      if (this.extra_multiQuestionTextInit === "" ||
        this.questionRange_minInit === "" || this.questionRange_maxInit === "") {
        return false;
      }else{
        return true;
      }
    }
  }

  removeQuestion_add(index: number) {
    this.finalQuestions.splice(index, 1);
  }

  removeQuestion_edit(index: number) {
    this.edit_checkpoint_multipleQuestions.splice(index, 1);
  }

  async getCheckpoints(siteId:number){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/sites/${siteId}/checkpoints`
      ).subscribe(
        (res: any) => {
          this.fetchError = false;
          this.checkpoints = res;
          this.loading = false;
          this.displayCheckpointTable = true;
        },
        (error: any) => {
          console.log(error);
          this.displayCheckpointTable = false;
       });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  async addCheckpointForm(f: NgForm){

    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/sites/${this.retrievedSite.id}/checkpoints`,

       {
        name: f.value.checkpointName,
        instructions: f.value.specialInstructions,
        canBeScannedBy: f.value.scannedBy,
        monitoringOption: f.value.monitorOptions,
        requestInterval: f.value.intervalNumber,
        requestIntervalUnit: f.value.intervalUnit,
        extraScanOption: f.value.extra_scan_options,
        messageToDisplay: f.value.extra_displayMessage,
        reportFormToOpen: f.value.extra_openReportForm,
        scanQuestion: f.value.extra_exceptionQuestion,
        scanFileUnder: f.value.extra_fileUnder,
        validateRangeFrom: f.value.extra_exceptionFrom,
        validateRangeTo: f.value.extra_exceptionTo,
        validateRangeBarcode: f.value.extra_exceptionBarcode,
        manualScan: f.value.manualScan,
        multipleQuestions: this.finalQuestions,
        

       }


       ).subscribe(
       (res: any) => {

         f.reset();
         window.location.reload();
       } ,
      (error : any) => {
        console.log("Error")
      }
    );

   } catch (error) {
     this.data.error(error['message']);
   }
  }

  edit_checkpoint_id: string="";
  edit_checkpoint_name: string="";
  edit_checkpoint_instructions: string="";
  edit_checkpoint_canBeScannedBy: number;
  edit_checkpoint_monitoringOption: string;
  edit_checkpoint_requestInterval: number;
  edit_checkpoint_requestIntervalUnit: number;
  edit_checkpoint_extraScanOption: string;
  edit_checkpoint_messageToDisplay: string="";
  edit_checkpoint_reportFormToOpen: number;
  edit_checkpoint_scanQuestion: string="";
  edit_checkpoint_scanFileUnder: number;
  edit_checkpoint_validateRangeFrom: number;
  edit_checkpoint_validateRangeTo: number;
  edit_checkpoint_validateRangeBarcode: boolean;
  edit_checkpoint_manualScan: number;
  edit_checkpoint_multipleQuestions: CheckpointQuestion[]=[];

  default_editQuestions: CheckpointQuestion[] = [];
  openCheckpointEditModal(content: any, checkpoint: Checkpoint) {
    this.modalService.open(content,
       { centered: true,
          size: 'lg',
          windowClass: 'detailsModal' ,
          backdrop: 'static'
        });

        this.edit_checkpoint_id = checkpoint.id;
        this.edit_checkpoint_name = checkpoint.name;
        this.edit_checkpoint_instructions = checkpoint.instructions;
        this.edit_checkpoint_canBeScannedBy  = checkpoint.canBeScannedBy;
        this.edit_checkpoint_monitoringOption = checkpoint.monitoringOption;
        this.edit_checkpoint_requestInterval = checkpoint.requestInterval;
        this.edit_checkpoint_requestIntervalUnit = checkpoint.requestIntervalUnit;
        this.edit_checkpoint_extraScanOption = checkpoint.extraScanOption;
        this.edit_checkpoint_messageToDisplay = checkpoint.messageToDisplay;
        this.edit_checkpoint_reportFormToOpen = checkpoint.reportFormToOpen;
        this.edit_checkpoint_scanQuestion = checkpoint.scanQuestion;
        this.edit_checkpoint_scanFileUnder = checkpoint.scanFileUnder;
        this.edit_checkpoint_validateRangeFrom = checkpoint.validateRangeFrom;
        this.edit_checkpoint_validateRangeTo = checkpoint.validateRangeTo;
        this.edit_checkpoint_validateRangeBarcode = checkpoint.validateRangeBarcode;
        this.edit_checkpoint_manualScan = checkpoint.manualScan;
        this.edit_checkpoint_multipleQuestions = checkpoint.multipleQuestions;
        this.default_editQuestions = checkpoint.multipleQuestions;
        
  }

  async editCheckpointForm(f: NgForm){

    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/sites/${this.retrievedSite.id}/checkpoints/${this.edit_checkpoint_id}`,

       {
        name: f.value.edit_checkpointName,
        instructions: f.value.edit_specialInstructions,
        canBeScannedBy: f.value.edit_scannedBy,
        monitoringOption: f.value.edit_monitorOptions,
        requestInterval: f.value.edit_intervalNumber,
        requestIntervalUnit: f.value.edit_intervalUnit,
        extraScanOption: f.value.edit_extra_scan_options,
        messageToDisplay: f.value.edit_extra_displayMessage,
        reportFormToOpen: f.value.edit_extra_openReportForm,
        scanQuestion: f.value.edit_extra_exceptionQuestion,
        scanFileUnder: f.value.edit_extra_fileUnder,
        validateRangeFrom: f.value.edit_extra_exceptionFrom,
        validateRangeTo: f.value.extra_exceptionTo,
        validateRangeBarcode: f.value.edit_extra_exceptionBarcode,
        manualScan: f.value.edit_manualScan,
        multipleQuestions: this.edit_checkpoint_multipleQuestions,
        

       }


       ).subscribe(
       (res: any) => {

         f.reset();
         window.location.reload();
       } ,
      (error : any) => {
        console.log("Error")
      }
    );

   } catch (error) {
     this.data.error(error['message']);
   }
  }


  urlToImport = '';
  excel_imported : File;

  openImportCheckpointModal(content: any) {
    this.modalService.open(content,
       { centered: true,
          size: 'md',
          windowClass: 'detailsModal' ,
          backdrop: 'static'
        });

  }


  async ImportCheckpoint(f: NgForm) {
    try {
      const formData = new FormData();
      formData.append('file', f.value.checkpointFile);
  
      await this.rest.post(
        `${this.data.getBaseUrl()}api/v1/sites/${this.retrievedSite.id}/checkpoints/import`,
        formData,
      ).subscribe(
        (res: any) => {
          window.location.reload();
        },
        (error: any) => {
          console.log('Error:', error);
        }
      );
    } catch (error) {
      this.data.error(error['message']);
    }
  }

// Tour Routes

  openTourModal(content: any) {
    this.modalService.open(content,
      { centered: true,
          size: 'lg',
          windowClass: 'detailsModal' ,
          backdrop: 'static'
        });

  }

  tourRoutes: TourRoute;
  tourLookups: TourLookup[] = [];
  
  tourRecurrence: string;

  weekDays: any = [
    {
      id: 0,
      name: 'Sunday'
    },
    {
      id: 1,
      name: 'Monday'
    },
    {
      id: 2,
      name: 'Tuesday'
    },
    {
      id: 3,
      name: 'Wednesday'
    },
    {
      id: 4,
      name: 'Thursday'
    },
    {
      id: 5,
      name: 'Friday'
    },
    {
      id: 6,
      name: 'Saturday'
    },
  ];

  monthDays: any = [
    {
      id: 0,
      name: '1'
    },
    {
      id: 1,
      name: '2'
    },
    {
      id: 2,
      name: '3'
    },
    {
      id: 3,
      name: '4'
    },
    {
      id: 4,
      name: '5'
    },
    {
      id: 5,
      name: '6'
    },
    {
      id: 6,
      name: '7'
    },
    {
      id: 7,
      name: '8'
    },
    {
      id: 8,
      name: '9'
    },
    {
      id: 9,
      name: '10'
    },
    {
      id: 10,
      name: '11'
    },
    {
      id: 11,
      name: '12'
    },
    {
      id: 12,
      name: '13'
    },
    {
      id: 13,
      name: '14'
    },
    {
      id: 14,
      name: '15'
    },
    {
      id: 15,
      name: '16'
    },
    {
      id: 16,
      name: '17'
    },
    {
      id: 17,
      name: '18'
    },
    {
      id: 18,
      name: '19'
    },
    {
      id: 19,
      name: '20'
    },
    {
      id: 20,
      name: '21'
    },
    {
      id: 21,
      name: '22'
    },
    {
      id: 22,
      name: '23'
    },
    {
      id: 23,
      name: '24'
    },
    {
      id: 24,
      name: '25'
    },
    {
      id: 25,
      name: '26'
    },
    {
      id: 26,
      name: '27'
    },
    {
      id: 27,
      name: '28'
    },
    {
      id: 28,
      name: '29'
    },
    {
      id: 29,
      name: '30'
    },
    {
      id: 30,
      name: '31'
    },
  ];

  clockTime: any = [
    {
      name: '00:00'
    },
    {
      name: '00:30'
    },
    {
      name: '01:00'
    },
    {
      name: '01:30'
    },
    {
      name: '02:00'
    },
    {
      name: '02:30'
    },
    {
      name: '03:00'
    },
    {
      name: '03:30'
    },
    {
      name: '04:00'
    },
    {
      name: '04:30'
    },
    {
      name: '05:00'
    },
    {
      name: '05:30'
    },
    {
      name: '06:00'
    },
    {
      name: '06:30'
    },
    {
      name: '07:00'
    },
    {
      name: '07:30'
    },
    {
      name: '08:00'
    },
    {
      name: '08:30'
    },
    {
      name: '09:00'
    },
    {
      name: '09:30'
    },
    {
      name: '10:00'
    },
    {
      name: '10:30'
    },
    {
      name: '11:00'
    },
    {
      name: '11:30'
    },
    {
      name: '12:00'
    },
    {
      name: '12:30'
    },
    {
      name: '13:00'
    },
    {
      name: '13:30'
    },
    {
      name: '14:00'
    },
    {
      name: '14:30'
    },
    {
      name: '15:00'
    },
    {
      name: '15:30'
    },
    {
      name: '16:00'
    },
    {
      name: '16:30'
    },
    {
      name: '17:00'
    },
    {
      name: '17:30'
    },
    {
      name: '18:00'
    },
    {
      name: '18:30'
    },
    {
      name: '19:00'
    },
    {
      name: '19:30'
    },
    {
      name: '20:00'
    },
    {
      name: '20:30'
    },
    {
      name: '21:00'
    },
    {
      name: '21:30'
    },
    {
      name: '22:00'
    },
    {
      name: '22:30'
    },
    {
      name: '23:00'
    },
    {
      name: '23:30'
    },
    
  ];

  async getTours(siteId:number){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/sites/${siteId}/tours`
      ).subscribe(
        (res: any) => {
          this.fetchError = false;
          this.tourRoutes = res;
          this.loading = false;
          this.displayToursTable = true;
        },
        (error: any) => {
          console.log(error);
          this.displayToursTable = false;
      });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  async getToursLookups(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/tours/lookups`
      ).subscribe(
        (res: any) => {
          this.fetchError = false;
          this.tourLookups = res;
          this.tourRecurrence = this.tourLookups['interval-units'][0].id;
        },
        (error: any) => {
          console.log(error);
      });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  tourTimes_add: TourDayTime[] = [];
  tourDay_field: any = 0;
  selectedDay: any = 0;
  tourTime_field: string = "00:00";
  tourTime_exists: boolean = false;

  addTime() {
    const newTime: TourDayTime = {
      day: parseInt(this.tourDay_field, 10),
      time: this.tourTime_field,
    };
    this.selectedDay = parseInt(this.tourDay_field, 10);
    // Check if the new time already exists in tourTimes_add
    const exists = this.tourTimes_add.some(
      (time) => time.day === newTime.day && time.time === newTime.time
    );

    if (exists) {
      this.tourTime_exists= true;
    } else {
      this.tourTime_exists= false;
      this.tourTimes_add.push(newTime);
      this.resetTimeField();
    }

  }

  resetTimeField() {
    this.tourTime_field = '00:00';
    this.tourDay_field = 0;
  }
  
  isDaySelected(dayId: number): boolean {
    return this.tourTimes_add.some(tourTime => tourTime.day === dayId);
  }

  getTourTimesForDay(dayId: number): TourDayTime[] {
    return this.tourTimes_add.filter(tourTime => tourTime.day === dayId);
  }

  deleteTime(dayId: number, time: string) {
    this.tourTimes_add = this.tourTimes_add.filter(
      (tourTime) => !(tourTime.day === dayId && tourTime.time === time)
    );
  }

  async addTourRoute(f: NgForm){

    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/sites/${this.retrievedSite.id}/tours`,

       {
        description: f.value.tourDesc,
        instructions: f.value.tourInstructions,
        assignedTo: f.value.tourAssigned,
        duration: f.value.tourDuration,
        gracePeriod: f.value.tourGrace,
        recurrenceId: f.value.tourRecurrenceType,
        schedules: this.tourTimes_add,
        
       }


       ).subscribe(
       (res: any) => {

         f.reset();
         window.location.reload();
       } ,
      (error : any) => {
        console.log("Error")
      }
    );

   } catch (error) {
     this.data.error(error['message']);
   }
  }
 
  recurrence_change(){
    this.tourTimes_add = [];
    this.tourTime_exists= false;
  }

  // Edit Tour Routes

  tourDay_field_edit: any = 0;
  selectedDay_edit: any = 0;
  tourTime_field_edit: string = "00:00";
  tourTime_exists_edit: boolean = false;

  edit_tourId: any;
  edit_tourDescRec: any;
  edit_tourAssignedRec: any;
  edit_tourInstructionsRec: any;
  edit_tourDurationRec: any;
  edit_tourGraceRec: any;
  edit_tourRecurrence: number;
  edit_tourRecurrence_default: number;
  tourTimes_edit: TourDayTime[] = [];
  tourTimes_edit_week: TourDayTime[] = [];
  tourTimes_edit_month: TourDayTime[] = [];

  tourTimes_edit_default: TourDayTime[] = [];

  recurrence_change_edit(){
    // this.tourTimes_edit = this.tourTimes_edit_default;
    
    this.tourTime_exists_edit= false;

    if (this.edit_tourRecurrence_default == 0){
      this.tourTimes_edit_week = this.tourTimes_edit_default;
      this.tourTimes_edit_month = [];
    }
    else if (this.edit_tourRecurrence_default == 1){
      this.tourTimes_edit_week = [];
      this.tourTimes_edit_month = this.tourTimes_edit_default;
    }
  }

  formatDayAndTime(schedules: any[]): { day: number, time: string }[] {
    return schedules.map(schedule => {
      const formattedTime = schedule.time.substring(0, 5); // Extract the first 5 characters (hh:mm)
      return { day: schedule.day, time: formattedTime };
    });
  }

  openTourEditModal(content: any, tour: TourRoute) {
    this.edit_tourId = tour.id;
    this.edit_tourDescRec = tour.description;
    this.edit_tourAssignedRec = tour.assignedTo;
    this.edit_tourInstructionsRec = tour.instructions;
    this.edit_tourDurationRec = tour.duration;
    this.edit_tourGraceRec = tour.gracePeriod;
    this.edit_tourRecurrence = tour.recurrenceId;
    this.edit_tourRecurrence_default = tour.recurrenceId;
    this.tourTimes_edit = this.formatDayAndTime(tour.schedules);
    this.tourTimes_edit_default = this.formatDayAndTime(tour.schedules);
    this.tourTimes_edit_week = this.formatDayAndTime(tour.schedules);
    this.tourTimes_edit_month = this.formatDayAndTime(tour.schedules);


    this.modalService.open(content,
      { centered: true,
          size: 'lg',
          windowClass: 'detailsModal' ,
          backdrop: 'static'
        });

    
  }

  addTime_edit_week() {
    const newTime: TourDayTime = {
      day: parseInt(this.tourDay_field_edit, 10),
      time: this.tourTime_field_edit,
    };
    this.selectedDay_edit = parseInt(this.tourDay_field_edit, 10);
    // Check if the new time already exists in tourTimes_add
    const exists = this.tourTimes_edit_week.some(
      (time) => time.day === newTime.day && time.time === newTime.time
    );

    if (exists) {
      this.tourTime_exists_edit= true;
    } else {
      this.tourTime_exists_edit= false;
      this.tourTimes_edit_week.push(newTime);
      this.resetTimeField_edit();
    }

  }

  addTime_edit_month() {
    const newTime: TourDayTime = {
      day: parseInt(this.tourDay_field_edit, 10),
      time: this.tourTime_field_edit,
    };
    this.selectedDay_edit = parseInt(this.tourDay_field_edit, 10);
    // Check if the new time already exists in tourTimes_add
    const exists = this.tourTimes_edit_month.some(
      (time) => time.day === newTime.day && time.time === newTime.time
    );

    if (exists) {
      this.tourTime_exists_edit= true;
    } else {
      this.tourTime_exists_edit= false;
      this.tourTimes_edit_month.push(newTime);
      this.resetTimeField_edit();
    }

  }

  resetTimeField_edit() {
    this.tourTime_field_edit = '00:00';
    this.tourDay_field_edit = 0;
  }
  
  isDaySelected_edit_week(dayId: number): boolean {
    return this.tourTimes_edit_week.some(tourTime => tourTime.day === dayId);
  }

  isDaySelected_edit_month(dayId: number): boolean {
    return this.tourTimes_edit_month.some(tourTime => tourTime.day === dayId);
  }

  getTourTimesForDay_edit_week(dayId: number): TourDayTime[] {
    return this.tourTimes_edit_week.filter(tourTime => tourTime.day == dayId);
  }

  getTourTimesForDay_edit_month(dayId: number): TourDayTime[] {
    return this.tourTimes_edit_month.filter(tourTime => tourTime.day == dayId);
  }

  deleteTime_edit_week(dayId: number, time: string) {
    this.tourTimes_edit_week = this.tourTimes_edit_week.filter(
      (tourTime) => !(tourTime.day === dayId && tourTime.time === time)
    );
  }

  deleteTime_edit_month(dayId: number, time: string) {
    this.tourTimes_edit_month = this.tourTimes_edit_month.filter(
      (tourTime) => !(tourTime.day === dayId && tourTime.time === time)
    );
  }

  async editTourRoute(f: NgForm){

    if (this.edit_tourRecurrence == 0){
      this.tourTimes_edit = this.tourTimes_edit_week;
    }
    else if (this.edit_tourRecurrence == 1){
      this.tourTimes_edit = this.tourTimes_edit_month;
    }

    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/sites/${this.retrievedSite.id}/tours/${this.edit_tourId}`,

       {
        description: f.value.edit_tourDesc,
        instructions: f.value.edit_tourInstructions,
        assignedTo: f.value.edit_tourAssigned,
        duration: f.value.edit_tourDuration,
        gracePeriod: f.value.edit_tourGrace,
        recurrenceId: f.value.edit_tourRecurrenceType,
        schedules: this.tourTimes_edit,
        
       }


       ).subscribe(
       (res: any) => {

         f.reset();
         window.location.reload();
       } ,
      (error : any) => {
        console.log("Error")
      }
    );

   } catch (error) {
     this.data.error(error['message']);
   }
  }

  tourId_checkpoint: any;
  tour_description_checkpoint: any;

  selectedCheckpoints = [];
  existedCheckpoints = [];

  openCheckpointTourModal(content: any, tour: TourRoute) {
    this.modalService.open(content,
      { centered: true,
          size: 'md',
          windowClass: 'detailsModal' ,
          backdrop: 'static'
        });
    this.tour_description_checkpoint = tour.description;
    this.tourId_checkpoint = tour.id;
    // this.existedCheckpoints = tour.checkpoints;
    this.existedCheckpoints = tour.checkpoints
      .filter(checkpoint => checkpoint.id !== undefined && checkpoint.id !== null)
      .map(checkpoint => checkpoint.id);
    this.selectedCheckpoints = this.existedCheckpoints;
    console.log(this.existedCheckpoints);
  }

  

  toggleCheckpoint(checkpoint_id) {
    if (this.isCheckpointSelected(checkpoint_id)) {
      this.selectedCheckpoints = this.selectedCheckpoints.filter(id => id !== checkpoint_id);
      
    } else {
      this.selectedCheckpoints.push(checkpoint_id);
    }
    console.log(this.selectedCheckpoints);
  }

  isCheckpointSelected(checkpoint_id) {
    return this.selectedCheckpoints.includes(checkpoint_id);
  }

  toggleAllCheckpoints(event) {
    if (event.target.checked) {
      // If "All Days" checkbox is checked, add all days to selectedDays
      this.selectedCheckpoints = this.checkpoints.map(check => check.id);
    } else {
      // If "All Days" checkbox is unchecked, remove all days from selectedDays
      this.selectedCheckpoints = [];
    }

    this.checkpoints.forEach(day => {
      const checkbox = document.getElementById(day.id.toString()) as HTMLInputElement;
      checkbox.checked = event.target.checked;
    });

  }

  isCheckpointExists(checkpointId: number): boolean {
    // Assuming your JSON data is stored in a variable named 'data'
    const selectedCheckpointt = this.existedCheckpoints.find(checkpoint => checkpoint === checkpointId);
    return !!selectedCheckpointt; // Return true if checkpoint exists, false otherwise
}

  async manageCheckpoints() {
    try {
      
      await this.rest.post(
        `${this.data.getBaseUrl()}api/v1/sites/${this.retrievedSite.id}/tours/${this.tourId_checkpoint}/checkpoints`,
        {
          checkpoints: this.selectedCheckpoints,
        },
      ).subscribe(
        (res: any) => {
          window.location.reload();
        },
        (error: any) => {
          console.log('Error:', error);
        }
      );
    } catch (error) {
      this.data.error(error['message']);
    }
  }

  // Emergency Contacts

  siteContacts: Contact[] = [];

  openModal(content: any) {
    this.modalService.open(content,
      { centered: true,
          size: 'md',
          windowClass: 'detailsModal' ,
          backdrop: 'static'
        });

  }

  deletedContactName: string;
  deletedContactId: string;

  editedContact: Contact;
  editedContact_id: number;

  openDeleteModal(content: any, contact:Contact) {
    this.modalService.open(content,
      { centered: true,
          size: 'md',
          windowClass: 'detailsModal' ,
          backdrop: 'static'
        });
    this.deletedContactName = contact.firstName + " " + contact.lastName;
    this.deletedContactId = contact.id;
  }

  openEditContactModal(content: any, contact:Contact) {
    this.modalService.open(content,
      { centered: true,
          size: 'md',
          windowClass: 'detailsModal' ,
          backdrop: 'static'
        });
    // this.editedContact.firstName = contact.firstName;
    // this.editedContact.lastName = contact.lastName;
    // this.editedContact.email = contact.email;
    // this.editedContact.mobile = contact.mobile;
    // this.editedContact.jobTitle = contact.jobTitle;
    // this.editedContact.isEmergency = contact.isEmergency;

    this.editedContact = contact;
  }

  displayContactsTable: boolean = false;

  async getContacts(siteId:number){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/sites/${siteId}/contacts`
      ).subscribe(
        (res: any) => {
          this.fetchError = false;
          this.siteContacts = res;
          this.loading = false;
          this.displayContactsTable = true;
        },
        (error: any) => {
          console.log(error);
          this.displayContactsTable = false;
      });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }


  async addContacts(f: NgForm){

    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/sites/${this.retrievedSite.id}/contacts`,

       {
        firstName: f.value.contactFName,
        lastName: f.value.contactLName,
        email: f.value.contactEmail,
        mobile: f.value.contactMob,
        jobTitle: f.value.contactJob,
        isEmergency: f.value.isEmergency,
        
       }


       ).subscribe(
       (res: any) => {

         f.reset();
         window.location.reload();
       } ,
      (error : any) => {
        console.log("Error")
      }
    );

   } catch (error) {
     this.data.error(error['message']);
   }
  }

  async deleteContacts(){

    try {

      await this.rest.deleteWithId(
       `${this.data.getBaseUrl()}api/v1/sites/${this.retrievedSite.id}/contacts/${this.deletedContactId}`


       ).subscribe(
       (res: any) => {

         window.location.reload();
       } ,
      (error : any) => {
        console.log("Error")
      }
    );

   } catch (error) {
     this.data.error(error['message']);
   }
  }

  async editContacts(f: NgForm){

    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/sites/${this.retrievedSite.id}/contacts/${this.editedContact.id}`,

       {
        firstName: f.value.contactFName,
        lastName: f.value.contactLName,
        email: f.value.contactEmail,
        mobile: f.value.contactMob,
        jobTitle: f.value.contactJob,
        isEmergency: f.value.isEmergency,
        
       }


       ).subscribe(
       (res: any) => {

         f.reset();
         window.location.reload();
       } ,
      (error : any) => {
        console.log("Error")
      }
    );

   } catch (error) {
     this.data.error(error['message']);
   }
  }

  siteEmployees: SiteEmployee[] = [];
  siteBannedEmployees: SiteEmployee[] = [];
  displayBannedTable: boolean = false;
  async getSiteEmployees(siteId:number){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/sites/${siteId}/employees`
      ).subscribe( 
        (res: any) => {
          
          this.siteEmployees = res;
          
        } ,
       (error : any) => {
        
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }
  
    // Banned & UnBanned
    bannedEmployee_id: string = "";
    unBannedEmployee_id: string = "";
  
    async getBanned(siteId:number){
      try {
        await this.rest.getAll(
          `${this.data.getBaseUrl()}api/v1/sites/${siteId}/employees/banned`
        ).subscribe(
          (res: any) => {
            this.fetchError = false;
            this.siteBannedEmployees = res;
            this.loading = false;
            this.displayBannedTable = true;
          },
          (error: any) => {
            console.log(error);
            this.displayContactsTable = false;
        });
  
      } catch (error) {
        console.log("error"+ error);
        this.data.error(error['message']);
        // this.loading = true;
  
      }
    }
  
    effectiveDateCheck:boolean = true;
    async ban(f:NgForm){
  
      try {
  
        await this.rest.post(
         `${this.data.getBaseUrl()}api/v1/sites/${this.retrievedSite.id}/employees/${this.bannedEmployee_id}/ban`,
         {
          effectiveDate: f.value.effectiveDate,
         }
  
         ).subscribe(
         (res: any) => {
           window.location.reload();
         } ,
        (error : any) => {
          console.log("Error")
        }
      );
  
     } catch (error) {
       this.data.error(error['message']);
     }
    }
  
    async unBan(id: string){
  
      try {
  
        await this.rest.post(
         `${this.data.getBaseUrl()}api/v1/sites/${this.retrievedSite.id}/employees/${id}/unban`,{}
  
         ).subscribe(
         (res: any) => {
           window.location.reload();
         } ,
        (error : any) => {
          console.log("Error")
        }
      );
  
     } catch (error) {
       this.data.error(error['message']);
     }
    }

  fenceAddForm :boolean = false;
  fencesDetails :any;
  triggerSettings: Trigger;
  stringPolygons :any[] = [];
  allowedFences: any[] = [];
  restrictedFences: any[] = [];
  async getSitePolygons(siteId:number){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/sites/${siteId}/geofence`
      ).subscribe(
        (res: any) => {

          this.fencesDetails = res;
          if (res.trigers === null) {
            this.triggerSettings = {
              minGpsAccuracy: 0,
              outOfZoneDetectionAlert: 1,
              outOfZoneReminderFrequency: 0,
              restrictedZoneEnteringAlert: 0,
              restrictedZoneCreateSystemException: 1,
              restrictedZoneExitingAlert: 1,
              restrictedZoneReminderFrequency: 0,
              allowedZoneEnteringAlert: 1,
              allowedZoneCreateSystemException: 1,
              allowedZoneExitingAlert: 1,
              allowedZoneReminderFrquency: 0
            };
          } else {
            this.triggerSettings = res.trigers;
          }
          // this.triggerSettings = res.trigers;

          for (const fence of this.fencesDetails.allowed) {
            this.allowedFences.push(fence);
          }

          for (const fence of this.fencesDetails.restricted) {
            this.restrictedFences.push(fence);
          }

          // Map allowed locations to green polygons
          this.fencesDetails.allowed.forEach(allowedObj => {
            this.stringPolygons.push({
              coordinates: allowedObj.location,
              fillColor: '#00FF00' // Green
            });
          });
          
          // Map restricted locations to red polygons
          this.fencesDetails.restricted.forEach(restrictedObj => {
            this.stringPolygons.push({
              coordinates: restrictedObj.location,
              fillColor: '#FF0000' // Red
            });
          });

          console.log(this.stringPolygons);
        },
        (error: any) => {
          console.log(error);
        });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  async deleteFence(id: string){

    try {

      await this.rest.deleteWithId(
       `${this.data.getBaseUrl()}api/v1/sites/${this.retrievedSite.id}/geofence/${id}`


       ).subscribe(
       (res: any) => {
         window.location.reload();
       } ,
      (error : any) => {
        console.log("Error")
      }
    );

   } catch (error) {
     this.data.error(error['message']);
   }
  }



  @ViewChild('mapElement', {static: false}) mapElement: ElementRef;

  ngAfterViewInit() {
    if (this.showGeoFencingContent) {
      this.initMap();
    }
  }

  polygon_coordinates: string[] = [];
  site_location: string="";
  initMap = () => {
    var mapOptions: any;
    var map: any;
    var location: any;
    var drawingManager: any;
    var coordinates: any[] = [];
    var selectedShape: any;
    var new_coordinates: any[] = []
    var lastElement: any;
    if(this.retrievedSite.location){
      this.site_location = this.retrievedSite.location;
    }else{
      this.site_location = "30.045282111816455,31.230016482138172";
    }
    

    let [lat, lang] = this.site_location.split(',');

    // Create an object with properties lat and lang
    let site_location = {
      lat: parseFloat(lat),
      lang: parseFloat(lang)
    };
    location = new google.maps.LatLng(site_location.lat, site_location.lang);
    mapOptions = {
        zoom: 14,
        center: new google.maps.LatLng(site_location.lat, site_location.lang),
        mapTypeId: google.maps.MapTypeId.RoadMap
    };

    map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);

    // const marker = new google.maps.Marker({
    //   position: { lat: 28.620585, lng: 77.228609 },
    //   map: map,
    //   title: 'Hello World!'
    // });

  //   var stringPolygonsArray = [
  //     {
  //         coordinates: ["30.090244,31.320976", "30.092992,31.34046", "30.085714,31.325783", "30.085343,31.318659"],
  //         fillColor: '#FF0000' // Red
  //     },
  //     {
  //         coordinates: ["30.101689,31.358785", "30.094412,31.378698", "30.091738,31.349859"],
  //         fillColor: '#00FF00' // Green
  //     }
  // ];

  // console.log(this.stringPolygons);

  // var stringPolygonsArray = this.stringPolygons;

  // Loop through the array and create polygons
    this.stringPolygons.map(function (polygonData) {
      // Convert string coordinates to LatLng objects
      var polygonCoords = polygonData.coordinates.map(function (coord) {
          var [lat, lng] = coord.split(',').map(Number);
          return new google.maps.LatLng(lat, lng);
      });

      // Create a polygon using the provided coordinates and fill color
      var polygon = new google.maps.Polygon({
          paths: polygonCoords,
          editable: false, // Set to true if you want the polygon to be editable
          draggable: false, // Set to true if you want the polygon to be draggable
          fillColor: polygonData.fillColor,
          fillOpacity: 0.5
      });

      // Set the map to center on the first point of each polygon
      map.setCenter(polygonCoords[0]);

      // Add the polygon to the map
      polygon.setMap(map);

      return polygon; // Return the polygon object
  });
    
    var all_overlays: any[] = [];
    // var selectedShape;
    drawingManager = new google.maps.drawing.DrawingManager({
        // drawingMode: google.maps.drawing.OverlayType.MARKER,
        drawingControl: true,
        drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
              google.maps.drawing.OverlayType.POLYGON,
                // google.maps.drawing.OverlayType.MARKER,
                // google.maps.drawing.OverlayType.CIRCLE,
                // google.maps.drawing.OverlayType.RECTANGLE
            ]
        },

        polygonOptions: {
            clickable: false,
            draggable: false,
            editable: false,
            // fillColor: '#ffff00',
            fillColor: '#0073cc',
            fillOpacity: 0.5,

        },
    });

    function clearSelection() {
        if (selectedShape) {
          selectedShape.setEditable(false);
          selectedShape = null;
        }
    }

    //to disable drawing tools
    function stopDrawing() {
      drawingManager.setMap(null);
    }

    function setSelection(shape:any) {
        clearSelection();
        // stopDrawing();
        selectedShape = shape;
        shape.setEditable(false);
    }


    const deleteSelectedShape = () => {
        if (selectedShape) {
          selectedShape.setMap(null);
          drawingManager.setMap(map);
          coordinates.splice(0, coordinates.length)
          this.fenceAddForm = false;
        }
    }

    function CenterControl(controlDiv:any, map:any) {

        // Set CSS for the control border.
        var controlUI = document.createElement('div');
        controlUI.style.backgroundColor = '#fff';
        controlUI.style.border = '2px solid #fff';
        controlUI.style.borderRadius = '3px';
        controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
        controlUI.style.cursor = 'pointer';
        controlUI.style.marginBottom = '22px';
        controlUI.style.textAlign = 'center';
        controlUI.title = 'Select to delete the shape';
        controlDiv.appendChild(controlUI);

        // Set CSS for the control interior.
        var controlText = document.createElement('div');
        controlText.style.color = 'rgb(25,25,25)';
        controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
        controlText.style.fontSize = '16px';
        controlText.style.lineHeight = '38px';
        controlText.style.paddingLeft = '5px';
        controlText.style.paddingRight = '5px';
        controlText.innerHTML = 'Delete Selected Area';
        controlUI.appendChild(controlText);

        //to delete the polygon
        controlUI.addEventListener('click', function () {
            deleteSelectedShape();
        });
    }

    const getPolygonCoords = (newShape: any) =>{
      this.polygon_coordinates.splice(0, this.polygon_coordinates.length);
    
      var len = newShape.getPath().getLength();
    
      for (var i = 0; i < len; i++) {
        var coordinate = newShape.getPath().getAt(i).toUrlValue(6);
        this.polygon_coordinates.push(coordinate.replace(/'/g, '"'));
      }
    
      console.log(this.polygon_coordinates);
    }



    drawingManager.setMap(map);

    google.maps.event.addListener(drawingManager, 'polygoncomplete', function (event) {
      event.getPath().getLength();
      google.maps.event.addListener(event, "dragend", getPolygonCoords(event));

      google.maps.event.addListener(event.getPath(), 'insert_at', function () {
        getPolygonCoords(event)
          
      });

      google.maps.event.addListener(event.getPath(), 'set_at', function () {
        getPolygonCoords(event)
      })
  })

    google.maps.event.addListener(drawingManager, 'overlaycomplete', (event) => {
      all_overlays.push(event);
        if (event.type !== google.maps.drawing.OverlayType.MARKER) {
          drawingManager.setDrawingMode(null);

            var newShape = event.overlay;
            newShape.type = event.type;
            google.maps.event.addListener(newShape, 'click', function () {
                setSelection(newShape);
            });
            setSelection(newShape);
        }
        
        this.fenceAddForm = true;
    })

    var centerControlDiv = document.createElement('div');
    var centerControl = new CenterControl(centerControlDiv, map);

    
    // centerControlDiv.index = 1;
    map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(centerControlDiv);

  }

  isRestricted :boolean=false;

  // GeoFence Add Fence
  async addFence(f: NgForm){

    this.isRestricted = f.value.fenceRestricted;
    const newFence = {
      "name": f.value.fenceName,
      "location": this.polygon_coordinates
    };

    if (this.isRestricted) {
      this.fencesDetails.restricted.push(newFence);
    } else {
      this.fencesDetails.allowed.push(newFence);
    }

    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/sites/${this.retrievedSite.id}/geofence`,

       this.fencesDetails

       ).subscribe(
       (res: any) => {
         window.location.reload();
       } ,
      (error : any) => {
        console.log("Error")
      }
    );

   } catch (error) {
     this.data.error(error['message']);
   }
  }

  // GeoFence Triggers Form
  async saveTriggers(f: NgForm){

    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/sites/${this.retrievedSite.id}/geofence/trigers`,

       
        {
          minGpsAccuracy: f.value.min_gps_acc,
          outOfZoneDetectionAlert: f.value.out_zone_detect,
          outOfZoneReminderFrequency: f.value.out_zone_reminder,
          restrictedZoneEnteringAlert: f.value.enter_restrict,
          restrictedZoneCreateSystemException: f.value.create_sys_exception_restricted,
          restrictedZoneExitingAlert: f.value.exit_restrict,
          restrictedZoneReminderFrequency: f.value.reminder_freq_restricted,
          allowedZoneEnteringAlert: f.value.enter_allowed,
          allowedZoneCreateSystemException: f.value.create_sys_exception_allowed,
          allowedZoneExitingAlert: f.value.exit_allowed,
          allowedZoneReminderFrquency: f.value.reminder_freq_allowed,
        }
       

       ).subscribe(
       (res: any) => {
         window.location.reload();
       } ,
      (error : any) => {
        console.log("Error")
      }
    );

   } catch (error) {
     this.data.error(error['message']);
   }
  }


  // Mobile Dispatch

  selectedDays = [];
  daysError: any;

  days: any = [
    
    {
      id: 1,
      name: 'Monday'
    },
    {
      id: 2,
      name: 'Tuesday'
    },
    {
      id: 3,
      name: 'Wednesday'
    },
    {
      id: 4,
      name: 'Thursday'
    },
    {
      id: 5,
      name: 'Friday'
    },
    {
      id: 6,
      name: 'Saturday'
    },
    {
      id: 0,
      name: 'Sunday'
    },
  ];

  toggleDay(day) {
    if (this.isSelectedDay(day)) {
      this.selectedDays = this.selectedDays.filter(id => id !== day.id);
    } else {
      this.selectedDays.push(day.id);
    }

  }

  isSelectedDay(day) {
    return this.selectedDays.includes(day.id);
  }

  toggleAllDays(event) {
    if (event.target.checked) {
      // If "All Days" checkbox is checked, add all days to selectedDays
      this.selectedDays = this.days.map(day => day.id);
    } else {
      // If "All Days" checkbox is unchecked, remove all days from selectedDays
      this.selectedDays = [];
    }

    this.days.forEach(day => {
      const checkbox = document.getElementById(day.id.toString()) as HTMLInputElement;
      checkbox.checked = event.target.checked;
    });
    
  }

  patrolTypes: any[] = [];

  async getPatrolType(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/patrol-types`
      ).subscribe(
        (res: any) => {
          this.patrolTypes = res;
        },
        (error: any) => {
          console.log(error);
      });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  routePositionsss: RoutePosition[] = [];
  async getRoutePositions(siteId: number){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/sites/${siteId}/mobile-dispatch/route-positions`
      ).subscribe(
        (res: any) => {
          this.routePositionsss = res;
        },
        (error: any) => {
          console.log(error);
      });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }
  
  async addDispatch(f: NgForm){

    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/sites/${this.retrievedSite.id}/mobile-dispatch/schedule`,

        {
          priceTier: f.value.priceTier,
          patrolType: f.value.patrolType,
          routePosition: f.value.routePositions,
          stayInMinutes: f.value.stayMins,
          visitCount: f.value.visitsNumber,
          timeFrom: f.value.dispatchTimeFrom,
          timeTo: f.value.dispatchTimeTo,
          startDate: f.value.dispatchDateFrom,
          endDate: f.value.dispatchDateTo,
          autoAssignRunsheet: f.value.autoAssign,
          days: this.selectedDays,
        }
       

       ).subscribe(
       (res: any) => {
         window.location.reload();
       } ,
      (error : any) => {
        console.log("Error")
      }
    );

   } catch (error) {
     this.data.error(error['message']);
   }
  }

  scheduleDispatches: ScheduleDispatch[] = [];
  displayDispatchContent: boolean = false;

  async getMobileDispatches(siteId: number){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/sites/${siteId}/mobile-dispatch/schedule`
      ).subscribe(
        (res: any) => {
          this.scheduleDispatches = res;
          this.displayDispatchContent = true;
          console.log(this.scheduleDispatches);
        },
        (error: any) => {
          console.log(error);
      });

    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }
  
  AssignedShiftDays: number[] = [];
  showAssignBtn: boolean = false;
  isEventActive: boolean = false;
  isActive: boolean = false;

  markAll() {
    this.isActive = !this.isActive;
    if (this.isActive) {
      // If "Mark All" is toggled on, push all temp.id values into AssignedShiftDays
      this.AssignedShiftDays = this.scheduleDispatches.map(temp => temp.id);
      // Toggle isActive for each slot
      this.scheduleDispatches.forEach(slot => slot.isActive = true);
    } else {
      // If "Mark All" is toggled off, reset AssignedShiftDays array
      this.AssignedShiftDays = [];
      // Reset isActive for each slot
      this.scheduleDispatches.forEach(slot => slot.isActive = false); 
      
    }
    this.updateShowAssignBtn();
    console.log(this.AssignedShiftDays);
  }

  slotSelect(tempDay: number) {
    const assignShift = tempDay;
    
    this.toggleShift(assignShift);
    this.updateShowAssignBtn();
  }


  toggleActive(templateDay: any) {
    templateDay.isActive = !templateDay.isActive;
  }

  updateShowAssignBtn() {
    this.showAssignBtn = this.AssignedShiftDays.length > 0;
  }

  toggleShift(day:number) {
    if (this.isSelectedShift(day)) {
      this.AssignedShiftDays = this.AssignedShiftDays.filter(shift => shift !== day);
    } else {
      this.AssignedShiftDays.push(day);
    }

    console.log(this.AssignedShiftDays);
  }

  isSelectedShift(day:number) {
    return this.AssignedShiftDays.includes(day);
  }


  async deleteSlot(){

    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/sites/${this.retrievedSite.id}/mobile-dispatch/schedule/delete`,

        {
          scheduleIds: this.AssignedShiftDays,
        }
       

       ).subscribe(
       (res: any) => {
         window.location.reload();
       } ,
      (error : any) => {
        console.log("Error")
      }
    );

   } catch (error) {
     this.data.error(error['message']);
   }
  }

  openAssignPosition(content: any) {
    this.modalService.open(content,
       { centered: true,
          size: 'md',
          windowClass: 'detailsModal' ,
          backdrop: 'static'
        });

  }

  async assignPositionFunc(f: NgForm){

    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/sites/${this.retrievedSite.id}/mobile-dispatch/schedule/assign-position`,

        {
          scheduleIds: this.AssignedShiftDays,
          positionId: f.value.assignPosition,
        }
       

       ).subscribe(
       (res: any) => {
         window.location.reload();
       } ,
      (error : any) => {
        console.log("Error")
      }
    );

   } catch (error) {
     this.data.error(error['message']);
   }
  }

}
