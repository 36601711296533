<div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h2 class="mb-4">Add Admin User</h2>
      </div>
      <div class="col-12"  *ngIf="itemAdded">
        <ngb-alert type="success"  [dismissible]="false" class="text-center fadeAlert">{{successMessage}}</ngb-alert>
      </div>

      <div class="col-12">
        <form #f="ngForm" (ngSubmit)="addAdmin()">
          <div class="row">
            
            <div class="form-group col-12 col-md-4">
              <label for="">Name</label>
              <input type="text" class="form-control" name="adminName" ngModel required #adminName="ngModel">
              <span class="text-danger" *ngIf="!adminName.valid && adminName.touched">Please Enter A Valid Answer</span>
            </div>
            <div class="form-group col-12 col-md-4">
                <label for="">Email</label>
                <input type="email" class="form-control" name="adminEmail" ngModel required #adminEmail="ngModel">
                <span class="text-danger" *ngIf="!adminEmail.valid && adminEmail.touched">Please Enter A Valid Answer</span>
            </div>
            <div class="form-group col-12 col-md-4">
                <label for="">Mobile</label>
                <input type="text" class="form-control" name="adminMobile" ngModel required #adminMobile="ngModel">
                <span class="text-danger" *ngIf="!adminMobile.valid && adminMobile.touched">Please Enter A Valid Answer</span>
            </div>
            <div class="form-group col-12 col-md-4">
                <label for="">Role</label>
                <select type="text" class="form-control" name="adminRole" ngModel required #adminRole="ngModel">
                    <option value="">Select Role</option>
                    <option *ngFor="let Role of Roles" [value]="Role._id">{{Role.roleTitle}}</option>
                </select>
                <span class="text-danger" *ngIf="!adminRole.valid && adminRole.touched">Please Choose A Valid Answer</span>
            </div>
            <div class="form-group col-12 col-md-4">
                <label for="">Password</label>
                <input type="password" class="form-control" name="adminPassword" [(ngModel)]="formPassword" required #adminPassword="ngModel"  (ngModelChange)="onChangePassword()">
                <span class="text-danger" *ngIf="!adminPassword.valid && adminPassword.touched">Please Enter A Valid Answer</span>
            </div>
            <div class="form-group col-12 col-md-4">
                <label for="">Confirm Password</label>
                <input type="password" class="form-control" name="confirmAdminPassword" [(ngModel)]="formConfirmPassword" required #confirmAdminPassword="ngModel" (ngModelChange)="onChangeConfirmPassword()">
                <span class="text-danger" *ngIf="!confirmAdminPassword.valid && confirmAdminPassword.touched || showMatchMessage">Passwords don't match</span>
            </div>
            <div class="form-group col-12 text-center">
              <button type="submit" [disabled]="!f.valid || !validPassword" class="btn  submitBtn" ><i class="fas fa-plus"></i> Add</button>
            </div>
          </div>
          
        </form>
      </div>

      <div class="col-12">
        <hr>
      </div>




      <div class="col-12 mb-3 alignContent-spaceBetween">
        <h2>Admin Users</h2>
      </div>
      <div class="col-12" *ngIf="fetchError">
        <ngb-alert type="danger"  [dismissible]="false" class="text-center">{{errorMessage}}</ngb-alert>
      </div>

      <div class="col-12"  *ngIf="itemAdded">
        <ngb-alert type="success"  [dismissible]="false" class="text-center fadeAlert">{{successMessage}}</ngb-alert>
      </div>

      <div class="col-12"  *ngIf="itemDeleted">
        <ngb-alert type="success"  [dismissible]="false" class="text-center fadeAlert">{{successMessage}}</ngb-alert>
      </div>

      <div class="col-12 text-center" *ngIf="loading">
        <img src="../../../assets/images/loader2.gif" class="loaderImage" alt="">
      </div>
      
      <div class="col-12" *ngIf="!fetchError && !loading">
        <div class="card">
          <div class="card-body" *ngIf="displayTable">
            <div class="table-responsive">
              <table datatable [dtOptions]="dtOptions" id="datatable" class="datatable table align-middle table-nowrap table-hover">
                <thead class="table-light">
                  <tr>
                    
                    <th scope="col">Role</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Mobile</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let AdminUser of AdminUsers">
                    
                    <td>{{AdminUser.userRole.roleTitle}}</td>
                    <td>{{AdminUser.name}}</td>
                    <td>{{AdminUser.email}}</td>
                    <td>{{AdminUser.mobileNo}}</td>
                    <td>
                      <!-- <button class="btn assignBtn" title="Edit" (click)="openModal(editModal, Role._id)"><i class="fas fa-edit"></i></button> -->
                      <button class="btn approveBtn" title="Reset Password" (click)="openModal(resetPassModal, AdminUser._id)"><i class="fas fa-key"></i> Reset Password</button>
                      <button class="btn deleteBtn" title="Delete" (click)="openModal(deleteModal, AdminUser._id)"><i class="fas fa-trash-alt"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12"  *ngIf="itemDeleted">
        <ngb-alert type="success"  [dismissible]="false" class="text-center fadeAlert">{{successMessage}}</ngb-alert>
      </div>
    </div>
</div>

<!-- <ng-template #editModal role="document" let-modal>
    <div class="modal-header">
      <h5 class="modal-title mt-0">Delete Role</h5>
      <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div>
        <form #f="ngForm">
            <div class="row">
              <div class="form-group col-12 col-md-4">
                <label for="">Role Title</label>
                <input type="text" class="form-control" name="roleName" ngModel required #roleName="ngModel">
                <span class="text-danger" *ngIf="!roleName.valid && roleName.touched">Please Enter A Valid Answer</span>
              </div>
              <div class="form-group col-12 col-md-4 btnPadding text-center">
                <button type="submit" [disabled]="!f.valid" class="btn w-100 submitBtn" ><i class="fas fa-plus"></i> Add</button>
              </div>
            </div>
            
        </form>
      </div>
      <div class="text-center">
        <button class="btn deleteBtn" title="Delete" (click)="deleteAdmin()">Delete</button>
        <button class="btn cancelBtn" (click)="modal.dismiss('Cross click')">Cancel</button>
      </div>
    </div>
    
  </ng-template> -->

<ng-template #resetPassModal role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Reset Password</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div>
      <form #reset="ngForm" (ngSubmit)="resetPass(reset)">
        <div class="row">
          <div class="form-group col-12 col-md-6">
            <label for="">Password</label>
            <input type="password" class="form-control" name="resetPassword" [(ngModel)]="resetFormPassword" required #resetPassword="ngModel"  (ngModelChange)="onChangeResetPassword()">
            <span class="text-danger" *ngIf="!resetPassword.valid && resetPassword.touched">Please Enter A Valid Answer</span>
        </div>
        <div class="form-group col-12 col-md-6">
            <label for="">Confirm Password</label>
            <input type="password" class="form-control" name="confirmResetPassword" [(ngModel)]="resetFormConfirmPassword" required #confirmResetPassword="ngModel" (ngModelChange)="onChangeConfirmResetPassword()">
            <span class="text-danger" *ngIf="!confirmResetPassword.valid && confirmResetPassword.touched || showMatchMessage">Passwords don't match</span>
        </div>
          <div class="form-group col-12 btnPadding text-center">
            <button type="submit" [disabled]="!reset.valid || !validPassword" class="btn submitBtn" ><i class="fas fa-key"></i> Reset</button>
            <button class="btn cancelBtn" (click)="modal.dismiss('Cross click')">Cancel</button>
          </div>
        </div>
        
    </form>
    </div>

  </div>
</ng-template>

<ng-template #deleteModal role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Delete Admin</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p class="text-center">
      <strong>
        Are you sure you want to delete this Admin?
      </strong>
    </p>
    <div class="text-center">
      <button class="btn deleteBtn" title="Delete" (click)="deleteAdmin()">Delete</button>
      <button class="btn cancelBtn" (click)="modal.dismiss('Cross click')">Cancel</button>
    </div>
  </div>
</ng-template>
