import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  NavigationEnd,
} from '@angular/router';
import { AdminRole } from '../core/models/adminRole';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router) {}
  retrievedObject :AdminRole ; 
  currentRoute : any;
  

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.retrievedObject = JSON.parse(localStorage.getItem('role'));
    // console.log(location.pathname);
    
    // this.router.events.filter(event => event instanceof NavigationEnd)
    //       .subscribe((event: any) => 
    //        {
    //           this.currentRoute = event.url;          
    //           console.log(event);
    //           console.log(this.currentRoute);
    //        });
    
    if (localStorage.getItem('token')) {
      return  true ;
    } else {
      console.log('No Token');
      this.router.navigate(['/account/login']);
      return false;
    }
  }
}
