import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/core/services/data.service';
import { RestApiService } from 'src/app/core/services/rest-api.service';
import { Uniform } from 'src/app/core/models/uniform';
import { Pagination } from 'src/app/core/models/pagination';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-uniforms',
  templateUrl: './uniforms.component.html',
  styleUrls: ['./uniforms.component.scss']
})
export class UniformsComponent implements OnInit {

  @ViewChild('f') AddZoneForm !: NgForm;
  @ViewChild('reset') resetPassForm !: NgForm;
  
  loading = true;
  fetchError = false;
  itemAdded = false;
  itemDeleted = false;

  dtOptions: any = {
    paging: false,
    lengthChange: false,
    info: false,
    searching: true,
  };

  uniforms: Uniform[] = [];
  displayTable: boolean = false;
  successMessage = '';
  errorMessage = '';

  PaginationObj: Pagination = {
    total: 0,
    perPage: 0,
    currentPage: 0,
    firstPage: 0,
    lastPage: 0,
    nextPage: 0,
    previousPage: 0,
    pageChange: new EventEmitter<number>(),
  };

  // Error handling variables
  internalIdErrorMessage = "";

  constructor( private rest: RestApiService, private data: DataService, private modalService: NgbModal ) { }


  ngOnInit(): void {
    this.getUniforms(1);
  }

  openModal(content: any) {
    this.modalService.open(content,
       { centered: true, 
          size: 'md', 
          windowClass: 'addLicenseModal' 
        });
    
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  onPageChange(page: number): void {
    this.loading = true;
    this.displayTable = false;
    this.getUniforms(page);
  }
  
  async getUniforms(pager:number){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/uniforms?page=${pager}`
      ).subscribe( 
        (res: any) => {
          this.successMessage = res.message;
          this.fetchError = false;
          this.uniforms = res.data;
          this.PaginationObj = res.pagination;
          this.loading = false;
          this.displayTable = true;
        },
        (error: any) => {
          this.fetchError = true;
          this.loading = false;
          this.errorMessage= error.error.message;
       });

    } catch (error) {
      console.log("error Zones"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }

  async addUniform(f: NgForm){
    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/uniforms`, 
       {
          name: f.value.uniName,

       }).subscribe( 
       (res: any) => {
        this.successMessage = res.message;
        this.fetchError = false;
        window.location.reload();
       } ,
      (error : any) => {
        this.errorMessage= error.error.message;
        this.internalIdErrorMessage= error.error.message;
        console.log(error.error);
      });


   } catch (error) {
     this.data.error(error['message']);
     console.log("error"+error);
     
   }
  }

}
