<div class="container-fluid">
    <div class="row">
      <div class="col-12 mb-3 alignContent-spaceBetween">
        <h2>Admin Users</h2>
        <button class="btn submitBtn" (click)="openModal(addUserModal)"><i class="fas fa-plus"></i> Add Admin</button>
      </div>

      <div class="col-12" *ngIf="fetchError">
        <ngb-alert type="danger"  [dismissible]="false" class="text-center">{{errorMessage}}</ngb-alert>
      </div>

      <!-- <div class="col-12"  *ngIf="userApproved">
        <ngb-alert type="success"  [dismissible]="false" class="text-center fadeAlert">{{successMessage}}</ngb-alert>
      </div>

      <div class="col-12"  *ngIf="userDeleted">
        <ngb-alert type="success"  [dismissible]="false" class="text-center fadeAlert">{{successMessage}}</ngb-alert>
      </div> -->

      <app-loader [loading]="loading"></app-loader>
      
      <div *ngIf="!fetchError && !loading" class="col-12">
        <div class="card">
          <div class="card-body" *ngIf="displayTable">
            <div class="table-responsive">
              <table datatable [dtOptions]="dtOptions" class="table align-middle table-nowrap table-hover">
                <thead class="table-light">
                  <tr>

                    <th scope="col">Name</th>
                    <th scope="col">Username</th>
                    <th scope="col">Role</th>
                    <th scope="col">Email</th>

                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let user of Users">
                    <td>{{user.firstName}} {{user.lastName}}</td>
                    <td>{{user.username}}</td>
                    <td>{{user.role.name}}</td>
                    <td>{{user.email}}</td>
                    
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <app-pagination
                [total]="PaginationObj.total"
                [perPage]="PaginationObj.perPage"
                [currentPage]="PaginationObj.currentPage"
                [firstPage]="PaginationObj.firstPage"
                [lastPage]="PaginationObj.lastPage"
                [nextPage]="PaginationObj.nextPage"
                [previousPage]="PaginationObj.previousPage"
                (pageChange)="onPageChange($event)"
              ></app-pagination>
            </div>
            
          </div>
        </div>
      </div>
    </div>
</div>
  
<!-- <ng-template #approveModal role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Approve User</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p class="text-center">
      <strong>
        Are you sure you want to Approve this user?
      </strong>
    </p>
    <div class="text-center">
      <button class="btn approveBtn" title="Approve" (click)="approveUser()">Approve</button>
      <button class="btn cancelBtn" (click)="modal.dismiss('Cross click')">Cancel</button>
    </div>
  </div>
  
</ng-template> -->

<!-- <ng-template #deleteModal role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Delete User</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p class="text-center">
      <strong>
        Are you sure you want to delete this user?
      </strong>
    </p>
    <div class="text-center">
      <button class="btn deleteBtn" title="Delete" (click)="deleteUser()">Delete</button>
      <button class="btn cancelBtn" (click)="modal.dismiss('Cross click')">Cancel</button>
    </div>
  </div>
  
</ng-template> -->

<!-- <ng-template #userDetailsModal role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">User Details</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="closeDetailsModal()"></button>
  </div>
  <div class="modal-body">

    <div class="row">
      
      <div class="col-12 col-md-3 text-center">
        <img src="{{selectedUserDetails.picture}}" width="100%" alt="">
        
      </div>
      <div class="col-12 col-md-9">
          <div class="row">
            <div class="col-12">
              <h5 class="colorBlue">Personal Information</h5>
              <hr>
            </div>
            <div class="col-12 col-md-6">
              <div class="singleUserInfo">
                <label for="">User Status</label>
                <p class="mt-2">
                  <span *ngIf="selectedUserDetails.isAdminApproved; else elseUserStatus" class="approved"><i class="fas fa-check-circle"></i> Approved</span>
                  <ng-template #elseUserStatus><span  class="notApproved"><i class="fas fa-times-circle"></i> Not Approved</span></ng-template>
                </p>
              </div>
              <div class="singleUserInfo">
                <label for="">EN Name</label>
                <p>
                  <strong>{{selectedUserDetails.name}}</strong>
                </p>
              </div>
              <div class="singleUserInfo">
                <label for="">Type</label>
                <p>
                  <strong>{{selectedUserDetails.userType}}</strong>
                </p>
              </div>
              <div class="singleUserInfo">
                <label for="">Mobile No.</label>
                <p>
                  <strong>{{selectedUserDetails.mobileNo}}</strong>
                </p>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="singleUserInfo">
                <label for="">Email</label>
                <p>
                  <strong>{{selectedUserDetails.email}}</strong>
                </p>
              </div>
              <div class="singleUserInfo">
                <label for="">Nationality</label>
                <p>
                  <strong>{{selectedUserDetails.nationality}}</strong>
                </p>
              </div>
              
              <div class="singleUserInfo">
                <label for="">Passport Number</label>
                <p>
                  <strong>{{selectedUserDetails.idPassportNo}}</strong>
                </p>
              </div>
            </div>

            
            
          </div>
      </div>

      <div class="col-12">
        <h5 class="colorBlue">Unit Information</h5>
        <hr>
      </div>
      <div class="col-12 col-md-4">
        <div class="singleUserInfo">
          <label for="">Unit Number</label>
          <p>
            <strong>{{selectedUserDetails.unitNumber}}</strong>
          </p>
        </div>
        <div class="singleUserInfo">
          <label for="">Unit Size</label>
          <p>
            <strong>{{selectedUserDetails.unitSize}}</strong>
          </p>
        </div>
        <div class="singleUserInfo">
          <label for="">Residence Place</label>
          <p>
            <strong>{{selectedUserDetails.residencePlace}}</strong>
          </p>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="singleUserInfo">
          <label for="">Landline</label>
          <p>
            <strong>{{selectedUserDetails.landline}}</strong>
          </p>
        </div>
        <div class="singleUserInfo">
          <label for="">Garden</label>
          <p *ngIf="selectedUserDetails.hasGarden; else elseGarden"><strong>Yes</strong></p>
          <ng-template #elseGarden><p><strong>No</strong></p></ng-template>
        </div>
        <div class="singleUserInfo">
          <label for="">Swimming Pool</label>
          <p *ngIf="selectedUserDetails.hasSwimmingPool; else elseSwimmingPool"><strong>Yes</strong></p>
          <ng-template #elseSwimmingPool><p><strong>No</strong></p></ng-template>
        </div>
      </div>

      <div class="col-12 col-md-4">
        <div class="singleUserInfo">
          <label for="">Owner Status</label>
          <p>
            <strong>{{selectedUserDetails.ownerStatus}}</strong>
          </p>
        </div>
        <div class="singleUserInfo">
          <label for="">No. Of Cars</label>
          <p>
            <strong>{{selectedUserDetails.noOfCars}}</strong>
          </p>
        </div>
         <div class="singleUserInfo">
          <label for="">Swimming Pool</label>
          <p *ngIf="selectedUserDetails.hasSwimmingPool; else elseSwimmingPool"><strong>Yes</strong></p>
          <ng-template #elseSwimmingPool><p><strong>No</strong></p></ng-template>
        </div> 
      </div>
    </div>

    
  </div>
  <div class="modal-footer">
    <div class="text-center">
      <button class="btn cancelBtn" (click)="closeDetailsModal()">Cancel</button>
    </div>
  </div>
  
</ng-template> -->
  
<ng-template #addUserModal role="document" let-modal >
  <div class="modal-header">
    <h5 class="modal-title mt-0">Add Admin User</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">

    <div class="row">
      <div *ngIf="errorMessage" class="col-12">
          <ngb-alert type="danger" [dismissible]="false" class="text-center">{{errorMessage}}</ngb-alert>
      </div>
      <div class="col-12">

        <form #f="ngForm" >
          <div class="row">

            <div class="form-group col-12">
              <label for="">First Name</label>
              <input type="text" class="form-control" name="adminFirstName" ngModel required #adminFirstName="ngModel">
              <span class="text-danger" *ngIf="!adminFirstName.valid && adminFirstName.touched">Please Enter A Valid Answer</span>
            </div>
            <div class="form-group col-12">
                <label for="">Last Name</label>
                <input type="text" class="form-control" name="adminLastName" ngModel required #adminLastName="ngModel">
                <span class="text-danger" *ngIf="!adminLastName.valid && adminLastName.touched">Please Enter A Valid Answer</span>
            </div>
            <div class="form-group col-12">
              <label for="">Email</label>
              <input type="email" class="form-control" name="adminEmail" ngModel required #adminEmail="ngModel">
              <span class="text-danger" *ngIf="!adminEmail.valid && adminEmail.touched">Please Enter A Valid Answer</span>
              <p class="text-danger m-0" *ngIf="emailErrorMessage"><strong>{{emailErrorMessage}}</strong></p>
            </div>
            <div class="form-group col-12">
              <label for="">Username</label>
              <input type="text" class="form-control" name="adminUsername" ngModel required #adminUsername="ngModel">
              <span class="text-danger" *ngIf="!adminUsername.valid && adminUsername.touched">Please Enter A Valid Answer</span>
              <p class="text-danger m-0" *ngIf="usernameErrorMessage"><strong>{{usernameErrorMessage}}</strong></p>
            </div>
            <div class="form-group col-12">
              <label for="">Mobile</label>
              <input type="tel"  pattern="[0-9]{11}" class="form-control" name="adminMobile" ngModel required #adminMobile="ngModel">
              <span class="text-danger" *ngIf="!adminMobile.valid && adminMobile.touched">Please Enter A Valid Answer</span>
              <p class="text-danger m-0" *ngIf="mobileErrorMessage"><strong>{{mobileErrorMessage}}</strong></p>
            </div>
            <div class="form-group col-12">
              <label for="">Create a Password</label>
              <input type="password" class="form-control" name="adminPassword" [(ngModel)]="formPassword" required #adminPassword="ngModel"  (ngModelChange)="onChangePassword()">
              <span class="text-danger" *ngIf="!adminPassword.valid && adminPassword.touched">Please Enter A Valid Answer</span>
              <p class="text-danger m-0" *ngIf="passwordErrorMessage"><strong>{{passwordErrorMessage}}</strong></p>
          </div>
          <div class="form-group col-12">
              <label for="">Confirm Password</label>
              <input type="password" class="form-control" name="confirmAdminPassword" [(ngModel)]="formConfirmPassword" required #confirmAdminPassword="ngModel" (ngModelChange)="onChangeConfirmPassword()">
              <span class="text-danger" *ngIf="!confirmAdminPassword.valid && confirmAdminPassword.touched || showMatchMessage">Passwords don't match</span>
          </div>

            <div class="col-12 text-center">
              <button type="submit" [disabled]="!f.valid" class="btn submitBtn" (click)="addUser(f)"><i class="fas fa-plus"></i> Add</button>
        
              <button class="btn cancelBtn" (click)="modal.dismiss('Cross click')">Cancel</button>
              
            </div>
          </div>

        </form>
      </div>
    </div>
    
    
  </div>
  
</ng-template>