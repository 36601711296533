import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/core/services/data.service';
import { RestApiService } from 'src/app/core/services/rest-api.service';
import { NgForm } from '@angular/forms';
import { AdminRole } from 'src/app/core/models/adminRole';
import { AdminUser } from 'src/app/core/models/adminUser';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit {
  @ViewChild('f') AddUserForm !: NgForm;
  @ViewChild('reset') resetPassForm !: NgForm;
  loading = true;
  fetchError = false;
  itemAdded = false;
  itemDeleted = false;
  Roles: AdminRole[] = [];
  AdminUsers: AdminUser[] = [];
  dtOptions: any = {};
  displayTable: boolean = false;
  selectedId = '';
  formPassword = '';
  formConfirmPassword = '';
  resetFormPassword = '';
  resetFormConfirmPassword = '';
  validPassword: boolean = false;
  showMatchMessage: boolean = false;

  successMessage = '';
  errorMessage = '';

  constructor( private rest: RestApiService, private data: DataService, private modalService: NgbModal ) { }

  ngOnInit(): void {
    this.dtOptions = {
      processing: true
    };
    this.AdminUsers = [];
    this.getRoles();
    this.getAdminUsers();
  }

  async getRoles(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/accounts/addUserRole`
      ).subscribe( 
        (res: any) => {
          this.successMessage = res.message;
          this.fetchError = false;
          this.Roles = res.roles;
          this.loading = false;
          this.displayTable = true;
        },
       (err) => {
        this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.data.error(error['message']);
      this.loading = true;
    }
  }

  async getAdminUsers(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/accounts/adminUsers`
      ).subscribe( 
        (res: any) => {
          this.successMessage = res.message;
          this.fetchError = false;
          this.AdminUsers = res.adminUsers;
          this.loading = false;
          this.displayTable = true;
        },
       (err) => {
        this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.data.error(error['message']);
      this.loading = true;
    }
  }

  openModal(content: any, itemId: string) {
    this.modalService.open(content, { centered: true });
    this.selectedId = itemId;
    
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  async resetPass(reset: NgForm){
    try {
      console.log(reset.value.resetPassword);
      console.log(this.selectedId);
      await this.rest.post(
       `${this.data.getBaseUrl()}api/accounts/resetpasswordAdmin`, 
       {
        newPassword: reset.value.resetPassword,
        UserID: this.selectedId,

       }).subscribe( 
       (res: any) => {
         this.successMessage = res.message;
          this.getAdminUsers();
         this.fetchError = false;
         this.itemAdded = true;
         this.selectedId = '';
         reset.reset();
         this.validPassword = true;
         this.closeModal();
         setTimeout(()=>{    
          this.itemAdded = false;
          }, 5000);
       } ,
      (err) => {
       this.errorMessage= err.message;
       this.fetchError = true;
       setTimeout(()=>{    
        this.fetchError = false;
        }, 5000);
       this.itemAdded = false;
      });


   } catch (error) {
     this.data.error(error['message']);
   }
  }

  async addAdmin(){
    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/accounts/signupAdmin`, 
       {
         email: this.AddUserForm.value.adminEmail,
         userRoleID: this.AddUserForm.value.adminRole,
         name: this.AddUserForm.value.adminName,
         mobileNo: this.AddUserForm.value.adminMobile,
         password: this.AddUserForm.value.adminPassword,

       }).subscribe( 
       (res: any) => {
         this.successMessage = res.message;
        //  this.getAdminUsers();
         this.fetchError = false;
         this.itemAdded = true;
         window.location.reload();
         this.AddUserForm.reset();
         this.validPassword = true;
       } ,
      (err) => {
       this.errorMessage= err.message;
       this.fetchError = true;
       this.itemAdded = false;
      });


   } catch (error) {
     this.data.error(error['message']);
   }
  }
  async deleteAdmin(){
    
    try {
      await this.rest.deleteWithId(
        `${this.data.getBaseUrl()}api/accounts/deleteAdminUserById/${this.selectedId}`
      ).subscribe( 
        (res: any) => {
          this.successMessage = res.message;
          this.fetchError = false;
          this.getAdminUsers();
          this.selectedId = '';
          this.closeModal();
          this.itemDeleted = true;
          window.location.reload();
        } ,
        (err) => {
          this.errorMessage= err.message;
          this.fetchError = true;
          this.itemDeleted = false;
        });

    } catch (error) {
      this.data.error(error['message']);
      console.log('Error While Deleting');
    }
  }

  onChangePassword() {
    this.formConfirmPassword = '';
  }

  onChangeConfirmPassword(){
    if(this.formPassword === this.formConfirmPassword){
      this.validPassword = true;
      this.showMatchMessage = false;
    }
    else{
      this.validPassword = false;
      this.showMatchMessage = true;
    }
  }

  onChangeResetPassword() {
    this.resetFormConfirmPassword = '';
  }

  onChangeConfirmResetPassword(){
    if(this.resetFormPassword === this.resetFormConfirmPassword){
      this.validPassword = true;
      this.showMatchMessage = false;
    }
    else{
      this.validPassword = false;
      this.showMatchMessage = true;
    }
  }
  

}
