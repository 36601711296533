<div class="container-fluid">
    <div class="row">
  
      <div class="col-12 mb-3 alignContent-spaceBetween">
        <h2>Sign In Logs</h2>
  
      </div>
  
      <div class="col-12" *ngIf="fetchError">
        <ngb-alert type="danger" [dismissible]="false" class="text-center">
          Something Went Wrong
        </ngb-alert>
      </div>
      <!-- 
  
         <div class="col-12"  *ngIf="itemDeleted">
          <ngb-alert type="success"  [dismissible]="false" class="text-center fadeAlert">{{successMessage}}</ngb-alert>
        </div>
  
        <div class="col-12"  *ngIf="itemAdded">
          <ngb-alert type="success"  [dismissible]="false" class="text-center fadeAlert">{{successMessage}}</ngb-alert>
        </div>
        -->
      <app-loader [loading]="loading"></app-loader>
  
      <div class="col-12" *ngIf="!fetchError && !loading">
        <div class="card">
          <div class="card-body" *ngIf="displayTable">
            <div class="table-responsive">
              <table 
                class="table align-middle table-nowrap table-hover">
                <thead class="table-light">
                  <tr>
  
                    <th scope="col">User</th>
                    <th scope="col">IP</th>
                    <th scope="col">Platform</th>
                    <th scope="col">Location</th>
                    <th scope="col">Action</th>
                    <th scope="col">Time / Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let l of logs">
  
                    <td>{{l.user.name}}</td>
                    <td>{{l.ip}}</td>
                    <td>{{l.platform}}</td>
                    <td>{{l?.location}}</td>
                    <td>{{l.action}}</td>
                    <td>{{l.createdAt | date:'HH:mm:ss / MM-dd-yyyy'}}</td>
                  </tr>
                </tbody>
              </table>
              <hr>
            </div>
  
          </div>
        </div>
      </div>
  
      <!-- <div class="col-12"  *ngIf="itemDeleted">
          <ngb-alert type="success"  [dismissible]="false" class="text-center fadeAlert">{{successMessage}}</ngb-alert>
        </div> -->
    </div>
  </div>