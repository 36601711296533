<div class="container-fluid">
    <div class="row">

      <div class="col-12 mb-3 alignContent-spaceBetween">
        <h2>Licenses</h2>
        <button class="btn submitBtn" (click)="openModal(addLicenseModal)"><i class="fas fa-plus"></i> Add License</button>
        
      </div>

      <div class="col-12" *ngIf="fetchError">
        <ngb-alert type="danger"  [dismissible]="false" class="text-center">
          Something Went Wrong
        </ngb-alert>
      </div>
      <!-- 

       <div class="col-12"  *ngIf="itemDeleted">
        <ngb-alert type="success"  [dismissible]="false" class="text-center fadeAlert">{{successMessage}}</ngb-alert>
      </div>

      <div class="col-12"  *ngIf="itemAdded">
        <ngb-alert type="success"  [dismissible]="false" class="text-center fadeAlert">{{successMessage}}</ngb-alert>
      </div>
      -->
      <app-loader [loading]="loading"></app-loader>

      <div class="col-12" *ngIf="!fetchError && !loading">
        <div class="card">
          <div class="card-body" *ngIf="displayTable">
            <div class="table-responsive">
              <table datatable [dtOptions]="dtOptions" id="datatable" class="datatable table align-middle table-nowrap table-hover">
                <thead class="table-light">
                  <tr>

                    <th scope="col">License Name</th>
                    <th scope="col">Has Issue Date</th>
                    <th scope="col">Has Expiry Date</th>
                    <th scope="col">Has License Number</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let License of Licenses">

                    <td>{{License.name}}</td>
                    <td>
                      <span *ngIf="License.hasIssueDate == 1" class=""><i class="fas fa-check-square trueCheck"></i></span>
                      <span *ngIf="License.hasIssueDate == 0" class=""><i class="fas fa-times-circle falseCheck"></i></span>
                    </td>
                    <td>
                      <span *ngIf="License.hasExpiryDate == 1" class=""><i class="fas fa-check-square trueCheck"></i></span>
                      <span *ngIf="License.hasExpiryDate == 0" class=""><i class="fas fa-times-circle falseCheck"></i></span>
                    </td>
                    <td>
                      <span *ngIf="License.hasLicenseNumber == 1" class=""><i class="fas fa-check-square trueCheck"></i></span>
                      <span *ngIf="License.hasLicenseNumber == 0" class=""><i class="fas fa-times-circle falseCheck"></i></span>
                    </td>
                    
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <app-pagination
                [total]="PaginationObj.total"
                [perPage]="PaginationObj.perPage"
                [currentPage]="PaginationObj.currentPage"
                [firstPage]="PaginationObj.firstPage"
                [lastPage]="PaginationObj.lastPage"
                [nextPage]="PaginationObj.nextPage"
                [previousPage]="PaginationObj.previousPage"
                (pageChange)="onPageChange($event)"
              ></app-pagination>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-12"  *ngIf="itemDeleted">
        <ngb-alert type="success"  [dismissible]="false" class="text-center fadeAlert">{{successMessage}}</ngb-alert>
      </div> -->
    </div>
</div>

<ng-template #addLicenseModal role="document" let-modal >
  <div class="modal-header">
    <h5 class="modal-title mt-0">Add License</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">

    <div class="row">
      <div class="col-12">
        <form #f="ngForm" >
          <div class="row">

            <div class="form-group col-12">
              <label for="">License Name</label>
              <input type="text" class="form-control" name="licenseName" ngModel required #licenseName="ngModel">
              <span class="text-danger" *ngIf="!licenseName.valid && licenseName.touched">Please Enter A Valid Answer</span>
            </div>
            <div class="form-group col-12 checkboxContainer">
              <input type="checkbox" id="isLicenseIssueDate" name="isLicenseIssueDate" ngModel #isLicenseIssueDate="ngModel"> 
              <label for="isLicenseIssueDate"> Has Issue Date</label>
            </div>
            <div class="form-group col-12 checkboxContainer">
              <input type="checkbox" id="isLicenseExpiryDate" name="isLicenseExpiryDate" ngModel #isLicenseExpiryDate="ngModel"> 
              <label for="isLicenseExpiryDate"> Has Expiry Date</label>
            </div>
            <div class="form-group col-12 checkboxContainer">
              <input type="checkbox" id="isLicenseNumber" name="isLicenseNumber" ngModel #isLicenseNumber="ngModel"> 
              <label for="isLicenseNumber"> Has License Number</label>
            </div>

            <div class="col-12 text-center">
              <button type="submit" [disabled]="!f.valid" class="btn submitBtn" (click)="addLicense(f)"><i class="fas fa-plus"></i> Add</button>
        
              <button class="btn cancelBtn" (click)="modal.dismiss('Cross click')">Cancel</button>
              
            </div>
          </div>

        </form>
      </div>
    </div>
    
    
  </div>
  
</ng-template>

