import { Component, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DataService } from "src/app/core/services/data.service";
import { RestApiService } from "src/app/core/services/rest-api.service";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-roles",
  templateUrl: "./roles.component.html",
  styleUrls: ["./roles.component.scss"],
})
export class RolesComponent implements OnInit {
  loading = true;
  fetchError = false;
  itemAdded = false;
  itemDeleted = false;

  dtOptions: any = {
    paging: false,
    lengthChange: false,
    info: false,
    searching: true,
  };

  roles: any[] = [];
  permissions: any[] = [];
  selectedPermissions: any[] = [];
  displayTable: boolean = false;
  successMessage = "";
  errorMessage = "";
  editRoleId = "";
  editRoleCategoryId = "";
  editedRole: any;

  constructor(
    private rest: RestApiService,
    private data: DataService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.getRoles();
    this.getPermissions();
  }

  openModal(content: any, size: any) {
    this.modalService.open(content, {
      centered: true,
      size: size,
      windowClass: "addLicenseModal",
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  async getRoles() {
    this.loading = true;
    this.displayTable = false;
    try {
      await this.rest.getAll(`${this.data.getBaseUrl()}api/v1/roles`).subscribe(
        (res: any) => {
          this.successMessage = res.message;
          this.fetchError = false;
          this.roles = res;
          this.loading = false;
          this.displayTable = true;
        },
        (error: any) => {
          this.fetchError = true;
          this.loading = false;
          this.errorMessage = error.error.message;
        }
      );
    } catch (error) {
      this.data.error(error["message"]);
      this.loading = true;
    }
  }

  async getPermissions() {
    this.loading = true;
    this.displayTable = false;
    try {
      await this.rest
        .getAll(`${this.data.getBaseUrl()}api/v1/permissions`)
        .subscribe(
          (res: any) => {
            this.successMessage = res.message;
            this.fetchError = false;
            this.permissions = res;
            this.loading = false;
            this.displayTable = true;
          },
          (error: any) => {
            this.fetchError = true;
            this.loading = false;
            this.errorMessage = error.error.message;
          }
        );
    } catch (error) {
      this.data.error(error["message"]);
      this.loading = true;
    }
  }

  async addRole(f: NgForm) {
    try {
      await this.rest
        .post(`${this.data.getBaseUrl()}api/v1/roles`, {
          name: f.value.roleName,
          description: f.value.roleDesc,
          categoryId: f.value.roleCategory,
          permissions: f.value.rolePermissions,
        })
        .subscribe(
          (res: any) => {
            this.successMessage = res.message;
            //  this.getAdminUsers();
            this.fetchError = false;
            this.itemAdded = true;
            this.getRoles();
            f.reset();
            this.closeModal();
          },
          (error: any) => {
            this.fetchError = true;
            this.itemAdded = false;
            this.closeModal();
            this.errorMessage = error.error.message;
          }
        );
    } catch (error) {
      console.log(error);
      this.data.error(error["message"]);
    }
  }

  async editRole(f: NgForm) {
    try {
      await this.rest
        .post(`${this.data.getBaseUrl()}api/v1/roles/${this.editRoleId}`, {
          name: f.value.roleName,
          description: f.value.roleDesc,
          categoryId: f.value.roleCategory,
          permissions: f.value.rolePermissions,
        })
        .subscribe(
          (res: any) => {
            this.successMessage = res.message;
            //  this.getAdminUsers();
            this.fetchError = false;
            this.itemAdded = true;
            this.getRoles();
            f.reset();
            this.closeModal();
          },
          (error: any) => {
            this.fetchError = true;
            this.itemAdded = false;
            this.closeModal();
            this.errorMessage = error.error.message;
          }
        );
    } catch (error) {
      console.log(error);
      this.data.error(error["message"]);
    }
  }

  EditRoleModal(content:any, roleId:any, category:any, role:any) {
    this.openModal(content, 'md');
    this.editRoleId = roleId;
    this.editRoleCategoryId = category;
    this.editedRole = role;
    this.selectedPermissions = role.permissions.map(permission => permission.id);
  }

  deletedRoleId:any;
  DeleteRoleModal(content:any, roleId:any) {
    this.openModal(content, 'md');
    this.deletedRoleId = roleId;
    
  }

  async deleteRole() {
    try {
      await this.rest
        .deleteWithId(`${this.data.getBaseUrl()}api/v1/roles/${this.deletedRoleId}`)
        .subscribe(
          (res: any) => {
            this.successMessage = res.message;
            this.fetchError = false;
            this.itemAdded = true;
            this.getRoles();
            this.getRoles();
            this.closeModal();
          },
          (error: any) => {
            this.fetchError = true;
            this.itemAdded = false;
            this.closeModal();
            this.errorMessage = error.error.message;
          }
        );
    } catch (error) {
      console.log(error);
      this.data.error(error["message"]);
    }
  }
}
