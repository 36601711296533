import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() total: number;
  @Input() perPage: number;
  @Input() currentPage: number;
  @Input() firstPage: number;
  @Input() lastPage: number;
  @Input() nextPage: number;
  @Input() previousPage: number;
  
  @Output() pageChange = new EventEmitter<number>();

  onPageChange(page: number): void {
    this.pageChange.emit(page);
  }
  
  getPagesArray(): number[] {
    const pages = [];
    for (let i = this.firstPage; i <= this.lastPage; i++) {
      pages.push(i);
    }
    return pages;
  }

}
