import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Payrun } from 'src/app/core/models/payrun';
import { DataService } from 'src/app/core/services/data.service';
import { RestApiService } from 'src/app/core/services/rest-api.service';
@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['./payroll.component.scss']
})
export class PayrollComponent implements OnInit {

  constructor(private rest: RestApiService, private data: DataService, private router: Router) { }

  dtOptions: any = {
    paging: true,
    lengthChange: false,
    info: false,
    searching: true,
  };

  Payruns: Payrun[] = [];
  loading = true;
  displayTable: boolean = false;

  ngOnInit(): void {

    this.getPayruns();
  }

  async getPayruns(){
    this.loading = true;
    this.displayTable = false;
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/mobile/payruns/1`
      ).subscribe(
        (res: any) => {
          this.Payruns = res.payruns;
          this.loading = false;
          this.displayTable = true;
        } ,
       (err) => {
        
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  onViewPayrun(id: string) {
      this.router.navigate(['/payroll/payrun', id]);
  }

}
