<div class="container-fluid">
  <div class="row">

    <div class="col-12 mb-3 alignContent-spaceBetween">
      <h2>Roles & Permissions</h2>
      <button class="btn submitBtn" (click)="openModal(addRoleModal, 'md')"><i class="fas fa-plus"></i> Add
        Role</button>

    </div>

    <div class="col-12" *ngIf="fetchError">
      <ngb-alert type="danger" [dismissible]="false" class="text-center">
        Something Went Wrong
      </ngb-alert>
    </div>
    <!-- 

       <div class="col-12"  *ngIf="itemDeleted">
        <ngb-alert type="success"  [dismissible]="false" class="text-center fadeAlert">{{successMessage}}</ngb-alert>
      </div>

      <div class="col-12"  *ngIf="itemAdded">
        <ngb-alert type="success"  [dismissible]="false" class="text-center fadeAlert">{{successMessage}}</ngb-alert>
      </div>
      -->
    <app-loader [loading]="loading"></app-loader>

    <div class="col-12" *ngIf="!fetchError && !loading">
      <div class="card">
        <div class="card-body" *ngIf="displayTable">
          <div class="table-responsive"  *ngFor="let r of roles">
            <h3>{{r.name}}</h3>
            <table 
              class="table align-middle table-nowrap table-hover">
              <thead class="table-light">
                <tr>

                  <th scope="col">Role Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Permissions</th>
                  <th scope="col">Edit</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let role of r.roles">

                  <td>{{role.name}}</td>
                  <td>{{role.description}}</td>
                  <td>
                    <div class="permissions">
                      <span  *ngFor="let permission of role?.permissions"> {{permission.name}} </span>
                    </div>
                  </td>
                  <td>
                    <button class="btn" (click)="EditRoleModal(editRoleModal, role.id, r.id, role)"><i class="far fa-edit"></i></button>
                  </td>
                  <td>
                    <button class="btn" (click)="DeleteRoleModal(deleteRoleModal, role.id)"><i class="fas fa-trash-alt"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr>
          </div>

        </div>
      </div>
    </div>

    <!-- <div class="col-12"  *ngIf="itemDeleted">
        <ngb-alert type="success"  [dismissible]="false" class="text-center fadeAlert">{{successMessage}}</ngb-alert>
      </div> -->
  </div>
</div>

<ng-template #addRoleModal role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Add Role</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">

    <div class="row">
      <div class="col-12">
        <form #f="ngForm">
          <div class="row">

            <div class="form-group col-12">
              <label for="">Role Name</label>
              <input type="text" class="form-control" name="roleName" ngModel required #roleName="ngModel">
              <span class="text-danger" *ngIf="!roleName.valid && roleName.touched">Please Enter A Valid Answer</span>
            </div>
            <div class="form-group col-12 ">
              <label for="">Description</label>
              <input type="text" class="form-control" name="roleDesc" ngModel required #roleDesc="ngModel">
              <span class="text-danger" *ngIf="!roleDesc.valid && roleDesc.touched">Please Enter A Valid Answer</span>
            </div>

            <div class="form-group col-12 ">
              <label for="">Category</label>
              <select type="text" class="form-control" name="roleCategory" ngModel required #roleCategory="ngModel">
                <option value="">Select Category</option>
                <option value="1">Admin Portals Roles</option>
                <option value="2">Staff Portals Roles</option>
                <option value="3">Client Portal Roles</option>
              </select>
              <span class="text-danger" *ngIf="!roleCategory.valid && roleCategory.touched">Please Enter A Valid
                Answer</span>
            </div>

            <div class="form-group col-12 ">
              <label for="">Permissions</label>
              <ng-select [multiple]="true" name="rolePermissions" ngModel required #rolePermissions="ngModel">
                <ng-option *ngFor="let p of permissions" [value]="p.id">{{p.name}}</ng-option>
              </ng-select>
              <span class="text-danger" *ngIf="!rolePermissions.valid && rolePermissions.touched">Please Enter A Valid
                Answer</span>
            </div>

            <div class="col-12 text-center">
              <button type="submit" [disabled]="!f.valid" class="btn submitBtn" (click)="addRole(f)"><i
                  class="fas fa-plus"></i> Add</button>

              <button class="btn cancelBtn" (click)="modal.dismiss('Cross click')">Cancel</button>

            </div>
          </div>

        </form>
      </div>
    </div>


  </div>

</ng-template>


<ng-template #editRoleModal role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Edit Role</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">

    <div class="row">
      <div class="col-12">
        <form #f="ngForm">
          <div class="row">

            <div class="form-group col-12">
              <label for="">Role Name</label>
              <input type="text" class="form-control" name="roleName" [(ngModel)]="editedRole.name" ngModel required #roleName="ngModel">
              <span class="text-danger" *ngIf="!roleName.valid && roleName.touched">Please Enter A Valid Answer</span>
            </div>
            <div class="form-group col-12 ">
              <label for="">Description</label>
              <input type="text" class="form-control" name="roleDesc" [(ngModel)]="editedRole.description" ngModel required #roleDesc="ngModel">
              <span class="text-danger" *ngIf="!roleDesc.valid && roleDesc.touched">Please Enter A Valid Answer</span>
            </div>

            <div class="form-group col-12 ">
              <label for="">Category</label>
              <select type="text" class="form-control" name="roleCategory" [(ngModel)]="editRoleCategoryId" ngModel required #roleCategory="ngModel">
                <option value="">Select Category</option>
                <option value="1">Admin Portals Roles</option>
                <option value="2">Staff Portals Roles</option>
                <option value="3">Client Portal Roles</option>
              </select>
              <span class="text-danger" *ngIf="!roleCategory.valid && roleCategory.touched">Please Enter A Valid
                Answer</span>
            </div>

            <div class="form-group col-12 ">
              <label for="">Permissions</label>
              <ng-select [multiple]="true" name="rolePermissions" ngModel [(ngModel)]="selectedPermissions" required #rolePermissions="ngModel">
                <ng-option *ngFor="let p of permissions" [value]="p.id">{{p.name}}</ng-option>
              </ng-select>
              <span class="text-danger" *ngIf="!rolePermissions.valid && rolePermissions.touched">Please Enter A Valid
                Answer</span>
            </div>

            <div class="col-12 text-center">
              <button type="submit" [disabled]="!f.valid" class="btn submitBtn" (click)="editRole(f)"><i
                  class="fas fa-plus"></i> Save</button>

              <button class="btn cancelBtn" (click)="modal.dismiss('Cross click')">Cancel</button>

            </div>
          </div>

        </form>
      </div>
    </div>


  </div>

</ng-template>

<!-- Delete Contact Modal -->
<ng-template #deleteRoleModal role="document" let-modal >
  <div class="modal-header">
    <h5 class="modal-title mt-0">Delete Role</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
          <div class="form-group text-center">
              <p class="m-0">Are you sure you want to delete this Role?</p>
          </div>

          <div class="form-group text-center">
              <button class="btn remove_Btn" (click)="deleteRole()">Delete</button>
          </div>
    
  </div>
   
</ng-template>