import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { Site } from '../models/site'; // Import your model here

@Injectable({
  providedIn: 'root'
})
export class AddSiteService {
  constructor(private http: HttpClient,  private data: DataService) {}

  getHeaders() {
    const token = localStorage.getItem('token');
    return token ? new HttpHeaders().set('Authorization', 'Bearer ' + token) : null;
  }
  
  addSite(data: any, formData: FormData): Observable<any> {
    const mergedData = {
      ...data,
      zones: data.zones.map((zone: any) => ({ id: zone.id, name: zone.name }))
    };

    const mergedFormData = new FormData();

    for (const key in mergedData) {
      if (mergedData.hasOwnProperty(key)) {
        mergedFormData.append(key, JSON.stringify(mergedData[key]));
      }
    }

    formData.forEach((value, key) => {
      mergedFormData.append(key, value);
    });

    return this.http.post<any>(`${this.data.getBaseUrl()}api/v1/sites`, mergedFormData, { headers: this.getHeaders() });
  }
}
