<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <ul class="nav nav-pills tabs_border" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button (click)="showSiteInfo()" class="nav-link active" id="pills-site-tab" data-bs-toggle="pill" data-bs-target="#pills-siteInfo" type="button" role="tab" aria-controls="pills-site-tab" aria-selected="true">Overview</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button (click)="showContracts()" class="nav-link" id="pills-contracts-tab" data-bs-toggle="pill" data-bs-target="#pills-contractsInfo" type="button" role="tab" aria-controls="pills-contracts-tab" aria-selected="false">Contracts</button>
                </li>

                <li class="nav-item" role="presentation">
                    <button (click)="showSchedulers()" class="nav-link" id="pills-schedule-tab" data-bs-toggle="pill" data-bs-target="#pills-scheduleInfo" type="button" role="tab" aria-controls="pills-schedule-tab" aria-selected="false">Schedule</button>
                </li>

                <li class="nav-item" role="presentation">
                    <button (click)="showCheckpoints()" class="nav-link" id="pills-checkpoint-tab" data-bs-toggle="pill" data-bs-target="#pills-checkpointInfo" type="button" role="tab" aria-controls="pills-checkpoint-tab" aria-selected="false">Checkpoint</button>
                </li>

                <li class="nav-item" role="presentation">
                    <button (click)="showTours()" class="nav-link" id="pills-tours-tab" data-bs-toggle="pill" data-bs-target="#pills-ToursInfo" type="button" role="tab" aria-controls="pills-tours-tab" aria-selected="false">Tour Routes</button>
                </li>

                <li class="nav-item" role="presentation">
                    <button (click)="showContacts()" class="nav-link" id="pills-contacts-tab" data-bs-toggle="pill" data-bs-target="#pills-contactsInfo" type="button" role="tab" aria-controls="pills-contacts-tab" aria-selected="false">Emergency Contacts</button>
                </li>

                <li class="nav-item" role="presentation">
                    <button (click)="showBanned()" class="nav-link" id="pills-ban-tab" data-bs-toggle="pill" data-bs-target="#pills-ban" type="button" role="tab" aria-controls="pills-ban-tab" aria-selected="false">Banned Employees</button>
                </li>

                <li class="nav-item" role="presentation">
                    <button (click)="showGeoFencing()" class="nav-link" id="pills-geo-tab" data-bs-toggle="pill" data-bs-target="#pills-geoInfo" type="button" role="tab" aria-controls="pills-geo-tab" aria-selected="false">Geo-Fencing</button>
                </li>

                <li class="nav-item" role="presentation">
                    <button (click)="showMobileDispatch()" class="nav-link" id="pills-geo-tab" data-bs-toggle="pill" data-bs-target="#pills-geoInfo" type="button" role="tab" aria-controls="pills-geo-tab" aria-selected="false">Mobile Dispatch</button>
                </li>
            </ul>

            <app-loader [loading]="loading"></app-loader> 
 
            <div *ngIf="showSiteDetails">
                <div *ngIf="viewData" class="row siteDetails">
                    <div class="col-12 col-sm-2">
                        <img class="companyImg" (error)="setDefaultImage()" src="{{imageUrl}}" width="100%" alt="">
                        <!-- <img class="companyImg" src="../../../assets/images/deafult_picture.png" width="100%" alt=""> -->
                    </div>
                    <div class="col-12 col-sm-10">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4">
                                <div class="single_site_details">
                                    <span>Type</span>
                                    <h5 [ngClass]="{
                                        'blueBg': retrievedSite.type?.id == '1',
                                        'yellowBg': retrievedSite.type?.id == '2',
                                        'greenBg': retrievedSite.type?.id == '3'
                                      }"
                                      >{{retrievedSite.type?.name}}</h5>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4">
                                <div class="single_site_details">
                                    <span>UID</span>
                                    <h5>#{{retrievedSite.internalId}}</h5>
                                </div>
                            </div>
                            
                            <div class="col-12 col-sm-6 col-md-4">
                                <div class="single_site_details">
                                    <span>Company Name</span>
                                    <h5>{{retrievedSite.company.name}}</h5>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4">
                                <div class="single_site_details">
                                    <span>Main Contact</span>
                                    <h5>{{retrievedSite.contact.firstName}} {{retrievedSite.contact.lastName}}</h5>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4">
                                <div class="single_site_details">
                                    <span>Job Title</span>
                                    <h5>{{retrievedSite.contact.jobTitle}}</h5>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4">
                                <div class="single_site_details">
                                    <span>Mobile</span>
                                    <h5>{{retrievedSite.contact.mobile}}</h5>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4">
                                <div class="single_site_details">
                                    <span>Email</span>
                                    <h5>{{retrievedSite.contact.email}}</h5>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4">
                                <div class="single_site_details">
                                    <span>Time Zone</span>
                                    <h5>{{retrievedSite.company.timezone}}</h5>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4">
                                <div class="single_site_details">
                                    <span>Language</span>
                                    <h5>{{retrievedSite.company.language}}</h5>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="single_site_details">
                                    <span>Zip Code</span>
                                    
                                    <div *ngIf="retrievedSite.zipcode === null">
                                        <h5>No Zip Code Added</h5> 
                                    </div>
                                    <div  *ngIf="retrievedSite.zipcode !== null">
                                        <h5>{{retrievedSite.zipcode}}</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-8">
                                <div class="single_site_details">
                                    <span>Address</span>

                                    <div *ngIf="retrievedSite.area === null || retrievedSite.city === null || retrievedSite.country === null">
                                        <h5>No Address Added</h5> 
                                    </div>
                                    <div  *ngIf="retrievedSite.area !== null && retrievedSite.city !== null && retrievedSite.country !== null">
                                        <h5>
                                            {{ retrievedSite.address }}, {{ retrievedSite.area.name }}, {{ retrievedSite.city.name }}, {{ retrievedSite.country.name }}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="retrievedSite.sameBillingAddress" class="col-12 col-md-4">
                                <div class="single_site_details">
                                    <span>Billing Address</span>
                                    <h5>Same As Address</h5> 
                                </div>
                            </div>
                            <div *ngIf="!retrievedSite.sameBillingAddress" class="col-12 col-md-4">
                                <div class="single_site_details">
                                    <span>Billing Zip Code</span>
                                    
                                    <div *ngIf="retrievedSite.billingZipcode === null">
                                        <h5>No Zip Code Added</h5> 
                                    </div>
                                    <div  *ngIf="retrievedSite.billingZipcode !== null">
                                        <h5>{{retrievedSite.billingZipcode}}</h5>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!retrievedSite.sameBillingAddress" class="col-12 col-md-8">
                                <div class="single_site_details">
                                    <span>Billing Address</span>

                                    <div *ngIf="retrievedSite.area === null || retrievedSite.city === null || retrievedSite.country === null">
                                        <h5>No Address Added</h5> 
                                    </div>
                                    <div  *ngIf="retrievedSite.area !== null && retrievedSite.city !== null && retrievedSite.country !== null">
                                        <h5>
                                            {{ retrievedSite.billingAddress }}, {{ retrievedSite.billingArea.name }}, {{ retrievedSite.billingCity.name }}, {{ retrievedSite.billingCountry.name }}
                                        </h5>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="retrievedSite.notes" class="col-12 col-sm-6 col-md-4">
                                <div class="single_site_details">
                                    <span>Notes</span>
                                    <h5>{{retrievedSite.notes}}</h5>
                                </div>
                            </div>

                            <div class="col-12" *ngIf="retrievedSite.zones.length">
                                <div class="single_site_details">
                                    <span>Zones</span>

                                    <div class="siteZones">
                                        <span *ngFor="let zone of retrievedSite.zones">{{zone.name}}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="showContractDetails">
                <div  *ngIf="displayTable" class="row">
                    <div class="col-12">
                        <div class="mb-3 alignContent-spaceBetween">
                            <h2>Contracts</h2>
                            <button  (click)="onAddContract(retrievedSite.id)" class="btn submitBtn"><i class="fas fa-plus" ></i> Add Contract</button>
                        </div>

                        <div *ngIf="siteContracts.length === 0; else notEmptyContracts">
                            <p class="text-center emptyContracts">
                                <strong>No Contracts To Show</strong>
                            </p>
                        </div>

                        <ng-template #notEmptyContracts>
                            <div class="card">
                                <div class="card-body" >
                                  <div class="table-responsive">
                                    <table datatable [dtOptions]="dtOptions" id="datatable" class="datatable table align-middle table-nowrap table-hover">
                                      <thead class="table-light">
                                        <tr>
                                            <th scope="col">Recurrence</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Payment Method</th>
                                            <th scope="col">Start Date</th>
                                            <th scope="col">End Date</th>
                                            <th scope="col">View</th>
                                            <th scope="col">Edit</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let contract of siteContracts">
                                         
                                            <td>{{contract.billingRecurrence.name}}</td>
                                            <td>{{contract.billingType.name}}</td>
                                            <td>{{contract.paymentMethod.name}}</td>
                                            <td>{{contract.startDate | date:"MM-dd-yyyy" }}</td>
                                            <td>{{contract.endDate | date:"MM-dd-yyyy" }}</td>
                                            <td>
                                                <button class="btn" (click)="openContractModal(contractDetailsModal, contract)"><i class="fas fa-eye"></i></button>
                                            </td>
                                            <td>
                                                <button class="btn" (click)="openEditModal(contractEditModal, contract)"><i class="far fa-edit"></i></button>
                                            </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>

                                </div>
                              </div>
                        </ng-template>
                    </div>
                </div>
            </div>

            <div *ngIf="showScheduleContent">
                <div *ngIf="viewData"  class="row">
                    <div class="col-12">
                      <div class="mb-3 alignContent-spaceBetween">
                        <h2>{{retrievedSite.company.name}}</h2>
                        <div class="button_align">
                            <button (click)="onViewAllSiteSchedule(retrievedSite.id)" class="btn submitBtn ml-2"><i class="fas fa-eye"></i> Prepare Schedule</button>
                        </div>
                      </div>
                    </div>
                </div>
              
                <div *ngIf="displayScheduleContent" class="row">
                    <div class="col-12">
                      <div class="card">
                        <div class="card-body">
                          <div class="app-calendar">
                            <!-- calendar -->
                            <full-calendar [options]="calendarOptions"></full-calendar>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>

            <div *ngIf="showCheckpointContent">
                <div  *ngIf="displayCheckpointTable" class="row">
                    <div class="col-12">
                        <div class="mb-3 alignContent-spaceBetween">
                            <h2>Checkpoints</h2>
                            <div class="d-flex gap-2">
                                <button (click)="openImportCheckpointModal(importCheckpoint)" class="btn importBtn"><i class="fas fa-file-import"></i> Import Checkpoint</button>
                                <button (click)="openCheckpointModal(addCheckpoint)" class="btn submitBtn"><i class="fas fa-plus" ></i> Create Checkpoint</button>
                            </div>
                            <!-- <button  (click)="onAddContract(retrievedSite.id)" class="btn submitBtn"><i class="fas fa-plus" ></i> Create Checkpoint</button> -->
                        </div>

                        <!-- <div *ngIf="siteContracts.length === 0; else notEmptyContracts">
                            <p class="text-center emptyContracts">
                                <strong>No Checkpoints To Show</strong>
                            </p>
                        </div> -->

                        <!-- <ng-template #notEmptyContracts> -->
                            <div class="card">
                                <div class="card-body" >
                                  <div class="table-responsive">
                                    <table datatable [dtOptions]="dtOptions" id="datatable" class="datatable table align-middle table-nowrap table-hover">
                                      <thead class="table-light">
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Instructions</th>
                                            <th scope="col">Edit</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr  *ngFor="let checkpoint of checkpoints">
                                         
                                            <td>{{checkpoint.name}}</td>
                                            <td>{{checkpoint.instructions}}</td>
                                            <td>
                                                <button class="btn" (click)="openCheckpointEditModal(editCheckpoint, checkpoint)"><i class="far fa-edit"></i></button>
                                            </td>

                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>

                                </div>
                              </div>
                    </div>
                </div>
            </div>

            <div *ngIf="showTourContent">
                <div  *ngIf="displayToursTable" class="row">
                    <div class="col-12">
                        <div class="mb-3 alignContent-spaceBetween">
                            <h2>Tour Routes</h2>
                            <div class="d-flex gap-2">
                                <button (click)="openTourModal(addTour)" class="btn submitBtn"><i class="fas fa-plus" ></i> Create Tour Route</button>
                            </div>
                        </div>

                        <!-- <ng-template #notEmptyContracts> -->
                        <div class="card">
                            <div class="card-body" >
                                <div class="table-responsive">
                                <table datatable [dtOptions]="dtOptions" id="datatable" class="datatable table align-middle table-nowrap table-hover">
                                    <thead class="table-light">
                                    <tr>
                                        <th class="w-50" scope="col">Description</th>
                                        <th scope="col">Edit</th>
                                        <th scope="col">Manage Checkpoints</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr  *ngFor="let tour of tourRoutes">
                                        
                                        <td>{{tour.description}}</td>
                                        <td>
                                            <button class="btn"  (click)="openTourEditModal(editTour, tour)"><i class="far fa-edit"></i></button>
                                        </td>

                                        <td>
                                            <button class="btn"  (click)="openCheckpointTourModal(tourCheckpoint, tour)"><i class="fas fa-cog"></i></button>
                                        </td>

                                    </tr>
                                    </tbody>
                                </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="showContactsContent">
                <div  *ngIf="displayContactsTable" class="row">
                    <div class="col-12">
                        <div class="mb-3 alignContent-spaceBetween">
                            <h2>Emergency Contacts</h2>
                            <div class="d-flex gap-2">
                                <button (click)="openModal(addContact)" class="btn submitBtn"><i class="fas fa-plus" ></i> Create Contact</button>
                            </div>
                        </div>

                        <!-- <ng-template #notEmptyContracts> -->
                        <div class="card">
                            <div class="card-body" >
                                <div class="table-responsive">
                                <table datatable [dtOptions]="dtOptions" id="datatable" class="datatable table align-middle table-nowrap table-hover">
                                    <thead class="table-light">
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Mobile</th>
                                        <th scope="col">Job Title</th>
                                        <th scope="col">Is Emergency?</th>
                                        <th scope="col">Edit</th>
                                        <th scope="col">Delete</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr  *ngFor="let contact of siteContacts">
                                        
                                        <td>{{contact.firstName}} {{contact.lastName}}</td>
                                        <td>{{contact.email}}</td>
                                        <td>{{contact.mobile}}</td>
                                        <td>{{contact.jobTitle}}</td>
                                        <td class="yes_tick" *ngIf="contact.isEmergency == 1"><i class="fas fa-check-circle"></i></td>
                                        <td class="no_tick" *ngIf="contact.isEmergency == 0"><i class="fas fa-times-circle"></i></td>
                                        <td>
                                            <button class="btn" (click)="openEditContactModal(editContact,contact)">
                                                <i class="far fa-edit"></i>
                                            </button>
                                        </td>
                                        <td>
                                            <button class="btn" (click)="openDeleteModal(deleteContact,contact)">
                                                <i class="fas fa-trash-alt"></i>
                                            </button>
                                        </td>
                                        
                                    </tr>
                                    </tbody>
                                </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="showBannedContent">
                <div  *ngIf="displayBannedTable" class="row">
                    <div class="col-12">
                        <div class="mb-3 alignContent-spaceBetween">
                            <h2>Banned Employees</h2>
                            <div class="d-flex gap-2">
                                <button (click)="openModal(banEmployee)" class="btn submitBtn">Ban Employee</button>
                            </div>
                        </div>

                        <!-- <ng-template #notEmptyContracts> -->
                        <div class="card">
                            <div class="card-body" >
                                <div class="table-responsive">
                                <table datatable [dtOptions]="dtOptions" id="datatable" class="datatable table align-middle table-nowrap table-hover">
                                    <thead class="table-light">
                                    <tr>
                                        <th scope="col">Employee Name</th>
                                        <th scope="col">Effective Date</th>
                                        <th scope="col">Unban</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let emp of siteBannedEmployees">
                                        
                                        <td>{{emp.firstName}} {{emp.lastName}}</td>
                                        <td>{{emp.effectiveDate | date:"MM-dd-yyyy"}}</td>
                                        <td>
                                            <button class="btn importBtn" (click)="unBan(emp.id)">Unban</button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div *ngIf="showGeoFencingContent">
                <div class="row">
                    <div class="col-12 col-md-7">
                        <div #mapElement style="height: 500px; width: 100%"></div>
                    </div>
                    <div class="col-12 col-md-5">
                        <div *ngIf="fenceAddForm" class="card">
                            <div class="card-body">
                                <form #f="ngForm">
                                    <div class="form-group">
                                        <label for="">Fence Name</label>
                                        <input type="text" class="form-control" ngModel required #fenceName="ngModel" name="fenceName" />
                                    </div>
                            
                                    <div class="form-group checkboxContainer">
                                        <input type="checkbox" ngModel required #fenceRestricted="ngModel" name="fenceRestricted" id="isRestricted" /> <label for="isRestricted">Restricted</label>
                                    </div>

                                    <div class="form-group">
                                        <button class="btn submitBtn" (click)="addFence(f)">Add</button>
                                    </div>
                                </form>
                            </div>
                            
                        </div>
                        <div class="card">
                            <div class="card-body" >

                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                      <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Zones</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                      <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Trigger</button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                                        <div class="table-responsive">
                                            <table id="datatable" class="datatable table align-middle table-nowrap table-hover">
                                                <thead class="table-light">
                                                    <tr>
                                                    <th scope="col">Fence Name</th>
                                                    <th scope="col">Access Type</th>
                                                    <th scope="col">Remove</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container *ngFor="let fence of allowedFences">
                                                    <tr>
                                                        <td>{{ fence.name }}</td>
                                                        <td><span class="allowedSpan">Allowed</span> </td>
                                                        <td>
                                                        <button class="btn removeBtn" (click)="deleteFence(fence.id)"><i class="fas fa-times-circle"></i></button>
                                                        </td>
                                                    </tr>
                                                    </ng-container>
                                                
                                                    <ng-container *ngFor="let fence of restrictedFences">
                                                    <tr>
                                                        <td>{{ fence.name }}</td>
                                                        <td><span class="restrictedSpan">Restricted</span></td>
                                                        <td>
                                                        <button class="btn removeBtn" (click)="deleteFence(fence.id)"><i class="fas fa-times-circle"></i></button>
                                                        </td>
                                                    </tr>
                                                    </ng-container>
                                                </tbody>
                                                </table>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                                        <form #fTriggers="ngForm">
                                            <diV class="row p-3">
                                                <div class="form-group col-12">
                                                    <label for="">Min GPS Accuracy</label>
                                                    <input type="text" class="form-control" name="min_gps_acc" [(ngModel)]="triggerSettings.minGpsAccuracy" ngModel required #min_gps_acc="ngModel" >
                                                </div>
                                                <div class="col-12"><hr></div>
                                                
                                                
                                                <div class="form-group col-12">
                                                    <label for="">Outside of zone detection</label>
                                                    <select type="text" class="form-control" name="out_zone_detect" [(ngModel)]="triggerSettings.outOfZoneDetectionAlert" ngModel required #out_zone_detect="ngModel">
                                                        <option  value="0">Don't Alert</option>
                                                        <option  value="1">Send Notification</option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-12">
                                                    <label for="">Out of zone Reminder (minutes)</label>
                                                    <input type="text" class="form-control" name="out_zone_reminder" [(ngModel)]="triggerSettings.outOfZoneReminderFrequency" ngModel required #out_zone_reminder="ngModel" >
                                                </div>
                                                <div class="col-12"><hr></div>

                                                <div class="form-group col-12">
                                                    <label for="">Entering a restricted zone</label>
                                                    <select type="text" class="form-control" name="enter_restrict" [(ngModel)]="triggerSettings.restrictedZoneEnteringAlert" ngModel required #enter_restrict="ngModel" >
                                                        <option  value="0">Don't Alert</option>
                                                        <option  value="1">Send Notification</option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-12">
                                                    <label for="">Create System Exception</label>
                                                    <select type="text" class="form-control" name="create_sys_exception_restricted" [(ngModel)]="triggerSettings.restrictedZoneCreateSystemException" ngModel required #create_sys_exception_restricted="ngModel" >
                                                        <option  value="0">Do not create system exception tickets</option>
                                                        <option  value="1">Create system exception ticket</option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-12">
                                                    <label for="">Exiting a restricted zone</label>
                                                    <select type="text" class="form-control" name="exit_restrict" [(ngModel)]="triggerSettings.restrictedZoneExitingAlert" ngModel required #exit_restrict="ngModel">
                                                        <option  value="0">Don't Alert</option>
                                                        <option  value="1">Send Notification</option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-12">
                                                    <label for="">Reminder Frequency (in minutes)</label>
                                                    <input type="text" class="form-control" name="reminder_freq_restricted" [(ngModel)]="triggerSettings.restrictedZoneReminderFrequency" ngModel required #reminder_freq_restricted="ngModel">
                                                </div>
                                                <div class="col-12"><hr></div>

                                                <div class="form-group col-12">
                                                    <label for="">Entering an allowed zone</label>
                                                    <select type="text" class="form-control" name="enter_allowed" [(ngModel)]="triggerSettings.allowedZoneEnteringAlert" ngModel required #enter_allowed="ngModel" >
                                                        <option  value="0">Don't Alert</option>
                                                        <option  value="1">Send Notification</option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-12">
                                                    <label for="">Create System Exception</label>
                                                    <select type="text" class="form-control" name="create_sys_exception_allowed" [(ngModel)]="triggerSettings.allowedZoneCreateSystemException" ngModel required #create_sys_exception_allowed="ngModel">
                                                        <option  value="0">Do not create system exception tickets</option>
                                                        <option  value="1">Create system exception ticket</option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-12">
                                                    <label for="">Exiting an allowed zone</label>
                                                    <select type="text" class="form-control" name="exit_allowed" [(ngModel)]="triggerSettings.allowedZoneExitingAlert" ngModel required #exit_allowed="ngModel">
                                                        <option  value="0">Don't Alert</option>
                                                        <option  value="1">Send Notification</option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-12">
                                                    <label for="">Reminder Frequency (in minutes)</label>
                                                    <input type="text" class="form-control" name="reminder_freq_allowed" [(ngModel)]="triggerSettings.allowedZoneReminderFrquency" ngModel required #reminder_freq_allowed="ngModel">
                                                </div>
                                                <div class="col-12"><hr></div>

                                                <div class="col-12">
                                                    <button class="btn submitBtn" (click)="saveTriggers(fTriggers)">Save</button>
                                                </div>

                                            </diV>
                                        </form>
                                    </div>
                                </div>
                                
                                

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div *ngIf="showMobileDispatchContent">
                <div class="row">
                    <div class="col-12">
                        <div class="mb-3 alignContent-spaceBetween">
                            <h2>Mobile Dispatch</h2>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">

                                <div class="dispatch_form">
                                    <form #f="ngForm">
                                        <div class="mb-3 alignContent-spaceBetween">
                                            <h4>Schedule Template</h4>
                                            <div class="form-group">
                                                <button [disabled]="!f.valid || selectedDays.length === 0" (click)="addDispatch(f)" class="btn submitBtn" ><i class="fas fa-plus"></i> Add</button>
                                            </div>
                                        </div>
                                        <div class="row section_style">
                                        <div class="form-group col-12 col-sm-6 col-md-4 col-lg-2">
                                            <label for="">Price Tier</label>
                                            <select  class="form-control" name="priceTier" ngModel #priceTier="ngModel">
                                                <option value="">Price Tier</option>
                                            </select>
                                            <span class="text-danger" *ngIf="!priceTier.valid && priceTier.touched">Please Enter A Valid Answer</span>
                                        </div> 
                                            <div class="form-group col-12 col-sm-6 col-md-4 col-lg-2">
                                                <label for="">Patrol Type</label>
                                                <select  class="form-control" name="patrolType" ngModel required #patrolType="ngModel">
                                                    <option value="">Select Type</option>
                                                    <option *ngFor="let type of patrolTypes" [value]="type.id">{{type.name}}</option>
                                                </select>
                                                <span class="text-danger" *ngIf="!patrolType.valid && patrolType.touched">Please Enter A Valid Answer</span>
                                                <!-- <p class="text-danger m-0" *ngIf="shiftBreakError"><strong>{{shiftBreakError}}</strong></p> -->
                                            </div>
                                            <div class="form-group col-12 col-sm-6 col-md-4 col-lg-2">
                                                <label for="">Time From</label>
                                                <input type="time" class="form-control" name="dispatchTimeFrom" ngModel required #dispatchTimeFrom="ngModel" >
                                                <span class="text-danger" *ngIf="!dispatchTimeFrom.valid && dispatchTimeFrom.touched">Please Enter A Valid Answer</span>
                                                <!-- <p class="text-danger m-0" *ngIf="timeFromError"><strong>{{timeFromError}}</strong></p> -->
                                            </div>
                                        <div class="form-group col-12 col-sm-6 col-md-4 col-lg-2">
                                            <label for="">Time To</label>
                                            <input type="time" class="form-control" name="dispatchTimeTo" ngModel required #dispatchTimeTo="ngModel" >
                                            <span class="text-danger" *ngIf="!dispatchTimeTo.valid && dispatchTimeTo.touched">Please Enter A Valid Answer</span>
                                            <!-- <p class="text-danger m-0" *ngIf="timeToError"><strong>{{timeToError}}</strong></p> -->
                                        </div>
                                        <div class="form-group col-12 col-sm-6 col-md-4 col-lg-2">
                                            <label for="">Date From</label>
                                            <input type="date" class="form-control" name="dispatchDateFrom" ngModel required #dispatchDateFrom="ngModel" >
                                            <span class="text-danger" *ngIf="!dispatchDateFrom.valid && dispatchDateFrom.touched">Please Enter A Valid Answer</span>
                                            <!-- <p class="text-danger m-0" *ngIf="timeFromError"><strong>{{timeFromError}}</strong></p> -->
                                        </div>
                                        
                                    <div class="form-group col-12 col-sm-6 col-md-4 col-lg-2">
                                        <label for="">Date To</label>
                                        <input type="date" class="form-control" name="dispatchDateTo" ngModel required #dispatchDateTo="ngModel" >
                                        <span class="text-danger" *ngIf="!dispatchDateTo.valid && dispatchDateTo.touched">Please Enter A Valid Answer</span>
                                        <!-- <p class="text-danger m-0" *ngIf="timeToError"><strong>{{timeToError}}</strong></p> -->
                                    </div>

                                    <div class="form-group col-12 col-sm-6 col-md-4 col-lg-2">
                                        <label for="">Route Positions (Optional)</label>
                                        <select  class="form-control" name="routePositions" ngModel #routePositions="ngModel">
                                            <option value="">Select Position</option>
                                            <option *ngFor="let route of routePositionsss" [value]="route.positionId">{{route.name}}</option>
                                        </select>
                                        <!-- <p class="text-danger m-0" *ngIf="shiftBreakError"><strong>{{shiftBreakError}}</strong></p> -->
                                    </div>

                                        <div class="form-group col-12 col-sm-6 col-md-4 col-lg-2">
                                            <label for="">Stay In Minutes</label>
                                            <input type="number" class="form-control" name="stayMins" ngModel required #stayMins="ngModel" >
                            
                                            <span class="text-danger" *ngIf="!stayMins.valid && stayMins.touched">Please Enter A Valid Answer</span>
                                            <!-- <p class="text-danger m-0" *ngIf="dailyShiftsError"><strong>{{dailyShiftsError}}</strong></p> -->
                                        </div>
                                        <div class="form-group col-12 col-sm-6 col-md-4 col-lg-2">
                                            <label for="">No. of visits</label>
                                            <input type="number" class="form-control" name="visitsNumber" ngModel required #visitsNumber="ngModel" >
                            
                                            <span class="text-danger" *ngIf="!visitsNumber.valid && visitsNumber.touched">Please Enter A Valid Answer</span>
                                            <!-- <p class="text-danger m-0" *ngIf="dailyShiftsError"><strong>{{dailyShiftsError}}</strong></p> -->
                                        </div>

                                        <div class="form-group col-12 col-sm-6 col-md-4 col-lg-3">
                                            <div class="checkboxContainer">
                                                <input type="checkbox" id="autoAssign" name="autoAssign" checked ngModel required #autoAssign="ngModel"> 
                                                <label for="autoAssign">Auto-Assign runsheet when possible</label>
                                            </div>
                                        </div>
                                        
                            
                                        <div class="form-group col-12">
                                            <label for="">Days</label>
                                            <div class="shiftDaysContainer">
                                                <div class="checkboxContainer">
                                                    <input type="checkbox" id="allDays" name="allDays" (change)="toggleAllDays($event)"> 
                                                    <label for="allDays">All Days</label>
                                                </div>
                                                <div class="checkboxContainer" *ngFor="let day of days">
                                                    <input type="checkbox" id="{{day.id}}" name="{{day.id}}" (change)="toggleDay(day)"> 
                                                    <label for="{{day.id}}"> {{day.name}}</label>
                                                </div>
                                            </div>
                                            
                                            <!-- <span class="text-danger" *ngIf="!shiftDays.valid && shiftDays.touched">Please Enter A Valid Answer</span> -->
                                            <p class="text-danger m-0" *ngIf="daysError"><strong>{{daysError}}</strong></p>
                                        </div>
                                        </div>
                                    </form>
                                </div>

                                <hr>

                                <div *ngIf="displayDispatchContent" class="dispatch_schedule">

                                    <div class="schedule_actions">
                                        <button class="btn assignBtn" (click)="markAll()">Mark All</button>
                                        <button *ngIf="showAssignBtn" class="btn approveBtn" (click)="openAssignPosition(assignPosition)">Assign To Position</button>
                                        <button *ngIf="showAssignBtn" class="btn deleteBtn" (click)="deleteSlot()">Delete</button>
                                    </div>
                                    
                                    <div class="app_template table-responsive">
                                        
                                        <table class="table table-bordered table-striped" >
                                            <thead>
                                              <tr>
                                                <th scope="col">Price Tier</th>
                                                <th scope="col" *ngFor="let day of days">{{ day.name }}</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                                
                                                    <tr>
                                                        <td>
                                                            <span >Not Billable</span>
                                                        </td>
                                                        
                                                        <td *ngFor="let day of days">
                                                            <ng-container *ngFor="let temp of scheduleDispatches">
                                                                <ng-container *ngIf="temp.day === day.id">
                                                                    <div class="template_slot" [ngClass]="{ 'activeDay': temp.isActive }" (click)="slotSelect(temp.id); toggleActive(temp)">
                                                                        <p class="template_time"><i class="fas fa-clock"></i> {{ temp.timeFrom }} / {{ temp.timeTo }}</p>
                                                                        <p class="template_employee slot_date"><span><i class="fas fa-calendar-alt"></i></span> <span>{{ temp.startDate }} <br> {{ temp.endDate }}</span> </p>
                                                                        <p class="template_employee"><i class="fas fa-stopwatch"></i> {{ temp.stayInMinutes }} - <i class="fas fa-car-side"></i> {{ temp.visitCount }}</p>
                                                                        <p *ngIf="temp.routePosition" class="template_employee"><i class="fas fa-map-marker-alt"></i> {{ temp.routePosition.name }}</p>
                                                                        <p class="template_employee"><i class="fas fa-check-circle"></i> {{ temp.patrolType.name }}</p>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                            
                                                        </td>
                                                    </tr>
                                            </tbody>
                                        </table>

                                        
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        
    </div>
</div>


<!-- Contract Details Modal -->
<ng-template #contractDetailsModal role="document" let-modal >
    <div class="modal-header">
      <h5 class="modal-title mt-0">Contract Details</h5>
      <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div class="row row-cols-lg-3">

        <div class="singleDetail">
            <span>Label</span>
            <p>{{retrievedSite.company.name}}</p>
        </div>
        <div class="singleDetail">
            <span>Code / ID</span>
            <p>{{retrievedSite.company.internalId}}</p>
        </div>

        <div class="singleDetail">
            <span>Service Start Date</span>
            <p>{{selectedContractDetails.startDate | date:"MM-dd-yyyy" }}</p>
        </div>

        <div *ngIf="selectedContractDetails.endDate" class="singleDetail">
            <span>Service End Date</span>
            <p>{{selectedContractDetails.endDate | date:"MM-dd-yyyy" }}</p>
        </div>

        <div class="singleDetail">
            <span>Payment Method</span>
            <p>{{selectedContractDetails.paymentMethod.name}}</p>
        </div>

        <div class="singleDetail">
            <span>Payment Term</span>
            <p>{{selectedContractDetails.paymentTerm.name}}</p>
        </div>

        <div class="singleDetail">
            <span>Billing Recurrence</span>
            <p>{{selectedContractDetails.billingRecurrence.name}}</p>
        </div>

        <div class="singleDetail">
            <span>Billing Type</span>
            <p>{{selectedContractDetails.billingType.name}}</p>
        </div>

        <div *ngIf="selectedContractDetails.billingItem" class="singleDetail">
            <span>Billing Item</span>
            <p>{{selectedContractDetails.billingItem.name}}</p>
        </div>

        <div class="singleDetail">
            <span>Cycle reference date</span>
            <p>{{selectedContractDetails.billingCycleRefDate | date:"MM-dd-yyyy" }}</p>
        </div>

        <div *ngIf="selectedContractDetails.externalContractId" class="singleDetail">
            <span>External Contract Id</span>
            <p>{{selectedContractDetails.externalContractId}}</p>
        </div>

        <div *ngIf="selectedContractDetails.poNumber" class="singleDetail">
            <span>PO Number</span>
            <p>{{selectedContractDetails.poNumber}}</p>
        </div>

        <div *ngIf="selectedContractDetails.estimatedPerPeriodRevenue" class="singleDetail">
            <span>Estimated Per Period Revenue</span>
            <p>{{selectedContractDetails.estimatedPerPeriodRevenue}}</p>
        </div>

        <div *ngIf="selectedContractDetails.taxClass" class="singleDetail">
            <span>Tax Class</span>
            <p>{{selectedContractDetails.taxClass.name}}</p>
        </div>
        <div *ngIf="selectedContractDetails.billRate"  class="singleDetail">
            <span>Bill Rate</span>
            <p>{{selectedContractDetails.billRate}}</p>
        </div>
        <div *ngIf="selectedContractDetails.holidayBilling"  class="singleDetail">
            <span>Holiday Billing</span>
            <p>{{selectedContractDetails.holidayBilling.name}}</p>
        </div>
        <div *ngIf="selectedContractDetails.holidayGroup"  class="singleDetail">
            <span>Holiday Group</span>
            <p>{{selectedContractDetails.holidayGroup.name}}</p>
        </div>
        <div *ngIf="selectedContractDetails.holidayMultiplier" class="singleDetail">
            <span>Holiday Multipiler</span>
            <p>{{selectedContractDetails.holidayMultiplier}}</p>
        </div>

        <div *ngIf="selectedContractDetails.serviceTypes" class="col-12 singleDetail">
            <span>Service Types</span>
            <div class="siteZones">
                <span class="mb-0" *ngFor="let serviceType of selectedContractDetails.serviceTypes">{{serviceType.name}}</span>
            </div>
        </div>
        
      </div>
      
      <div class="text-center">
        <button class="btn cancelBtn" (click)="modal.dismiss('Cross click')">Cancel</button>
      </div>
    </div>
    
</ng-template>

<!-- Contract Edit Modal -->
<ng-template #contractEditModal role="document" let-modal >
    <div class="modal-header">
      <h5 class="modal-title mt-0">Contract Details</h5>
      <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form #f="ngForm">
            <div class="row section_style">
              <div class="col-12">
                  <h4>Contract General Information</h4>
              </div>

              <div class="form-group col-12 col-md-6">
                  <label for="">Service Start Date</label>
                  <input type="date" class="form-control" name="contractServStartDate" ngModel [(ngModel)]="selectedEditContract.startDate" required #contractServStartDate="ngModel" >
                  <span class="text-danger" *ngIf="!contractServStartDate.valid && contractServStartDate.touched">Please Enter A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="startDateErrorMessage"><strong>{{startDateErrorMessage}}</strong></p>
              </div>
              <div class="form-group col-12 col-md-6">
                  <label for="">Service End Date (optional)</label>
                  <input type="date" class="form-control" name="contractServEndDate" ngModel [(ngModel)]="selectedEditContract.endDate" #contractServEndDate="ngModel" >
                  <p class="text-danger m-0" *ngIf="endDateErrorMessage"><strong>{{endDateErrorMessage}}</strong></p>
              </div>
            </div>
  
            
  
            <div class="row section_style">
              <div class="col-12">
                  <h4>Service Models</h4>
              </div>
              <div class="form-group col-12">
                  <label for="">Service Types</label>
                  <div class="zonesSelector_container">
                      <div class="checkboxContainer" *ngFor="let type of serviceTypes">
                          <input  [checked]="isTypeSelected(type.id)" type="checkbox" id="{{type.id}}" name="{{type.id}}"  (change)="toggleServiceType(type)"> 
                          <label for="{{type.id}}"> {{type.name}}</label>
                      </div>
                  </div>
                  <p class="text-danger m-0" *ngIf="serviceTypesErrorMessage"><strong>{{serviceTypesErrorMessage}}</strong></p>
              </div>
              <div class="col-12">
                  <hr>
              </div>
              <div class="form-group col-12 col-md-6">
                  <label for="">Payment Method</label>
                  <select class="form-control" name="contractPaymentMethod" ngModel [(ngModel)]="selectedEditContract.paymentMethod.id" required #contractPaymentMethod="ngModel">
                      <option  value="">Select Method</option>
                      <option *ngFor="let method of paymentMethods" [value]="method.id">{{method.name}}</option>
                  </select>
                  <span class="text-danger" *ngIf="!contractPaymentMethod.valid && contractPaymentMethod.touched">Please Choose A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="paymentMethodIdErrorMessage"><strong>{{paymentMethodIdErrorMessage}}</strong></p>
              </div>
  
              <div class="form-group col-12 col-md-6">
                  <label for="">Payment Terms</label>
                  <select class="form-control" name="contractPaymentTerms" ngModel [(ngModel)]="selectedEditContract.paymentTerm.id" required #contractPaymentTerms="ngModel">
                      <option  value="">Select Method</option>
                      <option *ngFor="let term of paymentTerms" [value]="term.id">{{term.name}}</option>
                  </select>
                  <span class="text-danger" *ngIf="!contractPaymentTerms.valid && contractPaymentTerms.touched">Please Choose A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="paymentTermIdErrorMessage"><strong>{{paymentTermIdErrorMessage}}</strong></p>
              </div>
  
              <div class="form-group col-12 col-md-6">
                  <label for="">External Contract ID (optional)</label>
                  <input type="text" class="form-control" name="contractOptionalId" ngModel [(ngModel)]="selectedEditContract.externalContractId"  #contractOptionalId="ngModel" >
                  <p class="text-danger m-0" *ngIf="externalContractIdErrorMessage"><strong>{{externalContractIdErrorMessage}}</strong></p>
              </div>
  
              <div class="form-group col-12 col-md-6">
                  <label for="">PO Number (optional)</label>
                  <input type="text" class="form-control" name="contractPONumber" ngModel [(ngModel)]="selectedEditContract.poNumber" #contractPONumber="ngModel" >
                  <p class="text-danger m-0" *ngIf="poNumberErrorMessage"><strong>{{poNumberErrorMessage}}</strong></p>
              </div>
  
              <div class="form-group col-12 col-md-6">
                  <label for="">Estimated Per Period Revenue (optional)</label>
                  <input type="text" class="form-control" name="estPerRevenue" ngModel [(ngModel)]="selectedEditContract.estimatedPerPeriodRevenue"  #estPerRevenue="ngModel" >
                  <p class="text-danger m-0" *ngIf="estimatedPerPeriodRevenueErrorMessage"><strong>{{estimatedPerPeriodRevenueErrorMessage}}</strong></p>
              </div>
  
            </div>
  
  
            <div class="row section_style">
              <div class="col-12">
                  <h4>Invoice Recurrence</h4>
              </div>
              <div class="form-group col-12 col-md-4">
                  <label for="">Billing Recurrence</label>
                  <select class="form-control" name="contractBillingRecurrence" ngModel [(ngModel)]="selectedEditContract.billingRecurrence.id" required #contractBillingRecurrence="ngModel">
                      <option  value="">Select Method</option>
                      <option *ngFor="let recurrence of billingRecurrences" [value]="recurrence.id">{{recurrence.name}}</option>
                  </select>
                  <span class="text-danger" *ngIf="!contractBillingRecurrence.valid && contractBillingRecurrence.touched">Please Choose A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="billingRecurrenceIdErrorMessage"><strong>{{billingRecurrenceIdErrorMessage}}</strong></p>
              </div>
  
              <div class="form-group col-12 col-md-4">
                  <label for="">Billing Type</label>
                  <select class="form-control" name="contractBillingType" ngModel [(ngModel)]="selectedEditContract.billingType.id" required #contractBillingType="ngModel">
                      <option  value="">Select Method</option>
                      <option *ngFor="let bType of billingTypes" [value]="bType.id">{{bType.name}}</option>
                  </select>
                  <span class="text-danger" *ngIf="!contractBillingType.valid && contractBillingType.touched">Please Choose A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="billingTypeIdErrorMessage"><strong>{{billingTypeIdErrorMessage}}</strong></p>
              </div>
  
              <div class="form-group col-12 col-md-4">
                  <label for="">Billing Item</label>
                  <select class="form-control" name="contractBillingItem" ngModel [(ngModel)]="selectedEditContract.billingItem.id" required #contractBillingItem="ngModel">
                      <option  value="">Select Item</option>
                      <option *ngFor="let bItem of billingItems" [value]="bItem.id">{{bItem.name}}</option>
                  </select>
                  <span class="text-danger" *ngIf="!contractBillingItem.valid && contractBillingItem.touched">Please Choose A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="billingItemErrorMessage"><strong>{{billingItemErrorMessage}}</strong></p>
              </div>
  
              <div class="form-group col-12 col-md-4">
                  <label for="">Cycle Reference Date</label>
                  <input type="date" class="form-control" name="cycleRefDate" ngModel [(ngModel)]="selectedEditContract.billingCycleRefDate" required #cycleRefDate="ngModel" >
                  <span class="text-danger" *ngIf="!cycleRefDate.valid && cycleRefDate.touched">Please Choose A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="billingCycleRefDateErrorMessage"><strong>{{billingCycleRefDateErrorMessage}}</strong></p>
              </div>
  
              <div class="form-group col-12 col-md-4">
                  <label for="">Tax Class</label>
                  <select class="form-control" name="taxClass" ngModel [(ngModel)]="selectedEditContract.taxClass.id" required #taxClass="ngModel">
                      <option  value="">Select Item</option>
                      <option *ngFor="let item of taxClasses" [value]="item.id">{{item.name}}</option>
                  </select>
                  <span class="text-danger" *ngIf="!taxClass.valid && taxClass.touched">Please Choose A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="taxClassErrorMessage"><strong>{{taxClassErrorMessage}}</strong></p>
              </div>
  
              <div class="form-group col-12 col-md-4">
                  <label for="">Bill Rate</label>
                  <input type="number" class="form-control" name="billRate" ngModel [(ngModel)]="selectedEditContract.billRate" required #billRate="ngModel" >
                  <span class="text-danger" *ngIf="!billRate.valid && billRate.touched">Please Choose A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="billRateErrorMessage"><strong>{{billRateErrorMessage}}</strong></p>
              </div>
  
              <div class="form-group col-12 col-md-4">
                  <label for="">Holiday Billing</label>
                  <select class="form-control" name="holidayBilling" ngModel [(ngModel)]="selectedEditContract.holidayBilling.id" required #holidayBilling="ngModel">
                      <option  value="">Select Item</option>
                      <option *ngFor="let item of holidayBillings" [value]="item.id">{{item.name}}</option>
                  </select>
                  <span class="text-danger" *ngIf="!holidayBilling.valid && holidayBilling.touched">Please Choose A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="holidayBillingsErrorMessage"><strong>{{holidayBillingsErrorMessage}}</strong></p>
              </div>
              <div class="form-group col-12 col-md-4">
                  <label for="">Holiday Group</label>
                  <select class="form-control" name="holidayGroup" ngModel [(ngModel)]="selectedEditContract.holidayGroup.id" required #holidayGroup="ngModel">
                      <option  value="">Select Item</option>
                      <option *ngFor="let item of holidayGroups" [value]="item.id">{{item.name}}</option>
                  </select>
                  <span class="text-danger" *ngIf="!holidayGroup.valid && holidayGroup.touched">Please Choose A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="holidayGroupsErrorMessage"><strong>{{holidayGroupsErrorMessage}}</strong></p>
              </div>
              <div class="form-group col-12 col-md-4">
                  <label for="">Holiday Multipiler</label>
                  <input type="number" class="form-control" name="holidayMultiplier" ngModel [(ngModel)]="selectedEditContract.holidayMultiplier" required #holidayMultiplier="ngModel" >
                  <span class="text-danger" *ngIf="!holidayMultiplier.valid && holidayMultiplier.touched">Please Choose A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="holidayMultipilerErrorMessage"><strong>{{holidayMultipilerErrorMessage}}</strong></p>
              </div>
  
            </div>
            
  
            
  
  
  
            <hr>
    
            <div class="row">
                <div class="form-group col-12 text-center">
                    <button [disabled]="!f.valid"  (click)="updateContract(f)" class="btn submitBtn" ><i class="fas fa-plus"></i> Add Contract</button>
                </div>
            </div>
            
          </form>
      
      
    </div>
    
</ng-template>

<!-- Create Checkpoint Modal -->
<ng-template #addCheckpoint role="document" let-modal >
    <div class="modal-header">
      <h5 class="modal-title mt-0">Create Checkpoint</h5>
      <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form #f="ngForm">
            <div class="row section_style">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="">Checkpoint Name</label>
                        <input type="text" class="form-control" name="checkpointName" ngModel required #checkpointName="ngModel" >
                        <span class="text-danger" *ngIf="!checkpointName.valid && checkpointName.touched">Please Enter A Valid Answer</span>
                    </div>
                    <div class="form-group">
                        <label for="">Special Instructions</label>
                        <textarea class="form-control" name="specialInstructions" ngModel  #specialInstructions="ngModel" rows="3"></textarea>
                    </div>
                    <div class="form-group">
                        <label for="">Can be scanned by</label>
                        <select class="form-control" name="scannedBy" [(ngModel)]="scanedByInit" ngModel  #scannedBy="ngModel">
                            <option value="">All</option>
                        </select>
                        <span class="text-danger" *ngIf="!scannedBy.valid && scannedBy.touched">Please Choose A Valid Answer</span>
                    </div>
                    <div class="form-group">
                        <label for="">Checkpoint Monitoring Options</label>
                        <select class="form-control" name="monitorOptions" [(ngModel)]="monitorOption" ngModel  #monitorOptions="ngModel">
                            
                            <option *ngFor="let monitor of checkpointLookups['monitoring-options']"  [value]="monitor.id">{{monitor.name}}</option>
                        </select>
                        <!-- <span class="text-danger" *ngIf="!monitorOptions.valid && monitorOptions.touched">Please Choose A Valid Answer</span> -->
                    </div>

                    <div *ngIf="monitorOption == '4'" class="form-group">
                        <label for="">Scan Request Intervals</label>
                        <div class="d-flex gap-2">
                            <input class="form-control" type="text" placeholder="Every" name="intervalNumber" ngModel required #intervalNumber="ngModel">
                            <select class="form-control" name="intervalUnit" [(ngModel)]="selectedInterval" ngModel required #intervalUnit="ngModel">
                                
                                <option *ngFor="let interval of checkpointLookups['interval-units']" [value]="interval.id">{{interval.name}}</option>
                            </select>
                        </div>
                        <span class="text-danger" *ngIf="!intervalNumber.valid && intervalNumber.touched || !intervalUnit.valid && intervalUnit.touched">Please Choose A Valid Answer</span>
                    </div>

                    
                    <!-- Allow manual scanning -->
                    <div class="form-group">
                        <label for="">Allow manual scanning (V.5.2)</label>
                        <select class="form-control" name="manualScan" [(ngModel)]="manualScanInit" ngModel  #manualScan="ngModel">
                            <option value="">Select Answer</option>
                            <option *ngFor="let manualScan of checkpointLookups['manual-scan']" [value]="manualScan.id">{{manualScan.name}}</option>
                        </select>
                    </div>
                </div>

                <div class="col-12 col-md-6">

                    <!-- Extra Scan Options -->
                    <div class="form-group">
                        <label for="">Extra Scan Options</label>
                        <select class="form-control" name="extra_scan_options" ngModel [(ngModel)]="extraOption" required #extra_scan_options="ngModel"  (change)="changeQuestionType()">
                            <option *ngFor="let extraOption of checkpointLookups['extra-scan-options']" [value]="extraOption.id">{{extraOption.name}}</option>
                        </select>
                        <span class="text-danger" *ngIf="!extra_scan_options.valid && extra_scan_options.touched">Please Choose A Valid Answer</span>
                    </div>

                    <!-- Extra Scan Options: display message -->
                    <div *ngIf="extraOption === '12'" class="form-group">
                        <label for="">Display a message</label>
                        <textarea class="form-control" name="extra_displayMessage" required ngModel #extra_displayMessage="ngModel" rows="2"></textarea>

                        <span class="text-danger" *ngIf="!extra_displayMessage.valid && extra_displayMessage.touched">Please Choose A Valid Answer</span>
                    </div>

                    <!-- Extra Scan Options: Open Report Form -->
                    <div *ngIf="extraOption === '13'" class="form-group">
                        <label for="">Report Form to open</label>
                        <select class="form-control" name="extra_openReportForm" ngModel [(ngModel)]="extra_reportOpenForm" required #extra_openReportForm="ngModel">
                            <option value="">Choose a report type</option>
                            <option *ngFor="let extraOption of checkpointLookups['reeport-form-to-open']" [value]="extraOption.id">{{extraOption.name}}</option>
                        </select>
                        <span class="text-danger" *ngIf="!extra_openReportForm.valid && extra_openReportForm.touched">Please Choose A Valid Answer</span>
                    </div>

                    <!-- Extra Scan Options: Exception Verification -->
                    <div *ngIf="extraOption === '14' || extraOption === '15' || extraOption === '16'">
                        <div class="form-group">
                            <label for="">Question</label>
                            <input type="text" class="form-control" name="extra_exceptionQuestion" ngModel required #extra_exceptionQuestion="ngModel" placeholder="Ex: What is the server room temperature"> 
                            <span class="text-danger" *ngIf="!extra_exceptionQuestion.valid && extra_exceptionQuestion.touched">Please Choose A Valid Answer</span>
                        </div>

                        <div *ngIf="extraOption === '14'" class="form-group">
                            <div class="d-flex gap-2">
                                <div class="w-100">
                                    <label for="">Allowed From</label>
                                    <input class="form-control" type="text" placeholder="From" name="extra_exceptionFrom" ngModel required #extra_exceptionFrom="ngModel">
                                </div>
                                <div class="w-100">
                                    <label for="">Allowed To</label>
                                    <input class="form-control" type="text" placeholder="To" name="extra_exceptionTo" ngModel required #extra_exceptionTo="ngModel">
                                </div>
                            </div>
                            <span class="text-danger" *ngIf="!extra_exceptionFrom.valid && extra_exceptionFrom.touched || !extra_exceptionTo.valid && extra_exceptionTo.touched">Please Choose A Valid Answer</span>
                            <div class="d-flex gap-2 align-items-center mt-2">
                                <input type="checkbox" id="extra_exceptionBarcode" name="extra_exceptionBarcode" ngModel #extra_exceptionBarcode="ngModel">
                                <label class="mb-0" for="extra_exceptionBarcode">Barcode</label>
                            </div>
                        </div>
                        
                    </div>

                    <div *ngIf="extraOption === '14' || extraOption === '15' || extraOption === '16' || extraOption === '17'">
                        <div class="form-group">
                            <label for="">If any answered, file under</label>
                            <select class="form-control" name="extra_fileUnder" ngModel [(ngModel)]="extra_fileUnderInit" #extra_fileUnder="ngModel">
                                <option value="">[ Do not link to any incident category ]</option>
                                <option *ngFor="let extraOption of checkpointLookups['file-under']" [value]="extraOption.id">{{extraOption.name}}</option>
                            </select>
                            <!-- <span class="text-danger" *ngIf="!extra_fileUnder.valid && extra_fileUnder.touched">Please Choose A Valid Answer</span> -->
                        </div>
                    </div>

                    <!-- Extra Scan Options: Exception Multiple Questions -->
                    <div *ngIf="extraOption == '17'">
                        <div class="questions_bg">
                            <div class="form-group">
                                <label for="">Type</label>
                                <select class="form-control" name="extra_multipleQuestionType"  [(ngModel)]="extra_multiQuestionTypeInit" #extra_multipleQuestionType="ngModel">
                                    <option *ngFor="let extraOption of checkpointLookups['question-types']" [value]="extraOption.id">{{extraOption.name}}</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label for="">Question</label>
                                <input type="text" class="form-control" name="extra_exceptionMultiQuestion" ngModel [(ngModel)]="extra_multiQuestionTextInit" #extra_exceptionMultiQuestion="ngModel" placeholder="Ex: What is the server room temperature"> 
                                <span class="text-danger" *ngIf="!extra_exceptionMultiQuestion.valid && extra_exceptionMultiQuestion.touched">Please Choose A Valid Answer</span>
                            </div>

                            <div class="form-group">
                                <div class="d-flex gap-2 align-items-center mt-2">
                                    <input type="checkbox" id="extra_questionRequired" name="extra_questionRequired" [(ngModel)]="extra_multiQuestionRequiredInit" ngModel #extra_questionRequired="ngModel">
                                    <label class="mb-0" for="extra_questionRequired">Required</label>
                                </div>
                            </div>

                            <div *ngIf="extra_multiQuestionTypeInit == '206' ||  extra_multiQuestionTypeInit == '207' || extra_multiQuestionTypeInit == '208'" class="form-group">
                                <label for="">Category</label>
                                <select class="form-control" name="extra_multipleQuestionCategory"  [(ngModel)]="extra_multiQuestionCategoryInit" #extra_multipleQuestionCategory="ngModel">
                                    <option value="">[ Do not link to any incident category ]</option>
                                    <option *ngFor="let extraOption of checkpointLookups['file-under']" [value]="extraOption.id">{{extraOption.name}}</option>
                                </select>
                            </div>

                            <div *ngIf="extra_multiQuestionTypeInit == '208'" class="form-group">
                                <label for="">Range</label>
                                <div class="d-flex gap-2">
                                    <input class="form-control" type="text" placeholder="Min" name="questionRange_min" [(ngModel)]="questionRange_minInit" ngModel required #questionRange_min="ngModel">
                                    <input class="form-control" type="text" placeholder="Max" name="questionRange_max" [(ngModel)]="questionRange_maxInit" ngModel required #questionRange_max="ngModel">
                                </div>
                            </div>

                            <div class="form-group text-center">
                                <button [disabled]="!areAllInputsFilled()" (click)="addQuestion()" class="btn submitBtn"><i class="fas fa-plus"></i> Add Question</button>
                            </div>
                            

                        </div>
                        

                        

                    </div>

                </div>
            </div>

            <div class="row">
                <div *ngIf="finalQuestions && finalQuestions.length > 0 && extraOption == '17'" class="addedQuestions">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Question</th>
                                <th>Required</th>
                                <th>Category</th>
                                <th>Range From</th>
                                <th>Range To</th>
                                <th>Remove</th>
                            </tr>
                            
                        </thead>
                        <tbody>
                            <tr *ngFor="let ques of finalQuestions; let i = index">
                                <td>{{getTypeName(ques.type)}}</td>
                                <td>{{ques.question}}</td>
                                <td *ngIf="ques.required === true">Yes</td>
                                <td *ngIf="ques.required === false">No</td>
                                <td>{{getCategoryName(ques.category)}}</td>
                                <td>{{ques.rangeFrom}}</td>
                                <td>{{ques.rangeTo}}</td>
                                <td>
                                    <button type="button" (click)="removeQuestion_add(i)" class="btn removeBtn" ><i class="fas fa-times-circle"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
  
            <div class="row">
                <div class="form-group col-12 text-center">
                    <button [disabled]="!f.valid"  class="btn submitBtn" (click)="addCheckpointForm(f)"><i class="fas fa-plus"></i> Add Checkpoint</button>
                </div>
            </div>
            
        </form>
      
      
    </div>
    
</ng-template>

<!-- Edit Checkpoint Modal -->
<ng-template #editCheckpoint role="document" let-modal >
    <div class="modal-header">
      <h5 class="modal-title mt-0">Edit Checkpoint</h5>
      <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form #f="ngForm">
            <div class="row section_style">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="">Checkpoint Name</label>
                        <input type="text" class="form-control" name="edit_checkpointName" [(ngModel)]="edit_checkpoint_name" ngModel required #edit_checkpointName="ngModel" >
                        <span class="text-danger" *ngIf="!edit_checkpointName.valid && edit_checkpointName.touched">Please Enter A Valid Answer</span>
                    </div>
                    <div class="form-group">
                        <label for="">Special Instructions</label>
                        <textarea class="form-control" name="edit_specialInstructions" ngModel [(ngModel)]="edit_checkpoint_instructions" #edit_specialInstructions="ngModel" rows="3"></textarea>
                    </div>
                    <div class="form-group">
                        <label for="">Can be scanned by</label>
                        <select class="form-control" name="edit_scannedBy" [(ngModel)]="edit_checkpoint_canBeScannedBy" ngModel  #edit_scannedBy="ngModel">
                            <option value="">All</option>
                        </select>
                        <span class="text-danger" *ngIf="!edit_scannedBy.valid && edit_scannedBy.touched">Please Choose A Valid Answer</span>
                    </div>
                    <div class="form-group">
                        <label for="">Checkpoint Monitoring Options</label>
                        <select class="form-control" name="edit_monitorOptions" [(ngModel)]="edit_checkpoint_monitoringOption" ngModel  #edit_monitorOptions="ngModel">
                            
                            <option *ngFor="let monitor of checkpointLookups['monitoring-options']"  [value]="monitor.id">{{monitor.name}}</option>
                        </select>
                        <!-- <span class="text-danger" *ngIf="!monitorOptions.valid && monitorOptions.touched">Please Choose A Valid Answer</span> -->
                    </div>

                    <div *ngIf="edit_checkpoint_monitoringOption == '4'" class="form-group">
                        <label for="">Scan Request Intervals</label>
                        <div class="d-flex gap-2">
                            <input class="form-control" type="number" placeholder="Every" name="edit_intervalNumber"  [(ngModel)]="edit_checkpoint_requestInterval" ngModel required #edit_intervalNumber="ngModel">
                            <select class="form-control" name="edit_intervalUnit" [(ngModel)]="edit_checkpoint_requestIntervalUnit" ngModel required #edit_intervalUnit="ngModel">
                                
                                <option *ngFor="let interval of checkpointLookups['interval-units']" [value]="interval.id">{{interval.name}}</option>
                            </select>
                        </div>
                        <span class="text-danger" *ngIf="!edit_intervalNumber.valid && edit_intervalNumber.touched || !edit_intervalUnit.valid && edit_intervalUnit.touched">Please Choose A Valid Answer</span>
                    </div>

                    
                    <!-- Allow manual scanning -->
                    <div class="form-group">
                        <label for="">Allow manual scanning (V.5.2)</label>
                        <select class="form-control" name="edit_manualScan" [(ngModel)]="edit_checkpoint_manualScan" ngModel  #edit_manualScan="ngModel">
                            <option value="">Select Answer</option>
                            <option *ngFor="let manualScan of checkpointLookups['manual-scan']" [value]="manualScan.id">{{manualScan.name}}</option>
                        </select>
                    </div>
                </div>

                <div class="col-12 col-md-6">

                    <!-- Extra Scan Options -->
                    <div class="form-group">
                        <label for="">Extra Scan Options</label>
                        <select class="form-control" name="edit_extra_scan_options" ngModel [(ngModel)]="edit_checkpoint_extraScanOption" required #edit_extra_scan_options="ngModel"  (change)="changeQuestionType_edit()">
                            <option *ngFor="let extraOption of checkpointLookups['extra-scan-options']" [value]="extraOption.id">{{extraOption.name}}</option>
                        </select>
                        <span class="text-danger" *ngIf="!edit_extra_scan_options.valid && edit_extra_scan_options.touched">Please Choose A Valid Answer</span>
                    </div>

                    <!-- Extra Scan Options: display message -->
                    <div *ngIf="edit_checkpoint_extraScanOption == '12'" class="form-group">
                        <label for="">Display a message</label>
                        <textarea class="form-control" name="edit_extra_displayMessage" required ngModel [(ngModel)]="edit_checkpoint_messageToDisplay" #edit_extra_displayMessage="ngModel" rows="2"></textarea>

                        <span class="text-danger" *ngIf="!edit_extra_displayMessage.valid && edit_extra_displayMessage.touched">Please Choose A Valid Answer</span>
                    </div>

                    <!-- Extra Scan Options: Open Report Form -->
                    <div *ngIf="edit_checkpoint_extraScanOption == '13'" class="form-group">
                        <label for="">Report Form to open</label>
                        <select class="form-control" name="edit_extra_openReportForm" ngModel [(ngModel)]="edit_checkpoint_reportFormToOpen" required #edit_extra_openReportForm="ngModel">
                            <option value="">Choose a report type</option>
                            <option *ngFor="let extraOption of checkpointLookups['reeport-form-to-open']" [value]="extraOption.id">{{extraOption.name}}</option>
                        </select>
                        <span class="text-danger" *ngIf="!edit_extra_openReportForm.valid && edit_extra_openReportForm.touched">Please Choose A Valid Answer</span>
                    </div>

                    <!-- Extra Scan Options: Exception Verification -->
                    <div *ngIf="edit_checkpoint_extraScanOption == '14' || edit_checkpoint_extraScanOption == '15' || edit_checkpoint_extraScanOption == '16'">
                        <div class="form-group">
                            <label for="">Question</label>
                            <input type="text" class="form-control" name="edit_extra_exceptionQuestion" ngModel [(ngModel)]="edit_checkpoint_scanQuestion" required #edit_extra_exceptionQuestion="ngModel" placeholder="Ex: What is the server room temperature"> 
                            <span class="text-danger" *ngIf="!edit_extra_exceptionQuestion.valid && edit_extra_exceptionQuestion.touched">Please Choose A Valid Answer</span>
                        </div>

                        <div *ngIf="edit_checkpoint_extraScanOption == '14'" class="form-group">
                            <div class="d-flex gap-2">
                                <div class="w-100">
                                    <label for="">Allowed From</label>
                                    <input class="form-control" type="text" placeholder="From" name="edit_extra_exceptionFrom" ngModel  [(ngModel)]="edit_checkpoint_validateRangeFrom" required #edit_extra_exceptionFrom="ngModel">
                                </div>
                                <div class="w-100">
                                    <label for="">Allowed To</label>
                                    <input class="form-control" type="text" placeholder="To" name="edit_extra_exceptionTo" ngModel  [(ngModel)]="edit_checkpoint_validateRangeTo" required #edit_extra_exceptionTo="ngModel">
                                </div>
                            </div>
                            <span class="text-danger" *ngIf="!edit_extra_exceptionFrom.valid && edit_extra_exceptionFrom.touched || !edit_extra_exceptionTo.valid && edit_extra_exceptionTo.touched">Please Choose A Valid Answer</span>
                            <div class="d-flex gap-2 align-items-center mt-2">
                                <input type="checkbox" id="extra_exceptionBarcode" name="edit_extra_exceptionBarcode" ngModel [(ngModel)]="edit_checkpoint_validateRangeBarcode" #edit_extra_exceptionBarcode="ngModel">
                                <label class="mb-0" for="extra_exceptionBarcode">Barcode</label>
                            </div>
                        </div>
                        
                    </div>

                    <div *ngIf="edit_checkpoint_extraScanOption == '14' || edit_checkpoint_extraScanOption == '15' || edit_checkpoint_extraScanOption == '16' || edit_checkpoint_extraScanOption == '17'">
                        <div class="form-group">
                            <label for="">If any answered, file under</label>
                            <select class="form-control" name="edit_extra_fileUnder" ngModel [(ngModel)]="edit_checkpoint_scanFileUnder" #edit_extra_fileUnder="ngModel">
                                <option value="">[ Do not link to any incident category ]</option>
                                <option *ngFor="let extraOption of checkpointLookups['file-under']" [value]="extraOption.id">{{extraOption.name}}</option>
                            </select>
                            <!-- <span class="text-danger" *ngIf="!extra_fileUnder.valid && extra_fileUnder.touched">Please Choose A Valid Answer</span> -->
                        </div>
                    </div>

                    <!-- Extra Scan Options: Exception Multiple Questions -->
                    <div *ngIf="edit_checkpoint_extraScanOption == '17'">
                        <div class="questions_bg">
                            <div class="form-group">
                                <label for="">Type</label>
                                <select class="form-control" name="extra_multipleQuestionType"  [(ngModel)]="extra_multiQuestionTypeInit" #extra_multipleQuestionType="ngModel">
                                    <option *ngFor="let extraOption of checkpointLookups['question-types']" [value]="extraOption.id">{{extraOption.name}}</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label for="">Question</label>
                                <input type="text" class="form-control" name="extra_exceptionMultiQuestion" ngModel [(ngModel)]="extra_multiQuestionTextInit"  #extra_exceptionMultiQuestion="ngModel" placeholder="Ex: What is the server room temperature"> 
                                <span class="text-danger" *ngIf="!extra_exceptionMultiQuestion.valid && extra_exceptionMultiQuestion.touched">Please Choose A Valid Answer</span>
                            </div>

                            <div class="form-group">
                                <div class="d-flex gap-2 align-items-center mt-2">
                                    <input type="checkbox" id="extra_questionRequired" name="extra_questionRequired" [(ngModel)]="extra_multiQuestionRequiredInit" ngModel #extra_questionRequired="ngModel">
                                    <label class="mb-0" for="extra_questionRequired">Required</label>
                                </div>
                            </div>

                            <div *ngIf="extra_multiQuestionTypeInit == '206' ||  extra_multiQuestionTypeInit == '207' || extra_multiQuestionTypeInit == '208'" class="form-group">
                                <label for="">Category</label>
                                <select class="form-control" name="extra_multipleQuestionCategory"  [(ngModel)]="extra_multiQuestionCategoryInit" #extra_multipleQuestionCategory="ngModel">
                                    <option value="">[ Do not link to any incident category ]</option>
                                    <option *ngFor="let extraOption of checkpointLookups['file-under']" [value]="extraOption.id">{{extraOption.name}}</option>
                                </select>
                            </div>

                            <div *ngIf="extra_multiQuestionTypeInit == '208'" class="form-group">
                                <label for="">Range</label>
                                <div class="d-flex gap-2">
                                    <input class="form-control" type="number" placeholder="Min" name="questionRange_min" [(ngModel)]="questionRange_minInit" ngModel  #questionRange_min="ngModel">
                                    <input class="form-control" type="number" placeholder="Max" name="questionRange_max" [(ngModel)]="questionRange_maxInit" ngModel  #questionRange_max="ngModel">
                                </div>
                            </div>

                            <div class="form-group text-center">
                                <button [disabled]="!areAllInputsFilled()" (click)="addQuestion_edit()" class="btn submitBtn"><i class="fas fa-plus"></i> Add Question</button>
                            </div>
                            

                        </div>
                        

                        

                    </div>

                </div>
            </div>

            <div class="row">
                <div *ngIf="edit_checkpoint_multipleQuestions && edit_checkpoint_multipleQuestions.length > 0 && edit_checkpoint_extraScanOption == '17'" class="addedQuestions">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Question</th>
                                <th>Required</th>
                                <th>Category</th>
                                <th>Range From</th>
                                <th>Range To</th>
                                <th>Remove</th>
                            </tr>
                            
                        </thead>
                        <tbody>
                            <tr *ngFor="let ques of edit_checkpoint_multipleQuestions; let i = index">
                                <td>{{getTypeName(ques.type)}}</td>
                                <td>{{ques.question}}</td>
                                <td *ngIf="ques.required === true">Yes</td>
                                <td *ngIf="ques.required === false">No</td>
                                <td>{{getCategoryName(ques.category)}}</td>
                                <td>{{ques.rangeFrom}}</td>
                                <td>{{ques.rangeTo}}</td>
                                <td>
                                    <button type="button" (click)="removeQuestion_edit(i)" class="btn removeBtn" ><i class="fas fa-times-circle"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
  
            <div class="row">
                <div class="form-group col-12 text-center">
                    <button [disabled]="!f.valid"  class="btn submitBtn" (click)="editCheckpointForm(f)"><i class="fas fa-plus"></i> Save Checkpoint</button>
                </div>
            </div>
            
        </form>
      
      
    </div>
    
</ng-template>


<!-- Import Checkpoint Modal -->
<ng-template #importCheckpoint role="document" let-modal >
    <div class="modal-header">
      <h5 class="modal-title mt-0">Import Checkpoint</h5>
      <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form #fImport="ngForm">
            <div class="form-group">
                <input type="file" class="form-control" name="checkpointFile" (change)="fImport.value.checkpointFile = $event.target.files[0]" ngModel required #checkpointFile="ngModel">
            </div>

            <div class="form-group text-center">
                <button [disabled]="!fImport.valid" class="btn importBtn" (click)="ImportCheckpoint(fImport)"><i class="fas fa-file-import"></i> Import</button>
            </div>
        </form>
      
    </div>
    
</ng-template>

<!-- Add Tour Route Modal -->
<ng-template #addTour role="document" let-modal >
    <div class="modal-header">
      <h5 class="modal-title mt-0">Create Tour Route</h5>
      <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form #f="ngForm">
            <div class="row section_style">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="">Description</label>
                        <input type="text" class="form-control" name="tourDesc" ngModel required #tourDesc="ngModel" >
                        <span class="text-danger" *ngIf="!tourDesc.valid && tourDesc.touched">Please Enter A Valid Answer</span>
                    </div>
                    <div class="form-group">
                        <label for="">Assigned To</label>
                        <select class="form-control" name="tourAssigned" ngModel  #tourAssigned="ngModel">
                            <option value="">All</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="">Special Instructions</label>
                        <textarea class="form-control" name="tourInstructions" ngModel  #tourInstructions="ngModel" rows="3"></textarea>
                    </div>
                    <div class="form-group">
                        <label for="">Estimated Tour Duration (Minutes)</label>
                        <input type="number" class="form-control" name="tourDuration" ngModel required #tourDuration="ngModel" >
                        <span class="text-danger" *ngIf="!tourDuration.valid && tourDuration.touched">Please Enter A Valid Answer</span>
                    </div>
                    <div class="form-group">
                        <label for="">Grace Period For Late Notification (Minutes)</label>
                        <input type="number" class="form-control" name="tourGrace" ngModel required #tourGrace="ngModel" >
                        <span class="text-danger" *ngIf="!tourGrace.valid && tourGrace.touched">Please Enter A Valid Answer</span>
                    </div>
                    
                </div>

                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <p class="info_message">
                            <i class="fas fa-info-circle"></i> If the grace period is set to 0, it will give 15 minutes by default
                        </p>
                    </div>
                    <div class="form-group">
                        <label for="">Recurrence Type</label>
                        <select class="form-control" name="tourRecurrenceType" (change)="recurrence_change()" ngModel [(ngModel)]="tourRecurrence" #tourRecurrenceType="ngModel">
                            <option *ngFor="let recurrence of tourLookups['interval-units']" [value]="recurrence.id">{{recurrence.name}}</option>
                        </select>
                        <span class="text-danger" *ngIf="!tourRecurrenceType.valid && tourRecurrenceType.touched">Please Enter A Valid Answer</span>
                    </div>

                    <div class="form-group">
                        <div>
                            <h5>Tour Schedule</h5>
                        </div>

                        <div *ngIf="tourRecurrence == '0'" class="schedule_weekly">
                            <div class="schedule_time_select">
                                <select class="form-select" name="tourDaySlot" ngModel [(ngModel)]="tourDay_field" #tourDaySlot="ngModel">
                                    <option *ngFor="let day of weekDays" [value]="day.id">{{day.name}}</option>
                                </select>
                                <select class="form-select" name="tourTimeSlot" ngModel [(ngModel)]="tourTime_field" #tourTimeSlot="ngModel">
                                    <option *ngFor="let time of clockTime" [value]="time.name">{{time.name}}</option>
                                </select>
                                <button class="btn submitBtn" (click)="addTime()">Add</button>
                            </div>
                            <div *ngIf="tourTime_exists">
                                <p class="text-danger">Time Already Exists</p>
                            </div>
                            
                            <div *ngFor="let day of weekDays" class="schedule_day">
                        
                                <div *ngIf="isDaySelected(day.id)" class="selected_hours mt-2">
                                    <div class="schedule_form">
                                        <label class="m-0" for="">{{day.name}}</label>
                                    </div>
                                    <div class="slots_container">
                                        <div *ngFor="let tourTime of getTourTimesForDay(day.id)" class="time_slot">
                                            <div class="single_time">
                                                <span>{{ tourTime.time }}</span>   
                                                <button class="btn p-0" (click)="deleteTime(day.id, tourTime.time)">x</button>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                        </div>

                        <div *ngIf="tourRecurrence == '1'" class="schedule_monthly">
                            <div class="schedule_time_select">
                                <select class="form-select" name="tourDaySlot" ngModel [(ngModel)]="tourDay_field" #tourDaySlot="ngModel">
                                    <option *ngFor="let day of monthDays" [value]="day.id">{{day.name}}</option>
                                </select>
                                <select class="form-select" name="tourTimeSlot" ngModel [(ngModel)]="tourTime_field" #tourTimeSlot="ngModel">
                                    <option *ngFor="let time of clockTime" [value]="time.name">{{time.name}}</option>
                                </select>
                                <button class="btn submitBtn" (click)="addTime()">Add</button>
                            </div>
                            <div *ngIf="tourTime_exists">
                                <p class="text-danger">Time Already Exists</p>
                            </div>
                            
                            <div *ngFor="let day of monthDays" class="schedule_day">
                        
                                <div *ngIf="isDaySelected(day.id)" class="selected_hours mt-2">
                                    <div class="schedule_form">
                                        <label class="m-0" for="">{{day.name}}</label>
                                    </div>
                                    <div class="slots_container">
                                        <div *ngFor="let tourTime of getTourTimesForDay(day.id)" class="time_slot">
                                            <div class="single_time">
                                                <span>{{ tourTime.time }}</span>   
                                                <button class="btn p-0" (click)="deleteTime(day.id, tourTime.time)">x</button>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                        </div>
                        
                    </div>
                </div>


            </div>

            <div class="row">
                <div class="form-group col-12 text-center">
                    <button [disabled]="!f.valid"  class="btn submitBtn"  (click)="addTourRoute(f)"><i class="fas fa-plus"></i> Create Tour</button>
                </div>
            </div>
        </form>
      
    </div>
    
</ng-template>

<!-- edit Tour Route Modal -->
<ng-template #editTour role="document" let-modal >
    <div class="modal-header">
      <h5 class="modal-title mt-0">Edit Tour Route</h5>
      <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form #f="ngForm">
            <div class="row section_style">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="">Description</label>
                        <input type="text" class="form-control" name="edit_tourDesc" ngModel [(ngModel)]="edit_tourDescRec" required #edit_tourDesc="ngModel" >
                        <span class="text-danger" *ngIf="!edit_tourDesc.valid && edit_tourDesc.touched">Please Enter A Valid Answer</span>
                    </div>
                    <div class="form-group">
                        <label for="">Assigned To</label>
                        <select class="form-control" name="edit_tourAssigned" [(ngModel)]="edit_tourAssignedRec" ngModel  #edit_tourAssigned="ngModel">
                            <option value="">All</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="">Special Instructions</label>
                        <textarea class="form-control" name="edit_tourInstructions" ngModel [(ngModel)]="edit_tourInstructionsRec" #edit_tourInstructions="ngModel" rows="3"></textarea>
                    </div>
                    <div class="form-group">
                        <label for="">Estimated Tour Duration (Minutes)</label>
                        <input type="number" class="form-control" name="edit_tourDuration" ngModel [(ngModel)]="edit_tourDurationRec" required #edit_tourDuration="ngModel" >
                        <span class="text-danger" *ngIf="!edit_tourDuration.valid && edit_tourDuration.touched">Please Enter A Valid Answer</span>
                    </div>
                    <div class="form-group">
                        <label for="">Grace Period For Late Notification (Minutes)</label>
                        <input type="number" class="form-control" name="edit_tourGrace" ngModel [(ngModel)]="edit_tourGraceRec" required #edit_tourGrace="ngModel" >
                        <span class="text-danger" *ngIf="!edit_tourGrace.valid && edit_tourGrace.touched">Please Enter A Valid Answer</span>
                    </div>
                    
                </div>

                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <p class="info_message">
                            <i class="fas fa-info-circle"></i> If the grace period is set to 0, it will give 15 minutes by default
                        </p>
                    </div>
                    <div class="form-group">
                        <label for="">Recurrence Type</label>
                        <select class="form-control" name="edit_tourRecurrenceType" (change)="recurrence_change_edit()" ngModel [(ngModel)]="edit_tourRecurrence" #edit_tourRecurrenceType="ngModel">
                            <option *ngFor="let recurrence of tourLookups['interval-units']" [value]="recurrence.id">{{recurrence.name}}</option>
                        </select>
                        <span class="text-danger" *ngIf="!edit_tourRecurrenceType.valid && edit_tourRecurrenceType.touched">Please Enter A Valid Answer</span>
                    </div>

                    <div class="form-group">
                        <div>
                            <h5>Tour Schedule</h5>
                        </div>

                        <div *ngIf="edit_tourRecurrence == 0" class="schedule_weekly">
                            <div class="schedule_time_select">
                                <select class="form-select" name="tourDaySlot" ngModel [(ngModel)]="tourDay_field_edit" #tourDaySlot="ngModel">
                                    <option *ngFor="let day of weekDays" [value]="day.id">{{day.name}}</option>
                                </select>
                                <select class="form-select" name="tourTimeSlot" ngModel [(ngModel)]="tourTime_field_edit" #tourTimeSlot="ngModel">
                                    <option *ngFor="let time of clockTime" [value]="time.name">{{time.name}}</option>
                                </select>
                                <button class="btn submitBtn" (click)="addTime_edit_week()">Add</button>
                            </div>
                            <div *ngIf="tourTime_exists_edit">
                                <p class="text-danger">Time Already Exists</p>
                            </div>
                            
                            <div *ngFor="let day of weekDays" class="schedule_day">
                        
                                <div *ngIf="isDaySelected_edit_week(day.id)" class="selected_hours mt-2">
                                    <div class="schedule_form">
                                        <label class="m-0" for="">{{day.name}}</label>
                                    </div>
                                    <div  class="slots_container">
                                        <div *ngFor="let tourTime of getTourTimesForDay_edit_week(day.id)" class="time_slot">
                                            <div class="single_time">
                                                <span>{{ tourTime.time }}</span>   
                                                <button class="btn p-0" (click)="deleteTime_edit_week(day.id, tourTime.time)">x</button>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                        </div>

                        <div *ngIf="edit_tourRecurrence == 1" class="schedule_monthly">
                            <div class="schedule_time_select">
                                <select class="form-select" name="tourDaySlot" ngModel [(ngModel)]="tourDay_field_edit" #tourDaySlot="ngModel">
                                    <option *ngFor="let day of monthDays" [value]="day.id">{{day.name}}</option>
                                </select>
                                <select class="form-select" name="tourTimeSlot" ngModel [(ngModel)]="tourTime_field_edit" #tourTimeSlot="ngModel">
                                    <option *ngFor="let time of clockTime" [value]="time.name">{{time.name}}</option>
                                </select>
                                <button class="btn submitBtn" (click)="addTime_edit_month()">Add</button>
                            </div>
                            <div *ngIf="tourTime_exists_edit">
                                <p class="text-danger">Time Already Exists</p>
                            </div>
                            
                            <div *ngFor="let day of monthDays" class="schedule_day">
                        
                                <div *ngIf="isDaySelected_edit_month(day.id)" class="selected_hours mt-2">
                                    <div class="schedule_form">
                                        <label class="m-0" for="">{{day.name}}</label>
                                    </div>
                                    <div *ngIf="tourTimes_edit_month" class="slots_container">
                                        <div *ngFor="let tourTime of getTourTimesForDay_edit_month(day.id)" class="time_slot">
                                            <div class="single_time">
                                                <span>{{ tourTime.time }}</span>   
                                                <button class="btn p-0" (click)="deleteTime_edit_month(day.id, tourTime.time)">x</button>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                        </div>
                        
                    </div>
                </div>


            </div>

            <div class="row">
                <div class="form-group col-12 text-center">
                    <button [disabled]="!f.valid"  class="btn submitBtn"  (click)="editTourRoute(f)"><i class="fas fa-plus"></i> Save Tour</button>
                </div>
            </div>
        </form>
      
    </div>
    
</ng-template>

<!-- Tour Checkpoint Modal -->
<ng-template #tourCheckpoint role="document" let-modal >
    <div class="modal-header">
      <h5 class="modal-title mt-0">Manage Checkpoints</h5>
      <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <h4>{{tour_description_checkpoint}}</h4>
        <form #f="ngForm">
            <div class="form-group col-12">
                <div class="shiftDaysContainer">
                  <div class="checkboxContainer">
                        <input type="checkbox" id="allCheckpoints" name="allCheckpoints" (change)="toggleAllCheckpoints($event)"> 
                        <label for="allCheckpoints">All Checkpoints</label>
                    </div>
                    <div class="checkboxContainer" *ngFor="let check of checkpoints">
                        <input type="checkbox" id="{{check.id}}" name="{{check.id}}" (change)="toggleCheckpoint(check.id)" [checked]="isCheckpointExists(check.id)"> 
                        <label for="{{check.id}}"> {{check.name}}</label>
                    </div>
                </div>
                
            </div>

            <div class="form-group text-center">
                <button [disabled]="!f.valid" class="btn submitBtn" (click)="manageCheckpoints()"><i class="fas fa-save"></i> Save</button>
            </div>
        </form>
      
    </div>
    
</ng-template>

<!-- Delete Contact Modal -->
<ng-template #deleteContact role="document" let-modal >
    <div class="modal-header">
      <h5 class="modal-title mt-0">Delete Contact</h5>
      <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
            <div class="form-group text-center">
                <p class="m-0">Are you sure you want to delete?</p>
                <label for=""><strong>{{deletedContactName}}</strong> </label>
            </div>

            <div class="form-group text-center">
                <button class="btn remove_Btn" (click)="deleteContacts()">Delete</button>
            </div>
      
    </div>
    
</ng-template>

<!-- Add Contact Modal -->
<ng-template #addContact role="document" let-modal >
    <div class="modal-header">
      <h5 class="modal-title mt-0">Create Contact</h5>
      <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form #f="ngForm">
            <div class="form-group">
                <label for="">First Name</label>
                <input type="text" class="form-control" name="contactFName" ngModel required #contactFName="ngModel" >
                <span class="text-danger" *ngIf="!contactFName.valid && contactFName.touched">Please Enter A Valid Answer</span>
            </div>

            <div class="form-group">
                <label for="">Last Name</label>
                <input type="text" class="form-control" name="contactLName" ngModel required #contactLName="ngModel" >
                <span class="text-danger" *ngIf="!contactLName.valid && contactLName.touched">Please Enter A Valid Answer</span>
            </div>

            <div class="form-group">
                <label for="">Email</label>
                <input type="email" class="form-control" name="contactEmail" ngModel required #contactEmail="ngModel" >
                <span class="text-danger" *ngIf="!contactEmail.valid && contactEmail.touched">Please Enter A Valid Answer</span>
            </div>

            <div class="form-group">
                <label for="">Mobile</label>
                <input type="tel" class="form-control" name="contactMob" ngModel required #contactMob="ngModel" >
                <span class="text-danger" *ngIf="!contactMob.valid && contactMob.touched">Please Enter A Valid Answer</span>
            </div>

            <div class="form-group">
                <label for="">Job Title</label>
                <input type="text" class="form-control" name="contactJob" ngModel required #contactJob="ngModel" >
                <span class="text-danger" *ngIf="!contactJob.valid && contactJob.touched">Please Enter A Valid Answer</span>
            </div>

            <div class="form-group checkboxContainer">
                <input type="checkbox" id="isEmergency" name="isEmergency" ngModel #isEmergency="ngModel" >
                <label for="isEmergency"> Is Emergency Contact?</label>
            </div>

            <div class="form-group text-center">
                <button [disabled]="!f.valid" class="btn submitBtn" (click)="addContacts(f)"><i class="fas fa-plus"></i> Create Contact</button>
            </div>
        </form>
      
    </div>
    
</ng-template>

<!-- Edit Contact Modal -->
<ng-template #editContact role="document" let-modal >
    <div class="modal-header">
      <h5 class="modal-title mt-0">Edit Contact</h5>
      <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form #f="ngForm">
            <div class="form-group">
                <label for="">First Name</label>
                <input type="text" class="form-control" name="contactFName" ngModel [(ngModel)]="editedContact.firstName" required #contactFName="ngModel" >
                <span class="text-danger" *ngIf="!contactFName.valid && contactFName.touched">Please Enter A Valid Answer</span>
            </div>

            <div class="form-group">
                <label for="">Last Name</label>
                <input type="text" class="form-control" name="contactLName" ngModel [(ngModel)]="editedContact.lastName" required #contactLName="ngModel" >
                <span class="text-danger" *ngIf="!contactLName.valid && contactLName.touched">Please Enter A Valid Answer</span>
            </div>

            <div class="form-group">
                <label for="">Email</label>
                <input type="email" class="form-control" name="contactEmail" ngModel [(ngModel)]="editedContact.email" required #contactEmail="ngModel" >
                <span class="text-danger" *ngIf="!contactEmail.valid && contactEmail.touched">Please Enter A Valid Answer</span>
            </div>

            <div class="form-group">
                <label for="">Mobile</label>
                <input type="tel" class="form-control" name="contactMob" ngModel [(ngModel)]="editedContact.mobile" required #contactMob="ngModel" >
                <span class="text-danger" *ngIf="!contactMob.valid && contactMob.touched">Please Enter A Valid Answer</span>
            </div>

            <div class="form-group">
                <label for="">Job Title</label>
                <input type="text" class="form-control" name="contactJob" ngModel  [(ngModel)]="editedContact.jobTitle" required #contactJob="ngModel" >
                <span class="text-danger" *ngIf="!contactJob.valid && contactJob.touched">Please Enter A Valid Answer</span>
            </div>

            <div class="form-group checkboxContainer">
                <input type="checkbox" id="isEmergency" name="isEmergency" ngModel [(ngModel)]="editedContact.isEmergency" #isEmergency="ngModel" >
                <label for="isEmergency"> Is Emergency Contact?</label>
            </div>

            <div class="form-group text-center">
                <button [disabled]="!f.valid" class="btn submitBtn" (click)="editContacts(f)"><i class="fas fa-save"></i> Save Contact</button>
            </div>
        </form>
      
    </div>
    
</ng-template>

<!-- Ban Employee Modal -->
<ng-template #banEmployee role="document" let-modal >
    <div class="modal-header">
      <h5 class="modal-title mt-0">Ban Employee</h5>
      <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <form #f="ngForm">
        <div class="modal-body">
            <div class="form-group text-center">
                <select class="form-select" ngModel [(ngModel)]="bannedEmployee_id" required #banEmpId="ngModel" name="banEmpId" id="">
                    <option value="">Select Employee</option>
                    <option *ngFor="let emp of siteEmployees" [value]="emp.id">{{emp.firstName}} {{emp.lastName}}</option>
                </select>
            </div>

            <div  class="checkboxContainer">
                <input  type="checkbox" id="effectiveCheck" name="effectiveCheck" [(ngModel)]="effectiveDateCheck" ngModel #effectiveCheck="ngModel"  >
                <label for="effectiveCheck">Effective Immediately</label>
              </div>
    
              <div *ngIf="!effectiveDateCheck" class="form-group mt-2">
                <label for="">Effective Date From</label>
                <input type="date" class="form-control" id="effectiveDate" name="effectiveDate" ngModel #effectiveDate="ngModel">
              </div>
    
            <div class="form-group text-center">
                <button class="btn remove_Btn" (click)="ban(f)" >Ban</button>
            </div>
          
        </div>
    </form>
    
    
</ng-template>

<!-- Assign Slot to position Slots Modal -->
<ng-template #assignPosition role="document" let-modal >
    <div class="modal-header">
      <h5 class="modal-title mt-0">Assign To Position</h5>
      <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
    </div>
    
    <div class="modal-body">
        <form #f="ngForm">
            <div class="form-group">
                <label for="">Select Position</label>
                <select  class="form-control" name="assignPosition" required ngModel #assignPosition="ngModel">
                    <option value="">Select Position</option>
                    <option *ngFor="let route of routePositionsss" [value]="route.positionId">{{route.name}}</option>
                </select>
                <span class="text-danger" *ngIf="!assignPosition.valid && assignPosition.touched">Please Enter A Valid Answer</span>
            </div>
    
            <div class="form-group text-center">
                <button [disabled]="!f.valid" class="btn submitBtn" (click)="assignPositionFunc(f)">Assign</button>
            </div>
        </form>
    </div>
    
</ng-template>