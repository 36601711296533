import { Component, OnInit, AfterViewInit  } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DataService } from "../../core/services/data.service";
import { RestApiService } from "../../core/services/rest-api.service";
import { NgForm } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Employee } from "src/app/core/models/employee";
import {
  ChangeDetectorRef,
  ElementRef,
  EventEmitter,
  Renderer2,
  ViewChild,
} from "@angular/core";

import { Calendar, CalendarOptions, EventInput } from "@fullcalendar/core";
import { DateSelectArg, EventClickArg, EventApi } from "@fullcalendar/core";

import dayGridPlugin from "@fullcalendar/daygrid";

import { EmployeeSites } from "src/app/core/models/employeeSites";

import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-employee-details",
  templateUrl: "./employee-details.component.html",
  styleUrls: ["./employee-details.component.scss"],
})
export class EmployeeDetailsComponent implements OnInit {
  daysError: any;
  timeFromError: any;
  timeToError: any;
  avalability_statusMessage: any;
  imageUrl = "";
  passwordErrorMessage = "";
  formPassword = "";
  formConfirmPassword = "";
  validPassword: boolean = false;
  showMatchMessage: boolean = false;
  urlToUpload = "";
  employee_picture: File;

  constructor(
    private route: ActivatedRoute,
    private rest: RestApiService,
    private data: DataService,
    private modalService: NgbModal,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    const userId = this.route.snapshot.paramMap.get("id");

    this.getEmpDetails(userId);
    this.getBanned(userId);
    this.getAllPositions(userId);
    this.getEmpSites(userId);
    this.getAvailability(userId);
  }

  employee: Employee;
  loading: boolean = true;
  effectiveDateCheck: boolean = true;
  showContent: boolean = false;
  fetchError = false;

  setDefaultImage() {
    this.imageUrl = "../../../assets/images/deafult_picture.png"; // Path to the default image
  }

  async getEmpDetails(id: string) {
    this.loading = true;
    this.showContent = false;
    try {
      await this.rest
        .getAllUsers(`${this.data.getBaseUrl()}api/v1/employees/${id}`)
        .subscribe(
          (res: any) => {
            // this.successMessage = res.message;
            this.fetchError = false;
            this.loading = false;
            this.employee = res;
            this.showContent = true;
            this.imageUrl = res.profilePicture;
            console.log(this.employee);
          },
          (error: any) => {
            this.showContent = false;
            this.fetchError = true;
            this.loading = false;
          }
        );
    } catch (error) {
      this.loading = true;
      this.data.error(error["message"]);
    }
  }

  showEmpDetails: boolean = true;
  showScheduleContent: boolean = false;
  showBannedContent: boolean = false;
  showAvailabilityContent: boolean = false;

  displayScheduleContent: boolean = false;
  loading_schedule: boolean = true;

  calendarOptions: CalendarOptions;
  currentEvents: EventApi[] = [];

  getEmployeeSites: EmployeeSites[] = [];

  // assignedEmployees: EmployeeSites[]=[];
  calendarTest: EventInput[] = [];

  showDetails() {
    this.showEmpDetails = true;
    this.showScheduleContent = false;
    this.showBannedContent = false;
    this.showAvailabilityContent = false;
  }

  showSchedulers() {
    this.showEmpDetails = false;
    this.showScheduleContent = true;
    this.showBannedContent = false;
    this.showAvailabilityContent = false;
    // this.renderer.selectRootElement(this.loadScheduleBtn.nativeElement).click();
    // this.setOptions()
  }

  showBanned() {
    this.showEmpDetails = false;
    this.showScheduleContent = false;
    this.showBannedContent = true;
    this.showAvailabilityContent = false;
  }

  showAvailability() {
    this.showEmpDetails = false;
    this.showScheduleContent = false;
    this.showBannedContent = false;
    this.showAvailabilityContent = true;
  }

  openModal(content: any) {
    this.modalService.open(content, {
      centered: true,
      size: "md",
      windowClass: "detailsModal",
      backdrop: "static",
    });
  }

  BannedSites: any[] = [];
  EmpSites: any[] = [];
  async getBanned(empId: string) {
    try {
      await this.rest
        .getAll(
          `${this.data.getBaseUrl()}api/v1/employees/${empId}/banned-sites`
        )
        .subscribe(
          (res: any) => {
            this.fetchError = false;
            this.BannedSites = res;
            console.log(this.BannedSites);
            this.loading = false;
          },
          (error: any) => {
            console.log(error);
          }
        );
    } catch (error) {
      console.log("error" + error);
      this.data.error(error["message"]);
      // this.loading = true;
    }
  }

  async getEmpSites(empId: string) {
    try {
      await this.rest
        .getAll(`${this.data.getBaseUrl()}api/v1/employees/${empId}/sites`)
        .subscribe(
          (res: any) => {
            this.fetchError = false;
            this.EmpSites = res;
            this.loading = false;
          },
          (error: any) => {
            console.log(error);
          }
        );
    } catch (error) {
      console.log("error" + error);
      this.data.error(error["message"]);
      // this.loading = true;
    }
  }

  async getAllPositions(userId: string) {
    try {
      await this.rest
        .getAll(
          `${this.data.getBaseUrl()}api/v1/employees/${userId}/schedulers/calendar`
        )
        .subscribe(
          (res: any) => {
            this.getEmployeeSites = res;
            this.setOptions();

            this.displayScheduleContent = true;
          },
          (error: any) => {
            this.fetchError = true;
            // this.loading_schedule = true;
            this.displayScheduleContent = false;
          }
        );
    } catch (error) {
      this.data.error(error["message"]);
      // this.loading = true;
    }
  }

  eventGuid: number;
  createEventId() {
    this.eventGuid = 0;
    return String(this.eventGuid++);
  }

  setEmps() {
    if (this.getEmployeeSites) {
      for (let i = 0; i < this.getEmployeeSites.length; i++) {
        const shiftDay = this.getEmployeeSites[i];
        this.calendarTest.push({
          id: this.createEventId(),
          title: shiftDay.schedulerName,
          start: shiftDay.from,
          end: shiftDay.to,
          color: "#000",
          textColor: "#fff",
          className: "eventColor",
        });
      }
    } else if (!this.getEmployeeSites) {
      this.calendarTest = [];
    }
  }

  setOptions() {
    // this.setAssigned();

    this.setEmps();

    this.calendarOptions = {
      plugins: [dayGridPlugin],
      headerToolbar: {
        left: "dayGridMonth,dayGridWeek,dayGridDay",
        center: "title",
        right: "prevYear,prev,next,nextYear",
      },

      initialView: "dayGridMonth",

      themeSystem: "bootstrap",
      events: this.calendarTest,
      weekends: true,
      editable: false,
      selectable: false,
      selectMirror: true,
      dayMaxEvents: true,
      displayEventTime: true,
      displayEventEnd: true,
      eventTimeFormat: {
        hour: "2-digit",
        minute: "2-digit",
        meridiem: "narrow",
        hour12: false,
      },
    };
    this.loading_schedule = false;
    this.displayScheduleContent = true;
  }

  async ban(f: NgForm) {
    try {
      await this.rest
        .post(
          `${this.data.getBaseUrl()}api/v1/sites/${
            f.value.empSitesList
          }/employees/${this.employee.userId}/ban`,
          {
            effectiveDate: f.value.effectiveDate,
          }
        )
        .subscribe(
          (res: any) => {
            window.location.reload();
          },
          (error: any) => {
            console.log("Error");
          }
        );
    } catch (error) {
      this.data.error(error["message"]);
    }
  }

  async unBan(id: string) {
    try {
      await this.rest
        .post(
          `${this.data.getBaseUrl()}api/v1/sites/${id}/employees/${
            this.employee.userId
          }/unban`,
          {}
        )
        .subscribe(
          (res: any) => {
            window.location.reload();
          },
          (error: any) => {
            console.log("Error");
          }
        );
    } catch (error) {
      this.data.error(error["message"]);
    }
  }

  // Availability

  days: any = [
    {
      id: 1,
      name: "Monday",
    },
    {
      id: 2,
      name: "Tuesday",
    },
    {
      id: 3,
      name: "Wednesday",
    },
    {
      id: 4,
      name: "Thursday",
    },
    {
      id: 5,
      name: "Friday",
    },
    {
      id: 6,
      name: "Saturday",
    },
    {
      id: 0,
      name: "Sunday",
    },
  ];

  availability: any[] = [];
  async getAvailability(empId: string) {
    try {
      await this.rest
        .getAll(
          `${this.data.getBaseUrl()}api/v1/employees/${empId}/availability`
        )
        .subscribe(
          (res: any) => {
            this.availability = res;
            console.log(this.availability);
          },
          (error: any) => {
            console.log(error);
          }
        );
    } catch (error) {
      console.log("error" + error);
      this.data.error(error["message"]);
      // this.loading = true;
    }
  }

  getSlotStyle(availability: string): any {
    if (availability === "MAYBE") {
      return { "background-color": "#d5a44c" };
    } else if (availability === "NOT_AVAILABLE") {
      return { "background-color": "#A64949" };
    } else {
      return {}; // Return an empty object if no specific style is needed
    }
  }

  async deleteSlot(id: string) {
    try {
      await this.rest
        .deleteWithId(
          `${this.data.getBaseUrl()}api/v1/employees/${
            this.employee.userId
          }/availability/${id}`
        )
        .subscribe(
          (res: any) => {
            window.location.reload();
          },
          (error: any) => {
            console.log("Error");
          }
        );
    } catch (error) {
      this.data.error(error["message"]);
    }
  }

  selectedDays = [];

  toggleDay(day) {
    if (this.isSelected(day)) {
      this.selectedDays = this.selectedDays.filter((id) => id !== day.id);
    } else {
      this.selectedDays.push(day.id);
    }
  }

  isSelected(day) {
    return this.selectedDays.includes(day.id);
  }

  toggleAllDays(event) {
    if (event.target.checked) {
      // If "All Days" checkbox is checked, add all days to selectedDays
      this.selectedDays = this.days.map((day) => day.id);
    } else {
      // If "All Days" checkbox is unchecked, remove all days from selectedDays
      this.selectedDays = [];
    }

    this.days.forEach((day) => {
      const checkbox = document.getElementById(
        day.id.toString()
      ) as HTMLInputElement;
      checkbox.checked = event.target.checked;
    });
  }

  async addSlot(f: NgForm) {
    try {
      await this.rest
        .post(
          `${this.data.getBaseUrl()}api/v1/employees/${
            this.employee.userId
          }/availability`,

          {
            timeFrom: f.value.slotTimeFrom,
            timeTo: f.value.slotTimeTo,
            days: this.selectedDays,
            availability: f.value.avalability_status,
          }
        )
        .subscribe(
          (res: any) => {
            f.reset();
            window.location.reload();
          },
          (error: any) => {
            error.error.errors.forEach((err) => {
              switch (err.field) {
                case "availability":
                  this.avalability_statusMessage = err.message;
                  break;

                case "timeFrom":
                  this.timeFromError = err.message;
                  break;

                case "timeTo":
                  this.timeToError = err.message;
                  break;

                // Add more cases for other fields if needed
              }
            });
          }
        );
    } catch (error) {
      this.data.error(error["message"]);
    }
  }

  // Change Password Functions

  onChangePassword() {
    this.formConfirmPassword = "";
  }

  onChangeConfirmPassword() {
    if (this.formPassword === this.formConfirmPassword) {
      this.validPassword = true;
      this.showMatchMessage = false;
    } else {
      this.validPassword = false;
      this.showMatchMessage = true;
    }
  }

  async changePassword(f: NgForm) {
    try {
      await this.rest
        .post(
          `${this.data.getBaseUrl()}api/v1/employees/${
            this.employee.userId
          }/change-password`,

          {
            password: f.value.userPassword,
            passwordConfirmation: f.value.confirmUserPassword,
          }
        )
        .subscribe(
          (res: any) => {
            f.reset();
            this.modalService.dismissAll("Cross click");
            this.toastr.success("Changed Succefully", "Password ", {
              positionClass: "toast-bottom-right",
            });
          },
          (error: any) => {
            error.error.errors.forEach((err) => {
              this.toastr.error(err.message, "Password ", {
                positionClass: "toast-bottom-right",
              });
            });
            // this.toastr.error(error.errors.message, 'Password ',{
            //   positionClass: 'toast-bottom-right',
            // });
          }
        );
    } catch (error) {
      this.data.error(error["message"]);
    }
  }

  async forceChangePassword() {
    try {
      await this.rest
        .post(
          `${this.data.getBaseUrl()}api/v1/employees/${
            this.employee.userId
          }/force-change-password`,

          {}
        )
        .subscribe(
          (res: any) => {
            this.modalService.dismissAll("Cross click");
            this.toastr.success(
              "Request Sent Successfuly",
              "Force Change Password ",
              {
                positionClass: "toast-bottom-right",
              }
            );
          },
          (error: any) => {
            error.error.errors.forEach((err) => {
              this.toastr.error(err.message, "Password ", {
                positionClass: "toast-bottom-right",
              });
            });
            // this.toastr.error(error.errors.message, 'Password ',{
            //   positionClass: 'toast-bottom-right',
            // });
          }
        );
    } catch (error) {
      this.data.error(error["message"]);
    }
  }

  imageFileChange(event: any) {
    this.employee_picture = event.target.files[0];
    this.createFormData(this.employee_picture);
    console.log(this.employee_picture);
  }

  createFormData(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    this.makeFileUrl(formData);
    console.log(file);
  }

  async makeFileUrl(file: FormData) {
    try {
      await this.rest
        .post(`${this.data.getBaseUrl()}api/v1/upload-file`, file)
        .subscribe(
          (res: any) => {
            this.fetchError = false;
            this.urlToUpload = res.path;
            console.log(res);
          },
          (error: any) => {
            console.log(error);
          }
        );
    } catch (error) {
      this.data.error(error["message"]);
    }
  }

  async uploadPicture() {
    try {
      await this.rest
        .post(
          `${this.data.getBaseUrl()}api/v1/employees/${
            this.employee.userId
          }/update-profile-picture`,
          {
            profilePicture: this.data.getBaseUrlOfImg() + this.urlToUpload,
          }
        )
        .subscribe(
          (res: any) => {
            this.modalService.dismissAll("Cross click");
            this.toastr.success("Updated Successfuly", "Profile Picture ", {
              positionClass: "toast-bottom-right",
            });
            this.getEmpDetails(this.employee.userId);
            this.clearPicture();
          },
          (error: any) => {
            console.log(error);
            this.toastr.error(error, "Profile Picture ", {
              positionClass: "toast-bottom-right",
            });
          }
        );
    } catch (error) {
      this.data.error(error["message"]);
    }
  }


  // Capture Image 

  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('canvasElement') canvasElement!: ElementRef<HTMLCanvasElement>;
  showImg: boolean = false;
  capturedImage: string | null = null;
  capturedToUpload = "";


  ngAfterViewInit(): void {
    // Camera will be started manually when the modal is opened
    
  }

  startCamera(): void {
    this.showImg = true;
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        this.videoElement.nativeElement.srcObject = stream;
      })
      .catch((error) => {
        console.error('Error accessing the camera', error);
      });
  }

  capture(): void {
    const video = this.videoElement.nativeElement;
    const canvas = this.canvasElement.nativeElement;
    const context = canvas.getContext('2d');

    if (context) {
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      this.capturedImage = canvas.toDataURL('image/png');
      console.log(this.capturedImage); // The captured image data URL

      this.saveImageAsFile();
    }
  }

  dataURLToBlob(dataURL: string): Blob {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }

  saveImageAsFile(): void {
    if (this.capturedImage) {
      const blob = this.dataURLToBlob(this.capturedImage);
      const file = new File([blob], 'captured-image.png', { type: 'image/png' });

      // You can now use the file, e.g., upload it to a server or save locally
      console.log(file);
      this.createFormData(file);
    } else {
      console.error('No captured image to save');
    }
  }

  clearPicture(): void {
    this.showImg = false;
    this.capturedImage = null
  }
}
