<div class="container-fluid">
    <div class="row">

      <div class="col-12 mb-3 alignContent-spaceBetween">
        <h2>Zones</h2>
        <button class="btn submitBtn" (click)="openModal(addZoneModal)"><i class="fas fa-plus"></i> Add Zone</button>

      </div>

      <div class="col-12" *ngIf="fetchError">
        <ngb-alert type="danger"  [dismissible]="false" class="text-center"><strong>Error:</strong> {{errorMessage}}</ngb-alert>
      </div>
      <!-- 

      <div class="col-12"  *ngIf="itemAdded">
        <ngb-alert type="success"  [dismissible]="false" class="text-center fadeAlert">{{successMessage}}</ngb-alert>
      </div> -->

      <!-- <div class="col-12"  *ngIf="itemDeleted">
        <ngb-alert type="success"  [dismissible]="false" class="text-center fadeAlert">{{successMessage}}</ngb-alert>
      </div> -->

      <!-- <div class="col-12"  *ngIf="itemAdded">
        <ngb-alert type="success"  [dismissible]="false" class="text-center fadeAlert">{{successMessage}}</ngb-alert>
      </div> -->

      <app-loader [loading]="loading"></app-loader>
      
      <div class="col-12" *ngIf="!fetchError && !loading">
        <div class="card">
          <div class="card-body" *ngIf="displayTable">
            <div class="table-responsive">
              <table datatable [dtOptions]="dtOptions" id="datatable" class="datatable table align-middle table-nowrap table-hover">
                <thead class="table-light">
                  <tr>
                    
                    <th scope="col">Name</th>
                    <th scope="col">Internal ID</th>
                    <th scope="col">Details</th>
                    <th scope="col">Address</th>
                    <th scope="col">Country</th>
                    <th scope="col">State</th>
                    <th scope="col">City</th>
                    <th scope="col">Zip Code</th>
                    <th scope="col">View</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let Zone of Zones">
                    
                    <td>{{Zone.name}}</td>
                    <td>{{Zone.internalId}}</td>
                    <td>{{Zone?.details}}</td> 
                    <td>{{Zone?.address}}</td>
                    <td>{{Zone.country?.name}}</td>
                    <td>{{Zone.city?.name}}</td>
                    <td>{{Zone.area?.name}}</td>
                    <td>{{Zone?.zipcode}}</td>
                    <td>
                      <button (click)="onViewZone(Zone.id)" class="btn"><i class="fas fa-eye"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <app-pagination
                [total]="PaginationObj.total"
                [perPage]="PaginationObj.perPage"
                [currentPage]="PaginationObj.currentPage"
                [firstPage]="PaginationObj.firstPage"
                [lastPage]="PaginationObj.lastPage"
                [nextPage]="PaginationObj.nextPage"
                [previousPage]="PaginationObj.previousPage"
                (pageChange)="onPageChange($event)"
              ></app-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>


<ng-template #addZoneModal role="document" let-modal >
  <div class="modal-header">
    <h5 class="modal-title mt-0">Add Zone</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">

    <div class="row">
      <div *ngIf="errorMessage" class="col-12">
        <ngb-alert type="danger" [dismissible]="false" class="text-center">{{errorMessage}}</ngb-alert>
      </div>
      <div class="col-12">

        <form #f="ngForm" >
          <div class="row row-cols-md-2">

            <div class="form-group col ">
              <label for="">Zone Name</label>
              <input type="text" class="form-control" name="zoneName" ngModel required #zoneName="ngModel">
              <span class="text-danger" *ngIf="!zoneName.valid && zoneName.touched">Please Enter A Valid Answer</span>
            </div>
            <div class="form-group col">
                <label for="">Internal ID</label>
                <input type="text" class="form-control" name="zoneIntId" ngModel required #zoneIntId="ngModel">
                <span class="text-danger" *ngIf="!zoneIntId.valid && zoneIntId.touched">Please Enter A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="internalIdErrorMessage"><strong>{{internalIdErrorMessage}}</strong></p>
            </div>
            <div class="form-group col">
              <label for="">Details</label>
              <input type="text" class="form-control" name="zoneDetails" ngModel required #zoneDetails="ngModel">
              <span class="text-danger" *ngIf="!zoneDetails.valid && zoneDetails.touched">Please Enter A Valid Answer</span>
              <p class="text-danger m-0" *ngIf="detailsErrorMessage"><strong>{{detailsErrorMessage}}</strong></p>
          </div>
          <div class="form-group col">
            <label for="">Address</label>
            <input type="text" class="form-control" name="zoneAddress" ngModel required #zoneAddress="ngModel">
            <span class="text-danger" *ngIf="!zoneAddress.valid && zoneAddress.touched">Please Enter A Valid Answer</span>
            <p class="text-danger m-0" *ngIf="addressErrorMessage"><strong>{{addressErrorMessage}}</strong></p>
        </div>
        <div class="form-group col">
          <label for="">Country</label>
          <select type="text" class="form-control" name="siteCountry" ngModel required #siteCountry="ngModel" (ngModelChange)="onChangeCountry($event)">
              <option  value="">Select Country</option>
              <option *ngFor="let Country of Countries" [value]="Country.id">{{Country.name}}</option>
          </select>
          <span class="text-danger" *ngIf="!siteCountry.valid && siteCountry.touched">Please Choose A Valid Answer</span>
          <p class="text-danger m-0" *ngIf="countryErrorMessage"><strong>{{countryErrorMessage}}</strong></p>
      </div>
      <div class="form-group col">
          <label for="">State</label>
          <select type="text" class="form-control" name="siteCity" ngModel required #siteCity="ngModel"  (ngModelChange)="onChangeCity($event)">
              <option  value="">Select State</option>
              <option *ngFor="let City of Cities" [value]="City.id">{{City.name}}</option>
          </select>
          <span class="text-danger" *ngIf="!siteCity.valid && siteCity.touched">Please Choose A Valid Answer</span>
          <p class="text-danger m-0" *ngIf="cityErrorMessage"><strong>{{cityErrorMessage}}</strong></p>
      </div>
      <div class="form-group col">
          <label for="">City</label>
          <select type="text" class="form-control" name="siteArea" ngModel required #siteArea="ngModel" >
              <option  value="">Select City</option>
              <option *ngFor="let Area of Areas" [value]="Area.id">{{Area.name}}</option>
          </select>
          <span class="text-danger" *ngIf="!siteArea.valid && siteArea.touched">Please Choose A Valid Answer</span>
          <p class="text-danger m-0" *ngIf="areaErrorMessage"><strong>{{areaErrorMessage}}</strong></p>
      </div>
  <div class="form-group col">
    <label for="">Zip Code</label>
    <input type="text" class="form-control" name="zoneZipCode" ngModel required #zoneZipCode="ngModel">
    <span class="text-danger" *ngIf="!zoneZipCode.valid && zoneZipCode.touched">Please Enter A Valid Answer</span>
    <p class="text-danger m-0" *ngIf="zipCodeErrorMessage"><strong>{{zipCodeErrorMessage}}</strong></p>
</div>

          </div>
          <div class="text-center">
            <button type="submit" [disabled]="!f.valid" class="btn submitBtn" (click)="addZone(f)"><i class="fas fa-plus"></i> Add</button>
      
            <button class="btn cancelBtn" (click)="modal.dismiss('Cross click')">Cancel</button>
            
          </div>

        </form>
      </div>
    </div>
    
    
  </div>
  
</ng-template>