import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/core/services/data.service';
import { RestApiService } from 'src/app/core/services/rest-api.service';
import { License } from 'src/app/core/models/license';
import { NgForm } from '@angular/forms';
import { Pagination } from 'src/app/core/models/pagination';


@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss']
})
export class LicenseComponent implements OnInit {

  @ViewChild('f') AddLicenseForm !: NgForm;
  @ViewChild('reset') resetPassForm !: NgForm;
  loading = true;
  fetchError = false;
  itemAdded = false;
  itemDeleted = false;
  dtOptions: any = {
    paging: false,
    lengthChange: false,
    info: false,
    searching: true,
  };
  Licenses: License[] = [];
  displayTable: boolean = false;
  successMessage = '';
  errorMessage = '';
  PaginationObj: Pagination = {
    total: 0,
    perPage: 0,
    currentPage: 0,
    firstPage: 0,
    lastPage: 0,
    nextPage: 0,
    previousPage: 0,
    pageChange: new EventEmitter<number>(),
  };

  constructor( private rest: RestApiService, private data: DataService, private modalService: NgbModal ) { }


  ngOnInit(): void {
    this.getLicenses(1);
  }

  openModal(content: any) {
    this.modalService.open(content,
       { centered: true, 
          size: 'md', 
          windowClass: 'addLicenseModal' 
        });
    
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  onPageChange(page: number): void {
    this.loading = true;
    this.displayTable = false;
    this.getLicenses(page);
  }

  async getLicenses(pager?:number){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/licenses?page=${pager}`
      ).subscribe( 
        (res: any) => {
          this.successMessage = res.message;
          this.fetchError = false;
          this.Licenses = res.data;
          this.PaginationObj = res.pagination;
          this.loading = false;
          this.displayTable = true;
        },
       (error: any ) => {
        this.fetchError = true;
          this.loading = false;
          this.errorMessage= error.error.message;
       });

    } catch (error) {
      this.data.error(error['message']);
      this.loading = true;
    }
  }

  async addLicense(f: NgForm){
    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/licenses`, 
       {
          name: f.value.licenseName,
          hasIssueDate: f.value.isLicenseIssueDate,
          hasExpiryDate: f.value.isLicenseExpiryDate,
          hasLicenseNumber: f.value.isLicenseNumber,

       }).subscribe( 
       (res: any) => {
         this.successMessage = res.message;
        //  this.getAdminUsers();
         this.fetchError = false;
         this.itemAdded = true;
         window.location.reload();
         f.reset();
         this.closeModal();
       } ,
       (error : any) => {
        this.fetchError = true;
        this.itemAdded = false;
        this.closeModal();
        this.errorMessage= error.error.message;
       });


   } catch (error) {
    console.log(error);
     this.data.error(error['message']);
     
   }
  }

}
