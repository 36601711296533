<app-loader [loading]="loading"></app-loader>
<div  class="container-fluid">
    <div *ngIf="displaySiteContent"  class="row">
      <div class="col-12">
        <div class="mb-3 alignContent-spaceBetween">
          <h2>Site Name: {{retrievedSite.company.name}}</h2>
          <div class="button_align">
            <!-- <button (click)="openShiftModal(addShift)" class="btn submitBtn"><i class="fas fa-plus" ></i> Add Shift</button> -->
            <button (click)="openSchedulerModal(addScheduler)" class="btn submitBtn"><i class="fas fa-plus" ></i> Add Position</button>
            <button (click)="onViewTemplate(siteId)" class="btn submitBtn blueBg"><i class="far fa-list-alt"></i> Template View</button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="displayContent"  class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="calendar_range">
              <form #fRange="ngForm">
                <div class="row section_style">
                    <div class="form-group col-12 col-md-3">
                        <label for="">Date From</label>
                        <input type="date" class="form-control" name="calendarBeginDate" ngModel  #calendarBeginDate="ngModel" >
                        
                    </div>
                    <div class="form-group col-12 col-md-3">
                        <label for="">Date To</label>
                        <input type="date" class="form-control" name="calendarEndDate" ngModel  #calendarEndDate="ngModel" >
                    </div>

                    <div class="form-group col-12 col-md-3 d-flex gap-2 align-items-end">
                      <button (click)="setRange(fRange)" class="btn submitBtn" ><i class="fas fa-plus"></i> Apply</button>
                      <button (click)="resetRange()" class="btn submitBtn" ><i class="fas fa-plus"></i> Reset</button>
                  </div>
                  
                </div>
      
                
            </form>
            <hr>
            </div>

            <div class="calendarShift">
              <form #f="ngForm">
                <div class="mb-3 alignContent-spaceBetween">
                  <h4>Add Shift</h4>
                  <div class="form-group">
                      <button [disabled]="!f.valid || selectedDays.length === 0" (click)="addShiftDetails(f)" class="btn submitBtn" ><i class="fas fa-plus"></i> Add Shift</button>
                  </div>
                </div>
                <div class="row section_style">
                  <div class="form-group col-12 col-sm-6 col-md-4 col-lg-2">
                    <label for="">Position</label>
                    <select  class="form-control" name="shiftPositionId" ngModel required #shiftPositionId="ngModel">
                      <option value="">Select Position</option>
                      <option *ngFor="let position of sitePositions" [value]="position.id">{{position.name}}</option>
                    </select>
                    <span class="text-danger" *ngIf="!shiftPositionId.valid && shiftPositionId.touched">Please Enter A Valid Answer</span>
                  </div>
                    <div class="form-group col-12 col-sm-6 col-md-4 col-lg-2">
                        <label for="">Break In Minutes</label>
                        <input type="number" class="form-control" name="shiftBreak" ngModel required #shiftBreak="ngModel" >
                        <span class="text-danger" *ngIf="!shiftBreak.valid && shiftBreak.touched">Please Enter A Valid Answer</span>
                        <p class="text-danger m-0" *ngIf="shiftBreakError"><strong>{{shiftBreakError}}</strong></p>
                    </div>
                    <div class="form-group col-12 col-sm-6 col-md-4 col-lg-2">
                        <label for="">Time From</label>
                        <input type="time" class="form-control" name="shiftTimeFrom" ngModel required #shiftTimeFrom="ngModel" >
                        <span class="text-danger" *ngIf="!shiftTimeFrom.valid && shiftTimeFrom.touched">Please Enter A Valid Answer</span>
                        <p class="text-danger m-0" *ngIf="timeFromError"><strong>{{timeFromError}}</strong></p>
                    </div>
                  <div class="form-group col-12 col-sm-6 col-md-4 col-lg-2">
                      <label for="">Time To</label>
                      <input type="time" class="form-control" name="shiftTimeTo" ngModel required #shiftTimeTo="ngModel" >
                      <span class="text-danger" *ngIf="!shiftTimeTo.valid && shiftTimeTo.touched">Please Enter A Valid Answer</span>
                      <p class="text-danger m-0" *ngIf="timeToError"><strong>{{timeToError}}</strong></p>
                  </div>
                  <div class="form-group col-12 col-sm-6 col-md-4 col-lg-2">
                      <label for="">Daily Shifts</label>
                      <input type="number" class="form-control" name="dailyShifts" ngModel required #dailyShifts="ngModel" >
      
                    <span class="text-danger" *ngIf="!dailyShifts.valid && dailyShifts.touched">Please Enter A Valid Answer</span>
                      <p class="text-danger m-0" *ngIf="dailyShiftsError"><strong>{{dailyShiftsError}}</strong></p>
                  </div>
                  
                  <div class="form-group col-12 col-sm-6 col-md-4 col-lg-2">
                    <label for="">Cycle Days</label>
                    <select  class="form-control" name="shiftCycleDays" ngModel required #shiftCycleDays="ngModel">
                      <option value="">Select Cylce</option>
                      <option value="7">7 Days</option>
                      <option value="14">14 Days</option>
                    </select>
                    <span class="text-danger" *ngIf="!shiftCycleDays.valid && shiftCycleDays.touched">Please Enter A Valid Answer</span>
                    <p class="text-danger m-0" *ngIf="cycleDaysError"><strong>{{cycleDaysError}}</strong></p>
                  </div>
      
                  <div class="form-group col-12">
                    <label for="">Days</label>
                    <div class="shiftDaysContainer">
                      <div class="checkboxContainer">
                        <input type="checkbox" id="allDays" name="allDays" (change)="toggleAllDays($event)"> 
                        <label for="allDays">All Days</label>
                      </div>
                      <div class="checkboxContainer" *ngFor="let day of days">
                        <input type="checkbox" id="{{day.id}}" name="{{day.id}}" (change)="toggleDay(day)"> 
                        <label for="{{day.id}}"> {{day.name}}</label>
                      </div>
                    </div>
                    
                    <!-- <span class="text-danger" *ngIf="!shiftDays.valid && shiftDays.touched">Please Enter A Valid Answer</span> -->
                      <p class="text-danger m-0" *ngIf="daysError"><strong>{{daysError}}</strong></p>
                  </div>
                </div>
      
            </form>
            <hr>
            </div>

            <div class="assignedEmps_container">
              <div class="mb-0 alignContent-spaceBetween">
                <h4>Assigned Employees on Site</h4>
              </div>
              <div class="assignedEmps">
                <div class="single_emp">
                  <div class="shortName">UN</div>
                  <div class="longName">Unassigned</div>
                  <div *ngIf="showAssignBtn" class="assignBtn_cont">
                    <button (click)="AssignEmployee(null)" class="btn assignEmp_btn">
                      <i class="fas fa-arrow-circle-right"></i>
                    </button>
                  </div>
                </div>
                <div class="single_emp" *ngFor="let emp of Employees">
                  <div class="shortName">{{emp.shortName}}</div>
                  <div class="longName">{{emp.firstName}} {{emp.lastName}}</div>
                  <div *ngIf="showAssignBtn" class="assignBtn_cont">
                    <button (click)="AssignEmployee(emp.id)" class="btn assignEmp_btn">
                      <i class="fas fa-arrow-circle-right"></i>
                    </button>
                  </div>
                </div>
              </div>
              <hr>
            </div>
            
            <div class="app-calendar">
              <!-- calendar -->
              <full-calendar [options]="calendarOptions"></full-calendar>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>




<ng-template #addScheduler role="document" let-modal >
  <div class="modal-header">
    <h5 class="modal-title mt-0">Add Position</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
      <form #fSchedule="ngForm">
          <div class="row section_style">
              <div class="form-group col-12 col-md-6">
                  <label for="">Position Name</label>
                  <input type="text" class="form-control" name="scheduleName" ngModel required #scheduleName="ngModel" >
                  <span class="text-danger" *ngIf="!scheduleName.valid && scheduleName.touched">Please Enter A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="ScheduleNameErrorMessage"><strong>{{ScheduleNameErrorMessage}}</strong></p>
              </div>
              <div class="form-group col-12 col-md-6">
                  <label for="">Begin Date</label>
                  <input type="date" class="form-control" name="scheduleBeginDate" ngModel required #scheduleBeginDate="ngModel" >
                  <span class="text-danger" *ngIf="!scheduleBeginDate.valid && scheduleBeginDate.touched">Please Enter A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="ScheduleBeginErrorMessage"><strong>{{ScheduleBeginErrorMessage}}</strong></p>
              </div>
            <div class="form-group col-12 col-md-6">
                <label for="">End Date</label>
                <input type="date" class="form-control" name="scheduleEndDate" ngModel #scheduleEndDate="ngModel" >
                <p class="text-danger m-0" *ngIf="ScheduleEndErrorMessage"><strong>{{ScheduleEndErrorMessage}}</strong></p>
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="">Break Rules</label>
                <select class="form-control" name="scheduleBreakRule" ngModel required #scheduleBreakRule="ngModel">
                  <option  value="">Select Rule</option>
                  <option *ngFor="let rule of breakRules" [value]="rule.id">{{rule.name}}</option>
              </select>
              <span class="text-danger" *ngIf="!scheduleBreakRule.valid && scheduleBreakRule.touched">Please Enter A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="ScheduleBreakRuleErrorMessage"><strong>{{ScheduleBreakRuleErrorMessage}}</strong></p>
            </div>
          </div>

          <div class="row">
              <div class="form-group col-12 text-center">
                  <button [disabled]="!fSchedule.valid" (click)="addSchedule(fSchedule)" class="btn submitBtn" ><i class="fas fa-plus"></i> Add Position</button>
              </div>
          </div>
          
      </form>
    
    
  </div>
  
</ng-template>


<!-- Add Shift modal -->
<ng-template #addShift role="document" let-modal >
  <div class="modal-header">
    <h5 class="modal-title mt-0">Add Shift</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
      <form #f="ngForm">
          <div class="row section_style">
            <div class="form-group col-12 col-md-6">
              <label for="">Position</label>
              <select  class="form-control" name="shiftPositionId" ngModel required #shiftPositionId="ngModel">
                <option value="">Select Position</option>
                <option *ngFor="let position of sitePositions" [value]="position.id">{{position.name}}</option>
              </select>
              <span class="text-danger" *ngIf="!shiftPositionId.valid && shiftPositionId.touched">Please Enter A Valid Answer</span>
            </div>
              <div class="form-group col-12 col-md-6">
                  <label for="">Break In Minutes</label>
                  <input type="number" class="form-control" name="shiftBreak" ngModel required #shiftBreak="ngModel" >
                  <span class="text-danger" *ngIf="!shiftBreak.valid && shiftBreak.touched">Please Enter A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="shiftBreakError"><strong>{{shiftBreakError}}</strong></p>
              </div>
              <div class="form-group col-12 col-md-6">
                  <label for="">Time From</label>
                  <input type="time" class="form-control" name="shiftTimeFrom" ngModel required #shiftTimeFrom="ngModel" >
                  <span class="text-danger" *ngIf="!shiftTimeFrom.valid && shiftTimeFrom.touched">Please Enter A Valid Answer</span>
                  <p class="text-danger m-0" *ngIf="timeFromError"><strong>{{timeFromError}}</strong></p>
              </div>
            <div class="form-group col-12 col-md-6">
                <label for="">Time To</label>
                <input type="time" class="form-control" name="shiftTimeTo" ngModel required #shiftTimeTo="ngModel" >
                <span class="text-danger" *ngIf="!shiftTimeTo.valid && shiftTimeTo.touched">Please Enter A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="timeToError"><strong>{{timeToError}}</strong></p>
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="">Daily Shifts</label>
                <input type="number" class="form-control" name="dailyShifts" ngModel required #dailyShifts="ngModel" >

              <span class="text-danger" *ngIf="!dailyShifts.valid && dailyShifts.touched">Please Enter A Valid Answer</span>
                <p class="text-danger m-0" *ngIf="dailyShiftsError"><strong>{{dailyShiftsError}}</strong></p>
            </div>
            
            <div class="form-group col-12 col-md-6">
              <label for="">Cycle Days</label>
              <select  class="form-control" name="shiftCycleDays" ngModel required #shiftCycleDays="ngModel">
                <option value="">Select Cylce</option>
                <option value="7">7 Days</option>
                <option value="14">14 Days</option>
              </select>
              <span class="text-danger" *ngIf="!shiftCycleDays.valid && shiftCycleDays.touched">Please Enter A Valid Answer</span>
              <p class="text-danger m-0" *ngIf="cycleDaysError"><strong>{{cycleDaysError}}</strong></p>
            </div>

            <div class="form-group col-12">
              <label for="">Days</label>
              <div class="shiftDaysContainer">
                <div class="checkboxContainer" *ngFor="let day of days">
                  <input type="checkbox" id="{{day.id}}" name="{{day.id}}" (change)="toggleDay(day)"> 
                  <label for="{{day.id}}"> {{day.name}}</label>
                </div>
              </div>
              
              <!-- <span class="text-danger" *ngIf="!shiftDays.valid && shiftDays.touched">Please Enter A Valid Answer</span> -->
                <p class="text-danger m-0" *ngIf="daysError"><strong>{{daysError}}</strong></p>
            </div>
          </div>

          <div class="row">
              <div class="form-group col-12 text-center">
                  <button [disabled]="!f.valid || selectedDays.length === 0" (click)="addShiftDetails(f)" class="btn submitBtn" ><i class="fas fa-plus"></i> Add Shift</button>
              </div>
          </div>
          
      </form>
  </div>
  
</ng-template>

<!-- Add Employee MODAL -->
<!-- <ng-template #modalShow let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Assign Employee</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="closeShiftModal()"></button>
  </div>
  <div class="modal-body">
      <form #f="ngForm">
          <div class="row section_style">
            <div class="form-group col-12 col-md-6">
              <label for="">Position</label>
              <h4>{{newEventDate.resource._resource.title}}</h4>
          </div>
              <div class="form-group col-12 col-md-6">
                  <label for="">Day</label>
                  <h4>{{newEventDate.date | date:'E, MMM dd yyyy' }}</h4>
                  <p class="text-danger m-0" *ngIf="daysMessageError"><strong>{{daysMessageError}}</strong></p>
              </div>
              
              <div *ngIf="showShiftMessage" class="col-12">
                <div class="shift_message">
                  <p class="text-center mb-0">This Position Has no Shift Added</p>
                  <div  class="text-center mt-2">
                    <button (click)="openShiftModal(addShift)" class="btn submitBtn"><i class="fas fa-plus" ></i> Add Shift</button>
                  </div>
                </div>
              </div>
            <div class="form-group col-12 col-md-6">
                <label for="">Time From (Optional)</label>
                <input type="time" class="form-control" name="empTimeFrom" ngModel #shiftTimeTo="ngModel" >
                <p class="text-danger m-0" *ngIf="timeFromError"><strong>{{timeFromError}}</strong></p>

            </div>

            <div class="form-group col-12 col-md-6">
              <label for="">Time To (Optional)</label>
              <input type="time" class="form-control" name="empTimeTo" ngModel #shiftTimeTo="ngModel" >
              <p class="text-danger m-0" *ngIf="timeToError"><strong>{{timeToError}}</strong></p> 
          </div>
          <div class="form-group col-12 col-md-6">
            <label for="">Employee</label>
            <select  class="form-control" name="shiftEmpId" ngModel required #shiftEmpId="ngModel">
              <option value="">Select Employee</option>
              <option *ngFor="let emp of Employees" [value]="emp.id">{{emp.firstName}} {{emp.lastName}}</option>
            </select>
            <span class="text-danger" *ngIf="!shiftEmpId.valid && shiftEmpId.touched">Please Enter A Valid Answer</span>
            <p class="text-danger m-0" *ngIf="employeeIDError"><strong>{{employeeIDError}}</strong></p>
        </div>

          </div>

          <div class="row">
              <div class="form-group col-12 text-center">
                  <button [disabled]="!f.valid" (click)="AssignEmployee(f)" class="btn submitBtn" ><i class="fas fa-save"></i> Save Employee</button>
              </div>
          </div>
          
      </form>
  </div>
</ng-template> -->
<!-- end modal-->

<!-- Edit Employee MODAL -->
<!-- <ng-template #editEmployee let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Edit Employee</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="closeShiftModal()"></button>
  </div>
  <div class="modal-body">
      <form #f="ngForm">
          <div class="row section_style">
            <div class="form-group col-12 col-md-6">
              <label for="">Position</label>
              <h4>{{editedEventData.event._def.extendedProps.positionName}}</h4>
          </div>
              <div class="form-group col-12 col-md-6">
                  <label for="">Day</label>
                  <h4>{{editedEventData.event.start | date:'E, MMM dd yyyy' }}</h4>
                  <p class="text-danger m-0" *ngIf="daysMessageError"><strong>{{daysMessageError}}</strong></p>
              </div>

            <div class="form-group col-12 col-md-6">
                <label for="">Time From (Optional)</label>
                <input type="time" class="form-control" name="empTimeFrom"  [(ngModel)]="editedTimeFrom" ngModel #shiftTimeTo="ngModel" >
                <p class="text-danger m-0" *ngIf="editedTimeFromError"><strong>{{editedTimeFromError}}</strong></p>

            </div>

            <div class="form-group col-12 col-md-6">
              <label for="">Time To (Optional)</label>
              <input type="time" class="form-control" name="empTimeTo"  [(ngModel)]="editedTimeTo" ngModel #shiftTimeTo="ngModel" >
              <p class="text-danger m-0" *ngIf="editedTimeToError"><strong>{{editedTimeToError}}</strong></p> 
          </div>
          
          <div class="form-group col-12 col-md-6">
            <label for="">Employee</label>
            <select  class="form-control" name="shiftEmpId" [(ngModel)]="editedEmpId" ngModel required #shiftEmpId="ngModel">
              <option value="">Select Employee</option>
              <option *ngFor="let emp of Employees" [value]="emp.id">{{emp.firstName}} {{emp.lastName}}</option>
            </select>
            <span class="text-danger" *ngIf="!shiftEmpId.valid && shiftEmpId.touched">Please Enter A Valid Answer</span>
            <p class="text-danger m-0" *ngIf="employeeIDError"><strong>{{employeeIDError}}</strong></p>
        </div>

          </div>

          <div class="row">
              <div class="form-group col-12 text-center">
                  <button [disabled]="!f.valid" (click)="EditEmployee(f)" class="btn submitBtn" ><i class="fas fa-plus"></i> Assign Employee</button>
              </div>
          </div>
          
      </form>
  </div>
</ng-template> -->
<!-- end modal-->