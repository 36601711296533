<div class="container-fluid">
  <div class="row">
    <div *ngIf="showContent" class="col-12 mb-3 alignContent-spaceBetween">
      <h2>Employee: {{employee.firstName}} {{employee.lastName}}</h2>
    </div>

    <div class="col-12" *ngIf="fetchError">
      <ngb-alert type="danger" [dismissible]="false" class="text-center">
        Something Went Wrong
      </ngb-alert>
    </div>

    <app-loader [loading]="loading"></app-loader>


    <div *ngIf="showContent">
      <ul class="nav nav-pills tabs_border" id="myTab" role="tablist">

        <li class="nav-item" role="presentation">
          <button (click)="showDetails()" class="nav-link active" id="pills-details-tab" data-bs-toggle="pill"
            data-bs-target="#details_tab" type="button" role="tab" aria-controls="pills-contracts-tab"
            aria-selected="false">Overview</button>
        </li>

        <li class="nav-item" role="presentation">
          <button (click)="showSites()" class="nav-link" id="pills-sites-tab" data-bs-toggle="pill"
            data-bs-target="#sites_tab" type="button" role="tab" aria-controls="pills-sites-tab"
            aria-selected="false">Sites</button>
        </li>

        <li class="nav-item" role="presentation">
          <button (click)="showSchedules()" class="nav-link" id="pills-schedules-tab" data-bs-toggle="pill"
            data-bs-target="#schedules_tab" type="button" role="tab" aria-controls="pills-schedules-tab"
            aria-selected="false">Schedules</button>
        </li>

        <li class="nav-item" role="presentation">
          <button (click)="showSchedulers()" class="nav-link " id="pills-schedule-tab" data-bs-toggle="pill"
            data-bs-target="#schedule_tab" type="button" role="tab" aria-controls="pills-schedule-tab"
            aria-selected="false">Calendar View</button>
        </li>

        <li class="nav-item" role="presentation">
          <button (click)="showTracks()" class="nav-link" id="pills-tracks-tab" data-bs-toggle="pill"
            data-bs-target="#tracks_tab" type="button" role="tab" aria-controls="pills-tracks-tab"
            aria-selected="false">Tracks</button>
        </li>

        <li class="nav-item" role="presentation">
          <button (click)="showBanned()" class="nav-link" id="pills-banned-tab" data-bs-toggle="pill"
            data-bs-target="#banned_tab" type="button" role="tab" aria-controls="pills-banned-tab"
            aria-selected="false">Banned Sites</button>
        </li>

        <li class="nav-item" role="presentation">
          <button (click)="showAvailability()" class="nav-link" id="pills-available-tab" data-bs-toggle="pill"
            data-bs-target="#available_tab" type="button" role="tab" aria-controls="pills-available-tab"
            aria-selected="false">Availability</button>
        </li>

        <li class="nav-item" role="presentation">
          <button (click)="showLicenses()" class="nav-link" id="pills-licenses-tab" data-bs-toggle="pill"
            data-bs-target="#licenses_tab" type="button" role="tab" aria-controls="pills-licenses-tab"
            aria-selected="false">Certificates</button>
        </li>

        <li class="nav-item" role="presentation">
          <button (click)="showEntitlement()" class="nav-link" id="pills-entitlement-tab" data-bs-toggle="pill"
            data-bs-target="#entitlement_tab" type="button" role="tab" aria-controls="pills-entitlement-tab"
            aria-selected="false">Entitlement</button>
        </li>

        <li class="nav-item" role="presentation">
          <button (click)="showTimeOff()" class="nav-link" id="pills-time-tab" data-bs-toggle="pill"
            data-bs-target="#time_tab" type="button" role="tab" aria-controls="pills-time-tab"
            aria-selected="false">Time Off</button>
        </li>

        

        

      </ul>
      <div class="tab-content" id="myTabContent">

        <div *ngIf="showEmpDetails" class="tab-pane fade active show" id="details_tab" role="tabpanel"
          aria-labelledby="home-tab" tabindex="0">
          <div class="modal-body">

            <div class="row">
              <div class="col-12 col-md-3 col-lg-2">
                <div class="singleDetail text-center">
                  <img class="companyImg" (error)="setDefaultImage()" src="{{imageUrl}}" width="100%" alt="">
                </div>

                <div class="employee_actions">
                  <button class="btn submitBtn" (click)="startCamera()">Snap A Picture</button>
                  <button class="btn submitBtn" (click)="openModal(snaPicture)">Upload A Picture</button>
                  <button class="btn submitBtn" (click)="openModal(changePass)">Change Password</button>
                  <button class="btn submitBtn" (click)="openModal(forceChangePass)">Force Change Password</button>
                </div>
              </div>


              <div class="col-12 col-md-9 col-lg-10">
                <div class="row">
                  <div class="col-12">
                    <!-- <button *ngIf="!showImg" (click)="startCamera()">Snap</button> -->
                    <div *ngIf="showImg" class="form-group">
                      <h3><strong>Snap A Profile Picture</strong></h3>
                      <div class="align_elements_vertically">
                        <div>

                          <video *ngIf="!capturedImage" #videoElement width="240" height="200" autoplay></video>
                          <div class="align_elements" *ngIf="!capturedImage">
                            <button class="btn submitBtn" (click)="capture()">Capture</button>
                            <button class="btn submitBtn" (click)="clearPicture()">Cancel</button>
                          </div>
                        </div>
                        <div>

                          <img *ngIf="capturedImage" width="240" height="240" [src]="capturedImage"
                            alt="Captured Image">
                        </div>
                        <div class="align_elements" *ngIf="capturedImage">
                          <button class="btn submitBtn" (click)="uploadPicture()">Upload</button>
                          <button class="btn submitBtn" (click)="clearPicture()">Cancel</button>

                        </div>
                        <canvas #canvasElement width="240" height="240" style="display: none;"></canvas>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row row-cols-lg-3">

                  <div class="singleDetail">
                    <span>Name</span>
                    <p>{{employee.firstName}} {{employee.lastName}}</p>
                  </div>
                  <div class="singleDetail">
                    <span>Status</span>
                    <p *ngIf="employee.isActive == true" class=""><i class="fas fa-check-square trueCheck"></i> Active
                    </p>
                    <p *ngIf="employee.isActive == false" class=""><i class="fas fa-times-circle falseCheck"></i> Not
                      Active</p>
                  </div>
                  <div class="singleDetail">
                    <span>Hiring Status</span>
                    <p *ngIf="employee.hiringStatus == 'HIRED'" class=""><i class="fas fa-check-circle trueCheck"></i>
                      Hired</p>
                    <p *ngIf="employee.hiringStatus == 'NOT_HIRED'" class=""><i
                        class="fas fa-times-circle falseCheck"></i> Not Hired</p>
                    <p *ngIf="employee.hiringStatus == 'PENDING_HIRING'" class=""><i
                        class="fas fa-minus-circle warningCheck"></i> Pending Hiring</p>
                  </div>

                  <div class="singleDetail">
                    <span>Internal ID</span>
                    <p>#{{employee.internalId}}</p>
                  </div>
                  <div class="singleDetail">
                    <span>Mobile</span>
                    <p>{{employee.mobile}}</p>
                  </div>
                  <div class="singleDetail">
                    <span>Username</span>
                    <p>{{employee.username}}</p>
                  </div>
                  <div class="singleDetail">
                    <span>Email</span>
                    <p>{{employee.email}}</p>
                  </div>
                  <div class="singleDetail">
                    <span>Gender</span>
                    <p>{{employee.gender}}</p>
                  </div>
                  <div class="singleDetail">
                    <span>Role</span>
                    <p>{{employee.role.name}}</p>
                  </div>
                  <div class="singleDetail">
                    <span>Zone</span>
                    <p>{{employee.zone?.name}}</p>
                  </div>
                  <div class="singleDetail">
                    <span>Birth Date</span>
                    <p>{{employee.birthdate}}</p>
                  </div>
                  <div class="singleDetail">
                    <span>Employment Date</span>
                    <p>{{employee.employmentDate}}</p>
                  </div>
                  <div class="singleDetail">
                    <span>Pay Rate</span>
                    <p>{{employee.payrate}}</p>
                  </div>
                  <div class="singleDetail">
                    <span>Pay Type</span>
                    <p>{{employee.payType}}</p>
                  </div>
                  <div class="singleDetail">
                    <span>Address</span>
                    <div *ngIf="!employee.address">
                      <p>No Address Added</p>
                    </div>
                    <div
                      *ngIf="employee.address && employee.address.area !== null && employee.address.city !== null && employee.address.country !== null">
                      <p>{{employee.address.address}}, {{employee.address.area.name}}, {{employee.address.city.name}},
                        {{employee.address.country.name}}.</p>
                    </div>
                  </div>

                  <div class="singleDetail">
                    <span>Zip Code</span>

                    <div *ngIf="employee.address.zipcode === null">
                      <p>No Zip Code Added</p>
                    </div>
                    <div *ngIf="employee.address.zipcode !== null">
                      <p>{{employee.address.zipcode}}</p>
                    </div>
                  </div>


                </div>

                <div class="row row-cols-lg-3" *ngIf="employee.termination">
                  <div class="singleDetail">
                    <span>Termination Reason</span>
                    <p class="terminate">{{employee.termination.reason}}</p>
                  </div>

                  <div class="singleDetail">
                    <span>Is Rehirable?</span>
                    <p *ngIf="employee.termination.is_rehirable == true"><i class="fas fa-check-square trueCheck"></i>
                      Rehirable</p>
                    <p *ngIf="employee.termination.is_rehirable == false"><i class="fas fa-times-circle falseCheck"></i>
                      Not Rehirable</p>
                  </div>
                </div>
              </div>
            </div>



            <div class="row">
              <div class="col-12">
                <hr>
              </div>
              <div class="col-12">
                <h4>Licenses</h4>
                <div class="table-responsive">
                  <table id="datatable" class="datatable table align-middle table-nowrap table-hover">
                    <thead class="table-light">
                      <tr>

                        <th scope="col">License Name</th>
                        <th scope="col">Issue Date</th>
                        <th scope="col">Expiry Date</th>
                        <th scope="col">License Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let license of employee.licenses">

                        <td>{{license.name}}</td>
                        <td>{{license.issueDate}}</td>
                        <td>{{license.expirationDate}}</td>
                        <td>{{license.licenseNumber}}</td>

                      </tr>
                    </tbody>
                  </table>
                  <div *ngIf="employee.licenses.length === 0">
                    <p class="text-center"><strong>No Licenses Added</strong> </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <hr>
              </div>

              <div class="col-12">
                <h4>Uniforms</h4>

                <div class="table-responsive">
                  <table id="datatable2" class="datatable table align-middle table-nowrap table-hover">
                    <thead class="table-light">
                      <tr>

                        <th scope="col">Uniform Name</th>
                        <th scope="col">Receiving Date</th>
                        <th scope="col">Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let uniform of employee.uniforms; let i = index">

                        <td>{{uniform.name}}</td>
                        <td>{{uniform.receivingDate}}</td>
                        <td>{{uniform.quantity}}</td>

                      </tr>
                    </tbody>
                  </table>
                  <div *ngIf="employee.uniforms.length === 0">
                    <p class="text-center"><strong>No Uniforms Added</strong> </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="showScheduleContent" class="" id="schedule_tab" role="tabpanel" aria-labelledby="profile-tab"
          tabindex="0">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <!-- <div class="text-center">
                            <button class="btn submitBtn" *ngIf="loading_schedule" (click)="setOptions()">Load Schedule</button>
                          </div> -->

                  <div *ngIf="displayScheduleContent" class="app-calendar">

                    <full-calendar [options]="calendarOptions"></full-calendar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="showBannedContent" class="" id="banned_tab" role="tabpanel" aria-labelledby="banned-tab"
          tabindex="0">
          <div class="row">
            <div class="col-12">
              <div class="mb-3 alignContent-spaceBetween">
                <h2>Banned Sites</h2>
                <div class="d-flex gap-2">
                  <button (click)="openModal(banEmployee)" class="btn submitBtn">Ban Site</button>
                </div>
              </div>
              <!-- <ng-template #notEmptyContracts> -->
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="datatable table align-middle table-nowrap table-hover">
                      <thead class="table-light">
                        <tr>
                          <th scope="col">Site Name</th>
                          <th scope="col">Effective Date</th>
                          <th scope="col">Unban</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let site of BannedSites">

                          <td>{{site.company.name}}</td>
                          <td>{{site.effectiveDate | date:"MM-dd-yyyy"}}</td>
                          <td>
                            <button class="btn importBtn" (click)="unBan(site.id)">Unban</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="showAvailabilityContent" class="" id="available_tab" role="tabpanel" aria-labelledby="available-tab"
          tabindex="0">
          <div class="row">
            <div class="col-12">

              <form #f="ngForm">
                <div class="mb-3 alignContent-spaceBetween">
                  <h2>Availability</h2>
                  <div class="d-flex gap-2">
                    <button [disabled]="!f.valid || selectedDays.length === 0" class="btn submitBtn"
                      (click)="addSlot(f)">Add Slot</button>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12 col-sm-4 col-md-4 col-lg-4">
                    <label for="">Time From</label>
                    <input type="time" class="form-control" name="slotTimeFrom" ngModel required
                      #slotTimeFrom="ngModel">
                    <span class="text-danger" *ngIf="!slotTimeFrom.valid && slotTimeFrom.touched">Please Enter A Valid
                      Answer</span>
                    <p class="text-danger m-0" *ngIf="timeFromError"><strong>{{timeFromError}}</strong></p>
                  </div>
                  <div class="form-group col-12 col-sm-4 col-md-4 col-lg-4">
                    <label for="">Time To</label>
                    <input type="time" class="form-control" name="slotTimeTo" ngModel required #slotTimeTo="ngModel">
                    <span class="text-danger" *ngIf="!slotTimeTo.valid && slotTimeTo.touched">Please Enter A Valid
                      Answer</span>
                    <p class="text-danger m-0" *ngIf="timeToError"><strong>{{timeToError}}</strong></p>
                  </div>
                  <div class="form-group col-12 col-sm-4 col-md-4 col-lg-4">
                    <label for="">Availability</label>

                    <select class="form-control" name="avalability_status" ngModel required
                      #avalability_status="ngModel">
                      <option value="">Select Availability</option>
                      <option value="MAYBE">Maybe</option>
                      <option value="NOT_AVAILABLE">Not Available</option>
                    </select>
                    <span class="text-danger" *ngIf="!avalability_status.valid && avalability_status.touched">Please
                      Enter A Valid Answer</span>
                    <p class="text-danger m-0" *ngIf="avalability_statusMessage">
                      <strong>{{avalability_statusMessage}}</strong>
                    </p>
                  </div>
                  <div class="form-group col-12">
                    <label for="">Days</label>
                    <div class="shiftDaysContainer">
                      <div class="checkboxContainer">
                        <input type="checkbox" id="allDays" name="allDays" (change)="toggleAllDays($event)">
                        <label for="allDays">All Days</label>
                      </div>
                      <div class="checkboxContainer" *ngFor="let day of days">
                        <input type="checkbox" id="{{day.id}}" name="{{day.id}}" (change)="toggleDay(day)">
                        <label for="{{day.id}}"> {{day.name}}</label>
                      </div>
                    </div>

                    <!-- <p class="text-danger m-0" *ngIf="daysError"><strong>{{daysError}}</strong></p> -->
                  </div>
                </div>
              </form>
              <!-- <ng-template #notEmptyContracts> -->
              <div class="card">
                <div class="card-body">
                  <div class="app_template table-responsive">
                    <table class="table table-bordered">
                      <thead>
                        <tr>

                          <th scope="col" *ngFor="let day of days">{{ day.name }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td *ngFor="let day of days">
                            <table class="table table-striped">
                              <tbody>
                                <tr *ngFor="let slot of availability">
                                  <td *ngIf="slot.day === day.id">
                                    <ng-container>
                                      <div class="template_slot" [ngStyle]="getSlotStyle(slot.availability)">
                                        <p class="template_time">{{ slot.timeFrom }} - {{ slot.timeTo }}</p>
                                        <p *ngIf="slot.availability === 'MAYBE'" class="template_employee">Maybe</p>
                                        <p *ngIf="slot.availability === 'NOT_AVAILABLE'" class="template_employee">Not
                                          Available</p>
                                        <button class="btn remove_slot" (click)="deleteSlot(slot.id)"><i
                                            class="fas fa-times-circle"></i></button>
                                      </div>
                                    </ng-container>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <!-- <ng-container *ngFor="let slot of availability">
                                            <ng-container *ngIf="slot.day === day.id">
                                              <div class="template_slot">
                                                <p class="template_time">{{ slot.timeFrom }} - {{ slot.timeTo }}</p>
                                                <p class="template_employee">{{ slot.availability }}</p>
                                              </div>
                                            </ng-container>
                                          </ng-container> -->
                          </td>

                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="showLicensesContent" class="" id="licenses_tab" role="tabpanel" aria-labelledby="licenses-tab"
          tabindex="0">
          <div class="row">
            <div class="col-12">
              <h4>Licenses</h4>
              <div class="table-responsive">
                <table id="datatable" class="datatable table align-middle table-nowrap table-hover">
                  <thead class="table-light">
                    <tr>

                      <th scope="col">License Name</th>
                      <th scope="col">Issue Date</th>
                      <th scope="col">Expiry Date</th>
                      <th scope="col">License Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let license of employee.licenses">

                      <td>{{license.name}}</td>
                      <td>{{license.issueDate}}</td>
                      <td>{{license.expirationDate}}</td>
                      <td>{{license.licenseNumber}}</td>

                    </tr>
                  </tbody>
                </table>
                <div *ngIf="employee.licenses.length === 0">
                  <p class="text-center"><strong>No Licenses Added</strong> </p>
                </div>
              </div>
            </div>

            <div class="col-12">
              <hr>
            </div>

            <div class="col-12">
              <h4>Uniforms</h4>

              <div class="table-responsive">
                <table id="datatable2" class="datatable table align-middle table-nowrap table-hover">
                  <thead class="table-light">
                    <tr>

                      <th scope="col">Uniform Name</th>
                      <th scope="col">Receiving Date</th>
                      <th scope="col">Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let uniform of employee.uniforms; let i = index">

                      <td>{{uniform.name}}</td>
                      <td>{{uniform.receivingDate}}</td>
                      <td>{{uniform.quantity}}</td>

                    </tr>
                  </tbody>
                </table>
                <div *ngIf="employee.uniforms.length === 0">
                  <p class="text-center"><strong>No Uniforms Added</strong> </p>
                </div>
              </div>
            </div>


          </div>
        </div>

        <div *ngIf="showEntitlementContent" class="" id="entitlement_tab" role="tabpanel" aria-labelledby="entitlement-tab"
          tabindex="0">
          <div class="row">
            <div class="col-12">
              <div class="mb-3 alignContent-spaceBetween">
                <h2>Entitlements</h2>
                <div class="d-flex gap-2">
                  <button (click)="openModal(addEntitlement)" class="btn submitBtn">Add Entitlement</button>
                </div>
              </div>
              <div class="table-responsive" *ngIf="entitlement_table">
                <table id="datatable" class="datatable table align-middle table-nowrap table-hover">
                  <thead class="table-light">
                    <tr>

                      <th scope="col">Entitlement Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let e of entitlements">

                      <td>{{e.entitlement}}</td>

                    </tr>
                  </tbody>
                </table>
                <div *ngIf="entitlements.length === 0">
                  <p class="text-center"><strong>No Entitlements Added</strong> </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="showTracksContent" class="" id="tracks_tab" role="tabpanel" aria-labelledby="tracks-tab"
          tabindex="0">
          <div class="row">
            <div class="mb-3 alignContent-spaceBetween">
              <h2>Tracks</h2>
            </div>

            <div class="form-group col-12 col-md-5">
              <div #mapElement style="height: 600px; width: 100%"></div>
            </div>

            <div class="form-group col-12 col-md-7">
              <h2>Employee Shifts</h2>
              <div class="table-responsive">
                <table id="datatable"  datatable [dtOptions]="dtOptions" class="datatable table align-middle table-nowrap table-hover">
                  <thead class="table-light">
                    <tr>

                      <th scope="col">View</th>
                      <th scope="col">Clock In</th>
                      <th scope="col">Clock Out</th>
                      <th scope="col">Working Hours</th>
                      <th scope="col">Location</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let shift of emp_shifts">
                      <td>
                        <button class="btn" (click)="set_location(shift?.lng,shift?.lat)"><i class="fas fa-eye"></i> </button>
                      </td>
                      <td>{{shift.clockIn | date: 'short'}}</td>
                      <td>{{shift.clockOut | date: 'short'}}</td>
                      <td>{{shift.workingHours}}</td>
                      <td>{{shift.location}}</td>

                    </tr>
                  </tbody>
                </table>
                
              </div>
            </div>

          </div>
        </div>

        <div *ngIf="showSitesContent" class="" id="sites_tab" role="tabpanel" aria-labelledby="sites-tab"
          tabindex="0">
          <div class="row">
            <div class="col-12">
              <div class="mb-3 alignContent-spaceBetween">
                <h2>Assigned Sites</h2>
              </div>
              <div class="table-responsive">
                <table id="datatable" datatable class="datatable table align-middle table-nowrap table-hover">
                  <thead class="table-light">
                    <tr>
                      <th scope="col">Site Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let s of EmpSites"> 

                      <td>{{s.company.name}}</td>

                    </tr>
                  </tbody>
                </table>
                <div *ngIf="EmpSites.length === 0">
                  <p class="text-center"><strong>No Sites Assigned</strong> </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="showSchedulesContent" class="" id="schedule_tab" role="tabpanel" aria-labelledby="schedule-tab"
          tabindex="0">
          <div class="row">
            <div class="col-12">
              <div class="mb-3 alignContent-spaceBetween">
                <h2>Schedules</h2>
              </div>
              <div class="table-responsive" >
                <table id="datatable"  datatable [dtOptions]="dtOptions" class="datatable table align-middle table-nowrap table-hover">
                  <thead class="table-light">
                    <tr>

                      <th scope="col">Clock In</th>
                      <th scope="col">Clock Out</th>
                      <th scope="col">Working Hours</th>
                      <th scope="col">Location</th>
                      <th scope="col">Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let shift of emp_shifts">
                      <td>{{shift.clockIn | date: 'short'}}</td>
                      <td>{{shift.clockOut | date: 'short'}}</td>
                      <td>{{shift.workingHours}}</td>
                      <td>{{shift.location}}</td>
                      <td>
                        <button class="btn submitBtn" (click)="openModal_lg(shiftNotes);loadShift(shift)">Check Notes</button>
                      </td>

                    </tr>
                  </tbody>
                </table>
                <div *ngIf="getEmployeeSites.length === 0">
                  <p class="text-center"><strong>No Shifts Added</strong> </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="showTimeOffContent" class="" id="time_tab" role="tabpanel" aria-labelledby="time-tab"
          tabindex="0">
          <div class="row">
            <div class="col-12">
              <div class="mb-3 alignContent-spaceBetween">
                <h2>Time Off</h2>
                <div class="d-flex gap-2">
                  <button (click)="openModal(add_timeOff)" class="btn submitBtn">Add Time Off</button>
                </div>
              </div>
              <div class="table-responsive">
                <table id="datatable" datatable [dtOptions]="dtOptions" class="datatable table align-middle table-nowrap table-hover">
                  <thead class="table-light">
                    <tr>
                      <th scope="col">Status</th>
                      <th scope="col">From</th>
                      <th scope="col">To</th>
                      <th scope="col">Reason</th>
                      <!-- <th scope="col">Type</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let t of time_offs"> 

                      <td>{{t.status}}</td>
                      <td>{{t.dateFrom | date: 'medium'}}</td>
                      <td>{{t.dateTo | date: 'medium'}}</td>
                      <td>{{t.reason}}</td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>


<!-- Ban Employee Modal -->
<ng-template #banEmployee role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Ban Site</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <form #f="ngForm">
    <div class="modal-body">

      <div class="form-group text-center">
        <select class="form-select" ngModel required #empSitesList="ngModel" name="empSitesList" id="empSitesList">
          <option value="">Select Site</option>
          <option *ngFor="let site of EmpSites" [value]="site.id">{{site.company.name}}</option>
        </select>
      </div>

      <div class="checkboxContainer">
        <input type="checkbox" id="effectiveCheck" name="effectiveCheck" [(ngModel)]="effectiveDateCheck" ngModel
          #effectiveCheck="ngModel">
        <label for="effectiveCheck">Effective Immediately</label>
      </div>

      <div *ngIf="!effectiveDateCheck" class="form-group mt-2">
        <label for="">Effective Date From</label>
        <input type="date" class="form-control" id="effectiveDate" name="effectiveDate" ngModel
          #effectiveDate="ngModel">
      </div>

      <div class="form-group text-center">
        <!-- <button class="btn remove_Btn" (click)="ban()" >Ban</button> -->
        <button class="btn remove_Btn" (click)="ban(f)">Ban</button>
      </div>

    </div>
  </form>

</ng-template>

<!-- Change Password Modal -->
<ng-template #changePass role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Change Password</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <form #f="ngForm">
    <div class="modal-body">

      <div class="form-group">
        <label for="">Create a Password</label>
        <input type="password" class="form-control" name="userPassword" [(ngModel)]="formPassword" required
          #userPassword="ngModel" (ngModelChange)="onChangePassword()">
        <span class="text-danger" *ngIf="!userPassword.valid && userPassword.touched">Please Enter A Valid Answer</span>
        <p class="text-danger m-0" *ngIf="passwordErrorMessage"><strong>{{passwordErrorMessage}}</strong></p>
      </div>
      <div class="form-group">
        <label for="">Confirm Password</label>
        <input type="password" class="form-control" name="confirmUserPassword" [(ngModel)]="formConfirmPassword"
          required #confirmUserPassword="ngModel" (ngModelChange)="onChangeConfirmPassword()">
        <span class="text-danger"
          *ngIf="!confirmUserPassword.valid && confirmUserPassword.touched || showMatchMessage">Passwords don't
          match</span>
      </div>

      <div class="form-group text-center">
        <button class="btn submitBtn" [disabled]="!f.valid || showMatchMessage"
          (click)="changePassword(f)">Save</button>
      </div>

    </div>
  </form>

</ng-template>

<!-- Force Change Password Modal -->
<ng-template #forceChangePass role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Force Change Password</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <form #f="ngForm">
    <div class="modal-body">

      <p>Are you sure you want to force change password to: <br> <strong>{{employee.firstName}}
          {{employee.lastName}}</strong> </p>

      <div class="form-group text-center">
        <!-- <button class="btn remove_Btn" (click)="ban()" >Ban</button> -->
        <button class="btn submitBtn" (click)="forceChangePassword()">Send Request</button>
      </div>

    </div>
  </form>

</ng-template>

<!-- Snap Picture Modal -->
<ng-template #snaPicture role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Upload A Picture</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <form #f="ngForm">
    <div class="modal-body">
      <div class="form-group">
        <label for="">Upload Profile Picture</label>
        <input type="file" class="form-control" name="empImage" ngModel required #empImage="ngModel"
          (change)="imageFileChange($event)">
        <span class="text-danger" *ngIf="!empImage.valid && empImage.touched">Please Enter A Valid Answer</span>
      </div>

      <div class="form-group text-center">
        <button class="btn submitBtn" [disabled]="!f.valid" (click)="uploadPicture()">Upload Picture</button>
      </div>

    </div>
  </form>

</ng-template>

<!-- Add Entitlement Modal -->
<ng-template #addEntitlement role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Add Entitlement</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <form #f_entitlement="ngForm">
    <div class="modal-body">

      <div class="form-group text-center">
        <label for="">Entitlement Name</label>
        <input type="text" class="form-control" name="entitlement_name" required #entitlement_name="ngModel" ngModel>
        <span class="text-danger" *ngIf="!entitlement_name.valid && entitlement_name.touched">Please Enter A Valid Answer</span>
      </div>
 

      <div class="form-group text-center">
        <button class="btn submitBtn" (click)="addEmp_entitlement(f_entitlement)">Add</button>
      </div>

    </div>
  </form>

</ng-template>

<!-- Add Shift Notes Modal -->
<ng-template #shiftNotes role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Shift Notes</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>

  <div class="row">
    <div class="col-12 col-lg-4">
      <form #f_notes="ngForm">
        <div class="modal-body">
    
          <div class="form-group">
            <label for="">Add Note</label>
            <input type="text" class="form-control" name="shift_note" required #shift_note="ngModel" ngModel>
            <span class="text-danger" *ngIf="!shift_note.valid && shift_note.touched">Please Enter A Valid Answer</span>
          </div>
     
    
          <div class="form-group">
            <button class="btn submitBtn" (click)="addNotes(f_notes)">Add</button>
          </div>
    
        </div>
      </form> 
    </div>
    <div class="col-12 col-lg-8">
      <div class="all_notes">
        <div *ngFor="let n of notes"> 
          <p>{{n.content}}</p> 
        </div>

      </div>
    </div>
  </div>
  

</ng-template>

<!-- Add Time Off Modal -->
<ng-template #add_timeOff role="document" let-modal> 
  <div class="modal-header">
    <h5 class="modal-title mt-0">Add Time Off</h5>
    <button type="button" class="btn-close" aria-hidden="true" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <form #f_timeOff="ngForm">
    <div class="modal-body"> 

      <div class="form-group ">
        <label for="">First Day Off</label>
        <input type="date" class="form-control" name="time_from" required #time_from="ngModel" ngModel>
        <span class="text-danger" *ngIf="!time_from.valid && time_from.touched">Please Enter A Valid Answer</span>
      </div>
      <div class="form-group ">
        <label for="">Return Date</label>
        <input type="date" class="form-control" name="time_to" required #time_to="ngModel" ngModel>
        <span class="text-danger" *ngIf="!time_to.valid && time_to.touched">Please Enter A Valid Answer</span>
      </div>
      <div class="form-group ">
        <label for="">Description</label>
        <input type="text" class="form-control" name="time_desc" required #time_desc="ngModel" ngModel>
        <span class="text-danger" *ngIf="!time_desc.valid && time_desc.touched">Please Enter A Valid Answer</span>
      </div> 
 

      <div class="form-group text-center">
        <button class="btn submitBtn" (click)="addTime_off(f_timeOff)">Create</button>
      </div>

    </div>
  </form>

</ng-template>