import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { AdminTabs } from '../core/enums/adminTabs';
import { AdminRole } from '../core/models/adminRole';

@Injectable()
export class PermissonsGuardService implements CanActivate {
  constructor(private router: Router) {}
  // retrievedObject :AdminRole ; 
  // currentRoute : any;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // this.retrievedObject = JSON.parse(localStorage.getItem('role'));

    // console.log(route.data.permission);
    
    return true;

  //   switch(route.data.permission as AdminTabs) { 
  //     case AdminTabs.users: { 
  //       if(this.retrievedObject.isShowUserTab){
  //         return true;
  //       } 
  //       else{
  //         this.router.navigate(['/unauthorized']);
  //         return false;
  //       }
  //     }
  //     case AdminTabs.adminUsers: { 
        
  //       if(this.retrievedObject.isShowAdminUserTab){
  //         return true;
  //       } 
  //       else{
  //         this.router.navigate(['/unauthorized']);
  //         return false;
  //       }
  //     }  
  //     case AdminTabs.adminRoles: { 
  //       if(this.retrievedObject.isShowAdminRolesTab){
  //         return true;
  //       } 
  //       else{
  //         this.router.navigate(['/unauthorized']);
  //         return false;
  //       }
  //     } 
  //     case AdminTabs.bookings: { 
  //       if(this.retrievedObject.isShowBookingTab){
  //         return true;
  //       } 
  //       else{
  //         this.router.navigate(['/unauthorized']);
  //         return false;
  //       }
  //     } 
  //     case AdminTabs.services: { 
  //       if(this.retrievedObject.isShowServicesTab){
  //         return true;
  //       } 
  //       else{
  //         this.router.navigate(['/unauthorized']);
  //         return false;
  //       } 
  //     } 
  //     case AdminTabs.facilities: { 
  //       if(this.retrievedObject.isShowFacilitiesTab){
  //         return true;
  //       }  
  //       else{
  //         this.router.navigate(['/unauthorized']);
  //         return false;
  //       }
  //     } 
  //     case AdminTabs.notifications: { 
  //       if(this.retrievedObject.isShowNotificationsTab){
  //         return true;
  //       } 
  //       else{
  //         this.router.navigate(['/unauthorized']);
  //         return false;
  //       }
  //     } 
  //     case AdminTabs.advertisements: { 
  //       if(this.retrievedObject.isShowAdsTab){
  //         return true;
  //       } 
  //       else{
  //         this.router.navigate(['/unauthorized']);
  //         return false;
  //       }
  //     } 
      
  //     default: { 
        
  //       return false;
        
  //     } 
  //  } 
   
    
  }
}
