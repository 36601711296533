
import {  Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router  } from '@angular/router';
import { DataService } from '../../../core/services/data.service';
import { RestApiService } from '../../../core/services/rest-api.service';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Zone } from 'src/app/core/models/zone';




@Component({
  selector: 'app-zone-details',
  templateUrl: './zone-details.component.html',
  styleUrls: ['./zone-details.component.scss']
})
export class ZoneDetailsComponent implements  OnInit {
  constructor(private route: ActivatedRoute, private rest: RestApiService, private data: DataService, private modalService: NgbModal, private router: Router) { }

  ngOnInit(): void {
    const userId = this.route.snapshot.paramMap.get('id');
    console.log(userId);
    this.zoneId = userId;
    this.getZoneDetails(userId);
    this.getPositions(userId);
    this.getSites(userId);
    this.getUnassignedSites(userId);
    this.getRequirements(userId);
    this.getBreakRules(userId);
    this.getRunSheets(userId);
    this.getRunSheetsAssignments(userId);
  }

  

  
  dtOptions: any = {
    paging: false,
    lengthChange: false,
    info: false,
    searching: true,
  };


  loading:boolean = true;
  
  zoneId:string = "";
  showContent:boolean = false;
  showDetailsContent:boolean = true;
  showPositionsContent:boolean = false;
  showSitesContent:boolean = false;
  showRunSheetContent:boolean = false;


  tempService:boolean = false;

  showDetails() {
    this.showDetailsContent = true;
    this.showPositionsContent = false;
    this.showSitesContent = false;
    this.showRunSheetContent = false;
  }

  showPositions() {
    this.showPositionsContent = true;
    this.showSitesContent = false;
    this.showDetailsContent = false;
    this.showRunSheetContent = false;
  }
  showSites() {
    this.showPositionsContent = false;
    this.showDetailsContent = false;
    this.showSitesContent = true;
    this.showRunSheetContent = false;
  }

  showRunSheets() {
    this.showPositionsContent = false;
    this.showDetailsContent = false;
    this.showSitesContent = false;
    this.showRunSheetContent = true;

  }


  positions: any[] =[];
  sites: any[] =[];
  zoneDetails: Zone;
  unassignedSites: any[] =[];
  requirements: any[] =[];
  breakRules: any[] =[];
  runSheets: any[] =[];
  runSheetsAssignment: any[] =[];

  async getZoneDetails(zoneId:string){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/zones/${zoneId}`
      ).subscribe(
        (res: any) => {
          this.zoneDetails = res;
          console.log(res);
          this.loading = false;
          this.showContent = true;
        },
        (error: any) => {
          console.log(error);
      });
  
    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;
  
    }
  }
  async getPositions(zoneId:string){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/zones/${zoneId}/positions`
      ).subscribe(
        (res: any) => {
          this.positions = res;
          console.log(this.positions);
          
        },
        (error: any) => {
          console.log(error);
      });
  
    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;
  
    }
  }

  async getSites(zoneId:string){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/zones/${zoneId}/sites`
      ).subscribe(
        (res: any) => {
          this.sites = res;
        },
        (error: any) => {
          console.log(error);
      });
  
    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;
  
    }
  }

  async getRequirements(zoneId:string){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/zones/${zoneId}/positions/requirements`
      ).subscribe(
        (res: any) => {
          this.requirements = res;
        },
        (error: any) => {
          console.log(error);
      });
  
    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;
  
    }
  }

  async getBreakRules(zoneId:string){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/zones/${zoneId}/positions/break-rules`
      ).subscribe(
        (res: any) => {
          this.breakRules = res;
        },
        (error: any) => {
          console.log(error);
      });
  
    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;
  
    }
  }

  

  async getUnassignedSites(zoneId:string){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/zones/${zoneId}/unassigned-sites`
      ).subscribe(
        (res: any) => {
          this.unassignedSites = res;
        },
        (error: any) => {
          console.log(error);
      });
  
    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;
  
    }
  }

  async deletePosition(id: string){

    try {

      await this.rest.deleteWithId(
        `${this.data.getBaseUrl()}api/v1/zones/${this.zoneId}/positions/${id}`


        ).subscribe(
        (res: any) => {
          window.location.reload();
        } ,
      (error : any) => {
        console.log("Error")
      }
    );

    } catch (error) {
      this.data.error(error['message']);
    }
  }
  

  openModal(content: any, modalSize:string) {
    this.modalService.open(content,
       { centered: true, 
          size: modalSize,
          backdrop: 'static', 
          windowClass: '' 
        });
    
  }

  async assignSite(f: NgForm){
    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/zones/${this.zoneId}/sites/${f.value.unassignedSelect}`,{}).subscribe( 
       (res: any) => {
        // this.modalService.dismissAll()
        // this.getSites(this.zoneId);
         window.location.reload();
       } ,
      (error : any) => {

      console.log(error.error);
      });


   } catch (error) {
     this.data.error(error['message']);
     console.log("error"+error);
     
   }
  }


  onViewSite(siteId: number) {
    this.router.navigate(['/viewSite', siteId]);
  }


  async addPosition(f: NgForm){
    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/zones/${this.zoneId}/positions`, 

       {
          name: f.value.postName,
          internalId: f.value.postId,
          description: f.value.shortDesc,
          scheduleMemo: f.value.memo,
          status: f.value.postStatus,
          serviceStartDate: f.value.beginDate,
          serviceEndDate: f.value.endDate,
          breakRuleId: f.value.breakRule,
          hardRequirements: f.value.hardReq,
          conditionalRequirements: f.value.condReq,
          softRequirements: f.value.softReq,

       }).subscribe( 
       (res: any) => {

         window.location.reload();

       } ,
      (error : any) => {

        console.log(error.error);
      });


   } catch (error) {
     this.data.error(error['message']);
     console.log("error"+error);
     
   }
  }


  async getRunSheets(zoneId:string){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/zones/${zoneId}/run-sheets`
      ).subscribe(
        (res: any) => {
          this.runSheets = res;
        },
        (error: any) => {
          console.log(error);
      });
  
    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;
  
    }
  }

  // Days Select 

  
  selectedDays = [];
  daysError: any;
  loading_sheet: boolean = false;
  loading_optimize: boolean = false;
  optimizingResults: boolean = false;

  days: any = [
    
    {
      id: 1,
      name: 'Monday'
    },
    {
      id: 2,
      name: 'Tuesday'
    },
    {
      id: 3,
      name: 'Wednesday'
    },
    {
      id: 4,
      name: 'Thursday'
    },
    {
      id: 5,
      name: 'Friday'
    },
    {
      id: 6,
      name: 'Saturday'
    },
    {
      id: 0,
      name: 'Sunday'
    },
  ];

  toggleDay(day) {
    if (this.isSelectedDay(day)) {
      this.selectedDays = this.selectedDays.filter(id => id !== day.id);
    } else {
      this.selectedDays.push(day.id);
    }

  }

  isSelectedDay(day) {
    return this.selectedDays.includes(day.id);
  }

  toggleAllDays(event) {
    if (event.target.checked) {
      // If "All Days" checkbox is checked, add all days to selectedDays
      this.selectedDays = this.days.map(day => day.id);
    } else {
      // If "All Days" checkbox is unchecked, remove all days from selectedDays
      this.selectedDays = [];
    }

    this.days.forEach(day => {
      const checkbox = document.getElementById(day.id.toString()) as HTMLInputElement;
      checkbox.checked = event.target.checked;
    });
    
  }


  async addRunSheet(f: NgForm){
    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/zones/${this.zoneId}/run-sheets`, 

       {
          positionId: f.value.runPosition,
          timeFrom: f.value.sheetBeginTime,
          timeTo: f.value.sheetEndTime,
          days: this.selectedDays,
          startDate: f.value.sheetBeginDate,
          description: f.value.customDesc,

       }).subscribe( 
       (res: any) => {

         window.location.reload();

       } ,
      (error : any) => {

        console.log(error.error);
      });


   } catch (error) {
     this.data.error(error['message']);
     console.log("error"+error);
     
   }
  }

  async getRunSheetsAssignments(zoneId:string){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/zones/${zoneId}/run-sheets/assignments`
      ).subscribe(
        (res: any) => {
          this.runSheetsAssignment = res;
        },
        (error: any) => {
          console.log(error);
      });
  
    } catch (error) {
      console.log("error"+ error);
      this.data.error(error['message']);
      // this.loading = true;
  
    }
  }

  async assginRunSheet(runId:number, shceduleId: number){
    this.loading_sheet= true;
    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/zones/${this.zoneId}/run-sheets/assignments`, 

       {
          scheduleId: shceduleId,
          runsheetId: runId,

       }).subscribe( 
       (res: any) => {
        this.loading_sheet= false;
        
       } ,
      (error : any) => {

        console.log(error.error);
      });


   } catch (error) {
     this.data.error(error['message']);
     console.log("error"+error);
     
   }
  }

  async autoAssginRunSheet(){
    this.loading_sheet= true;
    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/zones/${this.zoneId}/run-sheets/auto-assign`, 

       {}

      ).subscribe( 
       (res: any) => {
        this.loading_sheet= false;
        this.runSheetsAssignment = res;
       } ,
      (error : any) => {

        console.log(error.error);
      });


   } catch (error) {
     this.data.error(error['message']);
     console.log("error"+error);
     
   }
  }

  // Run Sheet Optimization

  optSheetID: number;
  openOptimizeModal(content: any, modalSize:string, runSheetId: number) {
    this.modalService.open(content,
       { centered: true, 
          size: modalSize,
          backdrop: 'static', 
          windowClass: '' 
        });
    this.optSheetID = runSheetId;
    this.optimizingResults = false;
    this.loading_optimize = false;
  }

  optimizeResult: any;
  driveTimeH: any;
  driveTimeM: any;
  async optimizeRunsheet(){
    this.loading_optimize= true;
    this.optimizingResults= false;
    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/zones/${this.zoneId}/run-sheets/${this.optSheetID}/optimize`, 

       {}
      ).subscribe( 
       (res: any) => {
        this.loading_optimize= false;
        this.optimizingResults = true;
        this.optimizeResult = res;
        // console.log(this.optimizeResult);
       } ,
      (error : any) => {

        console.log(error.error);
      });


   } catch (error) {
     this.data.error(error['message']);
     console.log("error"+error);
     
   }
  }

  onViewOptimize(zoneId: string, runsheetId: number) {
    this.modalService.dismissAll();
    this.router.navigate(['/zones/zoneDetails', zoneId, 'optimization', runsheetId]);
  }

}
