import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup, NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { Calendar, CalendarOptions, EventInput } from '@fullcalendar/core';
import { DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/core';

import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

import { category, calendarEvents, createEventId } from './data';

import Swal from 'sweetalert2';
import { RestApiService } from 'src/app/core/services/rest-api.service';
import { DataService } from 'src/app/core/services/data.service';
import { Schedule } from 'src/app/core/models/schedule';
import { SiteEmployee } from 'src/app/core/models/siteEmployee';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  // bread crumb items
  breadCrumbItems: Array<{}>;

  @ViewChild('modalShow') modalShow: TemplateRef<any>;
  @ViewChild('editmodalShow') editmodalShow: TemplateRef<any>;

  formEditData: FormGroup;
  submitted = false;
  category: any[];
  newEventDate: any;
  editEvent: any;
  calendarEvents: any[];
  // event form
  formData: FormGroup;
  calendarOptions: CalendarOptions;
  
  testShiftDays : [{
    day: string,
    timeFrom: string,
    timeTo: string,
    employee: {
        id: number,
        first_name: string,
        last_name: string,
    }
}]

  schedulerDetails: Schedule;
  fetchError = false;
  loading = true;
  displayContent = false;
  errorContent = false;
  siteId: any;
  scheduleId: any;

  shiftBreakError: any;
  timeFromError: any;
  timeToError: any;
  dailyShiftsError: any;
  daysError: any;
  cycleDaysError: any;
  employeeIDError: any;
  daysMessageError: any;

  selectedDays = [];
  Employees: SiteEmployee[] = [];


  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.siteId = +params.get('siteId');       // Access siteId from the URL
      this.scheduleId = +params.get('scheduleId'); // Access scheduleId from the URL

      this.getSchedulerDetailsById();
    });

    this.formData = this.formBuilder.group({
      title: ['', [Validators.required]],
      category: ['', [Validators.required]],
    });

    this.formEditData = this.formBuilder.group({
      editTitle: ['', [Validators.required]],
      editCategory: [],
    });
    this._fetchData();

    this.getSiteEmployees();
  }
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private rest: RestApiService,
    private data: DataService,
  ) {
    const name = Calendar.name;
  }
  

  async getSiteEmployees(){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/sites/${this.siteId}/employees`
      ).subscribe( 
        (res: any) => {
          
          this.Employees = res;
          
        } ,
       (error : any) => {
        
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getSchedulerDetailsById(){
    try {
      await this.rest.getAll(
        `${this.data.getBaseUrl()}api/v1/sites/${this.siteId}/schedulers/${this.scheduleId}`
      ).subscribe( 
        (res: any) => {
          this.fetchError = false;
          this.schedulerDetails = res;
          console.log(this.schedulerDetails);
          this.loading = false;
          if(this.schedulerDetails.shifts){
            this.testShiftDays = this.schedulerDetails.shifts.shiftDays;
          }
          
          this.setOptions();
          
          this.displayContent = true;

        },
        (error: any) => {
          this.fetchError = true;
          this.displayContent = false;
          this.loading = true;
       });

    } catch (error) {
      console.log("error Zones"+ error);
      this.data.error(error['message']);
      // this.loading = true;

    }
  }
  calendarTest: EventInput[]=[];
  
  eventGuid: number;
  createEventId() {
    this.eventGuid = 0;
      return String(this.eventGuid++);
  }

  setEmps(){
    if(this.testShiftDays){
      for (let i = 0; i < this.testShiftDays.length; i++) {
        const shiftDay = this.testShiftDays[i];
        this.calendarTest.push({
          id: this.createEventId(),
          title: shiftDay.employee.first_name + ' ' + shiftDay.employee.last_name,
          start: shiftDay.day + 'T' + shiftDay.timeFrom,
          end: shiftDay.day + 'T' + shiftDay.timeTo,
          className: 'bg-warning',
        });
      }
    }
    else if (!this.testShiftDays) {
      this.calendarTest = []
    }
    
  }

  setOptions(){
    this.setEmps();

    this.calendarOptions = {
      plugins: [
        interactionPlugin,
        dayGridPlugin,
        timeGridPlugin,
        listPlugin,
      ],
      headerToolbar: {
        left: 'dayGridMonth,dayGridWeek,dayGridDay',
        center: 'title',
        right: 'prevYear,prev,next,nextYear'
      },
  
      initialView: "dayGridMonth",
      initialDate: this.schedulerDetails.beginDate, // Set the initial date
      validRange: {
        start: this.schedulerDetails.beginDate, // Set the start date
        end: this.schedulerDetails.endDate,   // Set the end date
      },
      themeSystem: "bootstrap",
      initialEvents: this.calendarTest,
      weekends: true,
      editable: false,
      selectable: false,
      selectMirror: true,
      dayMaxEvents: true,
      displayEventTime: true,
      displayEventEnd: true,
      dateClick: this.openModal.bind(this),
      // eventClick: this.handleEventClick.bind(this),
      // eventsSet: this.handleEvents.bind(this),
      eventTimeFormat: { // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        meridiem: 'narrow',
        hour12: false
      }
    };

  }

  
  currentEvents: EventApi[] = [];


  days: any = [
    {
      id: 0,
      name: 'Sunday'
    },
    {
      id: 1,
      name: 'Monday'
    },
    {
      id: 2,
      name: 'Tuesday'
    },
    {
      id: 3,
      name: 'Wednesday'
    },
    {
      id: 4,
      name: 'Thursday'
    },
    {
      id: 5,
      name: 'Friday'
    },
    {
      id: 6,
      name: 'Saturday'
    },
  ];

  toggleDay(day) {
    if (this.isSelected(day)) {
      this.selectedDays = this.selectedDays.filter(id => id !== day.id);
    } else {
      this.selectedDays.push(day.id);
    }

    console.log(this.selectedDays);
  }

  isSelected(day) {
    return this.selectedDays.includes(day.id);
  }
  

  async addShiftDetails(f: NgForm){
    
    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/sites/${this.siteId}/schedulers/${this.scheduleId}/shifts`,

       {
        breakInMinutes: f.value.shiftBreak,
        timeFrom: f.value.shiftTimeFrom,
        timeTo: f.value.shiftTimeTo,
        dailyShifts: f.value.dailyShifts,
        cycleDays: parseInt(f.value.shiftCycleDays, 10),
        days: this.selectedDays

       }
       
       ).subscribe( 
       (res: any) => {

         f.reset();
         window.location.reload();
       } ,
      (error : any) => {
        error.error.errors.forEach(err => {
          switch (err.field) {
            case 'breakInMinutes':
              this.shiftBreakError = err.message;
              break;

            case 'timeFrom':
              this.timeFromError = err.message;
              break;

            case 'timeTo':
              this.timeToError = err.message;
              break;

            case 'dailyShifts':
              this.dailyShiftsError = err.message;
              break;

              case 'days':
              this.daysError = err.message;
              break;

              case 'cycleDays':
              this.cycleDaysError = err.message;
              break;
            

            // Add more cases for other fields if needed
          }
        });
      }
    );

   } catch (error) {
     this.data.error(error['message']);
   }
  }
  

  /**
   * Event click modal show
   */
  handleEventClick(clickInfo: EventClickArg) {
    this.editEvent = clickInfo.event;
    this.formEditData = this.formBuilder.group({
      editTitle: clickInfo.event.title,
      editCategory: clickInfo.event.classNames[0],
    });
    this.modalService.open(this.editmodalShow);
  }

  /**
   * Events bind in calander
   * @param events events
   */
  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }

  

  get form() {
    return this.formData.controls;
  }

  /**
   * Delete-confirm
   */
  confirm() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this.deleteEventData();
        Swal.fire('Deleted!', 'Event has been deleted.', 'success');
      }
    });
  }

  position() {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Event has been saved',
      showConfirmButton: false,
      timer: 1000,
    });
  }

  /**
   * Event add modal
   */
  openModal(event?: any) {
    this.newEventDate = event;
    console.log(this.newEventDate.date);
    this.modalService.open(this.modalShow,
      { centered: true, 
         size: 'lg', 
         windowClass: 'detailsModal' 
       });
  }

  /**
   * save edit event data
   */
  editEventSave() {
    const editTitle = this.formEditData.get('editTitle').value;
    const editCategory = this.formEditData.get('editCategory').value;
    
    const editId = this.calendarEvents.findIndex(
      (x) => x.id + '' === this.editEvent.id + ''
    );

    this.editEvent.setProp('title', editTitle);
    this.editEvent.setProp('classNames', editCategory);

    this.calendarEvents[editId] = {
      ...this.editEvent,
      title: editTitle,
      id: this.editEvent.id,
      classNames: editCategory + ' ' + 'text-white',
    };

    this.position();
    this.formEditData = this.formBuilder.group({
      editTitle: '',
      editCategory: '',
    });
    this.modalService.dismissAll();
  }

  /**
   * Delete event
   */
  deleteEventData() {
    this.editEvent.remove();
    this.modalService.dismissAll();
  }

  /**
   * Close event modal
   */
  closeEventModal() {
    this.formData = this.formBuilder.group({
      title: '',
      category: '',
    });
    this.modalService.dismissAll();
  }

  /**
   * Save the event
   */
  // AssignEmployee(f: NgForm) {
  //   if (this.formData.valid) {
  //     const title = this.formData.get('title').value;
  //     const className = this.formData.get('category').value;
  //     const calendarApi = this.newEventDate.view.calendar;
  //     calendarApi.addEvent({
  //       id: createEventId(),
  //       title,
  //       start: this.newEventDate.date,
  //       end: this.newEventDate.date,
  //       className: className + ' ' + 'text-white'
  //     });
  //     this.position();
  //     this.formData = this.formBuilder.group({
  //       title: '',
  //       category: '',
  //     });
  //     this.modalService.dismissAll();
  //   }
  //   this.submitted = true;
  // }

  async AssignEmployee(f: NgForm) {

    const datePipe = new DatePipe('en-US');
    this.newEventDate.date = datePipe.transform(this.newEventDate.date, 'yyyy-MM-dd');
  
    try {

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/sites/${this.siteId}/schedulers/${this.scheduleId}/shifts/update`,
  
       {
        day: this.newEventDate.date,
        timeFrom: f.value.empTimeFrom,
        timeTo: f.value.empTimeTo,
        employeeId: f.value.shiftEmpId
  
       }
       
       ).subscribe( 
       (res: any) => {
  
         f.reset();
         window.location.reload();
       } ,
      (error : any) => {
        error.error.errors.forEach(err => {
          switch (err.field) {
            case 'employeeId':
              this.employeeIDError = err.message;
              break;
            case 'day':
              this.daysMessageError = err.message;
              break;

            // Add more cases for other fields if needed
          }
        });
      }
    );
  
   } catch (error) {
     this.data.error(error['message']);
   }
  }

 

  /**
   * Fetches the data
   */
  private _fetchData() {
    // Event category
    this.category = category;
    // Calender Event Data
    this.calendarEvents = calendarEvents;
    // form submit
    this.submitted = false;
  }


  openShiftModal(content: any) {
    
    this.modalService.open(content,
       { centered: true, 
          size: 'lg', 
          windowClass: 'detailsModal' 
        });

        
  }

  closeShiftModal() {
    this.daysMessageError= '';
    this.modalService.dismissAll();
  }
  

}
