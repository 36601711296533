import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/core/services/data.service';
import { RestApiService } from 'src/app/core/services/rest-api.service';
import { AddSiteService } from 'src/app/core/services/addSite.service';
import { NgForm } from '@angular/forms';
import { Role } from 'src/app/core/models/role';
import { Zone } from 'src/app/core/models/zone';
import { Country } from 'src/app/core/models/country';
import { City } from 'src/app/core/models/city';
import { Area } from 'src/app/core/models/area';
import { Employee } from 'src/app/core/models/employee';
import { ActivatedRoute, Router } from '@angular/router';
import { TimeZone } from 'src/app/core/models/timeZone';
import { Language } from 'src/app/core/models/language';
import { Site } from 'src/app/core/models/site';
import { SiteType } from 'src/app/core/models/siteType';
declare var google: any;

@Component({
  selector: 'app-edit-site',
  templateUrl: './edit-site.component.html',
  styleUrls: ['./edit-site.component.scss']
})
export class EditSiteComponent implements OnInit {
  @ViewChild('f') AddZoneForm !: NgForm;
  @ViewChild('reset') resetPassForm !: NgForm;


  loading = true;
  loadingCont = true;
  fetchError = false;
  itemAdded = false;
  itemDeleted = false;
  Sites: Site[] = [];
  Roles: Role[] = [];
  Zones: Zone[] = [];
  Companies: any[] = [];
  Countries: Country[] = [];
  Cities: City[] = [];
  Areas: Area[] = [];
  billCities: City[] = [];
  billAreas: Area[] = [];
  TimeZones: TimeZone[] = [];
  Languages: Language[] = [];
  SiteTypes: SiteType[] = [];

  displayTable: boolean = false;

  selectedId = '';
  formPassword = '';
  formConfirmPassword = '';
  resetFormPassword = '';
  resetFormConfirmPassword = '';
  validPassword: boolean = false;
  showMatchMessage: boolean = false;
  successMessage = '';
  errorMessage = '';
  Employees: Employee[] = [];
  selectedZones = [];
  company_picture : File;
  urlToUpload = '';

  selectedEditId = '';

  selectedSiteType: string;

  // Error handling variables
  accountErrorMessage="";
  FNameErrorMessage="";
  lNameErrorMessage="";
  emailErrorMessage="";
  mobileErrorMessage="";
  jobTitleErrorMessage="";
  internalIdErrorMessage="";
  siteInternalIdErrorMessage
  CompTimeZoneErrorMessage="";
  CompNameErrorMessage="";
  CompLogoErrorMessage="";
  CompLanguageErrorMessage="";
  addressErrorMessage="";
  countryErrorMessage="";
  cityErrorMessage="";
  areaErrorMessage="";
  zipCodeErrorMessage="";
  companyIdErrorMessage="";

  selectedEditSite: Site;
  isCompId: boolean = false;

  billAddressErrorMessage="";
  billCountryErrorMessage="";
  billCityErrorMessage="";
  billAreaErrorMessage="";
  billZipCodeErrorMessage="";

  billSameAddressValue: boolean = false;
  
  
  constructor(private route: ActivatedRoute, private rest: RestApiService, private data: DataService, private modalService: NgbModal, private router: Router, private imgUploadService: AddSiteService ) { }


  ngOnInit(): void {
    const userId = this.route.snapshot.paramMap.get('id');
    // console.log(userId);
    this.getSiteInfo(userId);
    this.getZones();
    this.getCountries();
    this.getTimeZones();
    this.getLanguages();
    this.getSiteTypes();
    this.getCompanies();
    
  }

  async getSiteInfo(id: string){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/sites/${id}`
      ).subscribe(
        (res: any) => {
          // this.successMessage = res.message;
          this.selectedEditSite = res;
          this.billSameAddressValue = res.sameBillingAddress;
          this.selectedZones = res.zones.map(zone => zone.id);
          this.getCities(res.country.id);
          this.getAreas(res.city.id);
          // this.getBillCities(res.billingCountry.id);
          // this.getBillAreas(res.billingCity.id);
          setTimeout(() => {
            this.initMap();
          }, 100);
          this.fetchError = false;
          this.loadingCont = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loadingCont = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  
  isZoneSelected(zoneId: string): boolean {
    return this.selectedEditSite.zones.some(zone => zone.id === zoneId);
  }

  updateIsCompId() {
    this.isCompId = this.selectedEditSite.type.id === '3';
    console.log(this.isCompId);
    console.log(this.selectedEditSite.type.id);
  }

  async getCompanies(){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/companies`
      ).subscribe(
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.Companies = res;
          this.loading = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getZones(){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/zones?all=true`
      ).subscribe(
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.Zones = res.data;
          this.loading = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getCountries(){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/countries`
      ).subscribe(
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.Countries = res;
          this.loading = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getCities(id: string){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/cities/${id}`
      ).subscribe(
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.Cities = res;
          this.loading = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getAreas(id: string){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/areas/${id}`
      ).subscribe(
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.Areas = res;
          this.loading = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getBillCities(id?: string){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/cities/${id}`
      ).subscribe(
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.billCities = res;
          this.loading = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getBillAreas(id: string){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/areas/${id}`
      ).subscribe(
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.billAreas = res;
          this.loading = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  onChangeCountry(selectedValue: string) {
    this.getCities(selectedValue);
    this.Areas = [];
  }

  onChangeCity(selectedValue: string) {
    this.getAreas(selectedValue);
  }

  onChangeBillCountry(selectedValue: string) {
    this.getBillCities(selectedValue);
    this.billAreas = [];
  }

  onChangeBillCity(selectedValue: string) {
    this.getBillAreas(selectedValue);
  }

  async getSiteTypes(){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/site-types`
      ).subscribe(
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.SiteTypes = res;
          this.loading = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getTimeZones(){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/timezones`
      ).subscribe(
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.TimeZones = res;
          this.loading = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  async getLanguages(){
    try {
      await this.rest.getAllUsers(
        `${this.data.getBaseUrl()}api/v1/languages`
      ).subscribe(
        (res: any) => {
          // this.successMessage = res.message;
          this.fetchError = false;
          this.Languages = res;
          this.loading = false;
        } ,
       (err) => {
        // this.errorMessage= err.message;
        this.fetchError = true;
        this.loading = true;
       });

    } catch (error) {
      this.loading = true;
      this.data.error(error['message']);
    }

  }

  toggleZone(zone) {
    if (this.isSelected(zone)) {
      this.selectedZones = this.selectedZones.filter(id => id !== zone.id);
    } else {
      this.selectedZones.push(zone.id);
      console.log(this.selectedZones);
    }
  }

  isSelected(zone) {
    return this.selectedZones.includes(zone.id);
  }

  imageFileChange(event: any) {
    this.company_picture = event.target.files[0];
    // console.log(this.company_picture);
    // this.makeFileUrl(this.company_picture);
    this.createFormData(this.company_picture);
  }

  createFormData(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    console.log(formData);
    this.makeFileUrl(formData);

  }

  async makeFileUrl(file: FormData) {

    try {
      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/upload-file`,file

       ).subscribe(
       (res: any) => {
         this.fetchError = false;
         console.log(res);
         this.urlToUpload = res.path;
       } ,
      (error : any) => {
        console.log(error);
      }
    );

   } catch (error) {
     this.data.error(error['message']);
   }
  }

  async updateSite(f: NgForm){

    try {

      const requestObject = {
        // typeId: f.value.siteType,
        // internalId: f.value.siteInternalId,
        notes: f.value.siteNotes,
        location: this.siteGeo,
        contact: {
          firstName: f.value.contactFirstName,
          lastName: f.value.contactLastName,
          email: f.value.contactEmail,
          mobile: f.value.contactMobile,
          jobTitle: f.value.contactJobTitle,
        },
        address: {
          countryId: f.value.siteCountry,
          cityId: f.value.siteCity,
          areaId: f.value.siteArea,
          zipcode: f.value.siteZipCode,
          address: f.value.siteAdressStreet,
        },
        zones: this.selectedZones,
        sameBillingAddress: f.value.billSameAddress,
      };

      if (this.isCompId) {
        requestObject['companyId'] = f.value.companyId;
      } else {
        if (this.urlToUpload) {
          requestObject['company'] = {
            name: f.value.companyName,
            internalId: f.value.companyInternalId,
            timezone: f.value.companyTimeZone,
            logo: this.data.getBaseUrlOfImg() + this.urlToUpload,
            language: f.value.companyLang,
          };
        }else{
          requestObject['company'] = {
            name: f.value.companyName,
            internalId: f.value.companyInternalId,
            timezone: f.value.companyTimeZone,
            logo: this.selectedEditSite.company.logo,
            language: f.value.companyLang,
          };
        }

        // requestObject['internalId'] = f.value.siteInternalId;
      }

      if (!this.billSameAddressValue) {
        requestObject['billingAddress'] = {
          countryId: f.value.billCountry,
          cityId: f.value.billCity,
          areaId: f.value.billArea,
          zipcode: f.value.billZipCode,
          address: f.value.billAdressStreet,
        };
      }

      await this.rest.post(
       `${this.data.getBaseUrl()}api/v1/sites/${this.selectedEditSite.id}`,requestObject


       ).subscribe(
       (res: any) => {
         this.fetchError = false;
         f.reset();
         this.urlToUpload = "";
         window.location.reload();
       } ,
      (error : any) => {
        error.error.errors.forEach(err => {
          switch (err.field) {
            case 'type':
              this.accountErrorMessage = err.message;
              break;
            case 'company.name':
              this.CompNameErrorMessage = err.message;
              break;
              case 'companyId':
                this.companyIdErrorMessage = err.message;
                break;
            case 'internalId':
              this.internalIdErrorMessage = err.message;
              break;
            case 'company.timezone':
              this.CompTimeZoneErrorMessage = err.message;
              break;
            case 'company.logo':
              this.CompLogoErrorMessage = err.message;
              break;

            case 'company.language':
              this.CompLanguageErrorMessage = err.message;
              break;
            case 'address.countryId':
              this.countryErrorMessage = err.message;
              break;
            case 'address.cityId':
              this.cityErrorMessage = err.message;
              break;

            case 'address.areaId':
              this.areaErrorMessage = err.message;
              break;
            case 'address.address':
              this.addressErrorMessage = err.message;
              break;
              case 'address.zipcode':
              this.zipCodeErrorMessage = err.message;
              break;
            case 'contact.firstName':
              this.FNameErrorMessage = err.message;
              break;
            case 'contact.lastName':
              this.lNameErrorMessage = err.message;
              break;
            case 'contact.email':
              this.emailErrorMessage = err.message;
              break;
            case 'contact.mobile':
                this.mobileErrorMessage = err.message;
                break;
            case 'contact.jobTitle':
              this.jobTitleErrorMessage = err.message;
              break;

            case 'company.internalId':
              this.siteInternalIdErrorMessage = err.message;
              break;
            // Add more cases for other fields if needed
          }
        });

      }
    );

   } catch (error) {
     this.data.error(error['message']);
   }
  }





  // add site location
  siteGeo: string = "";
  @ViewChild('mapElement', {static: false}) mapElement: ElementRef;

  // ngAfterViewInit() {
  //   this.initMap();
  // }

  site_location: string="";
  initMap() {
    var mapOptions: any;
    var map: any;
    var location: any;
    var drawingManager: any;
    var coordinates: any[] = [];
    var selectedShape: any;
    var new_coordinates: any[] = []
    var lastElement: any;
    if(this.selectedEditSite.location){
      this.site_location = this.selectedEditSite.location;
    }else{
      this.site_location = "30.045282111816455,31.230016482138172";
    }

    let [lat, lang] = this.selectedEditSite.location.split(',');

    // Create an object with properties lat and lang
    let site_location = {
      lat: parseFloat(lat),
      lang: parseFloat(lang)
    };

    location = new google.maps.LatLng(site_location.lat, site_location.lang);
    mapOptions = {
        zoom: 14,
        center: new google.maps.LatLng(site_location.lat, site_location.lang),
        mapTypeId: google.maps.MapTypeId.RoadMap
    };

    map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);

    const marker = new google.maps.Marker({
      position: { lat: site_location.lat, lng: site_location.lang },
      map: map,
      // title: 'Hello World!'
    });

    var all_overlays: any[] = [];
    // var selectedShape;
    drawingManager = new google.maps.drawing.DrawingManager({
        // drawingMode: google.maps.drawing.OverlayType.MARKER,
        drawingControl: true,
        drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
              google.maps.drawing.OverlayType.MARKER,
            ]
        },
        markerOptions: {
            //icon: 'images/beachflag.png'
            clickable: false,
            draggable: true,
        },

    });


    function clearSelectionMarker() {
      if (selectedShape) {
        selectedShape = null;
      }
    }

    function stopDrawing() {
      drawingManager.setMap(null);
    }

    function setSelectionMarker(shape:any) {
      clearSelectionMarker();
      stopDrawing();
      selectedShape = shape;
    }

    function deleteSelectedShape() {
        if (selectedShape) {
          selectedShape.setMap(null);
          drawingManager.setMap(map);
          coordinates.splice(0, coordinates.length);
        }
    }

    function CenterControl(controlDiv:any, map:any) {

        // Set CSS for the control border.
        var controlUI = document.createElement('div');
        controlUI.style.backgroundColor = '#fff';
        controlUI.style.border = '2px solid #fff';
        controlUI.style.borderRadius = '3px';
        controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
        controlUI.style.cursor = 'pointer';
        controlUI.style.marginBottom = '22px';
        controlUI.style.textAlign = 'center';
        controlUI.title = 'Select to delete the shape';
        controlDiv.appendChild(controlUI);

        // Set CSS for the control interior.
        var controlText = document.createElement('div');
        controlText.style.color = 'rgb(25,25,25)';
        controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
        controlText.style.fontSize = '16px';
        controlText.style.lineHeight = '38px';
        controlText.style.paddingLeft = '5px';
        controlText.style.paddingRight = '5px';
        controlText.innerHTML = 'Delete Selected Area';
        controlUI.appendChild(controlText);

        //to delete the polygon
        controlUI.addEventListener('click', function () {
            deleteSelectedShape();
        });
    }


    const getMarkerCoords = (marker: any) => {
      var position = marker.getPosition();
      var lat = position.lat();
      var lng = position.lng();
      var siteGeoLocation = lat + ","+ lng;
      console.log(siteGeoLocation);
      this.siteGeo = siteGeoLocation;
      // Store the coordinates in a variable or perform any other actions needed
    }

    drawingManager.setMap(map);

    google.maps.event.addListener(drawingManager, 'markercomplete', function (marker) {
      google.maps.event.addListener(marker, 'click', function () {
        setSelectionMarker(marker);
        getMarkerCoords(marker);
      });
      google.maps.event.addListener(marker, "dragend", getMarkerCoords(marker));
      setSelectionMarker(marker);
      getMarkerCoords(marker);

    });

    var centerControlDiv = document.createElement('div');
    var centerControl = new CenterControl(centerControlDiv, map);


    // centerControlDiv.index = 1;
    map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(centerControlDiv);

  }

}
